import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DocumentsService } from 'src/app/services/documents.service';
import { GlobalService } from 'src/app/services/global.service';
import { UserDocumentsDetailComponent } from './user-documents-detail/user-documents-detail.component';

@Component({
  selector: 'app-user-documents',
  templateUrl: './user-documents.component.html',
  styleUrls: ['./user-documents.component.css']
})
export class UserDocumentsComponent implements OnInit {
  driver_license_selected: any;
  selfie_selected: any;
  student_license_selected: any;
  user_documents: any = [{id:1, name:"", phone:"", driver_license:"",selfie:"",student_license:""}];

  displayedColumns: Array<string> = ["id", "name", "phone", "driver_license", "selfie","student_license"];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  current_page = 0
  pageEvent: PageEvent;


  constructor(
    private documentService: DocumentsService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.get_documents();
  }

  get_documents(): void {
    this.user_documents = null;
    this.documentService.getDocuments({
      "driver_license": this.driver_license_selected || '',
      "selfie": this.selfie_selected || '',
      "student_license": this.student_license_selected  || ''
    }, (++this.current_page).toString(), this.pageSize.toString()).subscribe(result => {
      this.user_documents = result.data.result;
      this.length = result.data.count;
    }, this.globalService.handleError);
  }

  select_change(): void {
    this.current_page = 0;
    this.get_documents();
  }

  getResPagination(event:PageEvent): PageEvent {
    this.current_page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.get_documents();
    return event;
  }

  tableClick(user_data: any): void {
    this.dialog.open(UserDocumentsDetailComponent, {
      width: "90%",
      height: "90%",
      data: user_data
    }).afterClosed().subscribe(result => {
      user_data = result;
      
    });
  }

}
