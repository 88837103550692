<div class="">
    <div class="">
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                Vehicle Groups
                <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger"
                    (click)="addVehicleGroup()"><mat-icon>add_circle</mat-icon></button>
            </div>
            <hr>
            <div *ngFor="let element of data">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <p>{{ element.id }} - {{ element.name }} </p>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger"
                        (click)="removeGroup(element.id)"><mat-icon>remove_circle</mat-icon></button>
                </div>
                <div>
                    <span *ngFor="let model of element.vehicle_models">{{ model.name }},</span>
                </div>
                <hr>
            </div>
        </div>
    </div>
</div>