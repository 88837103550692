import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { ReservationDetailComponent } from '../../reservations/reservation-detail/reservation-detail.component';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.css']
})
export class AccountDetailComponent implements OnInit {
  reservations: any;
  displayedColumns: Array<any> = ["id", "plate", "start_date", "end_date", "total_price", "discounted_price", "amount_paid", "last_asecco_request", "last_refund_request", "refund_total", "order_id"];
  user_id: number;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri: any) => {
      this.user_id = uri.id;
      this.getRes();
    });
  }

  getRes(): void {
    this.userService.getAccountDetail(this.user_id).subscribe((result: any) => {
      
      this.reservations = result.data;
    }, this.globalService.handleError);
  }

  tableClick(id: number): void {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: id,
      minWidth: "500px"
    });
  }

}
