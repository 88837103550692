import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalBillingService } from 'src/app/services/additional-billing.service';
import { GlobalService } from 'src/app/services/global.service';
import { BillingItemNames } from 'src/environments/global';

@Component({
  selector: 'app-additional-billing-add-item',
  templateUrl: './additional-billing-add-item.component.html',
  styleUrls: ['./additional-billing-add-item.component.css']
})
export class AdditionalBillingAddItemComponent implements OnInit {
  billing_items = BillingItemNames;
  billingForm: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
    service_fee: new FormControl(null),
    discount_amount: new FormControl(null)
  });
  constructor(
    private dialogRef: MatDialogRef<AdditionalBillingAddItemComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private billingService: AdditionalBillingService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if(this.data) {
      this.billingService.addItemForBilling(this.data, this.billingForm).subscribe(this.globalService.successMessage, this.globalService.handleError)
      this.dialogRef.close(true);
    }
    else {
      this.dialogRef.close(this.billingForm.value);
    }
  }

}
