<div class="container">
    <button mat-raised-button color="primary" (click)="addNew()">Add New</button>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name }} </td>
        </ng-container>

        <ng-container matColumnDef="car_count">
            <th mat-header-cell *matHeaderCellDef> Car Count </th>
            <td mat-cell *matCellDef="let element"> {{element.carCount}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
    </table>

</div>