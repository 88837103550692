<div class="">

    <form [formGroup]="userSegmentsForm" (ngSubmit)="onFormSubmit()">
      
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" value="" formControlName="name">
        </mat-form-field>
  
        <div class="button">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>
</div>
