import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-park-locations-status',
  templateUrl: './park-locations-status.component.html',
  styleUrls: ['./park-locations-status.component.css']
})
export class ParkLocationsStatusComponent implements OnInit {
  data:any;
  subscription:any;
  constructor(
    private route: ActivatedRoute,
    private parkService: ParkingPlacesService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
        this.getPark(uri.id);
        this.subscription?.unsubscribe();
      });
    });
  }

  getPark(id:number): void {
    this.parkService.getParkingPlace(id).subscribe((result:any) => {
      if(result.success) {
        this.data = result.data;
      }
    }, this.globalService.handleError); 
  }


}
