<div class="container">
    <div fxLayout="row" fxLayoutGap="20px">
        <button mat-raised-button color="primary" (click)="addNew()">Add New</button>
        <button mat-raised-button color="primary" (click)="vehicleGroup()">Vehicle Groups</button>
        <button mat-raised-button color="primary" (click)="corporateGroup()">Corporate Group</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>



        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle_group">
            <th mat-header-cell *matHeaderCellDef> Vehicle group </th>
            <td mat-cell *matCellDef="let element"> {{element.vehicle_group}} </td>
        </ng-container>

        <ng-container matColumnDef="corporate_group">
            <th mat-header-cell *matHeaderCellDef> Corporate group </th>
            <td mat-cell *matCellDef="let element"> {{element.corporate_group}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element"> {{element.title_group}} </td>
        </ng-container>

        <ng-container matColumnDef="daily">
            <th mat-header-cell *matHeaderCellDef> Daily </th>
            <td mat-cell *matCellDef="let element"> {{element.daily}} </td>
        </ng-container>

        <ng-container matColumnDef="hourly">
            <th mat-header-cell *matHeaderCellDef> Hourly </th>
            <td mat-cell *matCellDef="let element"> {{element.hourly}} </td>
        </ng-container>

        <ng-container matColumnDef="minute">
            <th mat-header-cell *matHeaderCellDef> Minute </th>
            <td mat-cell *matCellDef="let element"> {{element.minute}} </td>
        </ng-container>

        <ng-container matColumnDef="every_15_minute">
            <th mat-header-cell *matHeaderCellDef> Every 15 minute </th>
            <td mat-cell *matCellDef="let element"> {{element.every_15_minute}} </td>
        </ng-container>

        <ng-container matColumnDef="start_time">
            <th mat-header-cell *matHeaderCellDef> Start time </th>
            <td mat-cell *matCellDef="let element"> {{element.start_time}} </td>
        </ng-container>

        <ng-container matColumnDef="end_time">
            <th mat-header-cell *matHeaderCellDef> End time </th>
            <td mat-cell *matCellDef="let element"> {{element.end_time}} </td>
        </ng-container>

        <ng-container matColumnDef="create_at">
            <th mat-header-cell *matHeaderCellDef> Create at </th>
            <td mat-cell *matCellDef="let element"> {{element.create_at}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
    </table>

</div>