<p>{{ header }} Vehicle Model</p>
<div class="electrip-form-container">
    <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="vehicleModelsEditForm" (ngSubmit)="saveVehicleModel()">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
        </mat-form-field>
        <br>

        <div style="margin-bottom: 10px; display:flex; flex-direction:column; gap: 10px">
            <mat-label>Vehicle Modal Image</mat-label>
            <input type="file" (change)="selectFile($event)">
            <img *ngIf="vehicleImage" [src]="vehicleImage.link" alt="image"
                style="width: 100px; ">
                <img *ngIf="initialValues && !vehicleImage" [src]="initialValues?.image" alt="image"
                    style="width: 100px; ">
        </div>

        <button mat-raised-button color="primary" type="submit" [disabled]="!vehicleModelsEditForm.valid">Submit</button>
    </form>
</div>