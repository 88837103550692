<div class="detail-window">
    <div>
        <mat-form-field appearance="fill">
            <mat-label>Driver License Status</mat-label>
            <mat-select [(value)]="driver_license_selected" (selectionChange)="select_change()">
                <mat-option value="">None</mat-option>
                <mat-option value="Waiting">Waiting</mat-option>
                <mat-option value="Rejected">Rejected</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Selfie Status</mat-label>
            <mat-select [(value)]="selfie_selected" (selectionChange)="select_change()">
                <mat-option value="">None</mat-option>
                <mat-option value="Waiting">Waiting</mat-option>
                <mat-option value="Rejected">Rejected</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Student License Status</mat-label>
            <mat-select [(value)]="student_license_selected" (selectionChange)="select_change()">
                <mat-option value="">None</mat-option>
                <mat-option value="Waiting">Waiting</mat-option>
                <mat-option value="Rejected">Rejected</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <table mat-table [dataSource]="user_documents" matSort class="">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element?.user?.name}} {{element?.user?.surname}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> phone </th>
                <td mat-cell *matCellDef="let element"> {{element?.user?.phoneNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="driver_license">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Driver License </th>
                <td mat-cell *matCellDef="let element" class="status_icon"> 
                    <div *ngIf="element.driver_license == 'Approved'">
                        <mat-icon class="approved_icon">thumb_up</mat-icon>
                    </div>
                    <div *ngIf="element.driver_license == 'Waiting'">
                        <mat-icon class="waiting_icon">hourglass_empty</mat-icon>
                    </div>
                    <div *ngIf="element.driver_license == 'Rejected'">
                        <mat-icon class="rejected_icon">thumb_down</mat-icon>
                    </div>
                </td>

            </ng-container>

            <ng-container matColumnDef="selfie">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Selfie </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.selfie == 'Approved'">
                        <mat-icon class="approved_icon">thumb_up</mat-icon>
                    </div>
                    <div *ngIf="element.selfie == 'Waiting'">
                        <mat-icon class="waiting_icon">hourglass_empty</mat-icon>
                    </div>
                    <div *ngIf="element.selfie == 'Rejected'">
                        <mat-icon class="rejected_icon">thumb_down</mat-icon>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="student_license">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Student License </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.student_license == 'Approved'">
                        <mat-icon class="approved_icon">thumb_up</mat-icon>
                    </div>
                    <div *ngIf="element.student_license == 'Waiting'">
                        <mat-icon class="waiting_icon">hourglass_empty</mat-icon>
                    </div>
                    <div *ngIf="element.student_license == 'Rejected'">
                        <mat-icon class="rejected_icon">thumb_down</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row)"></tr>
        </table>

        <div>
            <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="pageEvent=getResPagination($event)"></mat-paginator>
        </div>
    </div>
</div>