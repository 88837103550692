import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { UsersService } from 'src/app/services/users.service';
import { DriverAddNotificationComponent } from './driver-detail/driver-notifications/driver-add-notification/driver-add-notification.component';
import { SendMessageComponent } from './send-message/send-message.component';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.css']
})
export class DriversComponent implements OnInit {
  showSearch: boolean = false;
  listButtonsID: number = 0;
  users:any;
  allUsers:any;
  searchUsers: any;
  timeout: any;
  panelListShow: boolean = true;
  statusShow: boolean = true;
  selectedID: number;
  subscription:any;
  search_word: string;
  unFilteredUsers: Array<any>;

  constructor(
    private userService: UsersService,
    private route: ActivatedRoute,
    private mapControl: MapControlServiceService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getAllUsers();

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
      this.selectedID = uri.id;
        this.subscription?.unsubscribe();
      });
    });

  }
  
  getAllUsers(): void {
    this.userService.getAllUsers().subscribe((result:any) => {
      if(result.success) {
        this.users = result.data;
        this.allUsers = result.data;
        this.unFilteredUsers = result.data;
      }
    }, this.globalService.handleError);
  }

  closeSearch(): void {
    this.getAllUsers();
  }

  listSort(query:any): void {
    this.users.sort((a:any,b:any) => {
      if(a[query.id].toLowerCase() < b[query.id].toLowerCase()) {
        return query.value?-1:1;
      }
      if(a[query.id].toLowerCase() > b[query.id].toLowerCase()) {
        return query.value?1:-1;
      }
      return 0;
    });
  }

  listButtons(query:any): void {
    if(query.buttonID == this.listButtonsID) {
      if(this.search_word == "") {
        this.users = this.unFilteredUsers;
      }
      else {
        this.users = this.allUsers;
      }
      this.listButtonsID = 0;
      return;
    }
    this.listButtonsID = query.buttonID;
    if(this.search_word == "") {
      this.users = this.unFilteredUsers?.filter((car:any) => {
        var tempCar = car;
        query.id.forEach((element:any) => {
          tempCar = tempCar[element];
        });
        if(tempCar == query.value) {
          return true;
        }
      });
    }
    else {
      this.users = this.allUsers?.filter((car:any) => {
        var tempCar = car;
        query.id.forEach((element:any) => {
          tempCar = tempCar[element];
        });
        if(tempCar == query.value) {
          return true;
        }
      });
    }
  }

  onSearchChange(event:any) {
    this.search_word = event.target.value;
    if(event.target.value === "") {
      this.getAllUsers();
      return;
    }
    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        $this.userService.searchUser(event.target.value).subscribe((result:any) => {
          if(result.success) {
            $this.allUsers = result.data;
            $this.users = result.data;
          }
        }, $this.globalService.handleError);
      }
    }, 500);
  }

  send_message(): void {
    this.dialog.open(SendMessageComponent, {
      maxHeight: '95vh',
      width: '500px',
      autoFocus: false
    }).afterClosed().subscribe((data:any) => {
      if(data) {
        this.mapControl.showSnackbar("Message send succesfuly.");
      }
    });
  }

  send_firebase_message() {
    this.dialog.open(DriverAddNotificationComponent, {
      width: '400px',
      data: "firebase"
    });
  }

}
