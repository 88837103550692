<div *ngIf="!dataLoaded">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>


<div *ngIf="dataLoaded" class="insuranceAddOrEdit">

    <table class="expandedTable">
        <tr>
            <td>Vehicle ID.</td>
            <td>{{ data.vehicle_id }}</td>
        </tr>
    </table>

    <form [formGroup]="insurancesForm" (ngSubmit)="onFormSubmit()">
        <div class="selectboxField">
            <mat-form-field appearance="fill">
                <mat-label>Select an Insurance Type</mat-label>
                <mat-select formControlName="insuranceTypeId">
                    <mat-option *ngFor="let item of data.insuranceTypes" [value]="item.id" >{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <!-- <button mat-raised-button color="primary" type="button" (click)="insuranceTypeAdd()">+</button> -->
        </div>
        <mat-form-field class="example-full-width">
            <mat-label>Policy Number</mat-label>
            <input matInput placeholder="" value="" formControlName="policyNumber">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="insurancesForm" [rangePicker]="picker">
              <input matStartDate formControlName="startDate" placeholder="Start date">
              <input matEndDate formControlName="endDate" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="insurancesForm.controls.startDate.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="insurancesForm.controls.endDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
        <div class="button">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>
</div>
