<mat-dialog-content>
    <div *ngIf="!corporationData">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
    
    <div *ngIf="corporationData">
        <form [formGroup]="corporationForm" (ngSubmit)="onFormSubmit()" fxLayout="column" fxFlexAlign="stretch">
            <mat-form-field appearance="fill">
                <mat-label>Select an Reject Reason</mat-label>
                <mat-select formControlName="corporation" >
                    <mat-option *ngFor="let item of corporationData" [value]="item">{{ item.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="primary" [disabled]="!corporationForm.valid">Submit</button>
            </div>
        </form>
    </div>
</mat-dialog-content>