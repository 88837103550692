import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import { CorporationUsersComponent } from '../corporation-users/corporation-users.component';

@Component({
  selector: 'app-corporation-detail',
  templateUrl: './corporation-detail.component.html',
  styleUrls: ['./corporation-detail.component.css']
})
export class CorporationDetailComponent implements OnInit {
  data: any;
  selectedId: number;
  constructor(
    private corporateService: CorporateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.selectedId = +params.get('id');
      this.getCorp();
    });
  }

  getCorp(): void {
    this.corporateService.get_corporate(this.selectedId).subscribe(result => {

      this.data = result.data;
      // this.edit_corporation(this.data);

    }, this.globalService.handleError);
  }

  edit_corporation(data): void {
    this.dialog.open(CorporationUsersComponent, {
      data: data.id,
      width: '75%',
    });
  }

}
