import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import { StudentLicenseService } from 'src/app/services/student-license.service';
import { StudentLicenseDetailComponent } from './student-license-detail/student-license-detail.component';

@Component({
  selector: 'app-student-license',
  templateUrl: './student-license.component.html',
  styleUrls: ['./student-license.component.css']
})
export class StudentLicenseComponent implements OnInit {

  displayedColumns:Array<string>=["id", "name", "surname","email"];
  licenses:any;
  openedLicense: any = {};
  licenseFront: any;
  licenseBack: any;
  isDisabled: boolean = false;
  constructor(
    private licenseService:StudentLicenseService, 
    private _sanitizer: DomSanitizer, 
    private dialog:MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.getAllLicenses();
  }
  
  getAllLicenses() {
    this.licenseService.getStudentInfoAll().subscribe((result:any) => {
      
      this.licenses = result.data;
    }, this.globalService.handleError);
  }




  tableClick(id:number) {
    const dialogRef = this.dialog.open(StudentLicenseDetailComponent, {
      width: '80%',
      maxHeight: '95vh',
      data: id
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getAllLicenses();
    });
  }

}
