import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HgsService {

  constructor(private http: HttpClient) { }

  updateDatabase(): Observable<any>{
    var url = baseUrl + "payment/updatehgs";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    })

    return this.http.post<any>(url, {"pass":"ARHD3REY#*AGR"}, { headers: headers });     
  }

  getAllData(page_id): Observable<any>{
    var url = baseUrl + "payment/get_hgs_all/"+page_id;
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    })

    return this.http.post<any>(url, {}, { headers: headers });   
  }

}
