import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });

  url = BASEURL + "/userLicense/";

  constructor(
    private http: HttpClient,
  ) { }

  getMFile(guid: string): Observable<any> {
    return this.http.post<any>(`${this.url}getMFiles`, {file_id: guid}, { responseType: 'blob' as 'json', reportProgress: true, observe: "events", headers: this.headers });
  }

  getRejectedReasons(document_name: string): Observable<any> {
    return this.http.get<any>(`${this.url}getRejectedReasons/${document_name}`, { headers: this.headers });
  }

  getCorporations(): Observable<any> {
    return this.http.get<any>(`${this.url}getCorporations`, { headers: this.headers });
  }

}
