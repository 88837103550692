import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { GlobalService } from 'src/app/services/global.service';
import { DocumentService } from 'src/app/services/users/document.service';
import { CorporateCardService } from 'src/app/services/users/documents/corporate-card.service';
import { DOCUMENTS_VAR } from 'src/environments/environment';
import { CorporationsComponent } from '../../corporations/corporations.component';
import { RejectReasonComponent } from '../../reject-reason/reject-reason.component';

@Component({
  selector: 'app-corporate-card-detail',
  templateUrl: './corporate-card-detail.component.html',
  styleUrls: ['./corporate-card-detail.component.css']
})
export class CorporateCardDetailComponent implements OnInit {
  image: any;
  spinnerValue = 0;
  spinnerMode: ProgressSpinnerMode = 'indeterminate';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CorporateCardDetailComponent>,
    private corporateCardService: CorporateCardService,
    public globalService: GlobalService,
    private documentService: DocumentService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.documentService.getMFile(this.data.document_guid).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.spinnerMode = 'determinate';
          this.spinnerValue = Math.round(100 * event.loaded / event.total);
        }
        if (event.type === HttpEventType.Response) {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            this.image = reader.result;
          }, false);
          reader.readAsDataURL(event.body);
        }
      },
      error: this.globalService.handleError
    });
  }

  accept(): void {
    this.dialog.open(CorporationsComponent, {
      width: "400px",
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return;
        console.log(res, this.data);
        this.corporateCardService.setCorporateCardApproved(this.data.id, res.id).subscribe({
          next: (result: any) => {
            this.dialogRef.close(true);
          },
          error: this.globalService.handleError
        })
      }
    });
  }

  reject(): void {
    this.dialog.open(RejectReasonComponent, {
      data: DOCUMENTS_VAR.CorporateCard,
      width: "300px",
    }).afterClosed().subscribe({
      next: (res: any) => {
        if (!res) return;
        console.log(res, this.data);
        this.corporateCardService.setCorporateCardReject(this.data.id, res).subscribe({
          next: (result: any) => {
            this.dialogRef.close(true);
          },
          error: this.globalService.handleError
        })

      }
    });
  }


}
