import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css']
})
export class UserTableComponent implements OnInit {
  displayedColumns = ['id', 'status', 'registered_time', 'tckn', 'phone', 'email', 'birth_date', 'city', 'town', 'schedule', 'campaign', 'other_cards', 'corporate_info', 'driver_license_given', 'driver_license_expiration', 'student_card_given', 'student_card_expiration', 'reject_reason', 'approved_person'];
  dataSource: any;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  current_page = 0
  pageEvent: PageEvent;

  constructor(
    private userService: UsersService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.userService.getUserTable(this.pageSize, this.current_page + 1).subscribe({
      next: result => {
        this.dataSource = result.data;
        this.length = result.pagination.count;
      },
      error: this.globalService.handleError
    })
  }

  getResPagination(event:PageEvent): PageEvent {
    this.current_page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getData();
    return event;
  }
}
