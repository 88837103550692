import { createComponentDefinitionMap } from '@angular/compiler/src/render3/partial/component';
import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapControlServiceService {
  onGetData: EventEmitter<object> = new EventEmitter();
  isMapOpen: EventEmitter<boolean> = new EventEmitter();
  mapClick: EventEmitter<object> = new EventEmitter();
  mapDefaultValue: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  mapMark: EventEmitter<object> = new EventEmitter();
  mapFilter: BehaviorSubject<object> = new BehaviorSubject({"brand":"","search":"","statu":""});
  selectedCarID: BehaviorSubject<object> = new BehaviorSubject({});
  selectedParkID: BehaviorSubject<any> = new BehaviorSubject({});
  selectedChargeID: BehaviorSubject<any> = new BehaviorSubject({});

  carImageChange: EventEmitter<number> = new EventEmitter();

  constructor(
    public _snackBar: MatSnackBar
  ) {
      
    this.mapFilter.subscribe((mapfilter:any)=> {
      var filters = {
        brand: (brand:any) => mapfilter.brand == "" || mapfilter.brand == brand.name,
        model: (model:any) => mapfilter.search=="" || model.name.toLowerCase().includes(mapfilter.search.toLowerCase()),
        numberPlate: (numberPlate:any) => mapfilter.search == "" || numberPlate.toLowerCase().includes(mapfilter.search.toLowerCase()),
      };
      var carObj=[];
      carObj = this.mapDefaultValue.value;


      if(mapfilter.statu != "") {
        if(mapfilter.statu != -1) {
          carObj = carObj.filter((data:any) => {
            if(data.status.id == mapfilter.statu) {
              return true;
            }
          });
        }
      }
      if(mapfilter.brand != "") {
        carObj = carObj.filter((data:any) => {
          if(data.brand.name == mapfilter.brand) {
            return true;
          }
        });
      }
      if(mapfilter.search != "") {
        carObj = carObj.filter((data:any) => {
          if(
            data.numberPlate.toLowerCase().includes(mapfilter.search.toLowerCase()) ||
            data.year == mapfilter.search ||
            data.colour == mapfilter.search.toLowerCase() ||
            data.brand.name.toLowerCase().includes(mapfilter.search.toLowerCase()) ||
            data.model.name.toLowerCase().includes(mapfilter.search.toLowerCase())

            ) {
            return true;
          }
        });
      }
      this.onGetData.emit(carObj);




      return;
    });
  }

  private filterArray(array:any, filters:any) {
    const filterKeys = Object.keys(filters);
    return array.filter((item:any) => {
      // validates all filter criteria
      return filterKeys.every(key => {
        // ignores non-function predicates
        if (typeof filters[key] !== 'function') return true;
        return filters[key](item[key]);
      });
    });
  }

  public mapResetValue() {
    this.onGetData.emit(this.mapDefaultValue.value);
  }

  public resetAllValues() {
    this.onGetData.emit([]);
    this.isMapOpen.emit(false);
    this.mapFilter.next({"brand":"","search":""});
    this.mapDefaultValue.next([]);
  }

  public showSnackbar(text: string) {
    this._snackBar.open(text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });
  }

}


export class carID {
  id: number;
  year: number;
  transmission: any;
  model: {
    name:string;
  }
  colour: string;
  sittingCapacity: number;
  childSeat: boolean;
  coordinate: {
    cooridnate_exact_name:string;
  };
  licenseNumber: string;
  chassisNumber: string;
  hgsSerialNumber: string;
  numberPlate: string;
  brand: {
    name:string;
  };
  status: {
    name:string;
  }
}
