import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-future-reservation-of-vehicle',
  templateUrl: './future-reservation-of-vehicle.component.html',
  styleUrls: ['./future-reservation-of-vehicle.component.css']
})
export class FutureReservationOfVehicleComponent implements OnChanges {
  @Input() rez_id: number;
  reservations_feature: any;
  selected_rez_id: any;
  displayedColumns = ["id", "plate", "user_full_name", "usage", "brand_model", "start_destination", "end_destination", "start_time", "end_time"];
  vehicles: any;
  constructor(
    private userService: UsersService,
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
  }

  getResDetail(): void {
    this.reservations_feature = null;
    this.vehicles = null;
    this.userService.getReservationFeatureDetail(this.rez_id).subscribe({
      next: result => {
        this.reservations_feature = result.data;
      },
      error: this.globalService.handleError
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getResDetail();
  }

}
