<div class="google-map">
    <agm-map [latitude]="data?.continental.lat" [longitude]="data?.continental.long">
        <agm-marker [latitude]="data?.continental.lat" [longitude]="data?.continental.long" iconUrl="assets/icons/car.png" (mouseOver)="carPanel.open()" (mouseOut)="carPanel.close()">
            <agm-info-window #carPanel>
                <div class="map-info">

                    <p>
                        <b>Name : </b>
                        <span>{{ data?.brand.name }} {{ data?.model.name }}</span>
                    </p>

                    <p>
                        <b>Color : </b>
                        <span>{{ data?.colour }}</span>
                    </p>

                    <p>
                        <b>Plate : </b>
                        <span>{{ data?.number_plate }}</span>
                    </p>

                    <p>
                        <b>Status : </b>
                        <span>{{ data?.status.status_type.name }}</span>
                    </p>

                    <p>
                        <b>Year : </b>
                        <span>{{ data?.year }}</span>
                    </p>

                </div>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>