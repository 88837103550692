import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import { LicenseService } from 'src/app/services/license-selfie.service';
import { UserTagsComponent } from '../drivers/user-tags/user-tags.component';
import { OpenImage } from '../license/license-detail/license-detail.component';
import { RejectedReasonComponent } from '../rejected-reason/rejected-reason.component';
import { ImageService } from 'src/app/services/image-service.service';

@Component({
  selector: 'app-selfie',
  templateUrl: './selfie.component.html',
  styleUrls: ['./selfie.component.css']
})
export class SelfieComponent implements OnInit {
  displayedColumns:Array<string>=["id", "file_no", "given_date", "expiration_date"];
  licenses:any;
  openedLicense: any = {};
  licenseFront: any;
  licenseBack: any;
  isDisabled: boolean = false;
  constructor(
    private licenseService:LicenseService, 
    private _sanitizer: DomSanitizer, 
    private dialog:MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService,
    private imageService: ImageService
    ) { }

  ngOnInit(): void {
    this.getAllLicenses();
  }
  
  getAllLicenses() {

    this.openedLicense = {};
    this.licenseService.getWaitingSelfieApprovals().subscribe((result:any) => {
      
      this.licenses = result.data;
    }, this.globalService.handleError);
  }

  panelOpen(id:number) {
    this.isDisabled=true;

    this.licenseService.getWaitingSelfieApproval(id).subscribe((result:any) => {
      this.isDisabled=false;
      if(result.success) {
        this.openedLicense = result.data;
        this.imageService.getImage(this.openedLicense.image).subscribe(imageUrl => {
          this.licenseFront = this._sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
        });
      }
      
    }, this.globalService.handleError);
  }

  panelClosed(id:number) {
    this.openedLicense = {};
    this.licenseFront = "";
    this.licenseBack = "";
  }

  showImage(image:string) {
    const dialogRef = this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
  }

  panelReject(id:number) {
    this.isDisabled = true;
    const dialogRef = this.dialog.open(RejectedReasonComponent, {
      minWidth: '250px',
      data: "UserSelfie"
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result) {
        this.licenseService.setSelfieStatus(id,"Rejected",result.rejectedId).subscribe((data) => {
          this._snackBar.open('Selfie Reject.', 'Ok', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.getAllLicenses();
          this.isDisabled = false;
        });
      }
      this.isDisabled = false;
    }, this.globalService.handleError);
  }

  panelAccept(id:number) {
    this.isDisabled = true;
    this.licenseService.setSelfieStatus(id,"Approved").subscribe((data) => {
      this._snackBar.open('Selfie Approved.', 'Ok', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      this.getAllLicenses();
      this.isDisabled = false;
    }, this.globalService.handleError);
  }

  openUserTags(user_id: number): void {
    this.dialog.open(UserTagsComponent, {
      width: "500px",
      data: user_id
    });
  }

}
