<section class="example-section" [formGroup]="reasonGroup" >
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Select Reject Reason</h2>
        <div></div>
        <div *ngFor="let item of reasons">
            <mat-checkbox formControlName="{{ item.reason_title }}">
                <span class="wrap">
                    <b>
                        {{ item.reason_title }}
                    </b>
                    {{ item.reason_exp }}
                </span>
            </mat-checkbox>
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="end end">
        <button mat-raised-button color="primary" (click)="panelClose()">Done</button>
    </div>

</section>

