import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { CarService } from 'src/app/services/triggers/car.service';
import { VehicleSegmentOptionsDetailComponent } from './vehicle-segment-options-detail/vehicle-segment-options-detail.component';
import { VehicleSegmentOptionsEditComponent } from './vehicle-segment-options-edit/vehicle-segment-options-edit.component';

@Component({
  selector: 'app-vehicle-segment-options',
  templateUrl: './vehicle-segment-options.component.html',
  styleUrls: ['./vehicle-segment-options.component.css']
})
export class VehicleSegmentOptionsComponent implements OnInit {
  dataSource: any = null;
  displayedColumns: Array<string> = ["id", "name", "car_count"];
  constructor(
    private dialog: MatDialog,
    private global: GlobalService,
    private carService: CarService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.carService.getListVehicleSegment().subscribe(result => {
      
      this.dataSource = result.data;
    }, this.globalService.handleError);
  }

  addNew(): void {
    this.dialog.open(VehicleSegmentOptionsEditComponent, {
      width: "600px",
      maxHeight: "700px",
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      this.getList();
    });
  }

  tableClick(id: number): void {
    this.dialog.open(VehicleSegmentOptionsEditComponent, {
      data: id,
      width: "600px",
      maxHeight: "700px",
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      this.getList();
    });
  }

}
