<div mat-dialog-content>
    <h2>Seçilen statü : {{ data }}</h2>
    <ul>
        <li>Eğer daha önce bir atama yapıldı ise önceki silinip yeni atama yapılır, yani bir araca 2 kez farklı tarihlerde statü atayamazsınız.</li>
        <li>Başlama tarihi gelene kadar aracın statüsü devam eder. O saatte aracın statüsü seçtiğiniz {{ data }} değere gelir. Bitiş tarihinde ise tekrardan araç OPEN değerine dönecektir.</li>
        <li>Eğer aracın statüsü sürekli olarak kalmasını istiyorsanız tarih seçimi yapmayınız.</li>
    </ul>
    <div class="carDate">
        <!-- <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field> -->
        <form [formGroup]="range" fxLayout="column" fxLayoutAlign="space-around center">
            <mat-form-field appearance="fill">
                <mat-label>Start Date</mat-label>
                <input matInput placeholder="Start Date" formControlName="start" type="datetime-local">
            </mat-form-field>
    
            <mat-form-field appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput placeholder="End Date" formControlName="end" type="datetime-local">
            </mat-form-field>
        </form>


    </div>
</div>
<div mat-dialog-actions fxFlex fxLayoutAlign="center end">
    <button mat-button (click)="closeDialog()" cdkFocusInitial>Ok</button>
</div>