import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { UsersService } from 'src/app/services/users.service';
import { BASEURL, REFUND_STATUS, STATUS_VAR } from 'src/environments/environment';
import { ReservationAdditionalTimeComponent } from '../../reservations/reservation-additional-time/reservation-additional-time.component';
import { ReservationCancelComponent } from '../../reservations/reservation-cancel/reservation-cancel.component';
import { ReservationEndComponent } from '../../reservations/reservation-end/reservation-end.component';
import { ResrevationImagesComponent } from '../../reservations/resrevation-images/resrevation-images.component';

@Component({
  selector: 'app-reservation-detail-tab',
  templateUrl: './reservation-detail-tab.component.html',
  styleUrls: ['./reservation-detail-tab.component.css']
})
export class ReservationDetailTabComponent implements OnChanges {
  @Input() rez_id: number;

  reservation: any;
  baseUrl = BASEURL;
  status_var: any = STATUS_VAR;
  refund_status = REFUND_STATUS;


  constructor(
    private userService: UsersService,
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private dialog: MatDialog,
    private globalService: GlobalService,
    ) { 

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.getResDetail();
  }

  getResDetail(): void {
    this.reservation = null;
    this.userService.getReservationDetail(this.rez_id).subscribe({
      next: result => {
        this.reservation = result.data;
      },
      error: this.globalService.handleError
    });
  }



  reservationStatusChange(status: any): void {
    this.reservationService.setReservationStatus(this.rez_id, status).subscribe({
      next: result => {
        if (result.success) {
          this.globalService.successMessage(result);
          this.getResDetail();
        }
      },
      error: this.globalService.handleError
    });
  }

  set_additional_time(reservation: any): void {
    this.dialog.open(ReservationAdditionalTimeComponent, {
      data: reservation
    }).afterClosed().subscribe((data:any) => {
      if(data) {
        this.getResDetail();
        this.mapControl.showSnackbar("Reservation additional time added succesfuly.");
      }
    });
  }

  cancel_reservation(reservation: any): void {
    this.dialog.open(ReservationCancelComponent, {
      data: reservation
    }).afterClosed().subscribe((data: any) => {
      if(data) {
        this.getResDetail();
        this.mapControl.showSnackbar("Your cancellation has been processed.");
      }
    });
  }

  open_reservation_photos(reservation_id: number): void {
    this.dialog.open(ResrevationImagesComponent, {
      data: reservation_id,
      width: "70%",
      height: "70%",
    });
  }

  end_reservation(reservation_id: number): void {
    this.dialog.open(ReservationEndComponent, {
      data: reservation_id,
      width: "500px",
      autoFocus: false
    }).afterClosed().subscribe({
      next: (dialog) => {
        if(dialog == "OK") this.getResDetail();
      }
    });
  }

}
