import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddCorporateGroupComponent } from 'src/app/modules/pricing/corporate-group/add-corporate-group/add-corporate-group.component';
import { ListCorporateModelsComponent } from 'src/app/modules/pricing/corporate-group/add-corporate-group/list-corporate-models/list-corporate-models.component';
import { ListModelsComponent } from 'src/app/modules/pricing/vehicle-group/add-vehicle-group/list-models/list-models.component';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';
import { CarService } from 'src/app/services/triggers/car.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';

@Component({
  selector: 'app-user-corporate-vehicles-edit',
  templateUrl: './user-corporate-vehicles-edit.component.html',
  styleUrls: ['./user-corporate-vehicles-edit.component.css']
})
export class UserCorporateVehiclesEditComponent implements OnInit {

  userCorporateVehicleForm = new FormGroup({
    id: new FormControl(),
    name: new FormControl(),
    corporates: new FormArray([]),
    vehicles: new FormArray([])

  });
  corporate_list: any;
  vehicle_list: any;
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddCorporateGroupComponent>,
    private globalService: GlobalService,
    private priceService: PricingService,
    private carService: CarService,
    private corporateService: CorporateService
  ) { }

  ngOnInit(): void {
    this.priceService.getCorporateList().subscribe({
      next: (result) => {
        this.corporate_list = result.data;
      },
      error: this.globalService.handleError
    });

    this.carService.getVehicleList().subscribe({
      next: (result) => {
        this.vehicle_list = result.data;
      },
      error: this.globalService.handleError
    });
    if (this.data) {
      this.corporateService.getUserCorporateVehicle(this.data).subscribe({
        next: (result) => {
          result.data.id = this.data;
          this.userCorporateVehicleForm.patchValue(result.data);
          const corporate_items = this.userCorporateVehicleForm.get('corporates') as FormArray
          corporate_items.clear();
          for (let i = 0; i < result.data.corporates.length; i++) {
            const element = result.data.corporates[i];
            corporate_items.push(new FormControl(element));
          }

          const vehicle_items = this.userCorporateVehicleForm.get('vehicles') as FormArray
          vehicle_items.clear();
          for (let i = 0; i < result.data.vehicles.length; i++) {
            const element = result.data.vehicles[i];
            vehicle_items.push(new FormControl(element));
          }

        },
        error: this.globalService.handleError
      })
    }
  }

  onSubmit(): void {
    this.corporateService.saveUserCorporateVehicles(this.userCorporateVehicleForm).subscribe({
      next: (result) => {
        this.dialogRef.close();
        this.globalService.successMessage(result);
      },
      error: this.globalService.handleError
    })
  }

  addCorporate(): void {
    this.dialog.open(ListCorporateModelsComponent, {
      data: this.corporate_list
    }).afterClosed().subscribe({
      next: (result) => {

        if (result) {
          const items = this.userCorporateVehicleForm.get('corporates') as FormArray
          items.push(new FormControl({ id: result.id, name: result.name }))
        }
      }
    })
  }
  removeCorporate(item_id: number): void {
    const items = this.userCorporateVehicleForm.get('corporates') as FormArray
    items.removeAt(item_id)
  }

  addVehicle(): void {
    this.dialog.open(ListModelsComponent, {
      data: this.vehicle_list
    }).afterClosed().subscribe({
      next: (result) => {

        if (result) {
          const items = this.userCorporateVehicleForm.get('vehicles') as FormArray
          items.push(new FormControl({ id: result.id, name: result.name }))
        }
      }
    })
  }
  removeVehicle(item_id: number): void {
    const items = this.userCorporateVehicleForm.get('vehicles') as FormArray
    items.removeAt(item_id)
  }

  deleteElemenet(): void {
    if (confirm("Are you sure ?")) {
      this.corporateService.deleteUserCorporateVehicles(this.data).subscribe({
        next: (result) => {
          this.dialogRef.close();
          this.globalService.successMessage(result);
        },
        error: this.globalService.handleError
      })
    }
  }
}
