import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentCardService {

  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });

  url = BASEURL + "/userLicense/";

  constructor(
    private http: HttpClient,
  ) { }


  getStudentCards(paginator: any): Observable<any> {
    return this.http.get<any>(`${this.url}getStudentCards`, { headers: this.headers, params: { pageSize: paginator.pageSize, page: paginator.pageIndex + 1 } });
  }

  setStudentCardApproved(id: number): Observable<any> {
    return this.http.get<any>(`${this.url}setStudentCardApproved/${id}`, { headers: this.headers });
  }

  setStudentCardReject(doc_id: number, reject_reasons: any): Observable<any> {
    return this.http.post(this.url + "setStudentCardReject/" + doc_id, { reasons: reject_reasons }, { headers: this.headers });
  }
}
