import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorporateService {
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });

  corporates: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient
  ) { }

  get_corporates(params: any = null): void {
    this.http.get(baseUrl + "client/corporates", {headers: this.headers, params: params}).subscribe((result: any) => {
      this.corporates.next(result);
    });
  }

  get_corporate(id: number): Observable<any> {
    return this.http.get(baseUrl + "client/corporate/" + id, {headers: this.headers});
  }

  get_corporate_agreements(id: number): Observable<any> {
    return this.http.get(baseUrl + "client/corporate/" + id + "/agreements", { headers: this.headers});
  }

  getPrices(): Observable<any> {
    return this.http.get(baseUrl + "client/priceShcedules", { headers: this.headers});
  }

  save_corporation_agreements(id: number, form: FormGroup): Observable<any> {
    return this.http.post(baseUrl + "client/corporate/" + id + "/saveAgreements", form.value, {headers: this.headers});
  }

  delete_corporate_agreements(id: number, agreement_id: number): Observable<any> {
    return this.http.post(baseUrl + "client/corporate/" + id + "/deleteAgreements", {"agreement_id": id}, {headers: this.headers});
  }

  getCorporationSegmentTypes(): Observable<any> {
    return this.http.get(baseUrl + "client/getCorporationSegmentTypes", { headers: this.headers});
  }

  getCorporationSegment(id: number): Observable<any> {
    return this.http.get(baseUrl + "client/getCorporationSegment/" + id, { headers: this.headers});
  }

  addOrEditCorporationSegmentType(body: FormGroup): Observable<any> {
    var url = baseUrl + "client/addOrEditCorporationSegmentType";
    return this.http.post(url, body.value, { headers: this.headers });
  }

  corporationSegmentAddCorporation(id: number, corporation: any): Observable<any> {
    var url = baseUrl + "client/corporationSegmentType/" + id + "/addCorporation";
    return this.http.post(url, {corporation: corporation}, { headers: this.headers });
  }

  corporationSegmentRemoveCorporation(id: number, corporation:any): Observable<any> {
    var url = baseUrl + "client/corporationSegmentType/" + id + "/removeCorporation";
    return this.http.post(url, {corporation: corporation}, { headers: this.headers });
  }

  deleteCorporationSegment(id: number): Observable<any> {
    return this.http.get(baseUrl + "client/deleteCorporationSegmentType/" + id, { headers: this.headers });
  }

  getUserCorporateVehicles(): Observable<any> {
    return this.http.get(baseUrl + "client_user/getUserCorporateVehicles", { headers: this.headers });
  }

  getUserCorporateVehicle(id: number): Observable<any> {
    return this.http.get(baseUrl + "client_user/getUserCorporateVehicle/" + id, { headers: this.headers });
  }

  saveUserCorporateVehicles(form_data: FormGroup): Observable<any> {
    return this.http.post(baseUrl + "client_user/saveUserCorporateVehicles", form_data.value, { headers: this.headers })
  }

  deleteUserCorporateVehicles(id: number): Observable<any> {
    return this.http.get(baseUrl + "client_user/deleteUserCorporateVehicles/" + id, { headers: this.headers });
  }
  
  
}
