<div class="dialog">
    <form [formGroup]="imageForm" (ngSubmit)="onSubmit()" >
        <div class="images">
            <div *ngIf="image_uri">
                <button mat-button matSuffix mat-icon-button aria-label="Clear" class="remove-image" (click)="removeImage()"><mat-icon>remove_circle</mat-icon></button>
                <img [src]="image_uri" alt="" width="100%" height="100%" >
            </div>
            <input id="form-image" type="file"  accept="image/*" hidden (change)="onFileChanged($event.target.files)">
            <label for="form-image" *ngIf="!image_uri">
                <div class="upload-div">
                    <span>&#43;</span>
                </div>
            </label>
        </div>

        <button type="submit" mat-raised-button color="primary" [disabled]="!imageForm.valid">Submit</button>

    </form>

</div>