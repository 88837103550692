<div class="electrip-form">
    <mat-dialog-content >
        <h2>Add New Reservation Refund Settings</h2>
        <form [formGroup]="refundSettingsForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field>
                <mat-label>Bigger Than Minute </mat-label>
                <input matInput type="number" min="0" formControlName="bigger_minute">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Smaller Than Minute </mat-label>
                <input matInput type="number" min="0" formControlName="smaller_minute">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Refund Percentage </mat-label>
                <input matInput type="number" min="0" max="100" formControlName="refund_percentage">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Refund Amount </mat-label>
                <input matInput type="number" min="0" formControlName="refund_amount">
            </mat-form-field>


            <mat-form-field>
                <mat-label>Response Message</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="response_text"></textarea>
                <mat-hint>Use &lt;x&gt; for price.</mat-hint>

            </mat-form-field>

            <button type="button" mat-raised-button color="primary" (click)="openTestIt()" >Test it</button>
            <button type="submit" mat-raised-button color="primary" [disabled]="!refundSettingsForm.valid">Submit</button>



        </form>
    </mat-dialog-content>
</div>