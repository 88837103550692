import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-corporation-agreements-add',
  templateUrl: './corporation-agreements-add.component.html',
  styleUrls: ['./corporation-agreements-add.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    {provide: MAT_DATE_LOCALE, useValue: 'tr-TR'},
  ],
})
export class CorporationAgreementsAddComponent implements OnInit {
  agreementForm = new FormGroup({
    price_id: new FormControl(null, Validators.required),
    start: new FormControl(null, Validators.required),
    end: new FormControl(null, Validators.required),
    billing_period: new FormControl(false),
  });
  filteredPrice: Observable<any>;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private corporationService: CorporateService,
    private dialogRef: MatDialogRef<CorporationAgreementsAddComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.corporationService.getPrices().subscribe(params => {
      console.log(params);
      this.filteredPrice = this.agreementForm.get('price_id').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, params?.data))
      );
    }, this.globalService.handleError)
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toString().toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    this.agreementForm.patchValue({
      start: moment(this.agreementForm.get('start').value).format("YYYY-MM-DD"),
      end: moment(this.agreementForm.get('end').value).format("YYYY-MM-DD"),
      price_id: this.agreementForm.get('price_id').value.id,
    });
    this.corporationService.save_corporation_agreements(this.data, this.agreementForm).subscribe(params => {
      this.dialogRef.close();
    }, this.globalService.handleError);

  }

}
