import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { CarService } from 'src/app/services/triggers/car.service';
import { VehicleSegmentAddVehicleComponent } from './vehicle-segment-add-vehicle/vehicle-segment-add-vehicle.component';

@Component({
  selector: 'app-vehicle-segment-options-edit',
  templateUrl: './vehicle-segment-options-edit.component.html',
  styleUrls: ['./vehicle-segment-options-edit.component.css']
})
export class VehicleSegmentOptionsEditComponent implements OnInit {
  segmentForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null),
    items: new FormArray([]),
  });
  header: string = "Add";
  loading: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private carService: CarService,
    private globalService: GlobalService,
    private dialogRef: MatDialogRef<VehicleSegmentOptionsEditComponent>,
  ) { }

  ngOnInit(): void {
    if(this.data != null) {
      this.header = "Edit";
      this.getVehicleSegment();
    }
  }

  getVehicleSegment(): void {
    this.carService.getVehicleSegment(this.data).subscribe(result => {
      var data = result.data.data;
      this.segmentForm.patchValue(data);
      const items = this.segmentForm.get('items') as FormArray
      items.clear();
      for (let i = 0; i < data.items.length; i++) {
        const element = data.items[i];
        items.push(new FormControl(element));
      }
    }, this.globalService.handleError);
  }

  onSubmit(): void {
    this.carService.addOrEditSegmentType(this.segmentForm).subscribe(result => {
      this.globalService.successMessage(result);
      this.dialogRef.close(true);
    }, this.globalService.handleError)
    console.log(this.segmentForm.value)
  }

  addItem(): void {
    this.dialog.open(VehicleSegmentAddVehicleComponent, {
      minWidth: '400px',
      autoFocus: false,
      data: this.data
    }).afterClosed().subscribe(data => {
      if(data) {
        this.getVehicleSegment();
      }
    });

  }

  removeItem(item: any): void {
    this.carService.vehicleSegmentRemoveVehicle(this.data, item).subscribe(result => {
      this.getVehicleSegment();
      this.globalService.successMessage(result);
    }, this.globalService.handleError);
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  delete(id: number): void {
    if(confirm('Are you sure ?')) {
      this.carService.deleteVehicleSegment(id).subscribe(result => {
        this.globalService.successMessage(result);
        this.dialogRef.close(true);
      }, this.globalService.handleError);
    }
  }

}
