import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-reservation-add',
  templateUrl: './reservation-add.component.html',
  styleUrls: ['./reservation-add.component.css']
})
export class ReservationAddComponent implements OnInit {
  reservationForm = new FormGroup({
    user: new FormControl(null, Validators.required),
    start_time: new FormControl(null, Validators.required),
    start_destination: new FormControl(null, Validators.required),
    end_time: new FormControl(null, Validators.required),
    vehicle: new FormControl({value:null, disabled:true}, Validators.required),
    // payment_card: new FormControl({value: null, disabled: true}, Validators.required),
    // campaign_code: new FormControl(),
    // discounted_reason: new FormControl(),
    // user_corporate: new FormControl(),
    // total_price: new FormControl(null, Validators.required),
    note: new FormControl(),
  });
  
  filteredVehicles: Observable<any>;
  filteredUser: Observable<any>;
  filteredUserCorporate: Observable<any>;
  filteredStartDestination: Observable<any>;
  filteredEndDestination: Observable<any>;
  filteredStatus: Observable<any>;
  filteredPaymentCards: Observable<any>;
  filteredCampaignCodes: Observable<any>;


  loading: boolean = true;
  
  // ilerde users ve vehicle çok büyük olcağı için yüklenmesi uzun sürecek
  // o yüzden her valueChanges olduğunda sunucuya veri yollayıp o datayı göster
  constructor(
    private dialogRef: MatDialogRef<ReservationAddComponent>,
    private reservationService: ReservationsService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.reservationService.getAddReservationFieldsData().subscribe((result) => {
      this.loading = false;
      if(result.success) {

        this.filteredUser = this.reservationForm.get('user').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.users))
        );
        // this.filteredUserCorporate = this.reservationForm.get('user_corporate').valueChanges.pipe(
        //   startWith(''),
        //   map(value => this._filter(value, result?.data?.userCorporation))
        // );
        this.filteredStartDestination = this.reservationForm.get('start_destination').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.parkingPlaces))
        );
        // this.filteredCampaignCodes = this.reservationForm.get('campaign_code').valueChanges.pipe(
        //   startWith(''),
        //   map(value => this._filter(value, result?.data?.campaignCodes))
        // );
      }
    }, this.globalService.handleError);

    var old_value: any;
    var get_vehicles = (result: any) => {
      if(result?.user?.id && result?.start_destination?.id && result?.start_time && result?.end_time) {
        this.reservationService.getVehiclesForRes({
          user_id: result.user.id,
          parking_place_id: result.start_destination.id,
          start_time: result.start_time,
          end_time: result.end_time
        }).subscribe(result => {
          this.filteredVehicles = this.reservationForm.get('vehicle').valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value, result?.data?.vehicles))
          );
          // this.filteredPaymentCards = this.reservationForm.get('payment_card').valueChanges.pipe(
          //   startWith(''),
          //   map(value => this._filter(value, result?.data?.cards?.cardList))
          // );
          this.reservationForm.get('vehicle').enable();
          this.reservationForm.get('vehicle').setValue('', {emitEvent:false});
          // this.reservationForm.get('payment_card').enable();

          // for (let i = 0; i < result?.data?.cards?.cardList.length; i++) {
          //   const element = result?.data?.cards?.cardList[i];
          //   if(element.cardToken == result.data.cards.defaultToken) {
          //     this.reservationForm.get('payment_card').setValue(element, { emitEvent: false })
          //     break;
          //   }
          // }

        }, this.globalService.handleError);
      }
    }

    var get_price = (result:any) => {
      // if(result?.user?.id && result?.vehicle?.id && result.start_time && result.end_time) {
      //   this.reservationService.getPaymentPriceForRes(this.reservationForm).subscribe(result=> {
      //     this.reservationForm.get('total_price').setValue(result?.data?.price, { emitEvent: false })
      //   }, this.globalService.handleError);
      // }
    }

    this.reservationForm.valueChanges.subscribe((result) => {
      var changing_value = "";
      for (const [key, value] of Object.entries(result)) {
        if (value?.hasOwnProperty("id")) {
          if ( old_value?.[key]?.["id"] != value?.["id"]) {
            changing_value = key;
            break;
          }
        }
        else if(value) {
          if ( old_value?.[key] != value) {
            changing_value = key;
            break;
          }
        }
        else if(typeof old_value !== typeof value) {
          changing_value = key;
          break;
        }
      }
      
      if (["user", "start_destination", "start_time", "end_time"].includes(changing_value)) {
        get_vehicles(result);
      }
      if (["user", "vehicle", "start_time", "end_time", "campaign_code"].includes(changing_value)) {
        get_price(result);
      }
      old_value = result;
    });

  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    this.loading = true;
    this.reservationService.saveReservation(this.reservationForm).subscribe((result:any) => {
      if(result.success) {
        this.dialogRef.close(true);
        this.globalService.successMessage(result);
      }
    }, (error:any) => {
      this.loading = false;
      this.globalService.handleError(error);
    });
  }

}
