import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/services/users.service';
import * as moment from 'moment';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { CitizenshipTypeAddComponent } from '../citizenship-type/citizenship-type-add/citizenship-type-add.component';
import { GlobalService } from 'src/app/services/global.service';
@Component({
  selector: 'app-driver-edit',
  templateUrl: './driver-edit.component.html',
  styleUrls: ['./driver-edit.component.css']
})
export class DriverEditComponent implements OnInit {
  header: string;
  editForm = new FormGroup({
    name: new FormControl(),
    surname: new FormControl(),
    tcknOrPassport: new FormControl(),
    phoneNumber: new FormControl(),
    email: new FormControl(),
    birthDate: new FormControl(),
    isCitizen: new FormControl(false),
    // citizenshipTypeId: new FormControl(),
    gender: new FormControl()
  });
  dataLoad:boolean = false;
  id:number;
  citizentypes: any;
  constructor(
    private router: ActivatedRoute,
    private userService: UsersService,
    private _snackBar: MatSnackBar,
    private mapControl:MapControlServiceService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.router.parent.params.subscribe((uri:any) => {
      this.getUser(uri.id);
      this.id = uri.id;
    });
    this.getCitizenTypes();
  }

  getUser(id:number): void {
    this.dataLoad = false;
    this.userService.getUser(id).subscribe((result:any) => {
      this.dataLoad = true;
      if(result.success) {
        this.editForm.patchValue(result.data);
        this.editForm.patchValue({
          citizenshipTypeId: result.data?.citizenshipType?.id
        });
      }
    }, this.globalService.handleError);
  }

  saveForm(): void {
    // if(!this.editForm.valid || !this.editForm.value.citizenshipTypeId){
    //   this.mapControl.showSnackbar("Form is not valid");
    //   return;
    // }
    let finalValues = this.editForm.value;

    let formatted_birthDate = moment(this.editForm.value.birthDate).format('YYYY-MM-DD');
    finalValues.birthDate = formatted_birthDate;
    finalValues.userId = this.id;
    this.userService.editUser(finalValues).subscribe(result => {
      if(result.success){        
        this.mapControl.showSnackbar("User Updated");
      }
    }, this.globalService.handleError);

  }


  openCitizenType(): void {
    const dialogRef = this.dialog.open(CitizenshipTypeAddComponent, {
      width: "300px"
    }).afterClosed().subscribe(() => {
      this.getCitizenTypes();
      dialogRef.unsubscribe();
    });
  }

  getCitizenTypes(): void {
    this.userService.getCitizenTypes().subscribe((res:any) => {
      if(res.success) {
        this.citizentypes = res.data;
      }
    }, this.globalService.handleError);
  }


}
