<div mat-dialog-content>
    <mat-progress-bar mode="buffer" *ngIf="!loading"></mat-progress-bar>

    <div *ngIf="loading" fxLayout="row" fxLayoutGap="20px">
        <div style="max-width: 500px;">
            <div fxLayout="row" fxLayoutAlign="space-between center" >
                <h1>{{ header }} Price</h1>
                <button *ngIf="data" type="button" mat-raised-button color="warn" (click)="delete()">Delete</button>
            </div>

            <form [formGroup]="priceForm" autocomplete="off">

                <mat-form-field appearance="fill">
                    <mat-label>Name</mat-label>
                    <input matInput required formControlName="name">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Vehicle Segment</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="VehicleSegment" matInput
                        formControlName="vehicle_group" [matAutocomplete]="autoVehicleSegment">
                    <mat-autocomplete #autoVehicleSegment="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredVehicleSegment | async" [value]="option">
                            {{option?.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Corporation Segment</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="CorporationSegment" matInput
                        formControlName="corporate_group" [matAutocomplete]="autoCorporationSegment">
                    <mat-autocomplete #autoCorporationSegment="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let option of filteredCorporationSegment | async" [value]="option">
                            {{option?.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Title</mat-label>
                    <mat-select formControlName="title_group">
                        <mat-option value="">None</mat-option>
                        <mat-option [value]="item.key" *ngFor="let item of titleGroup">{{ item.value }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Daily</mat-label>
                    <input matInput required type="number" formControlName="daily" min="0">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Hourly</mat-label>
                    <input matInput required type="number" formControlName="hourly" min="0">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Minute</mat-label>
                    <input matInput required type="number" formControlName="minute" min="0">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Every 15 minute</mat-label>
                    <input matInput required type="number" formControlName="every_15_minute" min="0">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Start Time</mat-label>
                    <input matInput required type="datetime-local" formControlName="start_time">
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>End Time</mat-label>
                    <input matInput required type="datetime-local" formControlName="end_time">
                </mat-form-field>

            </form>
        </div>
        <div style="min-width: 500px;">
            <h1>Price History</h1>
            <mat-accordion *ngIf="old_price_list" >
                <mat-expansion-panel *ngFor="let item of old_price_list">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item.id}}
                        </mat-panel-title>
                        <mat-panel-description>
                            Change At: {{item.change_at}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div>
                        <table class="expandedTable">
                            <tr>
                                <td>Daily</td>
                                <td>{{ item.daily }}</td>
                            </tr>
                            <tr>
                                <td>Vehicle group</td>
                                <td>{{ item.vehicle_group }}</td>
                            </tr>
                            <tr>
                                <td>Corporate group</td>
                                <td>{{ item.corporate_group }}</td>
                            </tr>
                            <tr>
                                <td>Title</td>
                                <td>{{ item.title_group }}</td>
                            </tr>
                            <tr>
                                <td>Daily</td>
                                <td>{{ item.daily }}</td>
                            </tr>
                            <tr>
                                <td>Hourly</td>
                                <td>{{ item.hourly }}</td>
                            </tr>
                            <tr>
                                <td>Minute</td>
                                <td>{{ item.minute }}</td>
                            </tr>
                            <tr>
                                <td>Every 15 minute</td>
                                <td>{{ item.every_15_minute }}</td>
                            </tr>
                            <tr>
                                <td>Start time</td>
                                <td>{{ item.start_time }}</td>
                            </tr>
                            <tr>
                                <td>End time</td>
                                <td>{{ item.end_time }}</td>
                            </tr>
                            <tr>
                                <td>Create Time</td>
                                <td>{{ item.create_at }}</td>
                            </tr>
                        </table>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="onSubmit()">Save</button>
</div>