<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Select Model</h2>
        <form [formGroup]="itemForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Model</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Vehicle" matInput formControlName="model" [matAutocomplete]="autoVehicle">
                <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredVehicle | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!itemForm.valid">Submit</button>

        </form>
    </mat-dialog-content>
</div>