<div fxLayoutAlign="center center" fxFlexFill>
    <mat-card fxFlex="95" fxFlex.gt-xs="400px">
        <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="formGroup" (ngSubmit)="loginProcess()">

            
            <mat-form-field> 
                <input matInput placeholder="Username" formControlName="username">  
            </mat-form-field>
            
            <mat-form-field>
                <input matInput type="password" placeholder="Password" formControlName="password">         
            </mat-form-field>
            <!-- <re-captcha
                (resolved)="resolved($event)"
                siteKey="6LeXxrMmAAAAABQ9uSJyixj87hNJ3pDwW3clVSXm"
            ></re-captcha> -->

            <button mat-raised-button type="submit">Login</button>
        </form>
    </mat-card>

</div>