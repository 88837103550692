<div class="reservation-triple" fxLayout="row" fxLayoutGap="20px">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Search</mat-label>
        <input matInput (keyup)="searchRez($event.target.value)">
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Reservation Status</mat-label>
        <mat-select (selectionChange)="reservationStatusChange($event.value)">
            <mat-option value="">All</mat-option>
            <mat-option value="Waiting">Waiting</mat-option>
            <mat-option value="Approved">Approved</mat-option>
            <mat-option value="Rejected">Rejected</mat-option>
            <mat-option value="Closed">Closed</mat-option>
            <mat-option value="Ended">Ended</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div class="reservation-triple" fxLayout="row" fxLayoutGap="20px">
    <div fxFlex="50" fxLayout="column" fxLayoutGap="20px">
        <div fxLayout="column" fxLayoutGap="20px">
            <h1 fxLayoutAlign="center center">Current Reservations</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="reservations_current" class="">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngStyle]="{'color': element.reservationTimePenalty?'red':''}">
                                {{element.id}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef> Plate </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_full_name">
                        <th mat-header-cell *matHeaderCellDef> User Full Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.userName}} {{ element.userSurname }} </td>
                    </ng-container>

                    <ng-container matColumnDef="usage">
                        <th mat-header-cell *matHeaderCellDef> Usage </th>
                        <td mat-cell *matCellDef="let element"> {{ element.usage }} </td>
                    </ng-container>

                    <ng-container matColumnDef="brand_model">
                        <th mat-header-cell *matHeaderCellDef> Brand - Model </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.brand}} - {{element.vehicle?.model}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start_destination">
                        <th mat-header-cell *matHeaderCellDef> Start Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_destination">
                        <th mat-header-cell *matHeaderCellDef> End Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
                    </ng-container>

                    <ng-container matColumnDef="start_time">
                        <th mat-header-cell *matHeaderCellDef> Start Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_time">
                        <th mat-header-cell *matHeaderCellDef> End Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
                </table>
            </div>
        </div>
        <div>
            <h1 fxLayoutAlign="center center">Feature Reservations</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="reservations_feature" class="">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngStyle]="{'color': element.reservationTimePenalty?'red':''}">
                                {{element.id}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef> Plate </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_full_name">
                        <th mat-header-cell *matHeaderCellDef> User Full Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.userName}} {{ element.userSurname }} </td>
                    </ng-container>

                    <ng-container matColumnDef="usage">
                        <th mat-header-cell *matHeaderCellDef> Usage </th>
                        <td mat-cell *matCellDef="let element"> {{ element.usage }} </td>
                    </ng-container>

                    <ng-container matColumnDef="brand_model">
                        <th mat-header-cell *matHeaderCellDef> Brand - Model </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.brand}} - {{element.vehicle?.model}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start_destination">
                        <th mat-header-cell *matHeaderCellDef> Start Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_destination">
                        <th mat-header-cell *matHeaderCellDef> End Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
                    </ng-container>

                    <ng-container matColumnDef="start_time">
                        <th mat-header-cell *matHeaderCellDef> Start Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_time">
                        <th mat-header-cell *matHeaderCellDef> End Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
                </table>
            </div>
        </div>
        <div>
            <h1 fxLayoutAlign="center center">Ended Reservations</h1>
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="reservations_ended" class="">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let element">
                            <span [ngStyle]="{'color': element.reservationTimePenalty?'red':''}">
                                {{element.id}}
                            </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="plate">
                        <th mat-header-cell *matHeaderCellDef> Plate </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
                    </ng-container>

                    <ng-container matColumnDef="user_full_name">
                        <th mat-header-cell *matHeaderCellDef> User Full Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.userName}} {{ element.userSurname }} </td>
                    </ng-container>

                    <ng-container matColumnDef="usage">
                        <th mat-header-cell *matHeaderCellDef> Usage </th>
                        <td mat-cell *matCellDef="let element"> {{ element.usage }} </td>
                    </ng-container>

                    <ng-container matColumnDef="brand_model">
                        <th mat-header-cell *matHeaderCellDef> Brand - Model </th>
                        <td mat-cell *matCellDef="let element"> {{element.vehicle?.brand}} - {{element.vehicle?.model}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="start_destination">
                        <th mat-header-cell *matHeaderCellDef> Start Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_destination">
                        <th mat-header-cell *matHeaderCellDef> End Destination </th>
                        <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
                    </ng-container>

                    <ng-container matColumnDef="start_time">
                        <th mat-header-cell *matHeaderCellDef> Start Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
                    </ng-container>


                    <ng-container matColumnDef="end_time">
                        <th mat-header-cell *matHeaderCellDef> End Time </th>
                        <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
                </table>
                <div>
                    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                        (page)="pageEvent=getResPagination($event)"></mat-paginator>
                </div>
            </div>

        </div>
    </div>
    <div fxFlex="50">
        <mat-tab-group dynamicHeight>
            <mat-tab label="Sürücü & Yolculuk Bilgileri">
                <app-reservation-detail-tab *ngIf="selected_rez_id" [rez_id]="selected_rez_id">
                </app-reservation-detail-tab>
            </mat-tab>
            <mat-tab label="Araç Bilgileri">
                <app-vehicle-detail-tab *ngIf="selected_rez_id" [rez_id]="selected_rez_id"></app-vehicle-detail-tab>
            </mat-tab>
            <mat-tab label="Araca Ait Diğer Yolculuklar">
                <app-future-reservation-of-vehicle *ngIf="selected_rez_id" [rez_id]="selected_rez_id">
                </app-future-reservation-of-vehicle>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>