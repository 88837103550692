<div class="">
    <div class="mt-20 p-20">

        <table mat-table [dataSource]="reservations" class="mat-elevation-z8">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="plate">
                <th mat-header-cell *matHeaderCellDef> Plate </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle.plate}} </td>
            </ng-container>

            <ng-container matColumnDef="start_time">
                <th mat-header-cell *matHeaderCellDef> Start Time </th>
                <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
            </ng-container>

            <ng-container matColumnDef="end_time">
                <th mat-header-cell *matHeaderCellDef> End Time </th>
                <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">  </td>
            </ng-container>

            <ng-container matColumnDef="bill">
                <th mat-header-cell *matHeaderCellDef> Bill </th>
                <td mat-cell *matCellDef="let element"> {{element.bill}} </td>
            </ng-container>

            <ng-container matColumnDef="usage">
                <th mat-header-cell *matHeaderCellDef> Usage </th>
                <td mat-cell *matCellDef="let element"> {{element.usage}} </td>
            </ng-container>

            <ng-container matColumnDef="campaign">
                <th mat-header-cell *matHeaderCellDef> Campaign </th>
                <td mat-cell *matCellDef="let element"> {{element.campaign}} </td>
            </ng-container>

            <ng-container matColumnDef="brand_model">
                <th mat-header-cell *matHeaderCellDef> Brand - Model </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle.brand}} - {{element.vehicle.model}} </td>
            </ng-container>

            <ng-container matColumnDef="start_destination">
                <th mat-header-cell *matHeaderCellDef> Start Destination </th>
                <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
            </ng-container>


            <ng-container matColumnDef="end_destination">
                <th mat-header-cell *matHeaderCellDef> End Destination </th>
                <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Total Price </th>
                <td mat-cell *matCellDef="let element"> {{element.totalPrice}} {{ element.currency }} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row)"></tr>
        </table>

    </div>
</div>