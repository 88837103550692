<div class="detail-content">
    <div>
        <div class="detail-title">
            <h1>{{ station?.name }}</h1>
            <!-- <a mat-list-item routerLinkActive="list-item-active" routerLink="edit">
                Edit
            </a> -->
        </div>
        <div class="detail-list">
            <div>
                <p> Charge Station ID </p>
                <p>{{ station?.id }}</p>
            </div>
            <hr>

            <div>
                <p> City </p>
                <p>{{ station?.city }}</p>
            </div>
            <hr>

            <div>
                <p> Cp Code No </p>
                <p>{{ station?.cp_code_no }}</p>
            </div>
            <hr>

            <div>
                <p> Cp Type </p>
                <p>{{ station?.cp_type }}</p>
            </div>
            <hr>

            <div>
                <p> District </p>
                <p>{{ station?.district }}</p>
            </div>
            <hr>

            <div>
                <p> Charge Station Coordinate </p>
                <p> Lat : {{ station?.lat }} - Long : {{ station?.long }} </p>
            </div>
            <hr>

        </div>


    </div>
</div>