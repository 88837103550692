import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  getWaitingLicenseApprovals(): Observable<any> {
    this.url = baseUrl + "client/getWaitingLicenseApprovals";

    return this.http.get(this.url);
  }

  getWaitingLicenseApproval(id: number): Observable<any> {
    this.url = baseUrl + "client/getWaitingLicenseApproval/" + id;

    return this.http.get(this.url);
  }

  setLicenseStatus(id: number, status: string, rejectedId: number = null): Observable<any> {

    this.url = baseUrl + "userLicense/setDriverLicenseStatus/" + id;
    if (rejectedId != null) {
      return this.http.post<any>(this.url, { "status": status, "rejected_reason_id": rejectedId });
    }
    else {
      return this.http.post<any>(this.url, { "status": status });
    }
  }

  getWaitingSelfieApprovals(): Observable<any> {
    this.url = baseUrl + "userLicense/getSelfies";
    return this.http.get(this.url);
  }

  getWaitingSelfieApproval(id: number): Observable<any> {

    this.url = baseUrl + "userLicense/getSelfie/" + id;

    return this.http.get(this.url);
  }

  getUserSelfie(id: number): Observable<any> {
    this.url = baseUrl + "client/getUserSelfie/" + id;

    return this.http.get(this.url);
  }

  setSelfieStatus(id: number, status: string, rejectedId: number = null): Observable<any> {
    this.url = baseUrl + "userLicense/setSelfieStatus/" + id;
    if (rejectedId != null) {
      return this.http.post<any>(this.url, { "status": status, "rejected_reason_id": rejectedId });
    }
    else {
      return this.http.post<any>(this.url, { "status": status });
    }
  }


}