<div class="status-div">
    <div class="content-status">
        <div class="info-title">
            <h1>Status Info</h1>
        </div>
        <div class="info-router">
            <a mat-list-item routerLinkActive="list-item-active" routerLink="map">
                Show in map
            </a>
        </div>
        <div class="info-image">
            <agm-map [latitude]="data?.lat" [longitude]="data?.long">
                <agm-marker *ngIf="data" [latitude]="data?.lat" [longitude]="data?.long" iconUrl="/assets/icons/charging.png">
                </agm-marker>
            </agm-map>
        </div>
        <div>
            <h5>Cont Serial NO: 2682468426842682</h5>
        </div>
        <div class="info-live">
            <div class="left-align">
                <div>
                    <mat-hint>Anlık araç sayısı</mat-hint>
                    <span class="data">9</span>
                </div>
            </div>
            <div class="right-align">
                <div>
                    <mat-hint>Durum</mat-hint>
                    <span class="data">Active</span>
                </div>
            </div>
        </div>

    </div>
</div>