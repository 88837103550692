<div>
    <div class="loading-screen">
        <div class="loading-spinner" *ngIf="isLoading && dataSource">
            <mat-spinner></mat-spinner>
        </div>

        <table mat-table [dataSource]="dataSource" matSort class="hover">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Surname </th>
                <td mat-cell *matCellDef="let element"> {{element.surname}} </td>
            </ng-container>

            <ng-container matColumnDef="tc">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> TC </th>
                <td mat-cell *matCellDef="let element"> {{element.tc}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone </th>
                <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
            </ng-container>

            <ng-container matColumnDef="expiration_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Expiration Date </th>
                <td mat-cell *matCellDef="let element"> {{element.expiration_date}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row)"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="12">
                    <div fxLayoutAlign="center center">
                        No data matching the filter
                    </div>
                </td>
            </tr>
        </table>
    </div>

    <mat-paginator (page)="paginatorChange($event)" [pageSize]="pageEvent.pageSize" [length]="pageEvent.length"
        [pageSizeOptions]="[5, 10, 25, 50, 100]" showFirstLastButtons></mat-paginator>
</div>