<button class="add-new" mat-raised-button color="primary" (click)="add_campaign_code()">Add New</button>
<div class="campaign-code-table">
    <div style="margin-right: 20px">
        <table mat-table [dataSource]="dataSourceCampaignCode" class="mat-elevation-z8">
        
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
          
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> Code </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>
            
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Price </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.total">
                        {{element.total | currency:"TRY":'symbol-narrow':'0.2-2'}} 
                    </span>
                    <span *ngIf="element.percentage && !element.total">
                        %{{ element.percentage }}
                    </span>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="used">
                <th mat-header-cell *matHeaderCellDef> Used </th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.used">&#10003;</span>  
                    <span *ngIf="!element.used">&#10060;</span>  
                </td>
            </ng-container>
        
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="delete_campaign_code(element.id)">Delete</button></td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumnsCampaign"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsCampaign;"></tr>
        </table>

    </div>

</div>