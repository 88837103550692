import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { OpenImage } from '../../license/license-detail/license-detail.component';

@Component({
  selector: 'app-resrevation-images',
  templateUrl: './resrevation-images.component.html',
  styleUrls: ['./resrevation-images.component.css']
})
export class ResrevationImagesComponent implements OnInit {
  photos = [];
  loading = true;
  constructor(
    private reservationService: ReservationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.reservationService.getReservationImages(this.data).subscribe(result => {
      this.loading = false;
      for (let index = 0; index < result.data.length; index++) {
        const element = result.data[index];
        // this.photos.push(this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + element))
        this.photos.push(element)
      }
    }, this.globalService.handleError);
  }

  showImage(image:string) {
    this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
  }

}
