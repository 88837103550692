import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-user-segment-type-edit',
  templateUrl: './user-segment-type-edit.component.html',
  styleUrls: ['./user-segment-type-edit.component.css']
})
export class UserSegmentTypeEditComponent implements OnInit {
  userSegmentsForm: FormGroup = new FormGroup({
    name: new FormControl('')
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:number,
    private settingsService: SettingsService,
    public dialogRef: MatDialogRef<UserSegmentTypeEditComponent>,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    if(this.data) {
      this.settingsService.getUserSegmentTypeById(this.data).subscribe((result:any) => {
        if(result.success) {
          this.userSegmentsForm.setValue({
            name:result.data.name
          });
        }
      }, this.globalService.handleError)  
    }
  }

  onFormSubmit() {
    if(this.data) {
      this.userSegmentsForm.value['id'] = this.data;
      this.settingsService.editUserSegmentType(this.userSegmentsForm.value).subscribe((result:any) => {
        this.dialogRef.close({success:true});
      }, this.globalService.handleError);
    }
    else {
      this.settingsService.saveUserSegmentType(this.userSegmentsForm.value).subscribe((result:any) => {
        this.dialogRef.close({success:true});
      }, this.globalService.handleError);
    }
  }


}
