<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Add New Park Location Charge Station</h2>
        <form [formGroup]="parkLocationForm" (ngSubmit)="onSubmit()" autocomplete="off">

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Charge Station *</mat-label>
                <input type="text" name="charge-station" placeholder="Pick one" aria-label="Charge Station" matInput formControlName="charge_station" [matAutocomplete]="autoChargeStation">
                <mat-autocomplete  #autoChargeStation="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredChargeStations | async" [value]="option">
                        {{option.cp_code_no}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!parkLocationForm.valid">Submit</button>

        </form>
    </mat-dialog-content>
</div>