<div class="panel">
    <!-- <div class="panel-list" [ngClass]="{'d-none':!panelListShow}"> -->
    <div class="panel-list" >
        <div class="list-top">
            <h1>Corporate</h1>
            <div class="list-query">
                <!-- <mat-form-field class="example-form-field" *ngIf="showSearch">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" (input)="onSearchChange($event)">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="showSearch=false;closeSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field> -->
                <!-- <button mat-button *ngIf="!showSearch" (click)="showSearch=true;">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
                </button> -->

                <button mat-button color="warn" [matMenuTriggerFor]="menu">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item (click)="listSort({id: 'name', value: true})">Name A-Z</button>
                    <button mat-menu-item (click)="listSort({id: 'name', value: false})">Name Z-A</button>
                    <button mat-menu-item (click)="listSort({id: 'surname', value: true})">Surname A-Z</button>
                    <button mat-menu-item (click)="listSort({id: 'surname', value: false})">Surname Z-A</button> -->

                </mat-menu>
            </div>
        </div>
        <div class="list-content">
            <!-- <div class="list-buttons">
                <button [ngClass]="{'active':listButtonsID == 1}" (click)="listButtons({id: 'status', value: 'active',buttonID:1})">Active</button>
                <button [ngClass]="{'active':listButtonsID == 2}" (click)="listButtons({id: 'status', value: 'passive',buttonID:2})">Passive</button>
            </div> -->
                <!-- <app-driver-item *ngFor="let user of users" [data]="user"></app-driver-item> -->
            <app-list-item [behaviorSubject]="behavior_corporate" [routeUrl]="'/dashboard/corporation/'">
                <ng-template let-item>
                    <div class="item-center">
                        <h2>
                            {{ item.name }}
                        </h2>
                        <p>
                            {{ item.contact_name_surname }}
                        </p>
                    </div>
                </ng-template>
            </app-list-item>
        </div>
    </div>
    <div class="panel-content">
        <div class="content-control">
            <div class="d-flex">
                <button mat-button>
                    <mat-icon>view_quilt</mat-icon>
                </button>
                <button mat-button (click)="addCorporation()">
                    Add New Corporate
                </button>
            </div>
            <div class="list-buttons">
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/corporation/{{ selectedID }}" [routerLinkActiveOptions]="{exact: true}">
                    Details
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/corporation/{{ selectedID }}/agreements">
                    Agreements
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/corporation/{{ selectedID }}/account-details">
                    Account Details
                </a>
            </div>
            <button mat-button >
                <mat-icon>view_quilt</mat-icon>
            </button>
        </div>
        <div class="panel-dynamic">
            <div class="router-only">
                <router-outlet></router-outlet>
            </div>
            <div>
                <app-driver-status></app-driver-status>

            </div>
        </div>
    </div>
</div>