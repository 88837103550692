<div class="electrip-form-container">
    <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
    <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="editForm" (ngSubmit)="saveParkingPlace()" *ngIf="dataLoad">
        <h2>Edit {{ data?.name }}</h2>
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill">
            <mat-label>Latitude</mat-label>
            <input matInput required formControlName="lat">
        </mat-form-field>
        <br>

        <mat-form-field appearance="fill">
            <mat-label>Longitude</mat-label>
            <input matInput required formControlName="long">
        </mat-form-field>
        <br>

        <button mat-raised-button color="primary" type="submit">Submit</button>
    </form>


</div>