import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { CarService } from 'src/app/services/triggers/car.service';

@Component({
  selector: 'app-vehicle-segment-add-vehicle',
  templateUrl: './vehicle-segment-add-vehicle.component.html',
  styleUrls: ['./vehicle-segment-add-vehicle.component.css']
})
export class VehicleSegmentAddVehicleComponent implements OnInit {
  itemForm: FormGroup = new FormGroup({
    model: new FormControl(null),
  });
  loading: boolean = false;
  filteredVehicle: any = null;
  constructor(
    private vehicleService: CarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VehicleSegmentAddVehicleComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getVehicleList();
  }

  getVehicleList(): void {
    this.vehicleService.getVehicleList({'segment_free': 1}).subscribe(result => {
      this.filteredVehicle = this.itemForm.get('model').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }

  onSubmit(): void {
    this.vehicleService.vehicleSegmentAddVehicle(this.data, this.itemForm.get('model').value).subscribe(result => {
      
    }, this.globalService.handleError);
    this.dialogRef.close(this.itemForm.get('model'));
  }

    
  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

}
