import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { DriversService } from 'src/app/services/triggers/drivers.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-driver-add-notification',
  templateUrl: './driver-add-notification.component.html',
  styleUrls: ['./driver-add-notification.component.css']
})
export class DriverAddNotificationComponent implements OnInit {
  messageForm = new FormGroup({
    title: new FormControl(null, Validators.required),
    message: new FormControl(null, Validators.required),
  });
  constructor(
    private userService: UsersService,
    private dialogRef: MatDialogRef<DriverAddNotificationComponent>,
    private globalService: GlobalService,
    private driverService: DriversService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onSubmit(): void {
    if(this.data == "firebase") {
      this.userService.sendFirebaseMessageAll(this.messageForm.value.title, this.messageForm.value.message).subscribe((result:any) => {
        if(result.success) {
          this.dialogRef.close(true);
          this.globalService.successMessage(result);
        }
      }, this.globalService.handleError);
    }
    else {
      this.userService.saveNotification(this.driverService.get_driver_id(), this.messageForm.value.title, this.messageForm.value.message).subscribe((result:any) => {
        if(result.success) {
          this.dialogRef.close(true);
          this.globalService.successMessage(result);
        }
      }, this.globalService.handleError);
    }
  }

}
