import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { RefundSettingsAddTestComponent } from './refund-settings-add-test/refund-settings-add-test.component';

@Component({
  selector: 'app-refund-settings-add',
  templateUrl: './refund-settings-add.component.html',
  styleUrls: ['./refund-settings-add.component.css']
})
export class RefundSettingsAddComponent implements OnInit {
  refundSettingsForm = new FormGroup({
    bigger_minute: new FormControl(),
    smaller_minute: new FormControl(),
    refund_percentage: new FormControl(),
    refund_amount: new FormControl(),
    response_text: new FormControl(null, Validators.required),
  });
  
  constructor(
    private reservationService: ReservationsService,
    private global: GlobalService,
    private dialogRef: MatDialogRef<RefundSettingsAddComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if(this.data) {
      this.refundSettingsForm.patchValue(this.data);
    }
  }

  openTestIt(): void {
    this.dialog.open(RefundSettingsAddTestComponent, {
      data: this.refundSettingsForm,
      width: "500px",
    });
  }

  onSubmit(): void {
    if(this.data) {
      this.refundSettingsForm.addControl("id", new FormControl(this.data.id));
    }
    this.reservationService.saveRefundSettings(this.refundSettingsForm).subscribe(result => {
      this.dialogRef.close(true);
    }, this.global.handleError)
  }

}
