import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';
import { AddVehicleGroupComponent } from './add-vehicle-group/add-vehicle-group.component';

@Component({
  selector: 'app-vehicle-group',
  templateUrl: './vehicle-group.component.html',
  styleUrls: ['./vehicle-group.component.css']
})
export class VehicleGroupComponent implements OnInit {
  data: any;
  models: any;
  constructor(
    private priceService: PricingService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getVehicleGroup();
  }

  getVehicleGroup(): void {
    this.priceService.getVehicleGroupList().subscribe({
      next: (result) => {
        this.data = result.data;
      },
      error: this.globalService.handleError
    });
    this.priceService.getModelList().subscribe({
      next: (result) => {
        this.models = result.data;
      },
      error: this.globalService.handleError
    });
  }

  addVehicleGroup(): void {
    this.dialog.open(AddVehicleGroupComponent, {
      data: this.models,
      minWidth: "400px",
    }).afterClosed().subscribe({
      next: (result) => {
        this.getVehicleGroup();
      }
    })
  }
  removeGroup(id: number): void {
    if (confirm('Are you sure ?')) {
      this.priceService.deleteVehicleGroup(id).subscribe({
        next: (result) => {
          this.globalService.successMessage(result)
          this.getVehicleGroup();
        },
        error: this.globalService.handleError
      });
    }
  }

}
