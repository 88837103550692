import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalService {

  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  getAllGsmGateway():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/getAllGsmGateway";

    return this.http.get(this.url, { headers: headers });    
  }

}
