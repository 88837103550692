import { Direction } from '@angular/cdk/bidi';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';

@Component({
  selector: 'app-pricing-edit',
  templateUrl: './pricing-edit.component.html',
  styleUrls: ['./pricing-edit.component.css']
})
export class PricingEditComponent implements OnInit {
  priceForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    vehicle_group: new FormControl(),
    corporate_group: new FormControl(),
    title_group: new FormControl(),
    name: new FormControl(),
    daily: new FormControl(),
    hourly: new FormControl(),
    minute: new FormControl(),
    every_15_minute: new FormControl(),
    start_time: new FormControl(),
    end_time: new FormControl(),
  });
  loading: boolean = false;
  filteredVehicleSegment: any;
  filteredCorporationSegment: any;
  titleGroup: any;
  header: string = 'Add';
  old_price_list: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private priceService: PricingService,
    private dialogRef: MatDialogRef<PricingEditComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getPriceDetails();
    if(this.data) {
      this.header = 'Edit';
      this.priceService.getPriceDetail(this.data).subscribe({
        next: (result) => {
          this.priceForm.patchValue(result.data.price_detail);
          this.old_price_list = result.data.old_prices;
        },
        error: this.globalService.handleError
      })
    }
  }

  getPriceDetails(): void {
    this.loading = false;
    this.priceService.getSavePriceDetails().subscribe({
      next: (result) => {
        this.loading = true;
        this.filteredCorporationSegment = this.priceForm.get('corporate_group').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.corporate_group))
        );
        this.filteredVehicleSegment = this.priceForm.get('vehicle_group').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.vehicle_group))
        );

        this.titleGroup = result.data.title_group;
      },
      error: this.globalService.handleError
    });
  }

  onSubmit(): void {
    var start_time = this.priceForm.get('start_time');
    if (start_time.value == '') {
      start_time.setValue(null);
    }
    var end_time = this.priceForm.get('end_time');
    if (end_time.value == '') {
      end_time.setValue(null);
    }
    var end_time = this.priceForm.get('title_group');
    if (end_time.value == '') {
      end_time.setValue(null);
    }

    this.priceService.savePrice(this.priceForm).subscribe({
      next: (result) => {
        this.globalService.successMessage(result);
        this.dialogRef.close();
      },
      error: this.globalService.handleError
    });
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    if(!value) return data;
    const filterValue = value?.name ? value.name.toLowerCase() : value.toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  delete(): void {
    this.priceService.deletePrice(this.data).subscribe({
      next: (result) => {
        this.globalService.successMessage(result);
        this.dialogRef.close();
      },
      error: this.globalService.handleError
    })
  }

}
