import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CorporationService } from 'src/app/services/corporation.service';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import { CorporationSegmentsEditComponent } from './corporation-segments-edit/corporation-segments-edit.component';

@Component({
  selector: 'app-corporation-segments',
  templateUrl: './corporation-segments.component.html',
  styleUrls: ['./corporation-segments.component.css']
})
export class CorporationSegmentsComponent implements OnInit {
  dataSource: any = null;
  displayedColumns: Array<string> = ["id", "name", "car_count"];
  constructor(
    private dialog: MatDialog,
    private globalService: GlobalService,
    private corporationService: CorporateService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(): void {
    this.corporationService.getCorporationSegmentTypes().subscribe(result => {
      
      this.dataSource = result.data;
    }, this.globalService.handleError);
  }

  addNew(): void {
    this.dialog.open(CorporationSegmentsEditComponent, {
      width: "600px",
      maxHeight: "700px",
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      this.getList();
    });
  }

  tableClick(id: number): void {
    this.dialog.open(CorporationSegmentsEditComponent, {
      data: id,
      width: "600px",
      maxHeight: "700px",
      autoFocus: false,
    }).afterClosed().subscribe(result => {
      this.getList();
    });
  }

 


}
