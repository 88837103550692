<div class="panelInfo">
    <mat-progress-bar mode="buffer" *ngIf="openedLicense?.selfieID?false:true"></mat-progress-bar>
    <div *ngIf="openedLicense?.selfieID?true:false">
        <div class="document-dialog">
            <div class="images">
                <img [src]="licenseFront" (click)="showImage(licenseFront)">
            </div>
            <div>
                <div class="status">
                    Status: <b [ngStyle]="{'color': status_colors[openedLicense.status]}">{{ openedLicense.status }}</b>
                </div>
                <div class="rejected-reason" *ngIf="openedLicense.rejectedReason">
                    <h2>{{ openedLicense.rejectedReason.title }}</h2>
                    <h4>{{ openedLicense.rejectedReason.explanation }}</h4>

                </div>
            </div>
        </div>

        <div class="panel-buttons" *ngIf="openedLicense.status == 'Waiting'">
            <button mat-raised-button color="warn" (click)="panelReject(openedLicense.selfieID)" >Rejected</button>
            <button mat-raised-button color="primary" (click)="panelAccept(openedLicense.selfieID)" >Approved</button>

        </div>

    </div>

</div>