import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { DriversService } from 'src/app/services/triggers/drivers.service';
import { UsersService } from 'src/app/services/users.service';
import { DriverAddNotificationComponent } from './driver-add-notification/driver-add-notification.component';

@Component({
  selector: 'app-driver-notifications',
  templateUrl: './driver-notifications.component.html',
  styleUrls: ['./driver-notifications.component.css']
})
export class DriverNotificationsComponent implements OnInit {
  notify_data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private mapControl: MapControlServiceService,
    private dialog: MatDialog,
    private driverService: DriversService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.getNotifs();
  }
  
  getNotifs(): void {
    this.userService.getNotification(this.driverService.get_driver_id()).subscribe((data) => {
      this.notify_data = data.data;
    }, this.globalService.handleError);
  }

  delete_notify(id: number): void {
    if(confirm("Are you sure to delete this message")) {
      this.userService.delNotification(id).subscribe((result) => {
        this.getNotifs();
        if(result.success) {
          this.mapControl.showSnackbar("Deleted a message.");
        }
        else {
          this.mapControl.showSnackbar("Error delete_notify");
        }
      }, this.globalService.handleError);
    }
  }

  addNotifications(): void {
    this.dialog.open(DriverAddNotificationComponent, {
      width: '400px'
    }).afterClosed().subscribe((result) => {
      this.getNotifs();
    });
  }

}
