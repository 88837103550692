import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { MapControlServiceService } from '../map-control-service.service';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService extends MapControlServiceService {

  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(
    private mapControl: MapControlServiceService,
    public http: HttpClient,
    private globalService: GlobalService
  ) {
    super(mapControl._snackBar);
  }

  All_Reservations: BehaviorSubject<any> = new BehaviorSubject({ "data": null });

  getAllReservations(search_query: any, paginator: any): void {
    var url = baseUrl + "reservation/reservationList";

    var send_json = {
      headers: this.headers,
      params: { ...search_query, pageSize: paginator.pageSize, page: paginator.pageIndex + 1 }
    }

    this.http.get(url, send_json).subscribe((result: any) => {
      this.All_Reservations.next(result);
    }, this.globalService.handleError);
  }

  getAddReservationFieldsData(): Observable<any> {
    var url = baseUrl + "reservation/newReservationInformation";
    return this.http.get(url, { headers: this.headers });
  }

  saveReservation(body: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/saveReservationPanel";
    return this.http.post<any>(url, body.value, { headers: this.headers });
  }

  setReservationStatus(id: number, status: string): Observable<any> {
    var url = baseUrl + "reservation/setReservationStatus";
    return this.http.post<any>(url, { "id": id, "status": status }, { headers: this.headers });
  }

  setAdditionalTimeReservation(id: number, body: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/addAdditionalTimeReservation";
    return this.http.post<any>(url, { "id": id, ...body.value }, { headers: this.headers });
  }

  refundReservation(id: number, body: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/refundReservation";
    return this.http.post<any>(url, { "id": id, ...body.value }, { headers: this.headers });
  }

  getRefundList(): Observable<any> {
    var url = baseUrl + "reservation/getRefundReservationList";
    return this.http.get<any>(url, { headers: this.headers });
  }

  getAdditionalTimeCharge(id: number, time: string): Observable<any> {
    var url = baseUrl + "reservation/getAdditionalTimeCharge";
    return this.http.post<any>(url, { "id": id, "time": time }, { headers: this.headers });
  }

  refundPay(id: number): Observable<any> {
    var url = baseUrl + "reservation/refundReservationCharge";
    return this.http.post<any>(url, { "id": id }, { headers: this.headers });
  }

  getVehiclesForRes(data: any): Observable<any> {
    var url = baseUrl + "reservation/vehicleListForReservation";
    return this.http.post<any>(url, data, { headers: this.headers });
  }

  getPaymentPriceForRes(body: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/calculatePrepareReservationPrice";
    return this.http.post<any>(url, body.value, { headers: this.headers });
  }

  getRefundSettingsList(): Observable<any> {
    var url = baseUrl + "reservation/refundSettingsList";
    return this.http.get<any>(url, { headers: this.headers });
  }

  deleteRefundSettings(id: number): Observable<any> {
    var url = baseUrl + "reservation/deleteRefundSettings/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  saveRefundSettings(body: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/saveRefundSettings";
    return this.http.post<any>(url, body.value, { headers: this.headers });
  }

  refundSettingsTest(refundSettings: FormGroup, testData: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/refundSettingsTest";
    return this.http.post<any>(url, {
      "refundSettings": refundSettings.value,
      "testData": testData.value
    }, { headers: this.headers });
  }

  reservationListName(): Observable<any> {
    var url = baseUrl + "reservation/reservationNames";
    return this.http.get<any>(url, { headers: this.headers });
  }

  getReservationImages(reservation_id: number): Observable<any> {
    var url = baseUrl + "reservation/reservationImages/" + reservation_id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getReservationTable(day): Observable<any> {
    var url = baseUrl + "reservation/reservationTable";
    return this.http.get<any>(url, { headers: this.headers, params: { day: day } });
  }

  getExcel(): Observable<any> {
    var url = baseUrl + "reservation/exportCSVBilling";
    return this.http.get(url, { headers: this.headers });
  }

  downloadExcel(file_name: string): Observable<any> {
    var url = baseUrl + "reservation/downloadExportReservations";
    return this.http.post(url, { file_name }, { headers: this.headers, responseType: 'blob' });
  }

  getReservationEndInfo(id: number): Observable<any> {
    var url = baseUrl + "reservation/reservationEndInfo/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getReservationEndInfoPrice(id: number, endTime: string): Observable<any> {
    var url = baseUrl + "reservation/reservationEndInfoPrice/" + id;
    return this.http.post<any>(url, { end_time: endTime }, { headers: this.headers });
  }

  setReservationEndInfo(id: number, form: FormGroup): Observable<any> {
    var url = baseUrl + "reservation/setReservationEndInfo/" + id;
    return this.http.post<any>(url, form.value, { headers: this.headers });
  }

  saveRefundList(form: FormGroup): Observable<any> {
    var url = baseUrl + "payment/saveReservationRefund";
    return this.http.post<any>(url, form.value, { headers: this.headers });
  }

  reservationStatusListCurrent(search, status): Observable<any> {
    var url = baseUrl + "reservation/reservationStatusListCurrent";
    return this.http.get(url, { headers: this.headers, params: {search, status} });
  }

  reservationStatusListFeature(search, status): Observable<any> {
    var url = baseUrl + "reservation/reservationStatusListFeature";
    return this.http.get(url, { headers: this.headers, params: {search, status} });
  }

  reservationStatusListEnded(page_size: number, current_page: number, search, status): Observable<any> {
    var url = `${baseUrl}reservation/reservationStatusListEnded?pageSize=${page_size}&page=${current_page}`;
    return this.http.get(url, { headers: this.headers, params: {search, status} });
  }

  getReservationTerminateImages(reservation_id: number): Observable<any> {
    var url = baseUrl + "reservation/reservationTerminateImages/" + reservation_id;
    return this.http.get<any>(url, { headers: this.headers });
  }


  reservationApprove(res_id: number, status: boolean): Observable<any> {
    var url = baseUrl + "reservation/reservationApprovePortal";
    return this.http.post<any>(url, { res_id, status }, { headers: this.headers });
  }


}
