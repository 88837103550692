<button class="add-new" mat-raised-button color="primary" (click)="add_new()">Add New</button>
<div class="campaign-code-table">
    <div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>
          
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Price </th>
                <td mat-cell *matCellDef="let element">
                        {{element.price}} 
                </td>
            </ng-container>

            <ng-container matColumnDef="text">
                <th mat-header-cell *matHeaderCellDef> Text </th>
                <td mat-cell *matCellDef="let element"> {{element.response_text}} </td>
            </ng-container>

            <ng-container matColumnDef="update">
                <th mat-header-cell *matHeaderCellDef> Update </th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-raised-button color="primary" (click)="updateSettings(element)">Update</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef> Delete </th>
                <td mat-cell *matCellDef="let element"> 
                    <button mat-raised-button color="warn" (click)="deleteSettings(element.id)">Delete</button>
                </td>
            </ng-container>
            
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>

</div>