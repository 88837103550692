import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdditionalBillingService } from 'src/app/services/additional-billing.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationDetailComponent } from '../../reservations/reservation-detail/reservation-detail.component';
import { AdditionalBillingAddItemComponent } from '../additional-billing-add/additional-billing-add-item/additional-billing-add-item.component';

@Component({
  selector: 'app-additional-billing-detail',
  templateUrl: './additional-billing-detail.component.html',
  styleUrls: ['./additional-billing-detail.component.css']
})
export class AdditionalBillingDetailComponent implements OnInit {
  billing_data: any;
  constructor(
    private billingService: AdditionalBillingService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private dialogRef: MatDialogRef<AdditionalBillingDetailComponent>,
  ) { }

  ngOnInit(): void {
    this.getBilling();
  }
  
  getBilling(): void {
    this.billingService.getAdditionalBilling(this.data).subscribe(result => {
      this.billing_data = result.data;
      
    }, this.globalService.handleError);
  }

  removeItem(id: number, item_id: number): void {
    if(confirm("Are you sure ?")) {
      this.billingService.removeItemFromBilling(id, item_id).subscribe(result => {
        
        this.getBilling();
      }, this.globalService.handleError)
    }
  }

  addItem(id: number): void {
    this.dialog.open(AdditionalBillingAddItemComponent, {
      data: id,
    }).afterClosed().subscribe(result => {
      if(result) {
        this.getBilling();
      }
    });
  }

  openReservation(id: number): void {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: id
    });
  }

  sendSap(id: number): void {
    this.billingService.sendBillingToSap(id).subscribe(result => {
      this.getBilling();
      this.globalService.successMessage(result);
    }, this.globalService.handleError);
  }

  sendAsecco(id: number): void {
    this.billingService.sendBillingToAsecco(id).subscribe(result => {
      this.getBilling();
      this.globalService.successMessage(result);
    }, this.globalService.handleError);
  }

  deleteBilling(): void {
    this.billingService.deleteBilling(this.billing_data.id).subscribe({
      next: result => {
        this.globalService.successMessage(result);
        this.dialogRef.close("deleted");
      },
      error: this.globalService.handleError
    })
  }

}
