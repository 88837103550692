import { HttpClient, HttpRequest } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AdditionalBillingService } from 'src/app/services/additional-billing.service';
import { GlobalService } from 'src/app/services/global.service';
import { BASEURL } from 'src/environments/environment';
import { AdditionalBillingAddComponent } from './additional-billing-add/additional-billing-add.component';
import { AdditionalBillingDetailComponent } from './additional-billing-detail/additional-billing-detail.component';

@Component({
  selector: 'app-additional-billing',
  templateUrl: './additional-billing.component.html',
  styleUrls: ['./additional-billing.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class AdditionalBillingComponent implements OnInit {
  billing_data: any = null;
  displayedColumns: Array<string> = ["id", "reservation", "order_id", "name", "corporate_name", "using_type", "vehicle", "billing_items", "billing_price", "billing_date", "sending_sap", "sending_asecco"];
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  current_page = 0
  pageEvent: PageEvent;
  filter = "";
  excel_file: any;
  excel_progress: string = "Download Excel";
  last_write = "";
  constructor(
    private billingService: AdditionalBillingService,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private cd: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.getData();



  }

  addNewBilling(): void {
    this.dialog.open(AdditionalBillingAddComponent, {
      minWidth: "500px"
    }).afterClosed().subscribe(data => {
      if (data) {
        this.getData();
      }
    });
  }

  tableClick(id: number): void {
    this.dialog.open(AdditionalBillingDetailComponent, {
      minWidth: "500px",
      data: id,
      autoFocus: false
    }).afterClosed().subscribe(data => {
      if (data) {
        this.getData();
      }
    });
  }

  getData(): void {
    this.billingService.getAdditionalBillings(this.current_page + 1, this.pageSize, this.filter).subscribe(result => {
      this.cd.markForCheck();
      this.billing_data = result.data.data;
    }, this.globalService.handleError);
  }

  getDataPagination(event: PageEvent): PageEvent {
    this.current_page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getData();
    return event;
  }

  billingStatusChange(value: string): void {
    this.filter = value;
    this.getData();
  }


  async excelDownload(): Promise<void> {

    if(this.excel_progress != "Download Excel") return;

    this.excel_progress = "Please wait";
    this.billingService.getExcel().subscribe(result => {
    }, this.globalService.handleError);
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: '' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'additional_billings.xlsx';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100)
    // window.open(url);
  }


}
