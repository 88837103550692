

<div class="electrip-form">

    <mat-dialog-content>
        <h2>Add user</h2>
        <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()" autocomplete="off">


            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Users *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="User" matInput formControlName="usersControlName"
                    [matAutocomplete]="autoUser">
                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let user of users | async" [value]="user">
                        {{ user.phoneNumber }} - {{ user.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>



            <div class="add-button" style="margin-top:10px;">
                <button mat-raised-button color="primary">Add User</button>
            </div>

        </form>
    </mat-dialog-content>
</div>