<div class="electrip-form">
    <mat-dialog-content>
        <h2>Reservation will be cancelled. Do you confirm ?</h2>
        <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <mat-form-field>
                <input matInput type="number" placeholder="Refund amount" formControlName="refund_amount">
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!reservationForm.valid">Ok</button>

        </form>
    </mat-dialog-content>
</div>