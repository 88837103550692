import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
  
})
export class DefaultComponent implements OnInit {
  constructor() {

  }

  ngOnInit(): void {
    
  }

}
