import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { baseUrl } from 'src/environments/environment';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit {
  imageForm = new FormGroup({
    image: new FormControl(null, Validators.required)
  });
  image_uri: any;
  selected_image: any;
  constructor(
    private dialogRef: MatDialogRef<ImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data:number,
    private http: HttpClient,
  ) { }
  

  ngOnInit(): void {
  }

  onFileChanged(file: FileList): void {
    var reader = new FileReader();
    reader.onload = (event:any) => {
      this.image_uri = event.target.result;
      console.log(event.target);
    }
    reader.readAsDataURL(file.item(0));
    this.selected_image = file.item(0);
    this.imageForm.patchValue({
      image: this.selected_image
    });
  }


  removeImage(): void {
    this.image_uri = null;
    this.selected_image = null;
    this.imageForm.reset();
  }

  onSubmit(): void {

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    });
    this.http.post<any>(baseUrl + this.data["uploadUrl"], {"image": this.image_uri.split(",")[1]}, {headers:headers}).subscribe((result: any) => {
      // this.dialogRef.close(true);
    });
  }
}
