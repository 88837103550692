import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { CarService } from 'src/app/services/triggers/car.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-vehicle-map',
  templateUrl: './vehicle-map.component.html',
  styleUrls: ['./vehicle-map.component.css']
})
export class VehicleMapComponent implements OnInit {
  data:any;
  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehicleService,
    private carService: CarService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.carService.selected_car.subscribe(result => {
      
      if(result.data) {
        this.data = result.data;
      }
    }, this.globalService.handleError);    
    this.route.params.subscribe((uri:any) => {
      this.getCar(uri.id);
    });
  }
  
  getCar(id:number): void {
    this.carService.get_car(id);
  }
}
