import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css']
})
export class SendMessageComponent implements OnInit {
  reservationForm = new FormGroup({
    user: new FormControl(null, [
      Validators.required
    ]),
    message: new FormControl(),
  });
  filteredUser: Observable<any>;
  loading: boolean = true;

  constructor(
    private userService: UsersService,
    private dialogRef: MatDialogRef<SendMessageComponent>,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.userService.getUserList().subscribe((result) => {
      this.loading = false;
      if(result.success) {
        this.filteredUser = this.reservationForm.get('user').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.users))
        );
      }
    }, this.globalService.handleError);
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    this.userService.sendMessage(this.reservationForm).subscribe((result:any) => {
      if(result.success) {
        // this.dialogRef.close(result.data.reservationId);
      }
    }, this.globalService.handleError);
    
  }

}
