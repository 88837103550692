import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(
    public _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  private openSnackbar = (text: any) => {
    this._snackBar.open(text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });

  }

  public handleError = (error: any) => {
    console.log(error)
    if(error.status ==  403) {
      this.openSnackbar("You do not have permission to access this operation.");
      return
    }
    this.openSnackbar(error?.error?.errorMessage || "Error.");
  }

  public showMessage = (txt: any) => {
    this.openSnackbar(txt);
  }

  public successMessage = (result: any) => {
    this.openSnackbar(result.data.message);
  }

  public showImage(image: any) {
    this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
  }


}


@Component({
  selector: 'open-image',
  template: '<img [src]="data.changingThisBreaksApplicationSecurity || data" style="width:100%" >',
})
export class OpenImage {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}