import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { StatusTypeAddComponent } from '../../status-type/status-type-add/status-type-add.component';

@Component({
  selector: 'app-vehicle-status-add',
  templateUrl: './vehicle-status-add.component.html',
  styleUrls: ['./vehicle-status-add.component.css']
})
export class VehicleStatusAddComponent implements OnInit {
  status_types: any = [];
  status_type_name: string = "";

  vehicleStatusForm = new FormGroup({
    statusTypeId: new FormControl(),
    statusStartDate: new FormControl(),
    statusEndDate: new FormControl(),
  });
  constructor(
    private dialog: MatDialog,
    private vehicleService: VehicleService,
    private dialogRef: MatDialogRef<VehicleStatusAddComponent>,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.getStatusTypes();
  }

  openStatusType(): void {
    const dialogRef = this.dialog.open(StatusTypeAddComponent, {
      width: "300px"
    }).afterClosed().subscribe((name: any) => {
      if(typeof name === "string") {
        this.status_type_name = name;
      }
      this.getStatusTypes();
      dialogRef.unsubscribe();
    });
  }

  getStatusTypes(): void {
    this.vehicleService.getStatusTypes().subscribe((res:any) => {
      if(res.success) {
        this.status_types = res.data;
        console.log(this.status_types);
      }
    }, this.globalService.handleError);
  }


  onSubmit(): void {
    let statusStartDate = moment(this.vehicleStatusForm.value.statusStartDate).format('YYYY-MM-DD');
    this.vehicleStatusForm.value.statusStartDate = statusStartDate;
    let statusEndDate = moment(this.vehicleStatusForm.value.statusEndDate).format('YYYY-MM-DD');
    this.vehicleStatusForm.value.statusEndDate = statusEndDate;

    this.vehicleService.addVehicleStatus(this.vehicleStatusForm).subscribe((res:any) => {
      if (res.success) {
        this.mapControl.showSnackbar(res.data?.message);
      } else {
        this.mapControl.showSnackbar(res.errorMessage);
      }
      if(this.vehicleStatusForm.value.statusTypeId) {
        var status_obj = this.status_types.filter(((obj:any) => obj.id == this.vehicleStatusForm.value.statusTypeId));
        this.dialogRef.close({
          "id": res.data.id,
          "name": status_obj[0].name,
        });
      }
      else {
        this.dialogRef.close();
      }
    }, this.globalService.handleError);
  }

}
