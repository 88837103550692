import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-add-user-tags',
  templateUrl: './add-user-tags.component.html',
  styleUrls: ['./add-user-tags.component.css']
})
export class AddUserTagsComponent implements OnInit {
  form_var = new FormGroup({
    name: new FormControl(null, Validators.required),
    color: new FormControl('#ffffff', Validators.required),
  })
  constructor(
    private userService: UsersService,
    private dialogRef: MatDialogRef<AddUserTagsComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  submit_form(): void {
    this.userService.addUserTags(this.form_var).subscribe(res => {
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }


}
