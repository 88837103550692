import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { DocumentService } from 'src/app/services/users/document.service';

@Component({
  selector: 'app-corporations',
  templateUrl: './corporations.component.html',
  styleUrls: ['./corporations.component.css']
})
export class CorporationsComponent implements OnInit {
  corporationData: any;
  corporationForm: FormGroup = new FormGroup({
    corporation: new FormControl('', Validators.required),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<CorporationsComponent>,
    private documentService: DocumentService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.documentService.getCorporations().subscribe({
      next: (result: any) => {
        this.corporationData = result.data;
        console.log(result)
      },
      error: this.globalService.handleError
    })
  }

  onFormSubmit(): void {
    this.dialogRef.close(this.corporationForm.value.corporation);
  }
}
