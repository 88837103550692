<div class="vehicle-images">
    <div class="add-button">
        <button mat-raised-button color="primary" (click)="addImage()">Add New</button>
    </div>

    <div class="images">
        <div class="image" *ngFor="let image of image_data">
            <img src="{{ image.imageUrl }}" alt="" (click)="imageFullOpen(image)">
            <p>{{ image.name }}</p>

        </div>
    </div>

</div>