import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/services/documents.service';
import { GlobalService } from 'src/app/services/global.service';
import { RejectUserDocumentComponent } from '../reject-user-document/reject-user-document.component';

@Component({
  selector: 'app-user-document-driver-license',
  templateUrl: './user-document-driver-license.component.html',
  styleUrls: ['./user-document-driver-license.component.css']
})
export class UserDocumentDriverLicenseComponent implements OnInit {
  @Input() user_id: number;
  @Output() status = new EventEmitter<Object>();

  data: any;
  licenseFront: any;
  licenseFrontValue: number;
  licenseFrontMode: string = 'indeterminate';

  licenseBack: any;
  licenseBackValue: number;
  licenseBackMode: string = 'indeterminate';
  constructor(
    private documentService: DocumentsService,
    private _sanitizer: DomSanitizer,
    public globalService: GlobalService,
    public dialog: MatDialog,
  ) { }



  ngOnInit(): void {
    this.documentService.getUserDriverLicense(this.user_id).subscribe((result) => {
      this.data = result.data;

    }, this.globalService.handleError);
  }

  panelAccept(): void {
    this.documentService.setDriverLicenseStatus(this.data.id, 'Approved').subscribe(result => {
      this.data = result.data;
      this.status.emit({ process: 'driver_license', status: 'Approved' });
    }, this.globalService.handleError);
  }

  panelReject(): void {
    this.dialog.open(RejectUserDocumentComponent, {
      data: {
        model: 'DriverLicense',
        selected: this.data.license_rejected_reason
      },
      width: '500px',
      maxWidth: '90%'
    }).afterClosed().subscribe(res => {
      if (res === undefined) return;

      this.documentService.setDriverLicenseRejected(this.data.id, res).subscribe(result => {
        this.data = result.data;
        this.status.emit({ process: 'driver_license', status: 'Rejected' });
      }, this.globalService.handleError);

      console.log(res);
    });
  }


}
