import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CarService } from 'src/app/services/triggers/car.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-vehicle-status',
  templateUrl: './vehicle-status.component.html',
  styleUrls: ['./vehicle-status.component.css']
})
export class VehicleStatusComponent implements OnInit {
  data:any;
  subscription:any;
  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehicleService,
    private carService: CarService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.carService.selected_car.subscribe((result: any) => {
      if(result.data) {
        this.data = result.data;
      }
      else {
        this.data = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
