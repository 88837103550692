<!-- <div class="list-item Open" routerLinkActive="list-item-active" routerLink="/dashboard/charge-stations/{{ data.id }}"> -->
<div class="list-item Open" (click)="itemClick(data)">
    <div class="item-content">
        <div class="info-content">
            <div class="card-text">
                <h3><strong> {{ data.cp_code_no }} </strong></h3>
                <p> {{ data.city }} - {{ data.district }} </p>
                <mat-hint> {{ data.cp_type }} </mat-hint>
            </div>
        </div>
        <div class="list-icon">
            <div class="icon">
                <span>5</span>
                <mat-icon> ev_station</mat-icon>
            </div>

        </div>
    </div>
</div>