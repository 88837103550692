import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { MapControlServiceService } from './map-control-service.service';

@Injectable({
  providedIn: 'root'
})
export class CampaignCodeService extends MapControlServiceService {
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(
    private mapControl: MapControlServiceService,
    public http: HttpClient,
  ) {
    super(mapControl._snackBar);
  }
  
  getCampaignCodes(): Observable<any> {
    var url = baseUrl + "reservation/getCampaignCodes";
    return this.http.get(url, { headers: this.headers });
  }

  saveCampaignCode(body: FormGroup):Observable<any> {
    var url = baseUrl + "reservation/saveCampaignCode";
    return this.http.post<any>(url, body.value, { headers: this.headers });
  }

  deleteCampaignCode(id: number): Observable<any> {
    var url = baseUrl + "reservation/deleteCampaignCode";
    return this.http.post<any>(url, {"id": id}, { headers: this.headers });
  }

}
