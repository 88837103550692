import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { REFUND_STATUS } from 'src/environments/environment';
import { ReservationDetailComponent } from '../../reservation-detail/reservation-detail.component';

@Component({
  selector: 'app-add-reservation-refund',
  templateUrl: './add-reservation-refund.component.html',
  styleUrls: ['./add-reservation-refund.component.css']
})
export class AddReservationRefundComponent implements OnInit {
  billingForm = new FormGroup({
    reservation: new FormControl(null, Validators.required),
    tutar: new FormControl(null, Validators.required),
    neden: new FormControl(),
    aciklama: new FormControl()
  });
  res_detail: any;
  filteredReservation: any = null;
  refund_status = REFUND_STATUS;
  constructor(
    private reservationService: ReservationsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddReservationRefundComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

    this.reservationService.reservationListName().subscribe(result => {
      this.filteredReservation = this.billingForm.get('reservation').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);

    

    this.billingForm.valueChanges.subscribe(val => {
      if(val.reservation == "") {
        this.res_detail = null;
      }
      if(val.reservation.id && this.res_detail != val.reservation.id) {
        this.res_detail = val.reservation.id;
      }
    })
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  reservationDetail(): void {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: this.res_detail
    });
  }

  onSubmit(): void {
    this.reservationService.saveRefundList(this.billingForm).subscribe(result => {
      if(result.success) {
        this.dialogRef.close(true);
      }
      this.globalService.successMessage(result);
    }, this.globalService.handleError);
  }


}
