import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsurancesService {
  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  getAllInsurances():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/getAllInsurances";

    return this.http.get(this.url, { headers: headers });    
  }

  getAllInsuranceTypes():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/getAllInsuranceTypes";

    return this.http.get(this.url, { headers: headers });    
  }

  getInsurance(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/getVehicleInsurance/" + id;

    return this.http.get(this.url, { headers: headers });    
  }

  editInsurance(body:any):Observable<any> {
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/editInsurance";

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  saveInsurance(body:any):Observable<any> {
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/saveInsurance";

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  deleteInsurance(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/deleteInsurance/" + id;

    return this.http.get(this.url, { headers: headers });    
  }
}
