import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CorporationService } from 'src/app/services/corporation.service';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { CorporationEditComponent } from '../corporation-edit/corporation-edit.component';
import { CorporationUsersAddComponent } from './corporation-users-add/corporation-users-add.component';
import { CorporationCarsAddComponent } from './corporation-cars-add/corporation-cars-add.component';

@Component({
  selector: 'app-corporation-users',
  templateUrl: './corporation-users.component.html',
  styleUrls: ['./corporation-users.component.css']
})
export class CorporationUsersComponent implements OnInit {
  corpDetail: any;
  corpUsers: any;
  corpVehicles: any;
  displayedColumns: string[] = ['id', 'name', 'phone'];
  displayedColumnsVehicle: string[] = ['id', 'plate', 'remove'];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:number,
    private corporationService: CorporationService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<CorporationUsersComponent>,
    private dialog: MatDialog,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.getCorp();
    this.getAllCorpUsers();
    this.getAllVehicles();
  }
  
  getCorp() {
    this.corporationService.getCorporation(this.data).subscribe((result:any) => {
      if(result.success) {
        this.corpDetail = result.data;
        
      }
    }, this.globalService.handleError);
  }
  
  getAllCorpUsers() {
    this.corporationService.getAllCorporateUser(this.data).subscribe((result:any) => {
      if(result.success) {
        this.corpUsers = result.data;
        
      }
    }, this.globalService.handleError);
  }

  getAllVehicles() {
    this.corporationService.getAllCorporateVehicles(this.data).subscribe((result:any) => {
      if(result.success) {
        this.corpVehicles = result.data;
        
      }
    }, this.globalService.handleError);
  }

  removeVehicle(vehicle_id:number) {
    if (confirm("Are you sure ?")) {
      this.corporationService.removeVehicleToCorporate(this.data, vehicle_id).subscribe((result:any) => {
        this.getAllVehicles();
      }, this.globalService.handleError
      );
    }
  }

  editCorporation() {
    console.log(this.data);
    const dialogShow = this.dialog.open(CorporationEditComponent, {
      data: this.data
    });
    dialogShow.afterClosed().subscribe((result:any)=> {
      if(result == "ok") {
        this._snackBar.open('Corporation Successfully Change', 'Ok', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000,
        });
        this.getCorp();
        this.getAllCorpUsers();
      }
    });
  }

  delCorporation() {
    if (confirm("Are you sure ?")) {
      this.corporationService.deleteCorporation(this.data).subscribe((result:any) => {
        this.dialogClose("Deleted");
      }, this.globalService.handleError);
    }
  }

  addUserToCorporation() {
    var userIDs = []; 
    this.corpUsers.forEach(element => {
      userIDs.push(element.user_id);
    });
    const dialogShow = this.dialog.open(CorporationUsersAddComponent, {
      data:{
        corp_id:this.data,
        users:userIDs
      },
      width: '400px',
    });
    dialogShow.afterClosed().subscribe((result:any)=> {
      if(result == "ok") {
        this.getAllCorpUsers();
      }
    });
  
  }

  addVehicleToCorporation() {
    this.dialog.open(CorporationCarsAddComponent, {
      data: this.data,
      width: '400px',
    }).afterClosed().subscribe((result:any)=> {
      if(result == "ok") {
        this.getAllVehicles();
      }
    })
  }

  showUserDetail(userData:any) {
    const dialogShow = this.dialog.open(CorporationUserDetail, {
      data:{ ...userData, "corp_id":this.data },
    });
    dialogShow.afterClosed().subscribe((result:any)=> {
      if(result == "ok") {
        this.getAllCorpUsers();
      }
    });
  }

  dialogClose(text:string) {
    this._snackBar.open('Corporation Successfully ' + text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    this.dialogRef.close();
  }

}


@Component({
  selector: 'corp-user-detail',
  template: `
  <table class="expandedTable" >
    <tr>
        <td>Name</td>
        <td>{{ data?.name }}</td>
    </tr>
    <tr>
        <td>Surname</td>
        <td>{{ data?.surname }}</td>
    </tr>
    <tr>
        <td>TCKN or Passport</td>
        <td>{{ data?.tcknOrPassport }}</td>
    </tr>
    <tr>
        <td>Phone Number</td>
        <td>{{ data?.phoneNumber }}</td>
    </tr>
    <tr>
        <td>Birth Date</td>
        <td>{{ data?.birthDate }}</td>
    </tr>
    <tr>
        <td>Email</td>
        <td>{{ data?.email }}</td>
    </tr>
    <tr>
        <td>Given Country</td>
        <td>{{ data?.givenCountry }}</td>
    </tr>
    
  </table>

  <div class="add-button" style="margin-top:10px;">
    <button mat-raised-button color="warn" (click)="removeUser()">Delete User From Corporation</button> 
  </div>
  `,
  styleUrls: ['./corporation-users.component.css']
})
export class CorporationUserDetail implements OnInit{
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CorporationUserDetail>,
    private corporationService:CorporationService,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
    ) { 
      console.log(data);
    }
  ngOnInit(): void {

  }

    removeUser() {

      if (confirm("Are you sure ?")) {
        this.corporationService.removeUserToCorporate(this.data.user_id, this.data.corp_id).subscribe((result:any) => {
          this.dialogClose("Deleted");
        }, this.globalService.handleError);
      }
    }



  dialogClose(text:string) {
    this._snackBar.open('User Successfully ' + text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    this.dialogRef.close("ok");
  }
}





