import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InsurancesService } from 'src/app/services/insurances.service';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-insurances',
  templateUrl: './insurances.component.html',
  styleUrls: ['./insurances.component.css']
})
export class InsurancesComponent implements OnInit {
  insuranceType:number = 0;
  insuranceTypes:any;
  dataLoaded: boolean = false;
  insurance: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {id: number},
    private insuranceService: InsurancesService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
    ) { }


  ngOnInit(): void {
    this.insuranceService.getAllInsuranceTypes().subscribe((result:any)=> {
      if(result.success) {
        this.insuranceTypes = result.data;
      }
    }, this.globalService.handleError);

    this.getInsurenceByVehicleId();

  }
  
  getInsurenceByVehicleId() {
    this.insuranceService.getInsurance(this.data.id).subscribe((result:any) => {
      this.dataLoaded = true;
      if(result.success) {
        this.insurance = result.data.filter((item:any) => {
          return item.record_status;
        });
      }
    }, (error:any) => {
      this.dataLoaded = true;
      this.insurance = {};
    });
    
  }

  insuranceEdit(vehicle_id:number, item:any) {
    let dialogRef = this.dialog.open(InsuranceEdit, {
      width: '400px',
      data: {"vehicle_id":vehicle_id, "insurance":item, "insuranceTypes":this.insuranceTypes}
    });

    dialogRef.afterClosed().subscribe((data:any) => {
      if(data) {
        this.dataLoaded = false;
        if(data.edit) {
          this.insuranceService.editInsurance(data.data).subscribe((result:any) => {
            if(result.success) {
              this._snackBar.open('Insurance Successfully Change', 'Ok', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                duration: 5000,
              });
            }
            this.getInsurenceByVehicleId();
          });

        }
        else {
          this.insuranceService.saveInsurance(data.data).subscribe((result:any) => {
            if(result.success) {
              this._snackBar.open('Insurance Successfully Save', 'Ok', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                duration: 5000,
              });
            }
            this.getInsurenceByVehicleId();
          })
        }
      }
    }, this.globalService.handleError);
  }


  insuranceDelete(id:number) {
    this.dataLoaded = false;
    this.insuranceService.deleteInsurance(id).subscribe((result:any) => {
      if(result.success) {
        this._snackBar.open('Insurance Successfully Deleted', 'Ok', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000,
        });
        this.getInsurenceByVehicleId();
      }
    }, this.globalService.handleError)
  }

}

@Component({
  selector: 'app-insurancesEditOrAdd',
  templateUrl: './insurancesEditOrAdd.component.html',
  styleUrls: ['./insurances.component.css']
})
export class InsuranceEdit {
  dataLoaded:boolean = true;
  insuranceType:number = 0;
  insurancesForm: FormGroup = new FormGroup({
    insuranceTypeId: new FormControl(''),
    policyNumber: new FormControl(''),
    startDate: new FormControl(),
    endDate: new FormControl(),
    vehicleId: new FormControl(),
    id: new FormControl()
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {vehicle_id:number,insurance:any, insuranceTypes:any},
    private insuranceService: InsurancesService,
    private fb: FormBuilder,
    private dialog:MatDialog,
    public dialogRef: MatDialogRef<InsuranceEdit>,
    private _snackBar: MatSnackBar,
    ) {
      this.insurancesForm.patchValue({vehicleId:data.vehicle_id});
      if(data.insurance.id) {
        this.insurancesForm.setValue({
          insuranceTypeId:data.insurance.insurance_type_id,
          policyNumber:data.insurance.policy_number,
          startDate:data.insurance.start_date,
          endDate:data.insurance.end_date,
          id:data.insurance.id,
          vehicleId:data.vehicle_id,
        });
        
      }
      else {

      }
      console.log(data);

     }

  onFormSubmit(): void {
    this.insurancesForm.value['startDate'] = moment(this.insurancesForm.value['startDate']).format('YYYY-MM-DD HH:MM');
    this.insurancesForm.value['endDate'] = moment(this.insurancesForm.value['endDate']).format('YYYY-MM-DD HH:MM');
      if(this.data.insurance.id) {
        this.dialogRef.close({"edit":true,"data":this.insurancesForm.value});
      }
      else {
        this.dialogRef.close({"edit":false,"data":this.insurancesForm.value});
      }
  }


  insuranceTypeAdd() {
    // let dialogRef = this.dialog.open(InsuranceTypeAdd, {
    //   width: '300px',
    // });
    // console.log('sa');
  }


}

// @Component({
//   selector: 'app-InsuranceTypeAdd',
//   templateUrl: './insurancesTypeAdd.component.html',
//   styleUrls: ['./insurances.component.css']
// })
// export class InsuranceTypeAdd {
//   insurancesTypeForm: FormGroup = new FormGroup({
//     type_name: new FormControl('')
//   });
//   dataLoaded: boolean = false;
//   constructor() { }

//   onFormSubmit() {
//     console.log(this.insurancesTypeForm.value);

//   }
// }