import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { CarService } from 'src/app/services/triggers/car.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { InsurancesComponent } from '../car-info/insurances/insurances.component';
import { CarStatusComponent } from '../car-status/car-status.component';
import { VehicleImagesComponent } from '../vehicle-images/vehicle-images.component';

@Component({
  selector: 'app-vehicle-detail',
  templateUrl: './vehicle-detail.component.html',
  styleUrls: ['./vehicle-detail.component.css']
})
export class VehicleDetailComponent implements OnInit {
  dataLoad: boolean = false;
  data: any;
  vehicleStatuTypes: any;
  subscribe: Subscription;
  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehicleService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private mapControl: MapControlServiceService,
    private carService: CarService,
    private globalService: GlobalService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri: any) => {
      this.getCar(uri.id);
    });

    this.subscribe = this.carService.selected_car.subscribe((result: any) => {
      
      this.dataLoad = false;
      if (result.data) {
        this.dataLoad = true;
        this.data = result.data;
      }
    });


    this.vehicleService.getAllStatusType().subscribe(result => {
      if (result.success) {
        this.vehicleStatuTypes = result.data;
      }
    });

  }

  getCar(id: number): void {
    this.carService.get_car(id);
  }

  vehicleStatuChange(statuID: number, vehicleID: number, itemName: string) {
    const dialogRef = this.dialog.open(CarStatusComponent, {
      data: itemName,
      width: "500px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == undefined) {
        this.mapControl.showSnackbar("Nothing Change");
        return;
      }
      this.vehicleService.saveVehicleStatus({
        "statusTypeId": statuID,
        "vehicleId": vehicleID,
        "times": result,
      }).subscribe((data: any) => {
        this.vehicleService.getVehicle(vehicleID).subscribe(result => {
          if (result.success) {
            this.mapControl.showSnackbar('Successfuly Change');
          }
        });
      }, this.globalService.handleError);
    });
  }

  insurancesOpen(id: number) {
    const dialogRef = this.dialog.open(InsurancesComponent, {
      width: '500px',
      data: { "id": id },
    });
  }

  imagesOpen(id: number) {
    const dialogRef = this.dialog.open(VehicleImagesComponent, {
      width: '70%',
      height: '50vh',
      data: id,
    })
  }

  vehicleLock(id: number): void {
    this.vehicleService.vehicleLock(id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
    }, this.globalService.handleError);
  }

  vehicleUnLock(id: number): void {
    this.vehicleService.vehicleUnLock(id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
    }, this.globalService.handleError);
  }

  vehicleSuperLock(id: number): void {
    this.vehicleService.vehicleSuperLock(id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
    }, this.globalService.handleError);
  }

  vehicleSuperUnLock(id: number): void {
    this.vehicleService.vehicleSuperUnLock(id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
    }, this.globalService.handleError);
  }

  vehicleKontakKapat(id: number): void {
    this.vehicleService.vehicleKontakKapat(id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
    }, this.globalService.handleError);
  }
  

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  deleteVehicle(): void {
    if(!confirm("Are you sure to delete this vehicle?")) return;
    this.vehicleService.deleteVehicle(this.data.id).subscribe((result: any) => {
      this.mapControl.showSnackbar(result?.data?.message);
      this.router.navigate(['/dashboard/vehicles']).then(() => {
        window.location.reload();
      });
    }, this.globalService.handleError);
  }
}