<div class="detail-content">
    <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
    <div *ngIf="dataLoad">
        <div class="detail-title">
            <h1>{{ data.number_plate }}</h1>
            <div>
                <!-- <a mat-list-item routerLinkActive="list-item-active" (click)="deleteVehicle()">
                    Sil
                </a> -->
                <mat-icon (click)="deleteVehicle()">delete</mat-icon>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="edit">
                    Edit
                </a>
            </div>
        </div>
        <div class="detail-list">
            <div>
                <p>Car ID</p>
                <p>{{ data.id }}</p>
            </div>
            <hr>
            <div>
                <p>Brand</p>
                <p>{{ data.brand.name }}</p>
            </div>
            <hr>
            <div>
                <p>Model</p>
                <p>{{ data.model.name }}</p>
            </div>
            <hr>
            <div>
                <p> Year </p>
                <p>{{ data.year }}</p>
            </div>
            <hr>

            <div>
                <p> Transmission </p>
                <p>{{ data.transmission }}</p>
            </div>
            <hr>

            <div>
                <p> Color </p>
                <p>{{ data.colour }}</p>
            </div>
            <hr>

            <div>
                <p> Minimum Driver License Age </p>
                <p>{{ data.license_age }}</p>
            </div>
            <hr>

            <div>
                <p> Sitting Capacity </p>
                <p>{{ data.sittingCapacity }}</p>
            </div>
            <hr>

            <div>
                <p> Child Seat </p>
                <p>{{ data.childSeat }}</p>
            </div>
            <hr>
            <div>
                <p>Vehicle Commissioning Date</p>
                <p>{{ data.vehicleCommissioningDate }}</p>
            </div>
            <hr>
            <div>
                <p>Charge</p>
                <p>{{ data.continental?.charge }}</p>
            </div>
            <hr>
            <div>
                <p>Lock</p>
                <p>{{ data.continental?.lock }}</p>
            </div>
            <hr>
            <div>
                <p>Lat - Long</p>
                <p>{{ data.continental?.lat }} - {{ data.continental?.long }}</p>
            </div>
            <hr>
            

            <div>
                <p> Chassis Number </p>
                <p>{{ data.chassisNumber }}</p>
            </div>
            <hr>

            <div>
                <p> HGS Serial Number </p>
                <p>{{ data.hgsSerialNumber }}</p>
            </div>
            <hr>

            <div>
                <p> Vehicle Location </p>
                <p>{{ data.vehicle_place }}</p>
            </div>
            <hr>

            <div>
                <p> Vehicle Location Detailed</p>
                <p>{{ data.vehicle_place_detailed }}</p>
            </div>
            <hr>

        </div>

        <div class="editButton">
            <button mat-raised-button color="primary">Book</button>
            <button mat-raised-button color="primary" [matMenuTriggerFor]="statuButton">Statu</button>
            <mat-menu #statuButton="matMenu" yPosition="above">
                <button mat-menu-item *ngFor="let item of vehicleStatuTypes" (click)="vehicleStatuChange(item.id, data.id, item.name)">{{ item.name }}</button>
            </mat-menu>
            <button mat-raised-button color="primary" [matMenuTriggerFor]="controlButton">Controls</button>
            <mat-menu #controlButton="matMenu" yPosition="above">
                <button mat-menu-item (click)="vehicleUnLock(data.id)">Kapı Açma K07</button>
                <button mat-menu-item (click)="vehicleLock(data.id)">Kapı Kapatma K06</button>
                <button mat-menu-item (click)="vehicleSuperUnLock(data.id)">Immobilizer Kapatma K01</button>
                <button mat-menu-item (click)="vehicleSuperLock(data.id)">Immobilizer Açma K00</button>
                <button mat-menu-item (click)="vehicleKontakKapat(data.id)">Kontak Kapatma K55</button>
            </mat-menu>
            <button mat-raised-button color="primary">Damages</button>
            <button mat-raised-button color="primary" (click)="insurancesOpen(data.id)">Insurances</button>
            <button mat-raised-button color="primary" (click)="imagesOpen(data.id)">Images</button>
        </div>

    </div>
</div>