<div class="selfieComp">
    <h1>Selfie Approvals</h1>
    <div class="mt-20">
        <mat-accordion>

            <mat-expansion-panel *ngFor="let element of licenses" (opened)="panelOpen(element.id)" (closed)="panelClosed(element.id)" >
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{element.id}}
                    </mat-panel-title>
                    <mat-panel-description>
                        {{element.user.name}} {{ element.user.surname }} - {{element.user.email}}
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="panelInfo">
                    <p class="panelDate">
                        {{element.given_date}} - {{element.expiration_date}}
                    </p>
                    <mat-progress-bar mode="buffer" *ngIf="openedLicense?.id?false:true"></mat-progress-bar>
                    <div *ngIf="openedLicense.id?true:false">
                        <div class="flex">
                            <div class="flex-column width48">
                                <img [src]="licenseFront" (click)="showImage(licenseFront)">
                                <img [src]="licenseBack" (click)="showImage(licenseBack)">
                            </div>
                            <div class="width48">
                                <table class="expandedTable">
                                    <tr>
                                        <td>Name</td>
                                        <td>{{ openedLicense.user.name }}</td>
                                    </tr>
                                    <tr>
                                        <td>Surname</td>
                                        <td>{{ openedLicense.user.surname }}</td>
                                    </tr>
                                    <tr>
                                        <td>TCKN or Passport</td>
                                        <td>{{ openedLicense.user.tckn }}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone Number</td>
                                        <td>{{ openedLicense.user.phone_number }}</td>
                                    </tr>
                                    <tr>
                                        <td>Birth Date</td>
                                        <td>{{ openedLicense.user.birth_date }}</td>
                                    </tr>
                                    <tr>
                                        <td>Email</td>
                                        <td>{{ openedLicense.user.email }}</td>
                                    </tr>
                                    <tr>
                                        <td>Given Country</td>
                                        <td>{{ openedLicense.user.given_country }}</td>
                                    </tr>

                                </table>
                            </div>
                        </div>

                        <div class="panel-buttons">
                            <button class="user-buttons" mat-raised-button color="primary" (click)="openUserTags(element.user_id)" >Tags</button>
                            <button mat-raised-button color="warn" [disabled]="isDisabled" (click)="panelReject(openedLicense.id)">Rejected</button>
                            <button mat-raised-button color="primary" [disabled]="isDisabled" (click)="panelAccept(openedLicense.id)">Approved</button>

                        </div>

                    </div>

                </div>
            </mat-expansion-panel>

        </mat-accordion>
    </div>


</div>