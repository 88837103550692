<div>
    <div class="add-new">
        <button mat-button color="primary" (click)="addNotifications()">
            Add New
        </button>
    </div>
    <div class="box" *ngFor="let notify of notify_data">
        <div class="title">
            <h2>{{ notify.title }}</h2>
            <button mat-button (click)="delete_notify(notify.id)">Delete</button>
        </div>
        <div class="text">
            <p>
                {{ notify.message }}
            </p>
        </div>
    </div>
</div>