import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';
import { ListModelsComponent } from './list-models/list-models.component';

@Component({
  selector: 'app-add-vehicle-group',
  templateUrl: './add-vehicle-group.component.html',
  styleUrls: ['./add-vehicle-group.component.css']
})
export class AddVehicleGroupComponent implements OnInit {
  vehicleGroupForm = new FormGroup({
    name: new FormControl(),
    models: new FormArray([])
  });
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private priceService: PricingService,
    private dialogRef: MatDialogRef<AddVehicleGroupComponent>,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.priceService.saveVehicleGroup(this.vehicleGroupForm).subscribe({
      next: (result) => {
        this.dialogRef.close();
        this.globalService.successMessage(result);
      },
      error: this.globalService.handleError
    })
  }

  addModel(): void {
    this.dialog.open(ListModelsComponent, {
      data: this.data
    }).afterClosed().subscribe({
      next: (result) => {

        if (result) {
          const items = this.vehicleGroupForm.get('models') as FormArray
          items.push(new FormControl({id: result.id, name: result.name}))
        }
      }
    })
  }
  removeModel(item_id: number): void {
    const items = this.vehicleGroupForm.get('models') as FormArray
    items.removeAt(item_id)
  }
  
}
