<div class="electrip-form">
    <mat-dialog-content >
        <form [formGroup]="refundTestForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field>
                <mat-label>Start Time * </mat-label>
                <input matInput type="datetime-local" placeholder="Start Time *" formControlName="start_time">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Reservation Amount *</mat-label>
                <input matInput type="number" min="0" formControlName="amount">
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!refundTestForm.valid">Submit</button>

            <p>{{ response_text }}</p>

        </form>
    </mat-dialog-content>
</div>