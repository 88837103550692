import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { ParkLocationChargeStationAddComponent } from '../park-location-charge-station-add/park-location-charge-station-add.component';

@Component({
  selector: 'app-park-location-charge-stations',
  templateUrl: './park-location-charge-stations.component.html',
  styleUrls: ['./park-location-charge-stations.component.css']
})
export class ParkLocationChargeStationsComponent implements OnInit {
  dataLoad: boolean;
  parkDetail: any;
  parkId: number;
  constructor(
    private dialog: MatDialog,
    private parkingService: ParkingPlacesService,
    private route: ActivatedRoute,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.parkId = uri.id;
      this.getParkingPlaceByID();
    });
  }

  getParkingPlaceByID() {
    this.dataLoad = false;
    this.parkingService.getParkingPlace(this.parkId).subscribe((result:any) => {
      
      if(result.success) {
        this.dataLoad = true;
        this.parkDetail = result.data;
        // this.park_location_image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + result.data.image);
      }
    }, this.globalService.handleError);
  }

  addChargeStation(park_id: number) {
    this.dialog.open(ParkLocationChargeStationAddComponent, {
      minWidth: "500px",
      maxWidth: "100%",
      autoFocus: false,
      data: park_id
    }).afterClosed().subscribe(()=> {
      this.getParkingPlaceByID();
    });
  }

  
  removeChargeStation(park_id: number, id: number) {
    if(confirm("Are you sure ?")) {
      this.parkingService.removeParkingPlaceChargeStation(park_id, id).subscribe((result:any) => {
        this.getParkingPlaceByID();
      }, this.globalService.handleError);
    }
  }

}
