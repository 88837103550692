<div class="list-item Open" routerLinkActive="list-item-active" routerLink="/dashboard/park-locations/{{ data.id }}">
    <div class="item-content">
        <div class="info-content">
            <div class="card-text">
                <h3><strong> {{ data.name }} </strong></h3>
                <p> Statu </p>
                <mat-hint> Segment </mat-hint>
            </div>
        </div>
        <div class="list-icon">
            <div class="icon">
                <span>5</span>
                <mat-icon> ev_station</mat-icon>
            </div>

        </div>
    </div>
</div>