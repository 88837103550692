import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalService } from 'src/app/services/global.service';
import { SettingsService } from 'src/app/services/settings.service';
import { UserSegmentTypeEditComponent } from './user-segment-type-edit/user-segment-type-edit.component';

@Component({
  selector: 'app-user-segment-type',
  templateUrl: './user-segment-type.component.html',
  styleUrls: ['./user-segment-type.component.css']
})
export class UserSegmentTypeComponent implements OnInit {
  segmentTypes:any;
  displayedColumns: string[] = ['id', 'name', 'edit', 'delete'];
  constructor(
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.getAllSegmentTypes();
  }

  addSegmentType() {
    const dialogRef = this.dialog.open(UserSegmentTypeEditComponent, {});
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result) {
        this.dialogClose("Save");
      }
    });
  }

  getAllSegmentTypes() {
    this.settingsService.getAllUserSegmentTypes().subscribe((result:any) => {
      if(result.success) {
        this.segmentTypes = result.data.filter(data=> data.record_status == true);
      }
    }, this.globalService.handleError);
  }

  dialogClose(text:string) {
    this._snackBar.open('User Segment Type Successfully ' + text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    this.getAllSegmentTypes();
  }

  editSegmentType(id:number) {
    const dialogRef = this.dialog.open(UserSegmentTypeEditComponent, {data:id});
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result) {
        this.dialogClose("Change");
      }
    });
  }
  
  deleteSegmentType(id:number) {
    this.settingsService.deleteUserSegmentType(id).subscribe((result:any) => {
      
      this.dialogClose("Deleted");
    }, this.globalService.handleError)
  }


}
