<div>
    <div>
        <form [formGroup]="form_var" (ngSubmit)="submit_form()">

            <div *ngFor="let item of response_data">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <mat-checkbox [formControlName]="item.id">{{ item.name }}</mat-checkbox>
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <span class="color" [ngStyle]="{'background-color': item.color}"></span>
                        <button mat-icon-button color="warn" (click)="delete_tag(item.id)"><mat-icon>delete_forever</mat-icon></button>
                    </div>
                </div>
                <hr>
            </div>
            <div fxLayoutAlign="end center" fxLayoutGap="10px">
                <button mat-raised-button color="primary" (click)="addNewTag()">Add New</button>
                <button mat-raised-button color="primary">Submit</button>
            </div>
        </form>
    </div>

</div>