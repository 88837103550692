<!-- <div class="dashboard-selects">
    <div>
        <mat-form-field *ngIf="carBrandNames">
            <mat-label> Status </mat-label>
            <mat-select>
                <mat-option (click)="statuClick('')" value="0" >Reset</mat-option>
                <mat-option *ngFor="let statu of vehicleStatuTypes" value="statu.id" (click)="statuClick(statu.name)" >{{ statu.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field *ngIf="carBrandNames">
            <mat-label> Brand </mat-label>
            <mat-select>
                <mat-option (click)="brandClick('')" value="0" >Reset</mat-option>
                <mat-option *ngFor="let brand of carBrandNames" value="brand" (click)="brandClick(brand)" >{{ brand }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</div>

<agm-map [latitude]="latitude" [longitude]="longitude" (mapReady)="mapReadyHandler($event)" > 

    <agm-marker 
        *ngFor="let mark of marks"
        [latitude]="mark.coordinate?mark.coordinate.lat:mark.lat"
        [longitude]="mark.coordinate?mark.coordinate.long:mark.long"
        [iconUrl]="mark.coordinate?mark.coordinate.icon:mark.icon"
        (markerClick)="markerClicked(iw, mark)"
        (mouseOver)="onMouseOver(iw,mark.id)"
        (mouseOut)="onMouseOut(iw, mark.id)"
    >
    
        <agm-info-window #iw>

            <div>
                <p>{{mark.brand?mark.brand.name:mark.name}} - {{mark.model?mark.model.name:mark.name}}</p>
                <p>{{mark.coordinate?mark.coordinate.lat:mark.lat}}</p>
                <p>{{mark.coordinate?mark.coordinate.long:mark.long}}</p>
                
            </div>

        </agm-info-window>

    </agm-marker>

</agm-map> -->

<div class="dashboard-panel">
    <div class="filters">

        <mat-form-field appearance="fill">
            <mat-label>Car Filters</mat-label>
            <mat-select [formControl]="carFormControl" multiple (selectionChange)="carFilterChange($event.value)">
                <mat-optgroup *ngFor="let item of carFilterData | keyvalue" [label]="item.key">
                    <mat-option *ngFor="let values of item.value" [value]="values" [disabled]="values.disabled">
                        {{ values.name }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="google-map">
        <agm-map [latitude]="mapLat" [longitude]="mapLong">
            <agm-marker *ngFor="let car of carData" [latitude]="car.coordinate.lat" [longitude]="car.coordinate.long" iconUrl="assets/icons/car.png" (mouseOver)="onMouseOver(carPanel)" (mouseOut)="onMouseOut(carPanel)" (markerClick)="markClick('/dashboard/vehicles/' + car.id)">
                <agm-info-window #carPanel>
                    <div class="map-info">

                        <p>
                            <b>Name : </b>
                            <span>{{ car.brand.name }} {{ car.model.name }}</span>
                        </p>

                        <p>
                            <b>Color : </b>
                            <span>{{ car.colour }}</span>
                        </p>

                        <p>
                            <b>Plate : </b>
                            <span>{{ car.numberPlate }}</span>
                        </p>

                        <p>
                            <b>Status : </b>
                            <span>{{ car.status.name }}</span>
                        </p>

                        <p>
                            <b>Year : </b>
                            <span>{{ car.year }}</span>
                        </p>

                    </div>
                </agm-info-window>
            </agm-marker>

            <agm-marker *ngFor="let park of parkLocationsData" [latitude]="park.lat" [longitude]="park.long" iconUrl="assets/icons/parking.png" (mouseOver)="onMouseOver(parkPanel)" (mouseOut)="onMouseOut(parkPanel)" (markerClick)="markClick('/dashboard/park-locations/' + park.id)">
                <agm-info-window #parkPanel>
                    <div class="map-info">

                        <p>
                            <b>Name : </b>
                            <span>{{ park.name }}</span>
                        </p>


                    </div>
                </agm-info-window>
            </agm-marker>

            <agm-marker *ngFor="let charge of chargeLocationsData" [latitude]="charge.lat" [longitude]="charge.long" iconUrl="assets/icons/charging.png" (mouseOver)="onMouseOver(chargePanel)" (mouseOut)="onMouseOut(chargePanel)">
                <agm-info-window #chargePanel>
                    <div class="map-info">

                        <p>
                            <b>City : </b>
                            <span>{{ charge.city }}</span>
                        </p>

                        <p>
                            <b>Name : </b>
                            <span>{{ charge.cp_code_no }}</span>
                        </p>

                        <p>
                            <b>Type : </b>
                            <span>{{ charge.cp_type }}</span>
                        </p>

                        <p>
                            <b>District : </b>
                            <span>{{ charge.district }}</span>
                        </p>

                    </div>
                </agm-info-window>
            </agm-marker>


        </agm-map>
    </div>


</div>