import { Injectable } from '@angular/core';
import { Vehicle } from '../models/vehicle.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient) { }

  saveVehicle(body: FormGroup):Observable<any>{
    this.url = baseUrl + "vehicle/saveVehicle";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body.value, { headers: headers });     
  }

  editVehicle(body):Observable<any>{
    this.url = baseUrl + "vehicle/editVehicle";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      "Accept": "*/*",
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  getAllVehicles():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    this.url = baseUrl + "vehicle/getAllVehicleCoordinates";

    return this.http.get(this.url, { headers: headers });    
  }

  getVehicle(vehicle_id:any):Observable<any>{
    this.url = baseUrl + "vehicle/getVehicle/" + vehicle_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })


    return this.http.get(this.url, { headers: headers });    
  }


  getBrands():Observable<any>{
    this.url = baseUrl + "vehicle/getAllBrands";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })


    return this.http.get(this.url, { headers: headers });    
  }


  getModels():Observable<any>{
    this.url = baseUrl + "vehicle/getAllModels";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })


    return this.http.get(this.url, { headers: headers });    
  }

  getAllStatusType():Observable<any> {
    this.url = baseUrl + "vehicle/getAllStatusTypes";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  saveVehicleStatus(body:any):Observable<any> {
    this.url = baseUrl + "vehicle/saveVehicleStatus";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });    
  }

  getVehicleImages(carId: number) :Observable<any> {
    this.url = baseUrl + "vehicle/getVehicleImages/" + carId;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get<any>(this.url, { headers: headers });  
  }

  saveVehicleImage(image: any, carId: number, form_value):Observable<any> {
    this.url = baseUrl + "vehicle/saveImageLink";
    this.access_token = localStorage.getItem('access_token');
    const formData: FormData = new FormData();
    formData.append('image', image, image.name);
    formData.append('vehicleId', carId.toString());
    formData.append('note', form_value.note);
    if (form_value.reservation ) {
      formData.append('reservation', form_value.reservation.id);
    }
    else{
      formData.append('reservation', "");
    }
    formData.append('type', form_value.type);

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.access_token}`
    })
    
    return this.http.post<any>(this.url, formData, { headers: headers });    
  }

  deleteVehicleImage(imageId: number):Observable<any> {
    this.url = baseUrl + "vehicle/deleteImageLink/" + imageId;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {}, { headers: headers });    
  }

  deleteVehicle(id: number):Observable<any> {
    this.url = baseUrl + "vehicle/deleteVehicle/" + id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {}, { headers: headers });    
  }
  

  setVehicleCover(imageId: number, carId: number):Observable<any> {
    this.url = baseUrl + "vehicle/setVehicleCover";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    var data = {
      "imageId": imageId,
      "carId": carId,
    }

    return this.http.post<any>(this.url, data, { headers: headers });    
  }

  addProvider(formData: FormGroup): Observable<any> {
    var url = baseUrl + "vehicle/saveProvider";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, formData.value, { headers: headers });
  }

  addStatusType(formData: FormGroup): Observable<any> {
    var url = baseUrl + "vehicle/saveStatusType";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, formData.value, { headers: headers });
  }

  
  getAllProviders():Observable<any>{
    this.url = baseUrl + "vehicle/getAllProviders";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });
  }

  getStatusTypes():Observable<any>{
    this.url = baseUrl + "vehicle/getAllStatusTypes";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });
  }


  addVehicleStatus(formData: FormGroup): Observable<any> {
    var url = baseUrl + "vehicle/saveVehicleStatus";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, formData.value, { headers: headers });
  }

  vehicleLock(id: number): Observable<any> {
    var url = baseUrl + "vehicle/onlyLockVehiclePanel";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, {"vehicleId": id}, { headers: headers });
  }

  vehicleUnLock(id: number): Observable<any> {
    var url = baseUrl + "vehicle/onlyUnlockVehiclePanel";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, {"vehicleId": id}, { headers: headers });
  }

  vehicleSuperLock(id: number): Observable<any> {
    var url = baseUrl + "vehicle/lockVehiclePanel";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, {"vehicleId": id}, { headers: headers });
  }

  vehicleSuperUnLock(id: number): Observable<any> {
    var url = baseUrl + "vehicle/unlockVehiclePanel";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, {"vehicleId": id}, { headers: headers });
  }

  vehicleKontakKapat(id: number): Observable<any> {
    var url = baseUrl + "vehicle/ignitionVehiclePanel";
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(url, {"vehicleId": id}, { headers: headers });
  }
  
  
  vehicleInfo(rez_id: number):Observable<any>{
    this.url = baseUrl + "reservation/vehicleInfo/" + rez_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });
  }
  
}
