import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {
  @Input() behaviorSubject: BehaviorSubject<any>;
  @Input() routeUrl: string;

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  
  data_items: any;

  constructor() { }

  ngOnInit(): void {
    this.behaviorSubject?.subscribe(result => {
      console.log("list-item result", result);
      this.data_items = result?.data;
    });
  }

}
