<div class="detail-center">
    <div class="detail-content">
        <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
        <div *ngIf="dataLoad">
            <div class="detail-title">
                <h1>{{ parkDetail.name }} </h1>
                <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" (click)="editParkingPlace(parkDetail)">Edit</button>
                <!-- <a mat-list-item routerLink="edit">
                    Edit
                </a> -->
            </div>
            <div class="detail-list">
                <div>
                    <p> 
                        Park Place Image 
                        <!-- <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" (click)="addImage(parkDetail.id)"><mat-icon>edit</mat-icon></button> -->
                    </p>
                    <div *ngIf="parkDetail.image">
                        <img [src]="parkDetail.image" alt="" width="100%"> 
                    </div>
                </div>
                <div>
                    <p> Park Place ID </p>
                    <p>{{ parkDetail.id }}</p>
                </div>
                <hr>

                <div>
                    <p> Park Place Name </p>
                    <p>{{ parkDetail.name }}</p>
                </div>
                <hr>

                <div>
                    <p> Park Place Coordinate </p>
                    <p> Lat : {{ parkDetail.lat }} - Long : {{ parkDetail.long }} </p>
                </div>
                <hr>

                <div>
                    <p> Park Place Status </p>
                    <p> {{ parkDetail.status }} </p>
                </div>
                <hr>

                <div>
                    <p> District Code </p>
                    <p> {{ parkDetail.district_code }} </p>
                </div>
                <hr>
                
                <div>
                    <p> Park Place Location </p>
                    <p> City : {{ parkDetail.city?.name }} - Town : {{ parkDetail.town?.name }} </p>
                </div>
                <hr>
                

            </div>


        </div>
    </div>
</div>