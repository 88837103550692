import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { baseUrl } from 'src/environments/environment';
import { ReservationDetailComponent } from '../../reservations/reservation-detail/reservation-detail.component';
import { AddImageComponent } from './add-image/add-image.component';

@Component({
  selector: 'app-vehicle-images',
  templateUrl: './vehicle-images.component.html',
  styleUrls: ['./vehicle-images.component.css']
})
export class VehicleImagesComponent implements OnInit {
  baseUrl: any = baseUrl;
  image_data: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any ,
    private dialog: MatDialog,
    private vehicleService: VehicleService,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.vehicleService.getVehicleImages(this.data).subscribe((result:any) => {
      this.image_data = result.data;
    }, this.globalService.handleError);
    console.log(this.data);
  }

  imageFullOpen(img:any) {
    const dialogRef = this.dialog.open(VehicleImageFull, {
      width: '80%',
      maxHeight: '95vh',
      data: {
        "img": img,
        'carId': this.data
      }
    });

    dialogRef.afterClosed().subscribe((res:any) => {
      if(res == "ok") {
        this.getImages();        
        this.mapControl.showSnackbar("Image Deleted.");
      }
      else if(res == "img") {
        this.getImages();        
        this.mapControl.showSnackbar("Image Cover Successfuly Set.");
      }
    });
  }

  addImage() {
    console.log(this.data)
    const dialogRef = this.dialog.open(AddImageComponent, {
      width: '500px',
      data: this.data,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result:any) => {
      if(result == "ok") {
        this.getImages();        
        this.mapControl.showSnackbar("New Image Successfuly Added.");
      }
    });
  }

  getImages(): void {
    this.vehicleService.getVehicleImages(this.data).subscribe((result:any) => {
      this.image_data = result.data;
    }, this.globalService.handleError);
  }

}

@Component({
  selector: 'app-vehicle-image-full',
  template: `<div>
    <div class="add-button">
      <button mat-raised-button color="warn" (click)="deleteImage()">Delete</button>
      <button mat-raised-button color="primary" (click)="makePrimary()" *ngIf="!data.img.isCover">Make Primary</button>
    </div>
    <div class="full-image-div">
      <img class="image-full" [src]="data.img.imageUrl" alt="" >
      <table>
        <tr>
          <td>Type</td>
          <td>{{ data.img.type }}</td>
        </tr>
        <tr>
          <td>Reservation</td>
          <td><a (click)="openRes()"></a> {{ data.img.reservationId }}</td>
        </tr>
        <tr>
          <td>Note</td>
          <td>{{ data.img.note }}</td>
        </tr>
      </table>
    </div>
  </div>`,
  styleUrls: ['./vehicle-images.component.css']
})
export class VehicleImageFull {
  baseUrl: any = baseUrl;
  is_primary: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public vehicleService: VehicleService,
    public dialogRef: MatDialogRef<VehicleImageFull>,
    public mapControl: MapControlServiceService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) {
    console.log(data);
   }

  deleteImage(): void {
    this.vehicleService.deleteVehicleImage(this.data.img.id).subscribe((result:any) => {
      this.dialogRef.close("ok");
    }, this.globalService.handleError);
  }

  makePrimary(): void {
    this.vehicleService.setVehicleCover(this.data.img.id, this.data.carId).subscribe((result:any) => {
      this.mapControl.carImageChange.emit();
      this.dialogRef.close("img");
    }, this.globalService.handleError);
  }

  openRes() {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: this.data.img.reservationId
    });
  }
}