<div class="hgscontainer">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> Username </th>
            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="number">
            <th mat-header-cell *matHeaderCellDef> Number </th>
            <td mat-cell *matCellDef="let element"> {{element.number}} </td>
        </ng-container>

        <ng-container matColumnDef="activate">
            <th mat-header-cell *matHeaderCellDef> Activate </th>
            <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="userStatusChange(element.userId, 'Active')">Active</button></td>

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>