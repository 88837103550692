<div class="list-item {{data?.status?.name}}" routerLinkActive="list-item-active" routerLink="/dashboard/vehicles/{{ data.id }}">
    <div class="item-content">
        <div class="info-content">
            <div class="info">
                <img src="{{ carImage }}"  alt="" srcset="">
                <mat-hint>{{ data?.status?.name }}</mat-hint>
            </div>
            <div class="card-text">
                <p><strong> {{ data.brand.name }} {{ data.model.name }} </strong></p>
                <p> {{ data.numberPlate }} </p>
            </div>
        </div>
        <div class="list-icon">
            <div class="battery">
                <div class="battery-icon">
                    <mat-progress-bar class="battery-progress" value="82"></mat-progress-bar>
                </div>
                <p class="battery-icon-number">
                    {{ data.charge }} %
                </p>
            </div>

        </div>
    </div>
</div>