<div class="table-style">
    <div fxLayout="column" fxLayoutGap="20px">
        <div>
            <h1>
                <b>Driver License</b>
            </h1>
        </div>
        <div fxLayout="row" fxLayoutGap="50px">
            <div fxFlex="50" >
                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!licenseFront">
                    <mat-spinner  [value]="licenseFrontValue" [mode]="licenseFrontMode"></mat-spinner>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="image-zoom" [src]="licenseFront" width="100%" (click)="globalService.showImage(licenseFront)">
                </div>
            </div>
            <div fxFlex="50">
                <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!licenseBack">
                    <mat-spinner  [value]="licenseBackValue"  [mode]="licenseBackMode"></mat-spinner>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="image-zoom" [src]="licenseBack" width="100%" (click)="globalService.showImage(licenseBack)">
                </div>
            </div>
        </div>
        <div>
            <table>
                <tr>
                    <td>ID</td>
                    <td>{{ data?.id }}</td>
                </tr>
                <tr>
                    <td>Given Date</td>
                    <td>{{ data?.given_date }}</td>
                </tr>
                <tr>
                    <td>Expiration Date</td>
                    <td>{{ data?.expiration_date }}</td>
                </tr>
                <tr>
                    <td>File No</td>
                    <td>{{ data?.file_no }}</td>
                </tr>
                <tr>
                    <td>Create At</td>
                    <td>{{ data?.created_on }}</td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>
                        <div *ngIf="data?.status == 'Approved'">
                            <span class="approved_icon">{{ data?.status }}</span>
                        </div>
                        <div *ngIf="data?.status == 'Waiting'">
                            <span class="waiting_icon">{{ data?.status }}</span>
                        </div>
                        <div *ngIf="data?.status == 'Rejected'">
                            <span class="rejected_icon">{{ data?.status }}</span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="data?.status == 'Rejected'">
                    <td colspan="2">
                        License Rejected Reason
                        <ul>
                            <li *ngFor="let item of data?.license_rejected_reason" >
                                <b>{{ item.reason_title }}</b> - {{ item.reason_exp }}
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="data?.status != 'Approved'" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="15px">
            <button mat-raised-button color="warn" (click)="panelReject()">Rejected</button>
            <button mat-raised-button color="primary" (click)="panelAccept()">Approved</button>
        </div>
    </div>
</div>