import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import { StudentLicenseService } from 'src/app/services/student-license.service';
import { RejectedReasonComponent } from '../../rejected-reason/rejected-reason.component';

@Component({
  selector: 'app-student-license-detail',
  templateUrl: './student-license-detail.component.html',
  styleUrls: ['./student-license-detail.component.css']
})
export class StudentLicenseDetailComponent implements OnInit {
  openedLicense: any = {};
  licenseFront: any;
  licenseBack: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private licenseService: StudentLicenseService,
    private _sanitizer: DomSanitizer, 
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<StudentLicenseDetailComponent>,
    private dialog:MatDialog,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.licenseService.getStudentInfo(this.data).subscribe((result:any) => {
      if(result.success) {
        this.openedLicense = result.data;
        this.licenseFront = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + result.data.base64_front);
        this.licenseBack = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + result.data.base64_back);
      }
      
    }, this.globalService.handleError);
  }


  panelReject(id:number) {
    const dialogRef = this.dialog.open(RejectedReasonComponent, {
      minWidth: '250px',
      data: "StudentPhoto"
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result) {
        this.licenseService.setSelfieStatus(id,"Rejected",result.rejectedId).subscribe((data) => {
          this._snackBar.open('Licanse Rejected.', 'Ok', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close("");
        });
      }
    });


  }


  panelAccept(id:number) {
    this.licenseService.setSelfieStatus(id,"Approved").subscribe((data) => {
      this._snackBar.open('Licanse Approved.', 'Ok', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      this.dialogRef.close("");
    }, this.globalService.handleError);
  }

  showImage(image:string) {
    const dialogRef = this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
    console.log(image);
  }


}


@Component({
  selector: 'open-image',
  template: '<img [src]="data.changingThisBreaksApplicationSecurity" style="width:100%" >',
})
export class OpenImage {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}