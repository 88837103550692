import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParkingPlacesService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient) { }

  getAllParkingPlaces():Observable<any>{
    this.url = baseUrl + "vehicle/getAllParkingPlaces";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  getParkingPlace(parking_place_id:any):Observable<any>{
    this.url = baseUrl + "vehicle/getParkingPlace/" + parking_place_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  saveParkingPlace(body):Observable<any>{
    this.url = baseUrl + "vehicle/saveParkingPlace";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  editParkingPlace(body):Observable<any>{
    this.url = baseUrl + "vehicle/editParkingPlace";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  removeParkingPlaceChargeStation(park_id: number, id: number): Observable<any> {
    this.url = baseUrl + "vehicle/getParkingPlace/" + park_id + "/removeChargeStation/" + id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"park_id": park_id, "id":id}, { headers: headers });
  }

  getAllChargeStations(): Observable<any> {
    this.url = baseUrl + "vehicle/chargeStationNames";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })
    return this.http.get(this.url, { headers: headers });    
  }

  setChargeStationToParkingPlace(park_id: number, id: number): Observable<any> {
    this.url = baseUrl + "vehicle/getParkingPlace/" + park_id + "/setChargeStation/" + id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"park_id": park_id, "id":id}, { headers: headers });
  }
  
  getParkingPlaceVehicles(parking_place_id:any):Observable<any>{
    this.url = baseUrl + "vehicle/getParkingPlace/" + parking_place_id + "/vehicles";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  removeParkingPlaceVehicle(park_id: number, id: number): Observable<any> {
    this.url = baseUrl + "vehicle/getParkingPlace/" + park_id + "/removeVehicles";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"coordinate":id}, { headers: headers });
  }

  

  parkingPlaceSetVehicle(park_id: number, vehicle_id: number): Observable<any> {
    this.url = baseUrl + "vehicle/getParkingPlace/" + park_id + "/setVehicle";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"vehicle_id":vehicle_id}, { headers: headers });
  }

}
