import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';

@Component({
  selector: 'app-refund-settings-add-test',
  templateUrl: './refund-settings-add-test.component.html',
  styleUrls: ['./refund-settings-add-test.component.css']
})
export class RefundSettingsAddTestComponent implements OnInit {
  response_text = "";
  refundTestForm = new FormGroup({
    start_time: new FormControl(null, Validators.required),
    amount: new FormControl(null, Validators.required),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reservationService: ReservationsService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.reservationService.refundSettingsTest(this.data, this.refundTestForm).subscribe(result => {
      this.response_text = result.data.message;

      this.globalService.showMessage(result.data.title);
    }, this.globalService.handleError);
  }

}
