<div fxLayout="column" fxLayoutAlign=" none" fxLayoutGap="20px" style="padding: 30px">
    <div fxLayout="row">
        <form [formGroup]="filterGroup" autocomplete="off" fxLayout="row" fxLayoutAlign="center center"
            fxLayoutGap="20px" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="fill">
                <mat-label>Vehicle</mat-label>
                <mat-select formControlName="vehicle">
                    <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                        {{vehicle.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Choose a date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="date">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <button mat-raised-button color="primary">Filter</button>

        </form>
    </div>

    <div class="chart-div" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!show_chart_battery"></mat-spinner>

        <apx-chart *ngIf="show_chart_battery" [series]="chartBatteryOptions.series" [chart]="chartBatteryOptions.chart"
            [xaxis]="chartBatteryOptions.xaxis" [dataLabels]="chartBatteryOptions.dataLabels"
            [grid]="chartBatteryOptions.grid" [stroke]="chartBatteryOptions.stroke" [title]="chartBatteryOptions.title">
        </apx-chart>
    </div>


    <div class="chart-div" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!show_chart_km"></mat-spinner>
        <apx-chart *ngIf="show_chart_km" [series]="chartKmOptions.series" [chart]="chartKmOptions.chart" [xaxis]="chartKmOptions.xaxis"
            [dataLabels]="chartKmOptions.dataLabels" [grid]="chartKmOptions.grid" [stroke]="chartKmOptions.stroke"
            [title]="chartKmOptions.title"></apx-chart>
    </div>

    <div class="chart-div" fxLayoutAlign="center center">
        <mat-spinner *ngIf="!show_chart_energy_consumption"></mat-spinner>
        <apx-chart *ngIf="show_chart_energy_consumption" [series]="chartEnergyConsumptionOptions.series" [chart]="chartEnergyConsumptionOptions.chart"
            [xaxis]="chartEnergyConsumptionOptions.xaxis" [dataLabels]="chartEnergyConsumptionOptions.dataLabels"
            [grid]="chartEnergyConsumptionOptions.grid" [stroke]="chartEnergyConsumptionOptions.stroke"
            [title]="chartEnergyConsumptionOptions.title"></apx-chart>
    </div>

</div>