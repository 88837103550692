<div class="electrip-form">
    <h2>Add New Status Type</h2>
    <form [formGroup]="statusTypeForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
        </mat-form-field>
        <button type="submit" mat-button primary [disabled]="!statusTypeForm.valid">Submit</button>


    </form>
</div>