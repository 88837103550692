<form [formGroup]="endForm" (ngSubmit)="onSubmit()">

    <table>
        <tr>
            <td>Reservation End Date</td>
            <td>{{ result_data?.endTime }}</td>
        </tr>
        <tr>
            <td>Reservation Finish Date</td>
            <td>
                <input matInput type="datetime-local" formControlName="endTime">
            </td>
            <!-- <td>{{ result_data?.now }}</td> -->
        </tr>
        <tr>
            <td>Price</td>
            <td>
                <input matInput formControlName="price">
            </td>
        </tr>
    </table>

    <button mat-raised-button color="primary">Create Additional Billing</button>
</form>