import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StudentLicenseService {

  constructor(private http: HttpClient) { }

  getStudentInfoAll():Observable<any> {
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    });
    var url = baseUrl + "client/getStudentInfoAll";

    return this.http.get(url, { headers: headers });    
  }

  getStudentInfo(id:number):Observable<any> {
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    });
    var url = baseUrl + "client/getStudentInfo/" + id;

    return this.http.get(url, { headers: headers });    
  }


  setSelfieStatus(id:number, status:string, rejectedId:number = null):Observable<any> {
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    });
    var url = baseUrl + "client/setStudentStatus/" + id;
    return this.http.post<any>(url, {"status":status,"rejected_reason_id":rejectedId}, { headers: headers });     
    if(rejectedId != null) {
      return this.http.post<any>(url, {"status":status,"rejected_reason_id":rejectedId}, { headers: headers });     
    }
    else {
      return this.http.post<any>(url, {"status":status}, { headers: headers });     
    }
  }

  
}
