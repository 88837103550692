<div class="electrip-form">

    <mat-dialog-content >
        <h2>Add New Corporate Agreement</h2>
        <form [formGroup]="agreementForm" (ngSubmit)="onSubmit()" autocomplete="off">

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Price Schedule *</mat-label>
                <input type="text" name="city" placeholder="Pick one" aria-label="City" matInput formControlName="price_id" [matAutocomplete]="autoCity">
                <mat-autocomplete  #autoCity="matAutocomplete" [displayWith]="displayFn" >
                    <mat-option *ngFor="let option of filteredPrice | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Agreement Start Date</mat-label>
                <input matInput [matDatepicker]="agreement_start_date" formControlName="start">
                <mat-datepicker-toggle matSuffix [for]="agreement_start_date"></mat-datepicker-toggle>
                <mat-datepicker #agreement_start_date></mat-datepicker>
            </mat-form-field> 
            
            <mat-form-field appearance="fill">
                <mat-label>Agreement End Date</mat-label>
                <input matInput [matDatepicker]="agreement_end_date" formControlName="end">
                <mat-datepicker-toggle matSuffix [for]="agreement_end_date"></mat-datepicker-toggle>
                <mat-datepicker #agreement_end_date></mat-datepicker>
            </mat-form-field> 

            <mat-checkbox formControlName="billing_period">Bir sonraki ayın ilk günü</mat-checkbox>

            <button type="submit" mat-raised-button color="primary" [disabled]="!agreementForm.valid">Submit</button>
        </form>
    </mat-dialog-content>
</div>