import { HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/services/documents.service';
import { GlobalService, OpenImage } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';

@Component({
  selector: 'app-reservation-terminate-images',
  templateUrl: './reservation-terminate-images.component.html',
  styleUrls: ['./reservation-terminate-images.component.css']
})
export class ReservationTerminateImagesComponent implements OnInit {
  image_inside_back = null;
  image_inside_front = null;
  image_outside_back = null;
  image_outside_front = null;
  image_outside_left = null;
  image_outside_right = null;
  photos = [];
  loading = true;
  constructor(
    private reservationService: ReservationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private documentService: DocumentsService,
  ) { }


  setImage(img_key, key): void {
    this.documentService.getMFile(img_key).subscribe(event => {
          if (event.type === HttpEventType.Response) {
        let reader = new FileReader();
          reader.addEventListener("load", () => {
          this[key] = reader.result;
        }, false);
        reader.readAsDataURL(event.body);
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.reservationService.getReservationTerminateImages(this.data).subscribe(result => {
      this.setImage(result.data.image_inside_back, "image_inside_back");
      this.setImage(result.data.image_inside_front, "image_inside_front");
      this.setImage(result.data.image_outside_back, "image_outside_back");
      this.setImage(result.data.image_outside_front, "image_outside_front");
      this.setImage(result.data.image_outside_left, "image_outside_left");
      this.setImage(result.data.image_outside_right, "image_outside_right");
      
      this.loading = false;
      // for (let index = 0; index < result.data.length; index++) {
      //   const element = result.data[index];
      //   this.photos.push(this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + element))
      // }
    }, this.globalService.handleError);
  }

  showImage(image: string) {
    this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
  }
}
