import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { UsersService } from 'src/app/services/users.service';
import { baseUrl } from 'src/environments/environment';
import { DriverReservationsDetailComponent } from '../drivers/driver-reservations/driver-reservations-detail/driver-reservations-detail.component';
import { ReservationAddComponent } from './reservation-add/reservation-add.component';
import { ReservationDetailComponent } from './reservation-detail/reservation-detail.component';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.css']
})
export class ReservationsComponent implements AfterViewInit {
  reservations: any;
  // downloadExcelUrl: string = baseUrl + "reservation/exportCSV";
  downloadExcelUrl: string = baseUrl + "reservation/exportCSVBilling";
  displayedColumns: Array<any> = ["id", "order_id", "plate", "user_name", "talep", "usage", "brand_model", "start_destination", "end_destination", "start_time", "end_time", "reservationkm", "price", "status"];
  user_id: number;

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  current_page = 0
  pageEvent: PageEvent;
  typingTimer: any;
  subscribeEvent: Subscription;
  @ViewChild(MatSort) sort: MatSort;
  word: any = "";
  reservation_status:any = "";
  excel_file: any;
  excel_progress: string = "Download Excel";


  constructor(
    private userService: UsersService,
    private dialog:MatDialog,
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService,
    private cd: ChangeDetectorRef,

    ) { }

  ngOnInit(): void {
    this.getRes();
    
    this.subscribeEvent = this.reservationService.All_Reservations.subscribe((res:any) => {
      if('data' in res) {
        this.reservations = new MatTableDataSource(res.data?.reservations);
        this.length = res.data?.pagination?.count;
        this.reservations.sort = this.sort;
      }
      else {
        this.reservationService.showSnackbar("Reservation error.");
      }
      
    }, this.globalService.handleError);



  }

  ngAfterViewInit(): void {
    this.reservations.sort = this.sort;
  }
  tableClick(id: number): void {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: id,
      minWidth: "500px"
    }).afterClosed().subscribe(() => {
      this.getRes();
    });
  }

  getRes(page: number = this.current_page + 1): void {
    this.reservationService.getAllReservations({
        "search": this.word,
        "status": this.reservation_status,
    },{
      "pageSize": this.pageSize,
      "pageIndex": this.current_page
    });
  }

  getResPagination(event:PageEvent): PageEvent {
    this.current_page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getRes();
    return event;
  }

  searchRez(word: string): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.word = word;
      this.getRes();
    }, 300);
  }

  reservationStatusChange(value: string): void {
    if(value == "") {
      // this.reservation_status = {};
      // this.getRes();
      // return;  
    }
    // this.reservation_status = {
    //   status: value
    // };
    this.reservation_status = value;
    this.getRes();
  }

  addNewReservation(): void {
    this.dialog.open(ReservationAddComponent, {
      maxHeight: '95vh',
      width: '500px',
      autoFocus: false
    }).afterClosed().subscribe((data:any) => {
      if(data) {
        this.getRes();
      }
    });
    
  }

  excelDownload(): void {
    if(this.excel_progress != "Download Excel") return;

    this.excel_progress = "Please wait";

    this.reservationService.getExcel().subscribe(result => {
      // var name = result.data.name;
      //   this.excel_file = setInterval(() => {
      //     this.reservationService.downloadExcel(name).subscribe(result => {
      //       clearInterval(this.excel_file)
      //       this.downloadFile(result);
      //     }, err => {
      //       if(err.status == 404) return;
      //       clearInterval(this.excel_file)
      //       this.globalService.handleError(err)
      //     });
      //   }, 1000)
    }, this.globalService.handleError);
  }

  downloadFile(data: any) {
    const blob = new Blob([data], { type: '' });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'reservations.xlsx';
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      }, 100)
    // window.open(url);
  }

  ngOnDestroy(): void {
    this.subscribeEvent.unsubscribe();
  }


}