import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';

@Component({
  selector: 'app-reservation-end',
  templateUrl: './reservation-end.component.html',
  styleUrls: ['./reservation-end.component.css']
})
export class ReservationEndComponent implements OnInit {
  result_data: any;
  
  endForm: FormGroup = new FormGroup({
    endTime: new FormControl('', Validators.required),
    price: new FormControl('', Validators.required),
  });;

  old_end_time: string;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reservationService: ReservationsService,
    private globalService: GlobalService,
    private dialogRef: MatDialogRef<ReservationEndComponent>,
  ) { }

  ngOnInit(): void {
    
    this.loading = true;
    this.reservationService.getReservationEndInfo(this.data).subscribe(res => {
      this.loading = false;
      this.result_data = res.data;
      this.old_end_time = res.data.now;
      this.endForm.patchValue({
        price: res.data.price,
        endTime: res.data.now
      });
    });

    this.endForm.valueChanges.subscribe({
      next: (afterForm) => {
        if(afterForm.endTime != this.old_end_time) {
          this.loading = true;
          this.reservationService.getReservationEndInfoPrice(this.data, afterForm.endTime).subscribe({
            next: (res) => {
              this.loading = false;
              this.result_data = res.data;
              this.old_end_time = res.data.now;
              this.endForm.patchValue({
                price: res.data.price,
                endTime: res.data.now
              });
            },
            error: this.globalService.handleError
          });
        }
      },
      error: this.globalService.handleError
    });

  }

  onSubmit(): void {
    this.reservationService.setReservationEndInfo(this.data, this.endForm).subscribe({
      next: (res) => {
        this.dialogRef.close("OK");
      },
      error: this.globalService.handleError
    });
  }


}
