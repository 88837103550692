<div class="settings-flex">
    <mat-nav-list>
        <a mat-list-item routerLinkActive="list-item-active"  [routerLinkActiveOptions]="{exact: true}" *ngFor="let link of links" routerLink="{{ link.url }}">
            {{ link.name }} 
        </a>
    </mat-nav-list>

    <div class="router">
        <router-outlet></router-outlet>
    </div>
</div>