import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import { VehicleGroupComponent } from '../../pricing/vehicle-group/vehicle-group.component';
import { CorporateGroupComponent } from '../../pricing/corporate-group/corporate-group.component';
import { UserCorporateVehiclesEditComponent } from './user-corporate-vehicles-edit/user-corporate-vehicles-edit.component';

@Component({
  selector: 'app-user-corporate-vehicles',
  templateUrl: './user-corporate-vehicles.component.html',
  styleUrls: ['./user-corporate-vehicles.component.css']
})
export class UserCorporateVehiclesComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'corporate_count', 'vehicle_count'];
  dataSource = null;
  constructor(
    private corporateService: CorporateService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.corporateService.getUserCorporateVehicles().subscribe({
      next: (result) => {
        this.dataSource = result.data;
      },
      error: this.globalService.handleError
    })
  }

  addNew(): void {
    this.dialog.open(UserCorporateVehiclesEditComponent, {
      minWidth: "500px"
    }).afterClosed().subscribe(result => {
        this.getData();
    });
  }

  tableClick(id: number): void {
    this.dialog.open(UserCorporateVehiclesEditComponent, {
      minWidth: "500px",
      data: id
    }).afterClosed().subscribe(result => {
        this.getData();
    });
  }

  vehicleGroup(): void {
    this.dialog.open(VehicleGroupComponent, {
      minWidth: "500px",
    })
  }

  corporateGroup(): void {
    this.dialog.open(CorporateGroupComponent, {
      minWidth: "500px",
    })
  }



}
