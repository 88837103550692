import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';
import { ListCorporateModelsComponent } from './list-corporate-models/list-corporate-models.component';

@Component({
  selector: 'app-add-corporate-group',
  templateUrl: './add-corporate-group.component.html',
  styleUrls: ['./add-corporate-group.component.css']
})
export class AddCorporateGroupComponent implements OnInit {

  corporateGroupForm = new FormGroup({
    name: new FormControl(),
    models: new FormArray([])
  });
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private priceService: PricingService,
    private dialogRef: MatDialogRef<AddCorporateGroupComponent>,
    private globalService: GlobalService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.priceService.saveCorporateGroup(this.corporateGroupForm).subscribe({
      next: (result) => {
        this.dialogRef.close();
        this.globalService.successMessage(result);
      },
      error: this.globalService.handleError
    })
  }

  addModel(): void {
    this.dialog.open(ListCorporateModelsComponent, {
      data: this.data
    }).afterClosed().subscribe({
      next: (result) => {

        if (result) {
          const items = this.corporateGroupForm.get('models') as FormArray
          items.push(new FormControl({id: result.id, name: result.name}))
        }
      }
    })
  }
  removeModel(item_id: number): void {
    const items = this.corporateGroupForm.get('models') as FormArray
    items.removeAt(item_id)
  }
}
