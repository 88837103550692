<div class="user-table">
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> User ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Statü </th>
            <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="registered_time">
            <th mat-header-cell *matHeaderCellDef> Üyelik Tarihi </th>
            <td mat-cell *matCellDef="let element"> {{element.registered_time}} </td>
        </ng-container>

        <ng-container matColumnDef="tckn">
            <th mat-header-cell *matHeaderCellDef> TC </th>
            <td mat-cell *matCellDef="let element"> {{element.tckn}} </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef> Telefon </th>
            <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> E-posta </th>
            <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="birth_date">
            <th mat-header-cell *matHeaderCellDef> Doğum Günü </th>
            <td mat-cell *matCellDef="let element"> {{element.birth_date}} </td>
        </ng-container>

        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> İl </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
        </ng-container>

        <ng-container matColumnDef="town">
            <th mat-header-cell *matHeaderCellDef> İlçe </th>
            <td mat-cell *matCellDef="let element"> {{element.town}} </td>
        </ng-container>

        <ng-container matColumnDef="schedule">
            <th mat-header-cell *matHeaderCellDef> Tarife </th>
            <td mat-cell *matCellDef="let element"> {{element.schedule}} </td>
        </ng-container>

        <ng-container matColumnDef="campaign">
            <th mat-header-cell *matHeaderCellDef> Kampanya </th>
            <td mat-cell *matCellDef="let element"> {{element.campaign}} </td>
        </ng-container>

        <ng-container matColumnDef="other_cards">
            <th mat-header-cell *matHeaderCellDef> Diğer Kartlar </th>
            <td mat-cell *matCellDef="let element"> {{element.other_cards}} </td>
        </ng-container>

        <ng-container matColumnDef="corporate_info">
            <th mat-header-cell *matHeaderCellDef> Kurumsal Şirket Bilgisi </th>
            <td mat-cell *matCellDef="let element"> {{element.corporate_info}} </td>
        </ng-container>

        <ng-container matColumnDef="driver_license_given">
            <th mat-header-cell *matHeaderCellDef> Ehliyet Veriliş Tarihi </th>
            <td mat-cell *matCellDef="let element"> {{element.driver_license_given}} </td>
        </ng-container>

        <ng-container matColumnDef="driver_license_expiration">
            <th mat-header-cell *matHeaderCellDef> Ehliyet Son Kullanma Tarihi </th>
            <td mat-cell *matCellDef="let element"> {{element.driver_license_expiration}} </td>
        </ng-container>

        <ng-container matColumnDef="student_card_given">
            <th mat-header-cell *matHeaderCellDef> Öğrenci Veriliş Tarihi </th>
            <td mat-cell *matCellDef="let element"> {{element.student_card_given}} </td>
        </ng-container>

        <ng-container matColumnDef="student_card_expiration">
            <th mat-header-cell *matHeaderCellDef> Öğrenci Son Kullanma Tarihi </th>
            <td mat-cell *matCellDef="let element"> {{element.student_card_expiration}} </td>
        </ng-container>

        <ng-container matColumnDef="reject_reason">
            <th mat-header-cell *matHeaderCellDef> Red Nedeni </th>
            <td mat-cell *matCellDef="let element"> {{element.reject_reason}} </td>
        </ng-container>

        <ng-container matColumnDef="approved_person">
            <th mat-header-cell *matHeaderCellDef> Onaylayan Kişi </th>
            <td mat-cell *matCellDef="let element"> {{element.approved_person}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent=getResPagination($event)"></mat-paginator>
</div>