<p>{{ header }} Vehicle Brand</p>
<div class="electrip-form-container">
    <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="vehicleBrandsEditForm" (ngSubmit)="saveVehicleBrand()">
        <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput required formControlName="name">
        </mat-form-field>    
        <br>

        <button mat-raised-button color="primary" type="submit">Submit</button>
    </form>
</div>