import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-provider-add',
  templateUrl: './provider-add.component.html',
  styleUrls: ['./provider-add.component.css']
})
export class ProviderAddComponent implements OnInit {

  providerForm = new FormGroup({
    name: new FormControl(''),
  });


  constructor(
    private vehicleService: VehicleService,
    private mapControl: MapControlServiceService,
    private dialogRef: MatDialogRef<ProviderAddComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.vehicleService.addProvider(this.providerForm).subscribe((res: any) => {
      if (res.success) {
        this.mapControl.showSnackbar(res.data?.message);
      } else {
        this.mapControl.showSnackbar(res.errorMessage);
      }
      this.dialogRef.close();
    }, this.globalService.handleError);
  }

}
