import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CorporationService } from 'src/app/services/corporation.service';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'app-corporation-users-add',
  templateUrl: './corporation-users-add.component.html',
  styleUrls: ['./corporation-users-add.component.css']
})
export class CorporationUsersAddComponent implements OnInit {
  users: any;
  userForm: FormGroup = new FormGroup({
    usersControlName: new FormControl()
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CorporationUsersAddComponent>,
    private corporationService: CorporationService,
    private _snackBar: MatSnackBar,
    private userService: UsersService,
    private globalService: GlobalService
  ) {
    this.userService.getUserList().subscribe((result) => {
      if (result.success) {
        this.users = this.userForm.get('usersControlName').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data?.users))
        );
      }
    }, this.globalService.handleError);
  }
  ngOnInit(): void {
  }


  dialogClose(text: string) {
    this._snackBar.open('User Successfully ' + text, 'Ok', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 5000,
    });
    this.dialogRef.close("ok");
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onFormSubmit() {
    console.log(this.userForm.value);
    this.corporationService.addUserToCorporate(this.userForm.value.usersControlName.id, this.data.corp_id).subscribe((result: any) => {
      if (result.success) {
        this.dialogClose("Added");
      }
    }, this.globalService.handleError);
  }
}
