<mat-dialog-content>
    <div fxLayoutGap="20px" fxLayout="column">
        <div>
            <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!image">
                <mat-spinner [value]="spinnerValue" [mode]="spinnerMode"></mat-spinner>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="image-zoom" [src]="image" width="100%" (click)="globalService.showImage(image)">
            </div>
        </div>

        <table class="table-border">
            <tr>
                <td>User</td>
                <td><a (click)="dialogRef.close()" routerLink="/dashboard/drivers/{{ data.user_id }}">{{ data.name }} {{ data.surname }}</a></td>
            </tr>
            <tr>
                <td>Start Date</td>
                <td>{{ data.start_date }}</td>
            </tr>
            <tr>
                <td>Graduation Date</td>
                <td>{{ data.graduation_date }}</td>
            </tr>
        </table>

        <div fxLayoutAlign="end center" fxLayoutGap="10px">
            <button mat-raised-button color="warn" (click)="reject()">Rejected</button>
            <button mat-raised-button color="primary" (click)="accept()">Approved</button>
        </div>
    </div>
</mat-dialog-content>