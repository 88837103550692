import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehicleModelsService } from 'src/app/services/vehicle-models.service';
import { MatDialog } from '@angular/material/dialog';
import { VehicleModelEditComponent } from './vehicle-model-edit/vehicle-model-edit.component';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-vehicle-models',
  templateUrl: './vehicle-models.component.html',
  styleUrls: ['./vehicle-models.component.css']
})
export class VehicleModelsComponent implements OnInit {

  vehicleModels:any;
  displayedColumns: string[] = ['id', 'name','edit'];

  constructor(private VehicleModelsService:VehicleModelsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.vehicleModelsGet();
  }

  vehicleModelsGet() {
    this.VehicleModelsService.getAllVehicleModels().subscribe(result=>{
      if(result.success){    
        this.vehicleModels = [];    
        result.data.forEach(element => {
          var vehicle:any = new Object();
          var v  = new Object;
          for (let key in element){
              v[key] = element[key];
          }        
          this.vehicleModels.push(v);
        });
        this.changeDetectorRefs.detectChanges();        
      }
    }, this.globalService.handleError);
  }

  openEditDialog() {
    const dialogRef = this.dialog.open(VehicleModelEditComponent, {
      width: '500px',
      data: {id:0}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.vehicleModelsGet();
    });

  }

  openEditDialogID(id:number, name:string) {
    const dialogRef = this.dialog.open(VehicleModelEditComponent, {
      width: '500px',
      data: {id:id, name:name},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.vehicleModelsGet();
    });

  }

}
