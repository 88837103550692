import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LicenseService } from 'src/app/services/license-selfie.service';
import { UsersService } from 'src/app/services/users.service';
import { RejectedReasonComponent } from '../../rejected-reason/rejected-reason.component'
import { LicenseDetailComponent, OpenImage } from '../../license/license-detail/license-detail.component';
import { DriverDetailComponent } from '../driver-detail/driver-detail.component';
import { StudentLicenseDetailComponent } from '../../student-license/student-license-detail/student-license-detail.component';
import { UserPhotoStatusColor } from 'src/environments/global';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-driver-documents',
  templateUrl: './driver-documents.component.html',
  styleUrls: ['./driver-documents.component.css']
})
export class DriverDocumentsComponent implements OnInit {
  data:any;
  subscription:any;
  status_colors: Object = UserPhotoStatusColor;

  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.subscription = this.route.parent.params.subscribe((uri:any) => {
      this.getUser(uri.id);
      this.subscription?.unsubscribe();
    }, this.globalService.handleError);
  }

  getUser(id:number): void {
    if(!id) {
      return;
    }
    this.userService.getUserDocuments(id).subscribe((result:any) => {
      if(result.success) {
        this.data = result.data;
        console.log(this.data);
      }
    }, this.globalService.handleError);
  }

  driver_license(license: any): void {
    let dialogRef = this.dialog.open(LicenseDetailComponent, {
      width: '80%',
      data: license.licenseId
    });
  }

  user_selfie(selfie:any): void {
    let dialogRef = this.dialog.open(DriverDocumentsSelfie, {
      width: '80%',
      data: selfie
    });
  }

  student_photo(photo:any): void {
    let dialogRef = this.dialog.open(StudentLicenseDetailComponent, {
      width: '80%',
      data: photo.photo_id
    });
  }
}


@Component({
  selector: "app-driver-documents-selfie",
  templateUrl: './document-selfie.component.html',
  styleUrls: ['./driver-documents.component.css']
})
export class DriverDocumentsSelfie implements OnInit {
  openedLicense: any;
  licenseFront: any;
  licenseBack: any;
  status_colors: Object = UserPhotoStatusColor;



  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DriverDocumentsSelfie>,
    private licenseService: LicenseService,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.get_data();
  }
  
  get_data() {
    this.licenseService.getUserSelfie(this.data.selfieId).subscribe((result:any) => {
      if(result.success) {
        console.log("selfie", result.data);
        this.openedLicense = result.data;
        this.licenseFront = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + result.data.base64Selfie);
      }
    }, this.globalService.handleError);
  }


  panelReject(id:number) {
    const dialog_rejected = this.dialog.open(RejectedReasonComponent, {
      minWidth: '250px',
      data: "UserSelfie"
    });
    dialog_rejected.afterClosed().subscribe((result:any) => {
      if(result) {
        this.licenseService.setSelfieStatus(id,"Rejected",result.rejectedId).subscribe((data) => {
          this._snackBar.open('Selfie Reject.', 'Ok', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
        }, this.globalService.handleError);
        this.get_data();
      }
    });
  }

  panelAccept(id:number) {
    this.licenseService.setSelfieStatus(id,"Approved").subscribe((data) => {
      this._snackBar.open('Selfie Approved.', 'Ok', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      this.dialogRef.close();
    }, this.globalService.handleError);
  }


  showImage(image:string) {
    const dialogRef = this.dialog.open(OpenImage, {
      width: '80%',
      maxHeight: '95vh',
      data: image
    });
  }
}