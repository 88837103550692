<div fxLayout="column" fxLayoutGap="20px">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="reservations_feature" class="">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let element">
                    <span [ngStyle]="{'color': element.reservationTimePenalty?'red':''}">
                        {{element.id}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="plate">
                <th mat-header-cell *matHeaderCellDef> Plate </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
            </ng-container>

            <ng-container matColumnDef="user_full_name">
                <th mat-header-cell *matHeaderCellDef> User Full Name </th>
                <td mat-cell *matCellDef="let element"> {{element.userName}} {{ element.userSurname }} </td>
            </ng-container>

            <ng-container matColumnDef="usage">
                <th mat-header-cell *matHeaderCellDef> Usage </th>
                <td mat-cell *matCellDef="let element"> {{ element.usage }} </td>
            </ng-container>

            <ng-container matColumnDef="brand_model">
                <th mat-header-cell *matHeaderCellDef> Brand - Model </th>
                <td mat-cell *matCellDef="let element"> {{element.vehicle?.brand}} - {{element.vehicle?.model}}
                </td>
            </ng-container>

            <ng-container matColumnDef="start_destination">
                <th mat-header-cell *matHeaderCellDef> Start Destination </th>
                <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
            </ng-container>


            <ng-container matColumnDef="end_destination">
                <th mat-header-cell *matHeaderCellDef> End Destination </th>
                <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
            </ng-container>

            <ng-container matColumnDef="start_time">
                <th mat-header-cell *matHeaderCellDef> Start Time </th>
                <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
            </ng-container>


            <ng-container matColumnDef="end_time">
                <th mat-header-cell *matHeaderCellDef> End Time </th>
                <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="vehicles = row.avaiableVehicles"></tr>
        </table>
    </div>
    <div class="log-table" *ngIf="vehicles">
        <table>
            <tr>
                <td colspan="3"><b fxLayoutAlign="center center">Avaiable cars</b></td>
            </tr>
            <tr *ngFor="let log of vehicles">
                <td>
                    <div fxLayoutAlign="center center">
                        ID: {{ log.id }}
                    </div>
                </td>
                <td>
                    <div fxLayoutAlign="center center">
                        Plate: {{ log.plate }}
                    </div>
                </td>
                <td>
                    <div fxLayoutAlign="center center">
                        Model: {{ log.brand }} {{ log.model }}
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>