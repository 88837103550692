import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-park-locations-item',
  templateUrl: './park-locations-item.component.html',
  styleUrls: ['./park-locations-item.component.css']
})
export class ParkLocationsItemComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
  }

}
