import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CorporationService } from 'src/app/services/corporation.service';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-park-locations-add',
  templateUrl: './park-locations-add.component.html',
  styleUrls: ['./park-locations-add.component.css']
})
export class ParkLocationsAddComponent implements OnInit {
  loading: boolean = true;
  parkLocationForm = new FormGroup({
    id: new FormControl(null),
    name: new FormControl('', Validators.required),
    car_capacity: new FormControl(0, Validators.required),
    lat: new FormControl('', Validators.required),
    long: new FormControl('', Validators.required),
    image: new FormControl(''),
    city_id: new FormControl(null, Validators.required),
    town_id: new FormControl(null, Validators.required),
    address: new FormControl(''),
    district_code: new FormControl('', Validators.required),
  });
  filteredCitys: Observable<any>;
  filteredTowns: Observable<any>;
  constructor(
    private corpService: CorporationService,
    private parkService: ParkingPlacesService,
    private router: Router,
    private dialogRef: MatDialogRef<ParkLocationsAddComponent>,
    private mapController: MapControlServiceService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getCitys();
    if(this.data) {
      console.log(this.data);
      this.parkLocationForm.patchValue(this.data);
    }
  }

  getCitys(): void {
    this.corpService.getCityList().subscribe((result:any) => {
      this.filteredCitys = this.parkLocationForm.get('city_id').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
      if(this.data) {
        if(this.data.city) {
          this.parkLocationForm.patchValue({
            city_id: {id: this.data.city.id, name: this.data.city.name}
          });
          this.getTown(this.data.city.id);
        }
      }
      this.loading = false;
    }, this.globalService.handleError);
  }

  getTown(id:number): void {
    this.corpService.getTownList(id).subscribe((result:any) => {
      this.filteredTowns = this.parkLocationForm.get('town_id').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
      if(this.data) {
        this.parkLocationForm.patchValue({
          town_id: {id: this.data.town.id, name: this.data.town.name}
        });
      }
    }, this.globalService.handleError);
  }

  city_change(city: any): void {
    this.getTown(city?.id);
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }
  private _filter(value: any, data: any): string[] {
    
    const filterValue = value.name ? value.name.toLowerCase():value.toString().toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    this.parkLocationForm.patchValue({
      "city_id": this.parkLocationForm.get('city_id').value.id,
      "town_id": this.parkLocationForm.get('town_id').value.id,
    });
    this.parkService.saveParkingPlace(this.parkLocationForm.value).subscribe((result:any) => {
      if(result?.data?.id) {
        console.log(result?.data?.id);
        this.router.navigateByUrl('/dashboard/park-locations/' + result.data.id);
        this.mapController.showSnackbar("Successfully Added");
      }
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }
}
