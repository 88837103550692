<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Send Message</h2>
        <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()" autocomplete="off">


            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>User *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="User" matInput formControlName="user" [matAutocomplete]="autoUser">
                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredUser | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Message</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="message"></textarea>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!reservationForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>