import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { PricingService } from 'src/app/services/pricing.service';
import { AddCorporateGroupComponent } from './corporate-group/add-corporate-group/add-corporate-group.component';
import { CorporateGroupComponent } from './corporate-group/corporate-group.component';
import { PricingEditComponent } from './pricing-edit/pricing-edit.component';
import { AddVehicleGroupComponent } from './vehicle-group/add-vehicle-group/add-vehicle-group.component';
import { VehicleGroupComponent } from './vehicle-group/vehicle-group.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'vehicle_group', 'corporate_group', 'title', 'daily', 'hourly', 'minute', 'every_15_minute', 'start_time', 'end_time', 'create_at'];
  dataSource = null;

  constructor(
    private pricingService: PricingService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    this.pricingService.getPriceList().subscribe({
      next: (result) => {
        this.dataSource = result.data;
      },
      error: this.globalService.handleError
    })
  }

  addNew(): void {
    this.dialog.open(PricingEditComponent, {
      minWidth: "500px"
    }).afterClosed().subscribe(result => {
        this.getData();
    });
  }

  tableClick(id: number): void {
    this.dialog.open(PricingEditComponent, {
      minWidth: "500px",
      data: id
    }).afterClosed().subscribe(result => {
        this.getData();
    });
  }

  vehicleGroup(): void {
    this.dialog.open(VehicleGroupComponent, {
      minWidth: "500px",
    })
  }

  corporateGroup(): void {
    this.dialog.open(CorporateGroupComponent, {
      minWidth: "500px",
    })
  }

}
