import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DefaultComponent } from './layouts/default/default.component';
import { LoginComponent } from './login/login.component';
import { VehicleEditComponent } from './modules/vehicles/vehicle-edit/vehicle-edit.component';
import { VehiclesComponent } from './modules/vehicles/vehicles.component';
import { AuthguardGuard } from './authguard.guard';
import { LicenseComponent } from './modules/license/license.component';
import { SelfieComponent } from './modules/selfie/selfie.component';
import { CorporationComponent } from './modules/corporation/corporation.component';
import { SettingsComponent } from './modules/settings/settings.component';
import { UserSegmentTypeComponent } from './modules/settings/user-segment-type/user-segment-type.component';
import { VehicleDetailComponent } from './modules/vehicles/vehicle-detail/vehicle-detail.component';
import { DriversComponent } from './modules/drivers/drivers.component';
import { DriverDetailComponent } from './modules/drivers/driver-detail/driver-detail.component';
import { DriverEditComponent } from './modules/drivers/driver-edit/driver-edit.component';
import { VehicleMapComponent } from './modules/vehicles/vehicle-map/vehicle-map.component';
import { MoreComponent } from './modules/more/more.component';
import { ParkLocationsComponent } from './modules/park-locations/park-locations.component';
import { ParkLocationsEditComponent } from './modules/park-locations/park-locations-edit/park-locations-edit.component';
import { ParkLocationsDetailComponent } from './modules/park-locations/park-locations-detail/park-locations-detail.component';
import { ParkLocationsMapComponent } from './modules/park-locations/park-locations-map/park-locations-map.component';
import { ChargeStationsComponent } from './modules/charge-stations/charge-stations.component';
import { ChargeStationsDetailComponent } from './modules/charge-stations/charge-stations-detail/charge-stations-detail.component';
import { ChargeStationsEditComponent } from './modules/charge-stations/charge-stations-edit/charge-stations-edit.component';
import { ChargeStationsMapComponent } from './modules/charge-stations/charge-stations-map/charge-stations-map.component';
import { VehicleDamagesComponent } from './modules/vehicles/vehicle-damages/vehicle-damages.component';
import { DriverDocumentsComponent } from './modules/drivers/driver-documents/driver-documents.component';
import { HgsComponent } from './modules/more/hgs/hgs.component';
import { DriverReservationsComponent } from './modules/drivers/driver-reservations/driver-reservations.component';
import { ReservationsComponent } from './modules/reservations/reservations.component';
import { StudentLicenseComponent } from './modules/student-license/student-license.component';
import { DeactiveUsersComponent } from './modules/more/deactive-users/deactive-users.component';
import { ReservationRefundListComponent } from './modules/reservations/reservation-refund-list/reservation-refund-list.component';
import { CampaignCodeComponent } from './modules/campaign-code/campaign-code.component';
import { ParkLocationChargeStationsComponent } from './modules/park-locations/park-location-charge-stations/park-location-charge-stations.component';
import { ParkLocationVehiclesComponent } from './modules/park-locations/park-location-vehicles/park-location-vehicles.component';
import { CorporationDetailComponent } from './modules/corporation/corporation-detail/corporation-detail.component';
import { CorporationAgreementsComponent } from './modules/corporation/corporation-agreements/corporation-agreements.component';
import { RefundSettingsComponent } from './modules/reservations/refund-settings/refund-settings.component';
import { AdditionalBillingComponent } from './modules/additional-billing/additional-billing.component';
import { VehicleSegmentOptionsComponent } from './modules/vehicles/vehicle-segment-options/vehicle-segment-options.component';
import { CorporationSegmentsComponent } from './modules/corporation/corporation-segments/corporation-segments.component';
import { PricingComponent } from './modules/pricing/pricing.component';
import { ReservationTableComponent } from './modules/reservation-table/reservation-table.component';
import { LogsComponent } from './modules/drivers/logs/logs.component';
import { UserDocumentsComponent } from './modules/drivers/user-documents/user-documents.component';
import { AccountDetailComponent } from './modules/drivers/account-detail/account-detail.component';
import { CorporateAccountDetailsComponent } from './modules/corporation/corporate-account-details/corporate-account-details.component';
import { OtherDocumentsComponent } from './modules/other-documents/other-documents.component';
import { StudentCardComponent } from './modules/other-documents/student-card/student-card.component';
import { CorporateCardComponent } from './modules/other-documents/corporate-card/corporate-card.component';
import { UserTableComponent } from './modules/drivers/user-table/user-table.component';
import { ReservationsTripleComponent } from './modules/reservations-triple/reservations-triple.component';
import { SignalComponent } from './modules/signal/signal.component';
import { VehicleReportsComponent } from './modules/reports/vehicle-reports/vehicle-reports.component';
import { UserCorporateVehiclesComponent } from './modules/more/user-corporate-vehicles/user-corporate-vehicles.component';


const routes: Routes = [
  {
    path: '',
    component:LoginComponent,
  },
  {
    path: 'dashboard', 
    component: DefaultComponent,
    canActivate: [AuthguardGuard],
    children : [
      {
        path: '',
        // component: DashboardComponent
        component: ReservationsComponent
      },
      {
        path: 'reservation-triple',
        component: ReservationsTripleComponent
      },
      {
        path: 'signal',
        component: SignalComponent
      },
      {
        path: 'drivers',
        component: DriversComponent,
        children: [
          {
            path: 'user-table',
            component: UserTableComponent
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: DriverDetailComponent,
              },
              {
                path: 'edit',
                component: DriverEditComponent,
              },
              {
                path: 'documents',
                component: DriverDocumentsComponent,
              },
              {
                path: 'reservations',
                component: DriverReservationsComponent,
              },
              {
                path: 'journeys',
                component: DriverReservationsComponent,
              },
              {
                path: 'logs',
                component: LogsComponent,
              },
              {
                path: 'account-detail',
                component: AccountDetailComponent
              }
            ]
          }
        ]
      },
      {
        path: 'vehicles',
        component: VehiclesComponent,
        children: [
          {
            path: ':id',
            children: [
              {
                path: '',
                component: VehicleDetailComponent,
              },
              {
                path: 'edit',
                component: VehicleEditComponent,
              },
              {
                path: 'map',
                component: VehicleMapComponent
              },
              {
                path: 'damages',
                component: VehicleDamagesComponent,
              }

            ]
          }
        ]
      },
      {
        path: 'park-locations',
        component: ParkLocationsComponent,
        children: [
          {
            path: ':id',
            children: [
              {
                path: '',
                component: ParkLocationsDetailComponent,
              },
              {
                path: 'edit',
                component: ParkLocationsEditComponent,
              },
              {
                path: 'map',
                component: ParkLocationsMapComponent,
              },
              {
                path: 'charge-stations',
                component: ParkLocationChargeStationsComponent
              },
              {
                path: 'vehicles',
                component: ParkLocationVehiclesComponent
              }
            ]
          }
        ]
      },
      {
        path: 'charge-stations',
        component: ChargeStationsComponent,
        children: [
          {
            path: '',
            component: ChargeStationsDetailComponent,
          },
          {
            path: 'map',
            component: ChargeStationsMapComponent,
          },
          {
            path: ':id',
            children: [
              {
                path: '',
                component: ChargeStationsDetailComponent,
              },
              {
                path: 'edit',
                component: ChargeStationsEditComponent,
              },
              {
                path: 'map',
                component: ChargeStationsMapComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'license',
        component: LicenseComponent, 
      },
      {
        path: 'student-license',
        component: StudentLicenseComponent, 
      },
      {
        path: 'selfie',
        component: SelfieComponent, 
      },
      {
        path: 'corporation',
        component: CorporationComponent,
        children: [
          {
            path: ':id',
            children: [
              {
                path: '',
                component: CorporationDetailComponent,
              },
              {
                path: 'agreements',
                component: CorporationAgreementsComponent
              },
              {
                path: 'account-details',
                component: CorporateAccountDetailsComponent
              }
            ]
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        children: [{
          path: 'user-segment-type',
          component: UserSegmentTypeComponent,
        }]
      },
      {
        path: 'more',
        component: MoreComponent,
        children: [
          {
            path: 'hgs',
            component: HgsComponent,
          },
          {
            path: 'deactive-users',
            component: DeactiveUsersComponent
          },
          {
            path: 'refunded-reservation-list',
            component: ReservationRefundListComponent
          },
          {
            path: 'campaign-code',
            component: CampaignCodeComponent,
          },
          {
            path: 'refund-settings',
            component: RefundSettingsComponent
          },
          {
            path: 'vehicle-segment-options',
            component: VehicleSegmentOptionsComponent
          },
          {
            path: 'corporation-segment-options',
            component: CorporationSegmentsComponent
          },
          {
            path: 'user-documents',
            component: UserDocumentsComponent
          },
          {
            path: 'vehicle-reports',
            component: VehicleReportsComponent
          },
          {
            path: 'user-corporate-vehicles',
            component: UserCorporateVehiclesComponent
          }
        ]
      },
      {
        path: 'additional-billing',
        component: AdditionalBillingComponent,
      },
      {
        path: 'pricing',
        component: PricingComponent
      },
      {
        path: 'reservation-table',
        component: ReservationTableComponent
      },
      {
        path: 'other-documents',
        component: OtherDocumentsComponent,
        children: [
          {
            path: 'student-card',
            component: StudentCardComponent
          },
          {
            path: 'corporate-card',
            component: CorporateCardComponent
          }
        ]
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
