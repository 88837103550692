import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { CarService } from 'src/app/services/triggers/car.service';


@Component({
  selector: 'app-corporation-cars-add',
  templateUrl: './corporation-cars-add.component.html',
  styleUrls: ['./corporation-cars-add.component.css']
})
export class CorporationCarsAddComponent implements OnInit {
  filteredVehicles: Observable<any>;
  vehicleForm = new FormGroup({
    vehicle: new FormControl(null)
  })
  constructor(
    private dialogRef: MatDialogRef<CorporationCarsAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private vehicleService: CarService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    console.log(this.data)
    this.vehicleService.getVehicleList().subscribe((result) => {
      if (result.success) {
        this.filteredVehicles = this.vehicleForm.get('vehicle').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data))
        );
      }
    }, this.globalService.handleError);
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  dialogClose(text: string) {
    this.globalService.showMessage('Vehicle Successfully ' + text);
    this.dialogRef.close("ok");
  }

  onSubmit(): void {
    this.vehicleService.addVehicleToCorporation(this.data, this.vehicleForm.get('vehicle').value.id)
    .subscribe((result: any) => {
      if (result.success) {
        this.dialogClose("ok");
      }
    }, this.globalService.handleError);
  }

}
