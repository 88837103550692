import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { UsersService } from 'src/app/services/users.service';
import { BASEURL, baseUrl, REFUND_STATUS, STATUS_VAR } from 'src/environments/environment';
import { ReservationAdditionalTimeComponent } from '../reservation-additional-time/reservation-additional-time.component';
import { ReservationCancelComponent } from '../reservation-cancel/reservation-cancel.component';
import { ReservationEndComponent } from '../reservation-end/reservation-end.component';
import { ReservationTerminateImagesComponent } from '../reservation-terminate-images/reservation-terminate-images.component';
import { ResrevationImagesComponent } from '../resrevation-images/resrevation-images.component';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.css']
})
export class ReservationDetailComponent implements OnInit {

  reservation: any;
  baseUrl = BASEURL;
  status_var: any = STATUS_VAR;
  refund_status = REFUND_STATUS;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private dialog: MatDialog,
    private globalService: GlobalService,
    public dialogRef: MatDialogRef<ReservationDetailComponent>
    ) { 

  }

  ngOnInit(): void {
    this.getResDetail();
  }

  getResDetail(): void {
    this.userService.getReservationDetail(this.data).subscribe({
      next: result => {
        this.reservation = result.data;
      },
      error: this.globalService.handleError
    });
  }



  reservationStatusChange(status: any): void {
    this.reservationService.setReservationStatus(this.data, status).subscribe({
      next: result => {
        if (result.success) {
          this.globalService.successMessage(result);
          this.getResDetail();
        }
      },
      error: this.globalService.handleError
    });
  }

  set_additional_time(reservation: any): void {
    this.dialog.open(ReservationAdditionalTimeComponent, {
      data: reservation
    }).afterClosed().subscribe((data:any) => {
      if(data) {
        this.getResDetail();
        this.mapControl.showSnackbar("Reservation additional time added succesfuly.");
      }
    });
  }

  cancel_reservation(reservation: any): void {
    this.dialog.open(ReservationCancelComponent, {
      data: reservation
    }).afterClosed().subscribe((data: any) => {
      if(data) {
        this.getResDetail();
        this.mapControl.showSnackbar("Your cancellation has been processed.");
      }
    });
  }

  open_reservation_photos(reservation_id: number): void {
    this.dialog.open(ResrevationImagesComponent, {
      data: reservation_id,
      width: "70%",
      height: "70%",
    });
  }

  open_terminate_reservation_photos(reservation_id: number): void {
    this.dialog.open(ReservationTerminateImagesComponent, {
      data: reservation_id,
      width: "70%",
      height: "70%",
    });
  }
  
  end_reservation(reservation_id: number): void {
    this.dialog.open(ReservationEndComponent, {
      data: reservation_id,
      width: "500px",
      autoFocus: false
    }).afterClosed().subscribe({
      next: (dialog) => {
        if(dialog == "OK") this.getResDetail();
      }
    });
  }

  open_vehicle_photos(vehicle: any): void {
    this.dialog.open(ResrevationImagesComponent, {
      data: vehicle,
      width: "70%",
      height: "70%",
    });
  }

  approveReservation(): void {
    this.reservationService.reservationApprove(this.reservation.id, true).subscribe({
      next: result => {
        if(result.success) {
          this.globalService.successMessage(result);
          this.getResDetail();
        }
      },
      error: this.globalService.handleError
    });
  }

  rejectReservation(): void {
    this.reservationService.reservationApprove(this.reservation.id, false).subscribe({
      next: result => {
        if(result.success) {
          this.globalService.successMessage(result);
          this.getResDetail();
        }
      },
      error: this.globalService.handleError
    });
  }
}
