import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentsService } from 'src/app/services/documents.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-reject-user-document',
  templateUrl: './reject-user-document.component.html',
  styleUrls: ['./reject-user-document.component.css']
})
export class RejectUserDocumentComponent implements OnInit {
  reasonGroup: FormGroup = new FormGroup({

  });

  reasons: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private documentService: DocumentsService,
    public globalService: GlobalService,
    public dialogRef: MatDialogRef<RejectUserDocumentComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.data);
    this.documentService.getRejectedReasonList(this.data.model).subscribe(result => {
      result.data.forEach(element => {
        console.log(element);
        console.log(this.data);
        var checked = false;
        var filtered = this.data.selected?.filter((x: any) => x.id == element.id);
        console.log(filtered);
        this.reasonGroup.addControl(element.reason_title, new FormControl(filtered?.length > 0));
      });
      this.reasons = result.data;
    }, this.globalService.handleError);
  }

  panelClose(): void {
    this.dialogRef.close(this.reasonGroup.value);
  }

}
