import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { GlobalService } from '../global.service';
import { MapControlServiceService } from '../map-control-service.service';

@Injectable({
  providedIn: 'root'
})
export class DriversService extends MapControlServiceService{
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(
    private mapControl: MapControlServiceService,
    public http: HttpClient,
    private globalService: GlobalService
  ) {
    super(mapControl._snackBar);
  }

  selected_driver: BehaviorSubject<any> = new BehaviorSubject({"data": null});

  get_driver_id(): number {
    var val = this.selected_driver.value.data;
    if(val) {
      return val.user_id;
    }
    return 0;
  }
  
  get_driver(id: number): void {
    var url = baseUrl + "client/getUserById/" + id;
    this.http.get(url, { headers: this.headers }).subscribe((result:any) => {
      this.selected_driver.next(result);
    }, this.globalService.handleError);
  }

  get_driver_status(): Observable<any> {
    var url = baseUrl + "client/user/" + this.get_driver_id() + "/status";
    return this.http.get(url, { headers: this.headers });
  }
  

}
