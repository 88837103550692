import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';


@Component({
  selector: 'app-reservation-additional-time',
  templateUrl: './reservation-additional-time.component.html',
  styleUrls: ['./reservation-additional-time.component.css']
})
export class ReservationAdditionalTimeComponent implements OnInit {
  reservationForm = new FormGroup({
    additional_end_time: new FormControl(),
    note: new FormControl(this.data.note),
    get_paid: new FormControl(false),
    
  });
  show_paid: boolean = false;
  form_data: any;
  loading: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reservationService: ReservationsService,
    public dialogRef: MatDialogRef<ReservationAdditionalTimeComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.reservationService.setAdditionalTimeReservation(this.data.id, this.reservationForm).subscribe((result:any) => {
      if(result.success) {
          this.dialogRef.close(true);
        }
      }, this.globalService.handleError);
    
  }

  datetime_change(event: any) {
    this.reservationService.getAdditionalTimeCharge(this.data.id, event).subscribe((res:any) => {
      if(res.success) {
        this.form_data = res.data;
        if(res.data.corporate) {
          this.reservationForm.patchValue({get_paid: true});
          this.reservationForm.controls["get_paid"].disable();
          this.show_paid = true;
        }
      }
    }, this.globalService.handleError);
  }

}
