import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-status-type-add',
  templateUrl: './status-type-add.component.html',
  styleUrls: ['./status-type-add.component.css']
})
export class StatusTypeAddComponent implements OnInit {

  statusTypeForm = new FormGroup({
    name: new FormControl(''),
  });


  constructor(
    private vehicleService: VehicleService,
    private mapContorl: MapControlServiceService,
    private dialogRef: MatDialogRef<StatusTypeAddComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.vehicleService.addStatusType(this.statusTypeForm).subscribe((res: any) => {
      if (res.success) {
        this.mapContorl.showSnackbar(res.data?.message);
      } else {
        this.mapContorl.showSnackbar(res.errorMessage);
      }
      this.dialogRef.close(this.statusTypeForm.value.name);
    }, this.globalService.handleError);
  }


}
