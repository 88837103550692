import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';

@Component({
  selector: 'app-reservations-triple',
  templateUrl: './reservations-triple.component.html',
  styleUrls: ['./reservations-triple.component.css']
})
export class ReservationsTripleComponent implements OnInit {
  reservations_current: any;
  reservations_feature: any;
  reservations_ended: any;
  selected_rez_id: number = 0;
  displayedColumns = ["id", "plate", "user_full_name", "usage", "brand_model", "start_destination", "end_destination", "start_time", "end_time"];

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  current_page = 0
  pageEvent: PageEvent;
  typingTimer: any;
  word: any = "";
  reservation_status:any = "";

  constructor(
    private reservationService: ReservationsService
  ) { }

  ngOnInit(): void {
    this.getResCurrent();
    this.getResFeature();
    this.getResEnded();
  }

  tableClick(id: number): void {
    this.selected_rez_id = id;
  }

  getResCurrent(): void {
    this.reservationService.reservationStatusListCurrent(this.word, this.reservation_status).subscribe((data) => {
      this.reservations_current = data.data;
    });
  }

  getResFeature(): void {
    this.reservationService.reservationStatusListFeature(this.word, this.reservation_status).subscribe((data) => {
      this.reservations_feature = data.data;
    });
  }

  getResEnded(): void {
    this.reservationService.reservationStatusListEnded(this.pageSize, this.current_page + 1, this.word, this.reservation_status).subscribe((data) => {
      this.reservations_ended = data.data;
    });
  }

  getResPagination(event:PageEvent): PageEvent {
    this.current_page = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getResEnded();
    return event;
  }

  searchRez(word: string): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.word = word;
      this.getResCurrent();
      this.getResFeature();
      this.getResEnded();
    }, 300);
  }

  reservationStatusChange(value: string): void {
    this.reservation_status = value;
    this.getResCurrent();
    this.getResFeature();
    this.getResEnded();
  }

}
