import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  getAllUserSegmentTypes():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getAllUserSegmentTypes";

    return this.http.get(this.url, { headers: headers });    
  }

  getUserSegmentTypeById(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getUserSegmentTypeById/"+id;

    return this.http.get(this.url, { headers: headers });    
  }

  editUserSegmentType(body: any):Observable<any>{
    this.url = baseUrl + "client/editUserSegmentType";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  saveUserSegmentType(body: any):Observable<any>{
    this.url = baseUrl + "client/saveUserSegmentType";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  deleteUserSegmentType(id: any):Observable<any>{
    this.url = baseUrl + "client/deleteUserSegmentType/"+id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {}, { headers: headers });     
  }
  
}
