import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { DriversService } from 'src/app/services/triggers/drivers.service';
import { UsersService } from 'src/app/services/users.service';
import { UserTagsComponent } from '../user-tags/user-tags.component';
import { DriverNotificationsComponent } from './driver-notifications/driver-notifications.component';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.css']
})
export class DriverDetailComponent implements OnInit {
  dataLoad: boolean = false;
  data: any;
  user_id: number;
  subscribe: Subscription;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    public dialog: MatDialog,
    private mapControl: MapControlServiceService,
    private driverService: DriversService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.getUser(uri.id);
    });
    
    this.subscribe = this.driverService.selected_driver.subscribe((result:any) => {
      if(result.data) {
        this.dataLoad = true;
        this.data = result.data;
      }
    });
  }
  
  getUser(id:number): void {
    this.dataLoad = false;
    this.driverService.selected_driver.next({"data": null});
    this.driverService.get_driver(id);
  }

  showNotifications(): void {
    let dialogRef = this.dialog.open(DriverNotificationsComponent, {
      width: '500px',
      height: '70%',
    });
  }

  userStatusChange(user_id: number, status: string): void {
    this.userService.userActiveChange(user_id, status).subscribe(result => {
      if(result.success) {
        return this.mapControl.showSnackbar(result.data.message);
      }
    }, this.globalService.handleError);
  }
  
  sendWelcomeEmail(user_id: number): void {
    if(confirm("Are you sure ?")) {
      this.userService.sendWelcomeEmail(user_id).subscribe(result => {
        if(result.success) {
          return this.mapControl.showSnackbar(result.data.message);
        }
      }, this.globalService.handleError);
    }
  }

  ngOnDestroy(): void {
    this.driverService.selected_driver.next({"data": null});
    this.subscribe.unsubscribe();
  }

  openUserTags(): void {
    this.dialog.open(UserTagsComponent, {
      width: "500px",
      data: this.data.user_id
    });
  }

  cancelDeactivate(user_id): void {
    if(confirm("Are you sure ?")) {
      this.userService.userCancelDeactive(user_id).subscribe(result => {
        if(result.success) {
          return this.mapControl.showSnackbar(result.data.message);
        }
      }, this.globalService.handleError);
    }
  } 
}
