<div class="panel" fxLayout="column">
    <div fxLayout="column" fxLayoutAlign="none">
        <h2>Signal Levels Dashboard</h2>
    </div>

    <mat-form-field appearance="standard">
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="34BEE001 (plate or imei)" #input>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="vehicle_getaway_connect">
            <th mat-header-cell *matHeaderCellDef> Gateway Connection Status </th>
            <td mat-cell *matCellDef="let element">                 
                <div *ngIf="!element.vehicle_getaway_connect" style="color:red;"><mat-icon>wifi_off</mat-icon></div>
                <div *ngIf="element.vehicle_getaway_connect" style="color:green;"><mat-icon>wifi_on</mat-icon></div>
            </td>
        </ng-container>

        <ng-container matColumnDef="vehicle_pk">
            <th mat-header-cell *matHeaderCellDef> Vehicle ID </th>
            <td mat-cell *matCellDef="let element"> {{element.vehicle_pk}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle_plate">
            <th mat-header-cell *matHeaderCellDef> Vehicle Plate </th>
            <td mat-cell *matCellDef="let element"> {{element.vehicle_plate}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle_imei">
            <th mat-header-cell *matHeaderCellDef> Vehicle IMEI </th>
            <td mat-cell *matCellDef="let element"> {{element.vehicle_imei}} </td>
        </ng-container>

        <ng-container matColumnDef="vehicle_gsm_satalite">
            <th mat-header-cell *matHeaderCellDef> Satellite Signal Level </th>
            <td mat-cell *matCellDef="let element"> {{element.vehicle_gsm_satalite}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>