import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CorporationService } from 'src/app/services/corporation.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import { CorporationEditComponent } from './corporation-edit/corporation-edit.component';
import { CorporationUsersComponent } from './corporation-users/corporation-users.component';


@Component({
  selector: 'app-corporation',
  templateUrl: './corporation.component.html',
  styleUrls: ['./corporation.component.css']
})
export class CorporationComponent implements OnInit {

  behavior_corporate = this.corporationService.corporates;
  subscription: Subscription;
  selectedID: number;
  uri_subscription: Subscription;
  constructor(
    private corporationService: CorporateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    ) {
    
   }

  ngOnInit(): void {

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
      this.selectedID = uri.id;
        this.subscription?.unsubscribe();
      });
    });

    this.getAllCorps();
  }


  getAllCorps() {
    this.corporationService.get_corporates();
  }

  showCorporation(id:number) {
    // const dialogShow = this.dialog.open(CorporationUsersComponent, {
    //   data:id,
    //   width: '80%',
    //   maxHeight: '95vh',
    // });
    // dialogShow.afterClosed().subscribe((result:any)=> {
    //     this.getAllCorps();
    // });
  }


  addCorporation() {
    const dialogRef = this.dialog.open(CorporationEditComponent, {});
    dialogRef.afterClosed().subscribe((result:any) => {
      if(result) {
        this.getAllCorps();
      }
    })
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }

}
