<div class="detail-center">
    <div class="detail-content">
        <div class="add-button">
            <button mat-raised-button color="primary" type="submit" (click)="addNewAgreement()">Add New</button>
        </div>

        <div>
           <div *ngFor="let item of data">
                <div class="items">
                   <span>{{ item.start }} - {{ item.end }}</span>
                   <span *ngIf="item.price.total">
                       {{item.price.total | currency:"TRY":'symbol-narrow':'0.2-2'}} 
                   </span>
                   <span *ngIf="item.price.percentage && !item.price.total">
                       %{{ item.price.percentage }}
                   </span>
                   <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" (click)="removeItem(item.id)"><mat-icon>remove_circle</mat-icon></button>
                </div>
                <hr>
           </div> 
        </div>


    </div>
</div>