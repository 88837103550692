import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  access_token = localStorage.getItem('access_token');

  constructor(
    private http: HttpClient,
    private globalService: GlobalService
  ) { }

  getDocuments(params: any = {}, current_page: string, page_size: string): Observable<any> {

    var url = baseUrl + "userLicense/getUserDocuments";
    var url_new = new URL(url);
    url_new.searchParams.set("current_page", current_page);
    url_new.searchParams.set("page_size", page_size);
    return this.http.post<any>(url_new.toString(), params);     
  }

  getMFile(image_code: string): Observable<any> {
    var url = baseUrl + "userLicense/getMFiles";
    return this.http.post<any>(url, {file_id: image_code}, { responseType: 'blob' as 'json', reportProgress: true, observe: "events"});
  }

  getRejectedReasonList(model: string): Observable<any> {
    var url = baseUrl + "userLicense/rejectedReasonList";
    return this.http.post<any>(url, { model: model });
  }

  

  getUserDriverLicense(user_id: number): Observable<any> {
    var url = baseUrl + "userLicense/getUserDriverLicense/" + user_id;
    return this.http.get<any>(url);
  }

  getDriverLicenses(): Observable<any> {
    var url = baseUrl + "userLicense/getDriverLicenses";
    return this.http.get<any>(url);
  }

  getDriverLicense(user_id: number): Observable<any> {
    var url = baseUrl + "userLicense/getDriverLicense/" + user_id;
    return this.http.get<any>(url);
  }

  setDriverLicenseRejected(license_id: number, rejected_data: any): Observable<any> {
    var url = baseUrl + "userLicense/setDriverLicenseRejected/" + license_id;
    return this.http.post<any>(url, { rejected_data: rejected_data });
  }

  setDriverLicenseStatus(license_id: number, status: string): Observable<any> {
    var url = baseUrl + "userLicense/setDriverLicenseStatus/" + license_id;
    return this.http.post<any>(url, { status: status });
  }



  getUserSelfie(user_id: number): Observable<any> {
    var url = baseUrl + "userLicense/getUserSelfie/" + user_id;
    return this.http.get<any>(url);
  }

  setSelfieStatus(license_id: number, status: string): Observable<any> {
    var url = baseUrl + "userLicense/setSelfieStatus/" + license_id;
    return this.http.post<any>(url, { status: status });
  }

  setSelfieRejected(license_id: number, rejected_data: any): Observable<any> {
    var url = baseUrl + "userLicense/setSelfieRejected/" + license_id;
    return this.http.post<any>(url, { rejected_data: rejected_data });
  }



  getUserStudentPhoto(user_id: number): Observable<any> {
    var url = baseUrl + "userLicense/getUserStudentPhoto/" + user_id;
    return this.http.get<any>(url);
  }

  setStudentPhotoStatus(license_id: number, status: string): Observable<any> {
    var url = baseUrl + "userLicense/setStudentPhotoStatus/" + license_id;
    return this.http.post<any>(url, { status: status });
  }

  setStudentPhotoRejected(license_id: number, rejected_data: any): Observable<any> {
    var url = baseUrl + "userLicense/setStudentPhotoRejected/" + license_id;
    return this.http.post<any>(url, { rejected_data: rejected_data });
  }
  
}
