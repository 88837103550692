import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleModelsService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient) { }

  getAllVehicleModels():Observable<any>{
    this.url = baseUrl + "vehicle/getAllModels";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  getVehicleModel(parking_place_id:any):Observable<any>{
    this.url = baseUrl + "vehicle/getModel/" + parking_place_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  saveVehicleModel(body):Observable<any>{
    this.url = baseUrl + "vehicle/saveModel";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      "Accept": "*/*",
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  editVehicleModel(body):Observable<any>{
    this.url = baseUrl + "vehicle/editModel";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      "Accept": "*/*",
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }
  

}
