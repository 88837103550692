<div class="panel">
    <div class="panel-list">
        <div class="list-top">
            <h1>More</h1>
        </div>

        <div class="list-content">
            <div class="list-items">
                <div class="list-item" routerLinkActive="list-item-active" routerLink="deactive-users">
                    <div class="item-content">
                        <div class="info">
                            <h2>Deactive Users</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="hgs">
                    <div class="item-content">
                        <div class="info">
                            <h2>HGS</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="refunded-reservation-list">
                    <div class="item-content">
                        <div class="info">
                            <h2>Refunded Resevation List</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="campaign-code">
                    <div class="item-content">
                        <div class="info">
                            <h2>Campaign Code</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="refund-settings">
                    <div class="item-content">
                        <div class="info">
                            <h2>Reservation Refund Settings</h2>
                        </div>
                    </div>
                </div>
                <!-- <div class="list-item" routerLinkActive="list-item-active" routerLink="vehicle-segment-options">
                    <div class="item-content">
                        <div class="info">
                            <h2>Vehicle Segment Options</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="corporation-segment-options">
                    <div class="item-content">
                        <div class="info">
                            <h2>Corporation Segment Options</h2>
                        </div>
                    </div>
                </div> -->
                <div class="list-item" routerLinkActive="list-item-active" routerLink="user-documents">
                    <div class="item-content">
                        <div class="info">
                            <h2>User Documents</h2>
                        </div>
                    </div>
                </div>
                <div class="list-item" routerLinkActive="list-item-active" routerLink="user-corporate-vehicles">
                    <div class="item-content">
                        <div class="info">
                            <h2>User Corporate Vehicles</h2>
                        </div>
                    </div>
                </div>
                <!-- <div class="list-item" routerLinkActive="list-item-active" routerLink="vehicle-reports">
                    <div class="item-content">
                        <div class="info">
                            <h2>Vehicle Reports</h2>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>

    <div class="panel-content">
        <div class="panel-full">
            <div class="router-only">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>