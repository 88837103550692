<div class="panel">
    <div class="panel-list" [ngClass]="{'d-none':!panelListShow}">
        <div class="list-top">
            <h1>Drivers</h1>
            <div class="list-query">
                <mat-form-field class="example-form-field" *ngIf="showSearch">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" (input)="onSearchChange($event)">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="showSearch=false;closeSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-button *ngIf="!showSearch" (click)="showSearch=true;">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
                </button>

                <button mat-button color="warn" [matMenuTriggerFor]="menu">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="listSort({id: 'name', value: true})">Name A-Z</button>
                    <button mat-menu-item (click)="listSort({id: 'name', value: false})">Name Z-A</button>
                    <button mat-menu-item (click)="listSort({id: 'surname', value: true})">Surname A-Z</button>
                    <button mat-menu-item (click)="listSort({id: 'surname', value: false})">Surname Z-A</button>

                </mat-menu>
            </div>
        </div>
        <div class="progress-bar" *ngIf="!users">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="list-content" *ngIf="users">
            <div class="list-buttons">
                <button [ngClass]="{'active_btn':listButtonsID == 1}" (click)="listButtons({id: ['status'], value: 'active',buttonID:1})">Aktif</button>
                <button [ngClass]="{'active_btn':listButtonsID == 2}" (click)="listButtons({id: ['status'], value: 'blacklist',buttonID:2})">Blacklist</button>
                <button [ngClass]="{'active_btn':listButtonsID == 3}" (click)="listButtons({id: ['status'], value: 'deactive',buttonID:3})">Deactive</button>
                <button [ngClass]="{'active_btn':listButtonsID == 4}" (click)="listButtons({id: ['status'], value: 'new_user',buttonID:4})">New User</button>
                <button [ngClass]="{'active_btn':listButtonsID == 5}" (click)="listButtons({id: ['status'], value: 'missing_document',buttonID:5})">Missing Document</button>
                <button [ngClass]="{'active_btn':listButtonsID == 6}" (click)="listButtons({id: ['status'], value: 'waitin_approval',buttonID:6})">Waiting Approval</button>
                <button [ngClass]="{'active_btn':listButtonsID == 7}" (click)="listButtons({id: ['status'], value: 'rejected',buttonID:7})">Rejected</button>
         
            </div>
            <div class="list-items">
                <app-driver-item *ngFor="let user of users" [data]="user"></app-driver-item>
            </div>
        </div>
    </div>
    <div class="panel-content">
        <div class="content-control">
            <div class="d-flex">
                <button mat-button (click)="panelListShow=!panelListShow">
                    <mat-icon>view_quilt</mat-icon>
                </button>
                <button mat-button (click)="send_message()">
                    Send Message
                </button>
                <button mat-button (click)="send_firebase_message()" style="white-space: pre-wrap;width: 150px;">
                    Send Firebase Message All User
                </button>
                <button mat-button routerLink="/dashboard/drivers/user-table" >
                    User Table
                </button>
            </div>
            <div class="list-buttons">
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}" [routerLinkActiveOptions]="{exact: true}">
                    Details
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/map">
                    Map
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/documents">
                    Belgeler
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/reservations">
                    Rezervasyonlar
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/journeys">
                    Yolculuklar
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/account-detail">
                    Accounting Detail
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ selectedID }}/logs">
                    Loglar
                </a>
            </div>
            <button mat-button (click)="statusShow=!statusShow">
                <mat-icon>view_quilt</mat-icon>
            </button>
        </div>
        <div class="panel-dynamic">
            <div class="router-only">
                <router-outlet></router-outlet>
            </div>
            <div [ngClass]="{'d-none':!statusShow, '': true}">
                <app-driver-status></app-driver-status>

            </div>
        </div>
    </div>
</div>