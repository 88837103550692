import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private http:HttpClient, private _router:Router) { }

  login(data, repchata):Observable<any>{
    data.grant_type = 'password';
    data.client_id = '3ErHIvrkQiVgUbubL03R5BxFRbe0jYuVxzxxzyKm';
    data.client_secret = 'SpZlTDVWPsq9PLmeUHTdEHQJQTov6Isjt7GOfUcyEwB1upB6ADtaZdz7EYaW0oeMicjmFcagZx8ZzWgj74SSyNiyxPpq4NpmYUYed01QkAdcktnfsHQPLUxDVTMl8d4D';
    
    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('client_id', data.client_id);
    formData.append('client_secret', data.client_secret);
    formData.append('username', data.username);
    formData.append('password', data.password);
    // formData.append('recaptcha', repchata);
    
    return this.http.post(`${baseUrl}o/login_token/`, formData);
  }

  logOut() {
    var access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    })
    this.http.post(`${baseUrl}client/logOutApp`, {}, { headers: headers }).subscribe(
      (res:any) => {
        console.log(res);
        localStorage.clear();
        this._router.navigate(['/']);
      },
      err => {
        console.log(err);
        localStorage.clear();
        this._router.navigate(['/']);
      }
    );
  }


}


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Tokeninizi buradan alın
    const authToken =  localStorage.getItem('access_token');
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    });

    // Değiştirilmiş isteği gönderin
    return next.handle(authReq);
  }
}

