<div class="electrip-form">
    <mat-dialog-content>
        <h2>Add New Campaign Code</h2>
        <form [formGroup]="campaignCodeForm" (ngSubmit)="onSubmit()" autocomplete="off">

            <mat-form-field>
                <input matInput type="text" placeholder="Campaign Code" formControlName="code" min="1" >
            </mat-form-field>

            <mat-form-field>
                <input matInput type="number" placeholder="Percentage" formControlName="percentage" min="1" max="100" >
            </mat-form-field>

            <mat-form-field>
                <input matInput type="number" placeholder="Total" formControlName="total">
            </mat-form-field>

            <mat-form-field>
                <input matInput type="number" placeholder="Hours" formControlName="hour">
            </mat-form-field>

            <mat-checkbox formControlName="used_one_time">Use Only One Time</mat-checkbox>


            <button type="submit" mat-raised-button color="primary" [disabled]="!campaignCodeForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>