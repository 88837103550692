import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(private http: HttpClient) { }

  get_battery_reports(vehicle, start_time): Observable<any> {
    var url = BASEURL + "/admin_reports/vehicleBatteryReport";
    return this.http.post(url, { vehicle_id: vehicle, start_time: start_time }, { headers: this.headers });
  }
  get_km_reports(vehicle, start_time): Observable<any> {
    var url = BASEURL + "/admin_reports/vehicleKmReport";
    return this.http.post(url, { vehicle_id: vehicle, start_time: start_time }, { headers: this.headers });
  }
  get_energy_consumption_reports(vehicle, start_time): Observable<any> {
    var url = BASEURL + "/admin_reports/vehicleEnergyConsumptionReport";
    return this.http.post(url, { vehicle_id: vehicle, start_time: start_time }, { headers: this.headers });
  }
}
