<div mat-dialog-content>
    <form [formGroup]="modelsForm" autocomplete="off">
        <mat-form-field appearance="fill">
            <mat-label>Vehicle Model</mat-label>
            <mat-select formControlName="model">
                <mat-option value="">None</mat-option>
                <mat-option [value]="item.id" *ngFor="let item of data">{{ item.name }}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions align="end">
    <button mat-button (click)="onSubmit()">Save</button>
</div>