<div class="electrip-form-container">
    <h1>{{ header }}</h1>
    <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
    <form fxLayoutAlign="stretch" *ngIf="dataLoad" fxLayout="column" [formGroup]="vehicleEditForm"
        (ngSubmit)="saveVehicle()">
        <mat-form-field appearance="fill">
            <mat-label>Plate Number</mat-label>
            <input matInput required formControlName="number_plate">
        </mat-form-field>


        <div class="selectboxFlex">
            <mat-form-field appearence="fill">
                <mat-label>Brand</mat-label>
                <mat-select formControlName="brand">
                    <mat-option *ngFor="let brand of brands" [value]="brand.id">{{brand.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button type="button" color="primary" (click)="openBrand()">+</button>
        </div>


        <div class="selectboxFlex">
            <mat-form-field appearence="fill">
                <mat-label>Model</mat-label>
                <mat-select formControlName="model">
                    <mat-option *ngFor="let model of models" [value]="model.id">{{model.name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <button mat-raised-button type="button" color="primary" (click)="openModel()">+</button>
        </div>



        <div class="selectboxFlex">
            <mat-form-field appearence="fill">
                <mat-label>Parking Place</mat-label>
                <mat-select formControlName="parking_places">
                    <mat-option *ngFor="let model of parkingPlace" [value]="model.id">
                        {{model.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>



        <mat-form-field appearance="fill">
            <mat-label>Year</mat-label>
            <input matInput required formControlName="year">
        </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>Colour</mat-label>
            <input matInput required formControlName="colour">
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Sitting Capacity</mat-label>
            <input matInput required formControlName="sitting_capacity">
        </mat-form-field>



        
        <mat-form-field appearance="fill">
            <mat-label>Daily Price</mat-label>
            <input matInput required formControlName="daily_price">
        </mat-form-field>
        
        <mat-form-field appearance="fill">
            <mat-label>Hourly Price</mat-label>
            <input matInput required formControlName="hourly_price">
        </mat-form-field>
        
        <mat-form-field appearance="fill">
            <mat-label>Minute Price</mat-label>
            <input matInput required formControlName="minute_price">
        </mat-form-field>
        
        <mat-form-field appearance="fill">
            <mat-label>Currency</mat-label>
            <input matInput required formControlName="currency">
        </mat-form-field>



        <button mat-raised-button color="primary" type="submit">Submit</button>
    </form>


</div>