import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { AdditionalBillingAddItemComponent } from './additional-billing-add-item/additional-billing-add-item.component';

import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { AdditionalBillingService } from 'src/app/services/additional-billing.service';
import { GlobalService } from 'src/app/services/global.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-additional-billing-add',
  templateUrl: './additional-billing-add.component.html',
  styleUrls: ['./additional-billing-add.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class AdditionalBillingAddComponent implements OnInit {
  loading: boolean = true;
  billingForm: FormGroup = new FormGroup({
    reservation: new FormControl(null, Validators.required),
    billing_date: new FormControl(moment(), Validators.required),
    items: new FormArray([

    ]),
    note: new FormControl()
  });
  filteredReservation: any = null;
  
  constructor(
    private reservationService: ReservationsService,
    private dialog: MatDialog,
    private additionalService: AdditionalBillingService,
    private dialogRef: MatDialogRef<AdditionalBillingAddComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

    this.reservationService.reservationListName().subscribe(result => {
      this.loading = false;
      this.filteredReservation = this.billingForm.get('reservation').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }

  onSubmit(): void {
    this.additionalService.setAdditionalBillings({...this.billingForm.value, 'billing_date': (this.billingForm.get('billing_date').value).format('YYYY-MM-DD')}).subscribe(result => {
      this.dialogRef.close(true);
      this.globalService.successMessage(result);
    }, this.globalService.handleError);
  }

  addItem(): void {
    this.dialog.open(AdditionalBillingAddItemComponent, {
      minWidth: '400px'
    }).afterClosed().subscribe(data => {
      if(data) {
        const items = this.billingForm.get('items') as FormArray
        items.push(new FormControl(data))
      }
    })
  }

  removeItem(item_id: number): void {
    const items = this.billingForm.get('items') as FormArray
    items.removeAt(item_id)
  }
  
  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

}
