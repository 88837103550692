import { Component, OnInit } from '@angular/core';
import { HgsService } from 'src/app/services/hgs.service';

@Component({
  selector: 'app-hgs',
  templateUrl: './hgs.component.html',
  styleUrls: ['./hgs.component.css']
})
export class HgsComponent implements OnInit {
  dataSource:any;
  displayedColumns: string[] = ['id', 'hgs_no', 'plate', 'highway', 'toll'];

  constructor(private hgsService: HgsService) { }

  ngOnInit(): void {
    this.getAllData();
  }

  updateData(): void {
    this.hgsService.updateDatabase().subscribe((res:any) =>{
      console.log(res)
    });
  }

  getAllData(): void {
    this.hgsService.getAllData(1).subscribe((res:any) => {
      console.log(res);
      this.dataSource = res.data;
    })
  }

}
