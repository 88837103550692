<div class="edit-content">
    <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
    <div class="userEditComp" *ngIf="dataLoad">
        <div class="electrip-form-container">
            <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="editForm" (ngSubmit)="saveForm()">
                <mat-form-field appearance="fill">
                    <mat-label>Name</mat-label>
                    <input matInput required formControlName="name">
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                    <mat-label>Surname</mat-label>
                    <input matInput required formControlName="surname">
                </mat-form-field>
                <br>


                <mat-form-field appearance="fill">
                    <mat-label>TCKN or Passport</mat-label>
                    <input matInput required formControlName="tcknOrPassport">
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                    <mat-label>Phone Number</mat-label>
                    <input matInput required formControlName="phoneNumber">
                </mat-form-field>
                <br>

                <mat-form-field appearance="fill">
                    <mat-label>Email</mat-label>
                    <input matInput required formControlName="email">
                </mat-form-field>
                <br>

                <mat-checkbox formControlName="isCitizen">Is Citizen</mat-checkbox>

                <br>

                <div class="field-dynamic">
                    <mat-form-field>
                        <mat-label>Gender</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option value="Male">
                                Male
                            </mat-option>
                            <mat-option value="Female">
                                Female
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <br>


                <mat-form-field appearance="fill">
                    <mat-label>Birth Date</mat-label>
                    <input matInput [matDatepicker]="birthDate" formControlName="birthDate">
                    <mat-datepicker-toggle matSuffix [for]="birthDate"></mat-datepicker-toggle>
                    <mat-datepicker #birthDate></mat-datepicker>
                </mat-form-field>


                <button mat-raised-button color="primary" type="submit" [disabled]="!editForm.valid">Submit</button>
            </form>
        </div>
    </div>
</div>