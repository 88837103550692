export const UserPhotoStatusColor = Object.freeze({
    "Rejected": "red",
    "Approved": "green",
    "Waiting": "#c1c111",
});

export const BillingItemNames = Object.freeze({
    "ARAC_KIRALAMA": "Araç Kiralama",
    "OTOYOL_GECİS": "HGS",
    "TRAFİK_CEZASI": "Trafik cezası",
    "ARAC_HASAR": "Araç hasar",
    "DIGER_CEZALAR": "Diğer cezalar"
});