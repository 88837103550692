<div fxLayout="column" fxLayoutAlign=" none" class="reservation-table-div">
    <form [formGroup]="searchGroup" autocomplete="off" fxLayout="column" fxLayoutAlign=" stretch">

        <mat-form-field appearance="fill">
            <mat-label>Vehicles</mat-label>
            <mat-chip-list #vehicleList aria-label="Vehicles selection">
                <mat-chip *ngFor="let vehicle of selectedVehicles" (removed)="remove_vehicle_chip(vehicle)">
                    {{vehicle.id}} - {{vehicle.plate}}
                    <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip>
                <input placeholder="Add Vehicle" formControlName="vehicles" [matAutocomplete]="auto"
                    [matChipInputFor]="vehicleList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
            </mat-chip-list>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let vehicle of filteredVehicle | async" [value]="vehicle">
                    {{vehicle.id | number:'2.0-0' }} - {{vehicle.plate}} {{vehicle.brand}} {{vehicle.model}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </form>

    <div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button mat-icon-button (click)="date_back()">
                <mat-icon>keyboard_arrow_left</mat-icon>
            </button>
            <div><b>{{ date }}</b></div>
            <button mat-icon-button (click)="date_next()">
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>

        <div class="vehicle-timeline" fxLayout="row" fxLayoutAlign=" none">
            <div class="vehicle-list" fxLayout="column" fxLayoutGap="10px">
                <div *ngFor="let car of showVehicles" fxLayout="row" fxLayoutAlign="center center" [ngClass]="[car.status, 'vehicle']">
                    <div class="vehicle-info" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                        <div>
                            {{ car.plate }} - {{ car.brand }} {{ car.model }}
                        </div>
                        <div>
                            ({{ car.status }})
                        </div>
                    </div>
                </div>
            </div>
            <div class="timeline" fxLayout="column" fxLayoutGap="10px" ngStyle.gt-sm="overflow: auto;">
                <div *ngFor="let car of showVehicles" fxLayout="column" fxLayoutAlign="center start">
                    <div class="timeline-date" fxLayout="row" fxLayoutAlign=" none">
                        <div *ngFor="let hour of hours" fxLayout="row" fxLayoutAlign="start center">
                            <div>{{ hour | number:'2.0-0' }}</div>
                        </div>
                    </div>
                    <div class="timeline-stick">
                        <hr>
                    </div>
                    <div class="vehicle-reservations">
                        <div *ngFor="let reservation of car.reservations"
                            (click)="openReservation(reservation.reservationId)"
                            [ngStyle]="{'left.px': reservation.left, 'width.px': reservation.width}" fxLayout="column"
                            fxLayoutAlign="space-between none">
                            <div fxLayout="column" fxLayoutAlign="center center">
                                <div class="reservatio-info">
                                    <div fxLayout="row" fxLayoutAlign="center center">
                                        <div>{{ reservation.reservationId }}</div>
                                        <div>{{ reservation.username }}</div>
                                    </div>
                                </div>
                                <div class="reservation-bar" [ngStyle]="{'background-color': reservation.color}"></div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="reservation-hours">
                                <span>{{ reservation.startM }}</span>
                                <span>{{ reservation.endM }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div>
        <div *ngIf="all_vehicles">
            <div class="hours">
                <div class="move-icon">


                    <div>
                        {{ date }}
                    </div>
                </div>
                <span *ngFor="let saat of saatler">{{ saat }}</span>
            </div>
            <div *ngFor="let car of vehicles" class="reservation-line">
                <div class="vehicle-card">
                    <div class="vehicle-image">
                        <img _ngcontent-qhp-c263="" width="100" height="50" alt="" srcset=""
                            src="/assets/images/{{ car.brand.toLowerCase() }}-{{ car.model.toLowerCase() }}.png">
                    </div>
                    <div class="vehicle-name">
                        <p><b>{{ car.brand }} {{ car.model }} - {{ car.plate }}</b></p>
                        <p>{{ car.status }}</p>
                    </div>
                </div>
                <div class="reservation-hours">
                    <div *ngFor="let rez of car.reservations"
                        [ngStyle]="{'left': rez.left + 'px', 'width': rez.width + 'px'}" class="reservation-exists"
                        (click)="openReservation(rez.reservationId)"></div>
                    <div *ngFor="let item of saatler" class="hour"></div>
                </div>
            </div>
        </div>
    </div> -->

</div>