// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false
};

// export const baseUrl = 'http://127.0.0.1:8000/';
// export const BASEURL = 'http://127.0.0.1:8000';
// export const baseUrl = 'https://mfo.electrip.com.tr/';
// export const BASEURL = 'https://mfo.electrip.com.tr';
// export const baseUrl = 'https://electrip-backend-test.uptecra.com/';
// export const BASEURL = 'https://electrip-backend-test.uptecra.com';
// export const baseUrl = 'https://testmfo.electrip.com.tr/';
// export const BASEURL = 'https://testmfo.electrip.com.tr';
export const baseUrl = 'https://driveup.uptecra.com/';
export const BASEURL = 'https://driveup.uptecra.com';


export const STATUS_VAR = Object.freeze({
  Waiting: "Waiting",
  Approved: "Approved",
  Rejected: "Rejected",
  Closed: "Closed",
  Ended: "Ended"
});

export const REFUND_STATUS = Object.freeze({
  WRONG_AMOUNT: "Yanlış Tutar",
  CUSTOMER_GRIEVANCE: "Müşteri Mağduriyeti",
  OTHER: "Diğer"
});

export const DOCUMENTS_VAR = Object.freeze({
  DriverLicense: "DriverLicense",
  UserSelfie: "UserSelfie",
  StudentCard: "StudentCard",
  CorporateCard: "CorporateCard"
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
