import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle.service';
import * as moment from 'moment';
import { VehicleBrandsComponent } from 'src/app/modules/vehicle-brands/vehicle-brands.component';
import { MatDialog } from '@angular/material/dialog';
import { VehicleModelsComponent } from '../../vehicle-models/vehicle-models.component';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-vehicle-edit',
  templateUrl: './vehicle-edit.component.html',
  styleUrls: ['./vehicle-edit.component.css']
})
export class VehicleEditComponent implements OnInit {
  header: string;
  brands: any;
  models: any;
  parkingPlace: any;
  dataLoad: boolean = false;
  carID: number;
  selectedFiles: any;
  vehicleImage: any;
  data: any;
  vehicleEditForm = new FormGroup({
    number_plate: new FormControl(),
    brand: new FormControl(),
    model: new FormControl(),
    parking_places: new FormControl(),
    license_year: new FormControl(1),
    year: new FormControl(),
    colour: new FormControl(),
    sitting_capacity: new FormControl(),
    image: new FormControl(),
    daily_price: new FormControl("0"),
    hourly_price: new FormControl("0"),
    minute_price: new FormControl("0"),
    currency: new FormControl('₺'),
  });

  constructor(
    private dialog: MatDialog,
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService,
    private parkService: ParkingPlacesService,

  ) {

  }

  ngOnInit(): void {
    this.vehicleService.getBrands().subscribe((result: any) => {
      this.brands = result.data;
    }, this.globalService.handleError);
    this.vehicleService.getModels().subscribe((result: any) => {
      this.models = result.data;
    }, this.globalService.handleError);
    this.parkService.getAllParkingPlaces().subscribe((result: any) => {
      this.parkingPlace = result.data;
    }, this.globalService.handleError);

    this.route.params.subscribe((uri: any) => {
      this.carID = uri.id;
      this.getCar(uri.id);
    });
  }

  getCar(id: number): void {
    this.dataLoad = false;
    this.vehicleService.getVehicle(id).subscribe((result: any) => {
      this.data = result.data;

      this.data.corporate_vehicle = this.data.corporate_vehicle == "False" ? false : true;
      this.vehicleEditForm.patchValue(this.data);
      this.vehicleEditForm.controls['brand'].setValue(result.data.brand.id, { onlySelf: true });
      this.vehicleEditForm.controls['model'].setValue(result.data.model.id, { onlySelf: true });
      this.vehicleEditForm.controls['parking_places'].setValue(result.data.coordinate.parking_place, { onlySelf: true });


      this.dataLoad = true;
    }, this.globalService.handleError);
  }


  openBrand(): void {
    const dialogRef = this.dialog.open(VehicleBrandsComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.vehicleService.getBrands().subscribe(result => {
        if (result.success) {
          this.brands = result.data;
        }
      }, this.globalService.handleError)
    });
  }

  openModel() {
    const dialogRef = this.dialog.open(VehicleModelsComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(result => {
      this.vehicleService.getModels().subscribe(result => {
        if (result.success) {
          this.models = result.data;
        }
      }, this.globalService.handleError)
    });
  }

  saveVehicle() {

    if (!this.vehicleEditForm.valid) {
      this._snackBar.open('Form is not valid', 'Dismiss', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      return;
    }
    let finalValues = this.vehicleEditForm.value;
    finalValues.modelId = this.vehicleEditForm.value.model;
    finalValues.brandId = this.vehicleEditForm.value.brand;
    finalValues.providerId = 1;

    let formatted_vehicleCommissioningDate = moment(this.vehicleEditForm.value.vehicleCommissioningDate).format('YYYY-MM-DD');
    finalValues.vehicleCommissioningDate = formatted_vehicleCommissioningDate;

    finalValues.id = this.carID;

    const formData = new FormData();

    Object.entries(finalValues).forEach(([key, value]:any) => {
      formData.append(key, value);
    });
    if (this.vehicleImage)
      formData.append('image', this.vehicleImage.file);
      
    this.vehicleService.editVehicle(formData).subscribe(result => {
      if (result.success) {
        this._snackBar.open('Vehicle Updated', 'Dismiss', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000,
        });
      }
    }, this.globalService.handleError);
  }


}