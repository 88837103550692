import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SignalService } from 'src/app/services/signal.service';

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.css']
})
export class SignalComponent implements OnInit {

  vehicleData;
  dataSource;
  displayedColumns = ["vehicle_pk", "vehicle_plate", "vehicle_gsm_satalite", "vehicle_imei", "vehicle_getaway_connect"];

  constructor(
    private signalService: SignalService
  ) { }

  ngOnInit(): void {
    this.signalService.getAllGsmGateway().subscribe({
      next: (data) => {
        console.log(data);
        this.vehicleData = data.data;
        this.dataSource = new MatTableDataSource(this.vehicleData);

      }
    })
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
