<div class="licenseComp">
    <h1>License Approvals</h1>
    <div class="mt-20">

        <table mat-table [dataSource]="licenses" class="mat-elevation-z8">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.user.name}} </td>
            </ng-container>

            <ng-container matColumnDef="surname">
                <th mat-header-cell *matHeaderCellDef> Surname </th>
                <td mat-cell *matCellDef="let element"> {{element.user.surname}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element"> {{element.user.email}} </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
        </table>

    </div>
</div>