import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { baseUrl } from 'src/environments/environment';
import { ImageUploadComponent } from '../../public/image-upload/image-upload.component';
import { ParkLocationChargeStationAddComponent } from '../park-location-charge-station-add/park-location-charge-station-add.component';
import { ParkLocationsAddComponent } from '../park-locations-add/park-locations-add.component';

@Component({
  selector: 'app-park-locations-detail',
  templateUrl: './park-locations-detail.component.html',
  styleUrls: ['./park-locations-detail.component.css']
})
export class ParkLocationsDetailComponent implements OnInit {
  parkIdExist: boolean = false;
  subscription: any;
  dataLoad: boolean = false;
  parkDetail: any;
  parkId: number;
  baseUrl: string = baseUrl;
  park_location_image: any;
  constructor(
    private mapControl: MapControlServiceService,
    private parkingService: ParkingPlacesService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private _sanitizer: DomSanitizer, 
    private globalService: GlobalService
    ) {
   }

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.parkIdExist= true;
      this.parkId = uri.id;
      this.getParkingPlaceByID();
    });
  }

  getParkingPlaceByID() {
    this.dataLoad = false;
    this.parkingService.getParkingPlace(this.parkId).subscribe((result:any) => {
      
      if(result.success) {
        this.dataLoad = true;
        this.parkDetail = result.data;
        // this.park_location_image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + result.data.image);
      }
    }, this.globalService.handleError);
  }

  editParkingPlace(parkPlace: any): void {
    const dialogRef = this.dialog.open(ParkLocationsAddComponent, {
      minWidth: "500px",
      maxWidth: "100%",
      autoFocus: false,
      data: parkPlace
    }).afterClosed().subscribe(()=> {
      this.getParkingPlaceByID();
    });
  }



  addImage(park_id: number): void {
    this.dialog.open(ImageUploadComponent, {
      autoFocus: false,
      data: {
        "uploadUrl": "vehicle/getParkingPlace/" + park_id + "/setImage",
      }
    }).afterClosed().subscribe(()=> {
      this.getParkingPlaceByID();
    });
  }

}
