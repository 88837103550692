<div>
    <form fxLayoutAlign="stretch" fxLayout="column" [formGroup]="messageForm" (ngSubmit)="onSubmit()">

        <mat-form-field appearance="fill">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title">
        </mat-form-field>
        <br>

        <mat-form-field class="example-full-width">
            <mat-label>Message</mat-label>
            <textarea formControlName="message" matInput></textarea>
        </mat-form-field>

        <div class="button">
            <button mat-raised-button color="primary" type="submit" [disabled]="!messageForm.valid">Submit</button>
        </div>

    </form>
</div>