import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { DriversService } from 'src/app/services/triggers/drivers.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-driver-status',
  templateUrl: './driver-status.component.html',
  styleUrls: ['./driver-status.component.css']
})
export class DriverStatusComponent implements OnInit {
  data:any;
  user_data:any;
  subscribe: Subscription;
  constructor(
    private driverService: DriversService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.subscribe = this.driverService.selected_driver.subscribe((result: any) => {
      
      this.user_data = result.data;
      if(result.data) {
        this.driverService.get_driver_status().subscribe((result: any) => {
          if(result.data) {
            this.data = result.data;
          }
        }, this.globalService.handleError);
      }
      else {
        this.data = null;
      }
    });
  }


  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }
}
