import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-park-locations-map',
  templateUrl: './park-locations-map.component.html',
  styleUrls: ['./park-locations-map.component.css']
})
export class ParkLocationsMapComponent implements OnInit {
  data:any;
  constructor(
    private route: ActivatedRoute,
    private parkingService: ParkingPlacesService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.getPark(uri.id);
    });
  }
  
  getPark(id:number): void {
    this.parkingService.getParkingPlace(id).subscribe((result:any) => {
      this.data = result.data;
    }, this.globalService.handleError); 
  }
}
