import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { ParkLocationsAddComponent } from './park-locations-add/park-locations-add.component';

@Component({
  selector: 'app-park-locations',
  templateUrl: './park-locations.component.html',
  styleUrls: ['./park-locations.component.css']
})
export class ParkLocationsComponent implements OnInit {
  panelListShow: boolean = true;
  showSearch: boolean = false;
  statusShow: boolean = true;

  locations: Array<any>;
  allLocations: Array<any>;

  selectedID: number;

  subscription:any;
  constructor(
    private parkingService: ParkingPlacesService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getAllLocations();

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
        this.selectedID = uri.id;
        this.subscription?.unsubscribe();
      });
    });
  }

  getAllLocations(): void {
    this.parkingService.getAllParkingPlaces().subscribe((result:any) => {
      if(result.success) {
        this.locations = result.data;
        this.allLocations = result.data;
      }
    }, this.globalService.handleError);
  }

  onSearchChange(event: any):void {
    if(event.target.value == "") {
      this.locations = this.allLocations;
    }
    this.locations = this.allLocations.filter(x=> x.name?x.name.toLowerCase().includes(event.target.value.toLowerCase()):false);
  }

  listSort(query:any): void {
    this.locations?.sort((a:any,b:any) => {
      var temp_a = a;
      var temp_b = b;
      query.id.forEach((element: any) => {
        temp_a = temp_a[element];
        temp_b = temp_b[element];
      });
      if(temp_a.toLowerCase() < temp_b.toLowerCase()) {
        return query.value?-1:1;
      }
      if(temp_a.toLowerCase() > temp_b.toLowerCase()) {
        return query.value?1:-1;
      }
      return 0;
    });
  }

  closeSearch(): void {
    this.locations = this.allLocations;
  }

  addNewParkingLocation(): void {
    const dialogRef = this.dialog.open(ParkLocationsAddComponent, {
      minWidth: "500px",
      maxWidth: "100%",
      autoFocus: false,
    }).afterClosed().subscribe(()=> {
      this.getAllLocations();
    });
  }




}
