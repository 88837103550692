import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  links:any = [
    {'name':'Settings', 'url': '/dashboard/settings'},
    {'name':'User Segment Type', 'url': '/dashboard/settings/user-segment-type'},

  ];
  constructor() { }

  ngOnInit(): void {
  }

}
