import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { AdditionalBillingDetailComponent } from '../../additional-billing/additional-billing-detail/additional-billing-detail.component';
import { ReservationDetailComponent } from '../../reservations/reservation-detail/reservation-detail.component';
import { DriverReservationsDetailComponent } from './driver-reservations-detail/driver-reservations-detail.component';

@Component({
  selector: 'app-driver-reservations',
  templateUrl: './driver-reservations.component.html',
  styleUrls: ['./driver-reservations.component.css']
})
export class DriverReservationsComponent implements OnInit {
  reservations: any;
  displayedColumns: Array<any> = ["id", "plate", "start_time", "end_time", "status", "bill", "usage", "campaign", "brand_model", "start_destination", "end_destination", "price"];
  user_id: number;
  constructor(
    private route: ActivatedRoute,
    private userService: UsersService,
    private dialog: MatDialog,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri: any) => {
      this.user_id = uri.id;
      this.getRes();
    });
  }

  tableClick(row: any): void {
    if (row.billing_id) {
      this.dialog.open(AdditionalBillingDetailComponent, {
        data: row.billing_id
      }).afterClosed().subscribe(result => {
        this.getRes();
      });
    }
    else {
      const dialogRef = this.dialog.open(ReservationDetailComponent, {
        maxHeight: '95vh',
        data: row.id
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getRes();
      });
    }
  }

  getRes(): void {
    if (this.route.snapshot.routeConfig.path == "journeys") {
      this.userService.getReservation(this.user_id).subscribe((result: any) => {
        
        this.reservations = result.data;
      }, this.globalService.handleError);
    }
    else if (this.route.snapshot.routeConfig.path == "reservations") {
      this.userService.getFeaturedReservation(this.user_id).subscribe((result: any) => {
        this.reservations = result.data;
      }, this.globalService.handleError);
    }
  }

}
