import { Component, Input, OnInit } from '@angular/core';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';

@Component({
  selector: 'app-charge-stations-item',
  templateUrl: './charge-stations-item.component.html',
  styleUrls: ['./charge-stations-item.component.css']
})
export class ChargeStationsItemComponent implements OnInit {
  @Input() data: any;

  constructor(
    private mapControl: MapControlServiceService,
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  itemClick(item:any): void {
    this.mapControl.selectedChargeID.next(item);
  }

}
