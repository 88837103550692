import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RejectedReasonService {
  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  getRejectedReasonDriverLicense(model_name: string):Observable<any> {
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getRejectedReason/" + model_name;

    return this.http.get(this.url, { headers: headers });
  }

  getRejectedReasons():Observable<any> {
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getRejectedReasons";

    return this.http.get(this.url, { headers: headers });    
  }

  getRejectedReasonByLicense(license_uri: string):Observable<any> {
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getRejectedReason" + license_uri;

    return this.http.get(this.url, { headers: headers });    
  }
  
}
