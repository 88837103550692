<div class="google-map">
    <agm-map [latitude]="data?.lat" [longitude]="data?.long">
        <agm-marker [latitude]="data?.lat" [longitude]="data?.long" iconUrl="assets/icons/parking.png" (mouseOver)="parkPanel.open()" (mouseOut)="parkPanel.close()">
            <agm-info-window #parkPanel>
                <div class="map-info">

                    <p>
                        <b>Name : </b>
                        <span>{{ data?.name }}</span>
                    </p>


                </div>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>