import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CorporationService } from 'src/app/services/corporation.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-corporation-edit',
  templateUrl: './corporation-edit.component.html',
  styleUrls: ['./corporation-edit.component.css']
})
export class CorporationEditComponent implements OnInit {
  corporationForm: FormGroup = new FormGroup({
    name: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(),
    town: new FormControl(),
    contact_name_surname: new FormControl(''),
    contact_phone_number: new FormControl(''),
    contact_title: new FormControl(''),
    phone_number: new FormControl(''),
    tax_number: new FormControl(''),
    tax_office: new FormControl(''),
    mail_end: new FormControl(''),
  });
  filteredCitys: Observable<any>;
  filteredTowns: Observable<any>;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data:number,
    private corporationService: CorporationService,
    public dialogRef: MatDialogRef<CorporationEditComponent>,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.corporationService.getCityList().subscribe((result:any) => {
      this.filteredCitys = this.corporationForm.get('city').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
    if(this.data) {
      this.corporationService.getCorporation(this.data).subscribe((result:any) => {
        if(result.success) {
          
          if(result.data.city) {
            this.getTown(result.data.city.id);
          }
          this.corporationForm.patchValue(result.data);
          console.log(this.corporationForm.value);
        }
      }, this.globalService.handleError);
    }

  }

  onFormSubmit() {
    if(this.data) {
      this.corporationForm.value['corporationId'] = this.data;
      this.corporationService.editCorporation(this.corporationForm.value).subscribe((result:any) => {
        this.dialogRef.close("ok");
      }, this.globalService.handleError);
    }
    else {
      this.corporationService.saveCorporation(this.corporationForm.value).subscribe((result:any) => {
        this.dialogRef.close("ok");
      }, this.globalService.handleError);
    }
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }
  private _filterT(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  getTown(id:number): void {
    this.corporationService.getTownList(id).subscribe((result:any) => {
      this.filteredTowns = this.corporationForm.get('town').valueChanges.pipe(
        startWith(''),
        map(value => this._filterT(value, result?.data))
      );
    }, this.globalService.handleError);
  }

  city_change(city: any): void {
    this.getTown(city?.id);
  }

  displayTn(data: any): string {
    return data?.name ? data.name : '';
  }
}
