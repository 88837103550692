<div class="detail-center">
    <div class="detail-content">
        <div *ngIf="parkDetail">
            <p> 
                Park Place Charge Stations 
                <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" (click)="addChargeStation(parkDetail.id)"><mat-icon>add_circle</mat-icon></button>
            </p>
            <div *ngFor="let element of parkDetail.stations">
                <div class="park-place-charge-stations">
                    <p>{{ element.cp_type }} - {{ element.cp_code_no }} </p>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" (click)="removeChargeStation(parkDetail.id, element.id)"><mat-icon>remove_circle</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
</div>