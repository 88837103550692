import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { MapControlServiceService } from '../map-control-service.service';

@Injectable({
  providedIn: 'root'
})
export class CarService extends MapControlServiceService{
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(
    private mapControl: MapControlServiceService,
    public http: HttpClient
  ) {
    super(mapControl._snackBar);
  }

  selected_car: BehaviorSubject<any> = new BehaviorSubject({"data": null});

  get_car_id(): number {
    var val = this.selected_car.value.data;
    if(val) {
      return val.user_id;
    }
    return 0;
  }
  
  get_car(id: number): void {
    var url = baseUrl + "vehicle/getVehicle/" + id;
    this.http.get(url, { headers: this.headers }).subscribe((result:any) => {
      this.selected_car.next(result);
    });
  }
  
  getVehicleList(params: any = null): Observable<any>{
    var url = baseUrl + "vehicle/getVehicleList";
    return this.http.get(url, { headers: this.headers, params: params });
  }

  addVehicleToCorporation(corporation_id: number, vehicle_id: number): Observable<any> {
    var url = baseUrl + "vehicle/addVehicleToCorporation";
    return this.http.post(url, {corporation_id: corporation_id, vehicle_id: vehicle_id}, { headers: this.headers });
  }

  addOrEditSegmentType(body: FormGroup): Observable<any> {
    var url = baseUrl + "vehicle/addOrEditSegmentType";
    return this.http.post(url, body.value, { headers: this.headers });
  }

  getListVehicleSegment(): Observable<any>{
    var url = baseUrl + "vehicle/getSegmentList";
    return this.http.get(url, { headers: this.headers });
  }

  getVehicleSegment(id: number): Observable<any>{
    var url = baseUrl + "vehicle/segmentType/" + id;
    return this.http.get(url, { headers: this.headers });
  }

  vehicleSegmentAddVehicle(id: number, model: any): Observable<any> {
    var url = baseUrl + "vehicle/segmentType/" + id + "/addVehicle";
    return this.http.post(url, {model: model}, { headers: this.headers });
  }

  vehicleSegmentRemoveVehicle(id: number, model:any): Observable<any> {
    var url = baseUrl + "vehicle/segmentType/" + id + "/removeVehicle";
    return this.http.post(url, {model: model}, { headers: this.headers });
  }

  getModelPriceList(): Observable<any>{
    var url = baseUrl + "payment/getPriceList";
    return this.http.get(url, { headers: this.headers });
  }

  editPrice(body: FormGroup): Observable<any> {
    var url = baseUrl + "payment/editPrice";
    return this.http.post(url, body.value, { headers: this.headers });
  }

  getPrice(id: number): Observable<any>{
    var url = baseUrl + "payment/getPrice/" + id;
    return this.http.get(url, { headers: this.headers });
  }

  deleteVehicleSegment(id: number): Observable<any> {
    return this.http.get(baseUrl + "vehicle/deleteVehicleSegmentType/" + id, { headers: this.headers});
  }

  deletePrice(id: number): Observable<any> {
    return this.http.get(baseUrl + "payment/deletePrice/" + id, { headers: this.headers});
  }

  getPriceHistory(id: number): Observable<any> {
    return this.http.get(baseUrl + "payment/priceHistory/" + id, { headers: this.headers});
  }
  
}
