import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VehicleBrandsService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient) { }

  getAllVehicleBrands():Observable<any>{
    this.url = baseUrl + "vehicle/getAllBrands";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  getVehicleBrand(parking_place_id:any):Observable<any>{
    this.url = baseUrl + "vehicle/getBrand/" + parking_place_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  saveVehicleBrand(body):Observable<any>{
    this.url = baseUrl + "vehicle/saveBrand";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  editVehicleBrand(body):Observable<any>{
    this.url = baseUrl + "vehicle/editBrand";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }
  

}
