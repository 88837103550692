<div class="hgscontainer">
    <div class="buttons">
        <button mat-raised-button color="primary" type="submit" (click)="updateData()">Update Data</button>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="hgs_no">
            <th mat-header-cell *matHeaderCellDef> HGS No </th>
            <td mat-cell *matCellDef="let element"> {{element.hgs_no}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="plate">
            <th mat-header-cell *matHeaderCellDef> Plate </th>
            <td mat-cell *matCellDef="let element"> {{element.plate}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="highway">
            <th mat-header-cell *matHeaderCellDef> Highway </th>
            <td mat-cell *matCellDef="let element"> {{element.highway}} </td>
        </ng-container>

        <ng-container matColumnDef="toll">
            <th mat-header-cell *matHeaderCellDef> Toll </th>
            <td mat-cell *matCellDef="let element"> {{element.toll}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>