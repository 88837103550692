<div class="">
    <div class="mt-20 p-20 res-table">
        <div class="reservation-top">
            <div>
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label>Search</mat-label>
                    <input matInput (keyup)="searchRez($event.target.value)">
                </mat-form-field>
                <mat-form-field appearance="fill">
                    <mat-label>Reservation Status</mat-label>
                    <mat-select (selectionChange)="reservationStatusChange($event.value)">
                        <mat-option value="">All</mat-option>
                        <mat-option value="Waiting">Waiting</mat-option>
                        <mat-option value="Approved">Approved</mat-option>
                        <mat-option value="Rejected">Rejected</mat-option>
                        <mat-option value="Closed">Closed</mat-option>
                        <mat-option value="Ended">Ended</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <a mat-raised-button color="primary" routerLink="reservation-table" >Reservation Table</a>
                <a mat-raised-button color="primary" routerLink="reservation-triple" >Reservation Triple</a>
                <!-- <a mat-raised-button color="primary" href="{{ downloadExcelUrl }}" >Download Excel</a> -->
                <button mat-raised-button color="primary" (click)="excelDownload()">{{ excel_progress }}</button>
                <button mat-raised-button color="primary" (click)="addNewReservation()">Add New</button>
            </div>

        </div>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="reservations" matSort class="">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
                    <td mat-cell *matCellDef="let element">
                        <span [ngStyle]="{'color': element.reservationTimePenalty?'red':''}">
                            {{element.id}} 
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="order_id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.orderId}} </td>
                </ng-container>

                <ng-container matColumnDef="plate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Plate </th>
                    <td mat-cell *matCellDef="let element"> {{element.vehicle?.plate}} </td>
                </ng-container>

                <ng-container matColumnDef="user_name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Driver </th>
                    <td mat-cell *matCellDef="let element"> {{element.userName}} {{ element.userSurname }} </td>
                </ng-container>

                <ng-container matColumnDef="talep">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Talep </th>
                    <td mat-cell *matCellDef="let element">
                        <span [ngStyle]="{
                            'background-color': element.requestStatus === 'Waiting' ? 'yellow' :
                                                element.requestStatus === 'Approved' ? 'green' :
                                                element.requestStatus === 'Rejected' ? 'red' : 'transparent',
                            'padding': '5px 8px',
                            'border-radius': '3px'
                        }">
                            {{ element.requestStatus }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="usage">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Usage </th>
                    <td mat-cell *matCellDef="let element"> {{ element.usage }} </td>
                </ng-container>

                <ng-container matColumnDef="brand_model">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand - Model </th>
                    <td mat-cell *matCellDef="let element"> {{element.vehicle?.brand}} - {{element.vehicle?.model}} </td>
                </ng-container>

                <ng-container matColumnDef="start_destination">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Destination </th>
                    <td mat-cell *matCellDef="let element"> {{element.startDestination}} </td>
                </ng-container>


                <ng-container matColumnDef="end_destination">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> End Destination </th>
                    <td mat-cell *matCellDef="let element"> {{element.endDestination}} </td>
                </ng-container>

                <ng-container matColumnDef="start_time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.startTime}} </td>
                </ng-container>


                <ng-container matColumnDef="end_time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> End Time </th>
                    <td mat-cell *matCellDef="let element"> {{element.endTime}} </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
                    <td mat-cell *matCellDef="let element"> {{element.totalPrice | currency:element.currency:'symbol-narrow':'0.2-2'}} </td>
                </ng-container>

                <ng-container matColumnDef="reservationkm">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total KM </th>
                    <td mat-cell *matCellDef="let element"> {{element.reservationKM}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
            </table>

            <div>
                <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent=getResPagination($event)"></mat-paginator>
            </div>

        </div>
    </div>
</div>