import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CampaignCodeService } from 'src/app/services/campaign-code.service';
import { GlobalService } from 'src/app/services/global.service';
import { CampaignCodeAddComponent } from './campaign-code-add/campaign-code-add.component';

@Component({
  selector: 'app-campaign-code',
  templateUrl: './campaign-code.component.html',
  styleUrls: ['./campaign-code.component.css']
})
export class CampaignCodeComponent implements OnInit {
  dataSourceCampaignCode: any;
  displayedColumnsCampaign: Array<string> = ["id", "code", "price", "used", "delete"];
  constructor(
    private campaignCodeService: CampaignCodeService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.get_codes();
  }
  
  get_codes(): void {
    this.campaignCodeService.getCampaignCodes().subscribe((result: any) => {
      
      this.dataSourceCampaignCode = result.data;
    }, this.globalService.handleError);
  }

  add_campaign_code(): void {
    this.dialog.open(CampaignCodeAddComponent).afterClosed().subscribe(result => {
      if (result) {
        this.get_codes();
      }
    }, this.globalService.handleError);
  }

  delete_campaign_code(id: number): void {
    if(confirm("Are you sure ?")) {
      this.campaignCodeService.deleteCampaignCode(id).subscribe((result: any) => {
        this.get_codes();
      }, this.globalService.handleError);
    }
  }

}
