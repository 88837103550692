<div *ngIf="isDisabled">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>


<div *ngIf="!isDisabled" class="insuranceAddOrEdit">

    <form [formGroup]="rejectedForm" (ngSubmit)="onFormSubmit()">
        <div class="selectboxField">
            <mat-form-field appearance="fill">
                <mat-label>Select an Reject Reason</mat-label>
                <mat-select formControlName="rejectedId" (selectionChange)="matSelectChange($event.value)">
                    <mat-option *ngFor="let item of rejectData" [value]="item.rejected_reason_id">{{ item.rejected_title }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div>
            <p>
                Reason: {{ reason }}
            </p>
        </div>
        <div class="button">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>
</div>