import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { VehicleModelsService } from 'src/app/services/vehicle-models.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { CarService } from 'src/app/services/triggers/car.service';
import { GlobalService } from 'src/app/services/global.service';

export interface DialogData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-vehicle-model-edit',
  templateUrl: './vehicle-model-edit.component.html',
  styleUrls: ['./vehicle-model-edit.component.css']
})
export class VehicleModelEditComponent implements OnInit {
  vehicleModelsEditForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl(null),
  });
  id:number;
  initialValues:any;
  header:string;
  filteredPrice: any;
  vehicleImage: any;

  constructor(
    private route: ActivatedRoute, 
    private vehicleModelsService: VehicleModelsService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VehicleModelEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private carService: CarService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    if(this.data.id) {
      this.id = this.data.id;
    }
    else {
      this.id = +this.route.snapshot.paramMap.get('id');
    }
    this.vehicleModelsEditForm.patchValue({
      id: this.id
    });

    if(this.id == 0){
      this.header = "Add";
    }
    else {
      this.header = "Edit";
    }
    
    this.fetchFormData(this.id);
    // this.getPriceList();
  }

  getPriceList(): void {
    this.carService.getModelPriceList().subscribe(result => {
      this.filteredPrice = this.vehicleModelsEditForm.get('price').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }


  fetchFormData(id:number){    

    if(id != 0){
      this.vehicleModelsService.getVehicleModel(id).subscribe(result => {
        if(result.success){
          this.initialValues = result.data;
          this.vehicleModelsEditForm.patchValue({
            price: result.data.price,
            name: result.data.name
          });
        }
      }, this.globalService.handleError);
    }
  }


  saveVehicleModel(){
    if(!this.vehicleModelsEditForm.valid){
      this._snackBar.open('Form is not valid', 'Dismiss', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      return;
    }
    let finalValues = this.vehicleModelsEditForm.value;
    const formData = new FormData();
    Object.entries(finalValues).forEach(([key, value]:any) => {
      formData.append(key, value);
    });

    if (this.vehicleImage)
      formData.append('image', this.vehicleImage.file);


    if(this.id > 0){
      //UPDATE ACTION
      this.vehicleModelsService.editVehicleModel(formData).subscribe(result => {
        if(result.success){        
          this._snackBar.open('Model Updated', 'Dismiss', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close();
        }
      }, this.globalService.handleError)
    }
    else {
      //CREATE ACTION
      this.vehicleModelsService.saveVehicleModel(formData).subscribe(result => {
        if(result.success){        
          this._snackBar.open('Model Saved.', 'Dismiss', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close();
        }
      }, this.globalService.handleError)

    }

  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  selectFile(event) {
    console.log(event.target.files)
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (_event: any) => {
        this.vehicleImage = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    else {
      this.vehicleImage = null;
    }
  }


}
