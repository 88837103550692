<div class="">
    <div class="mt-20 p-20">
        (*) Corporation
        <table mat-table [dataSource]="reservations" class="mat-elevation-z8">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> Trip ID </th>
                <td mat-cell *matCellDef="let element"> {{element.id}}<span *ngIf="element.is_corporate">*</span></td>
            </ng-container>

            <ng-container matColumnDef="plate">
                <th mat-header-cell *matHeaderCellDef> Plate </th>
                <td mat-cell *matCellDef="let element"> {{element.plate}} </td>
            </ng-container>

            <ng-container matColumnDef="start_date">
                <th mat-header-cell *matHeaderCellDef> Start Time </th>
                <td mat-cell *matCellDef="let element"> {{element.start_date}} </td>
            </ng-container>

            <ng-container matColumnDef="end_date">
                <th mat-header-cell *matHeaderCellDef> Start Time </th>
                <td mat-cell *matCellDef="let element"> {{element.start_date}} </td>
            </ng-container>

            <ng-container matColumnDef="total_price">
                <th mat-header-cell *matHeaderCellDef> Total Price </th>
                <td mat-cell *matCellDef="let element"> {{element.total_price}} </td>
            </ng-container>

            <ng-container matColumnDef="discounted_price">
                <th mat-header-cell *matHeaderCellDef> Discounted Price </th>
                <td mat-cell *matCellDef="let element"> {{element.discounted_price}} </td>
            </ng-container>

            <ng-container matColumnDef="amount_paid">
                <th mat-header-cell *matHeaderCellDef> Amount Paid </th>
                <td mat-cell *matCellDef="let element"> {{element.amount_paid}} </td>
            </ng-container>

            <ng-container matColumnDef="last_asecco_request">
                <th mat-header-cell *matHeaderCellDef> Last Asecco Request </th>
                <td mat-cell *matCellDef="let element"> {{element.last_asecco_request}} </td>
            </ng-container>

            <ng-container matColumnDef="refund_total">
                <th mat-header-cell *matHeaderCellDef> Refund Total </th>
                <td mat-cell *matCellDef="let element"> {{element.refund_total}} </td>
            </ng-container>

            <ng-container matColumnDef="last_refund_request">
                <th mat-header-cell *matHeaderCellDef> Last Refund Request </th>
                <td mat-cell *matCellDef="let element"> {{element.last_refund_request}} </td>
            </ng-container>


            <ng-container matColumnDef="order_id">
                <th mat-header-cell *matHeaderCellDef> Order ID </th>
                <td mat-cell *matCellDef="let element"> {{element.order_id}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
        </table>

    </div>
</div>