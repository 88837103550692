<div class="list-item {{ data.status }}" routerLinkActive="list-item-active" routerLink="/dashboard/drivers/{{ data.user_id }}">
    <div class="item-content">
        <div class="info">
            <span class="name">{{ data.name }} {{ data.surname }} </span>
            <span class="statu">{{ status_name[data.status] }}</span>
            <span class="segment">Segment</span>
        </div>
        <div class="list-icon">
            <span>{{ data.isCitizen?'Türkiye':data.givenCountry }}</span>
            <mat-icon>flag</mat-icon>
        </div>
    </div>
</div>