import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';
import { CorporationAgreementsAddComponent } from './corporation-agreements-add/corporation-agreements-add.component';

@Component({
  selector: 'app-corporation-agreements',
  templateUrl: './corporation-agreements.component.html',
  styleUrls: ['./corporation-agreements.component.css']
})
export class CorporationAgreementsComponent implements OnInit {
  selectedId: number;
  data: any;
  constructor(
    private corporateService: CorporateService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.selectedId = +params.get('id');
      this.getAgreements();
    });
  }

  getAgreements(): void {
    this.corporateService.get_corporate_agreements(this.selectedId).subscribe(params => {
      this.data = params.data;
      console.log(params);
    }, this.globalService.handleError);
  }

  addNewAgreement(): void {
    this.dialog.open(CorporationAgreementsAddComponent, {
      data: this.selectedId,
      autoFocus: false,
    }).afterClosed().subscribe(params => {
      this.getAgreements();
    });
  }

  removeItem(id: number): void {
    if(confirm('Are you sure ?')) {
      this.corporateService.delete_corporate_agreements(this.selectedId, id).subscribe(params => {
        this.getAgreements();
      }, this.globalService.handleError);
    }
  }
}
