import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vehicle-damages',
  templateUrl: './vehicle-damages.component.html',
  styleUrls: ['./vehicle-damages.component.css']
})
export class VehicleDamagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
