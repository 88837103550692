import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-park-locations-edit',
  templateUrl: './park-locations-edit.component.html',
  styleUrls: ['./park-locations-edit.component.css']
})
export class ParkLocationsEditComponent implements OnInit {
  data:any;
  editForm = new FormGroup({
    name: new FormControl(''),
    lat: new FormControl(''),
    long: new FormControl(''),
  });
  dataLoad:boolean = false;
  id: number;

  constructor(
    private router: ActivatedRoute,
    private parkingPlacesService: ParkingPlacesService,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService
  ) { }
  

  ngOnInit(): void {
    this.router.parent.params.subscribe((uri:any) => {
      this.id = uri.id;
      this.getPark(uri.id);
    });
  }

  getPark(id:number): void {
    this.dataLoad = false;
    this.parkingPlacesService.getParkingPlace(id).subscribe((result:any) => {
      if(result.success) {
        this.data = result.data;
        this.editForm.patchValue(result.data);
        this.dataLoad = true;
      }
    }, this.globalService.handleError);
  }
  saveParkingPlace(){
    if(!this.editForm.valid){
      this._snackBar.open('Form is not valid', 'Dismiss', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      return;
    }
    let finalValues = this.editForm.value;

    finalValues.id = this.id;
    this.parkingPlacesService.editParkingPlace(finalValues).subscribe(result => {
      if(result.success){        
        this._snackBar.open('Park Place Updated', 'Dismiss', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 5000,
        });
      }
    }, this.globalService.handleError)

  }

}
