import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-citizenship-type-add',
  templateUrl: './citizenship-type-add.component.html',
  styleUrls: ['./citizenship-type-add.component.css']
})
export class CitizenshipTypeAddComponent implements OnInit {

  citizenTypeForm = new FormGroup({
    name: new FormControl(''),
  });


  constructor(
    private userService: UsersService,
    private mapControl: MapControlServiceService,
    private dialogRef: MatDialogRef<CitizenshipTypeAddComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.userService.addCitizenType(this.citizenTypeForm).subscribe((res: any) => {
      if (res.success) {
        this.mapControl.showSnackbar(res.data?.message);
      } else {
        this.mapControl.showSnackbar(res.errorMessage);
      }
      this.dialogRef.close();
    }, this.globalService.handleError);
  }


}
