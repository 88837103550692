<div class="add-image">
    <form [formGroup]="billingForm" (ngSubmit)="saveImage()" autocomplete="off">
            
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Reservation </mat-label>
            <input type="text" placeholder="Pick one" aria-label="Reservation" matInput formControlName="reservation" [matAutocomplete]="autoReservation">
            <mat-autocomplete #autoReservation="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let option of filteredReservation | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>


        <mat-form-field appearance="fill">
            <mat-label>Type</mat-label>
            <mat-select formControlName="type">
                <mat-option value="KASKO">KASKO</mat-option>
                <mat-option value="POLICE">POLICE</mat-option>
                <mat-option value="RUHSAT">RUHSAT</mat-option>
                <mat-option value="KAZA">KAZA</mat-option>
                <mat-option value="DIGER">DIGER</mat-option>
            </mat-select>
          </mat-form-field>

       
        <mat-form-field>
            <mat-label>Note</mat-label>
            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="note"></textarea>
        </mat-form-field>

        <input type="file" class="file-upload" (change)="imageChange($event.target.files)" accept="image/*">

        <div class="car-image">
            <img #uploadImage [src]="imageUrl" alt="" width="400">
        </div>
        <div class="add-button">
            <button mat-raised-button color="primary" >Add</button>
        </div>
    </form>

</div>