import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';

@Component({
  selector: 'app-charge-stations-status',
  templateUrl: './charge-stations-status.component.html',
  styleUrls: ['./charge-stations-status.component.css']
})
export class ChargeStationsStatusComponent implements OnInit {

  data:any;
  subscription:any;
  constructor(
    private route: ActivatedRoute,
    private mapControl: MapControlServiceService
  ) { }

  ngOnInit(): void {
    this.subscription = this.mapControl.selectedChargeID.subscribe((data:any) => {
      this.data = data;
    });
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
