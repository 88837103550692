import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { VehicleService } from 'src/app/services/vehicle.service';
import { VehicleBrandsComponent } from '../../vehicle-brands/vehicle-brands.component';
import { VehicleModelsComponent } from '../../vehicle-models/vehicle-models.component';
import { ProviderAddComponent } from '../providers/provider-add/provider-add.component';
import { VehicleStatusAddComponent } from '../vehicle-states/vehicle-status-add/vehicle-status-add.component';

@Component({
  selector: 'app-vehicle-add',
  templateUrl: './vehicle-add.component.html',
  styleUrls: ['./vehicle-add.component.css']
})
export class VehicleAddComponent implements OnInit {
  providers: any = [];
  brands: any = [];
  models: any = [];
  status_name: string;
  status_id: number;
  parkingPlace: any;

  carForm = new FormGroup({
    number_plate: new FormControl(),
    brand: new FormControl(),
    model: new FormControl(),
    parking_places: new FormControl(),
    license_year: new FormControl(1),
    year: new FormControl(),
    sitting_capacity: new FormControl(),
    colour: new FormControl(),
    daily_price: new FormControl("0"),
    hourly_price: new FormControl("0"),
    minute_price: new FormControl("0"),
    currency: new FormControl('₺'),
  });

  constructor(
    public dialog: MatDialog,
    private vehicleService: VehicleService,
    private mapControl: MapControlServiceService,
    private dialogRef: MatDialogRef<VehicleAddComponent>,
    private parkService: ParkingPlacesService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getBrands();
    this.getModels();
    this.getParkingPlaces();
  }

  getParkingPlaces(): void {
    this.parkService.getAllParkingPlaces().subscribe((result: any) => {
      this.parkingPlace = result.data;
    }, this.globalService.handleError);
  }

  openProvider(): void {
    const dialogRef = this.dialog.open(ProviderAddComponent, {}).afterClosed().subscribe(() => {
      this.getProviders();
      dialogRef.unsubscribe();
    });
  }

  getProviders(): void {
    this.vehicleService.getAllProviders().subscribe((res:any) => {
      if(res.success) {
        this.providers = res.data;
      }
    }, this.globalService.handleError);    
  }

  openBrand(): void {
    const dialogRef = this.dialog.open(VehicleBrandsComponent, {
      width: "300px"
    }).afterClosed().subscribe(() => {
      this.getBrands();
      dialogRef.unsubscribe();
    });
  }

  getBrands(): void {
    this.vehicleService.getBrands().subscribe((res:any) => {
      if(res.success) {
        this.brands = res.data;
      }
    }, this.globalService.handleError)
  }

  openModel(): void {
    const dialogRef = this.dialog.open(VehicleModelsComponent, {
      width: "300px"
    }).afterClosed().subscribe(() => {
      this.getModels();
      dialogRef.unsubscribe();
    });
  }

  getModels(): void {
    this.vehicleService.getModels().subscribe((res:any) => {
      if(res.success) {
        this.models = res.data;
      }
    }, this.globalService.handleError);
  }

  openStatus(): void {
    const dialogRef = this.dialog.open(VehicleStatusAddComponent, {
      width: "400px",
    }).afterClosed().subscribe((result) => {
      
      if(result != undefined) {
        this.status_id = result.id;
        this.status_name = result.name;
        this.carForm.get("status").setValue(this.status_id);
      }
      dialogRef.unsubscribe();
    });
  }

  onSubmit() {
    this.vehicleService.saveVehicle(this.carForm).subscribe((res:any) => {
      this.globalService.successMessage(res);
      this.dialogRef.close();
    }, this.globalService.handleError);
  }


}
