import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle.service';
import {} from '@angular/google-maps';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { VehicleAddComponent } from './vehicle-add/vehicle-add.component';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-vehicles',
  templateUrl: './vehicles.component.html',
  styleUrls: ['./vehicles.component.css'],
})
export class VehiclesComponent implements OnInit {
  showSearch: boolean = false;
  panelListShow: boolean = true;
  statusShow: boolean = true;
  
  cars: Array<any>;
  allCars: Array<any>;
  unFilteredCars: Array<any>;

  listButtonsID: number = 0;
  selectedID: number = 0;
  subscription:any;
  subscriptionCarImage:Subscription;

  constructor(
    private vehicleService: VehicleService,
    private route: ActivatedRoute,
    public mapControl: MapControlServiceService,
    public dialog: MatDialog,
    private globalService: GlobalService
  ) {
    // const a = new google.maps.LatLng(41.041983, 28.989841);
    // const b = new google.maps.LatLng(41.06748, 29.01795);
    // const c = google.maps.geometry.spherical.computeDistanceBetween(a,b);
    // console.log(c.toFixed(0));
  }

  ngOnInit(): void {
    this.getAllCars();

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
        this.selectedID = uri.id;
        this.subscription?.unsubscribe();
      });
    });

    this.subscriptionCarImage = this.mapControl.carImageChange.subscribe(() => {
      this.getAllCars();
    });

  }

  getAllCars(): void {

    this.vehicleService.getAllVehicles().subscribe((result:any) => {
      if(result.success) {
        
        this.cars = result.data;
        this.allCars = result.data;
        this.unFilteredCars = result.data;
      }
    }, this.globalService.handleError);
  }

  listSort(query:any): void {
    this.cars?.sort((a:any,b:any) => {
      var temp_a = a;
      var temp_b = b;
      query.id.forEach((element: any) => {
        temp_a = temp_a[element];
        temp_b = temp_b[element];
      });

      if(temp_a.toLowerCase() < temp_b.toLowerCase()) {
        return query.value?-1:1;
      }
      if(temp_a.toLowerCase() > temp_b.toLowerCase()) {
        return query.value?1:-1;
      }
      return 0;
    });
  }

  onSearchChange(event:any): void {
    this.cars = this.allCars?.filter((car:any) => {
      var val:string = event.target.value;
      if(
        car.brand?.name?.toLowerCase().includes(val.toLowerCase()) ||
        car.model?.name?.toLowerCase().includes(val.toLowerCase()) ||
        car.numberPlate?.toLowerCase().includes(val.toLowerCase()) ||
        car.year == val
      ) {
        return true;
      }
    });
  }

  closeSearch(): void {
    this.cars = this.allCars;
  }

  listButtons(query:any): void {
    if(query.buttonID == this.listButtonsID) {
      this.cars = this.allCars = this.unFilteredCars;
      this.listButtonsID = 0;
      return;
    }
    this.listButtonsID = query.buttonID;
    this.cars = this.allCars = this.unFilteredCars?.filter((car:any) => {
      var tempCar = car;
      query.id.forEach((element:any) => {
        tempCar = tempCar[element];
      });
      if(tempCar == query.value) {
        return true;
      }
    });
    console.log(query);
  }

  addNewVehicle(): void {
    const dialogRef = this.dialog.open(VehicleAddComponent, {
      minWidth: "500px",
      maxHeight: "90%"
    }).afterClosed().subscribe(()=> {
      this.getAllCars();
    });
  }

  ngOnDestroy() {
    this.subscriptionCarImage.unsubscribe();
  }  

}



// export class VehiclesComponent implements OnInit {
//   vehicles: any;
//   mapMarks: Array<any>;
//   showSearch: boolean = false;
//   currentVehicles: any;
//   displayedColumns: string[] = ['id', 'numberPlate', 'brand', 'model', 'status', 'chargeStatus'];
//   vehicleStatuTypes: any;
//   carStatusFilterTypes:Array<any> = [];
//   carStatusFilterType: number = -1;
//   showLeftDashboard: boolean;
//   showRightDashboard: boolean;
//   carStatusInfo: any;

//   constructor(
//     private vehicleService:VehicleService,
//     private mapControl: MapControlServiceService,
//   ) {

//     mapControl.selectedCarID.subscribe((result:any) => {
//       this.carStatusInfo = result;
//     });


//     this.vehicleService.getAllStatusType().subscribe(result=> {
//       if(result.success){
//         this.vehicleStatuTypes = result.data;
//       }
//     });

//     mapControl.onGetData.subscribe((data:any) => {
//       this.vehicles = data;
//     });
//   }
  
//   ngOnInit(): void {
//     this.vehicleService.getAllVehicles().subscribe(result=>{
//       if(result.success){
//         this.vehicles = result.data;
//         this.currentVehicles = result.data;
//         this.allCars();
//       }
//     });
//   }


//   toggleLeftDashboard():void  {
//     this.showLeftDashboard = !this.showLeftDashboard;
//   }

//   toggleRightDashboard():void {
//     this.showRightDashboard = !this.showRightDashboard;
//   }

//   allCars(): void {
//     console.log(this.vehicles);
//     this.vehicles.forEach((element: any) => {
//       element.coordinate['icon'] = "assets/icons/car.png";
//       element.coordinate['id'] = element.id;

//     });
//     let uniq = {};
//     this.mapControl.mapDefaultValue.next(this.vehicles);
//   }

//   // resetVehicles():void {
//   //   this.mapControl.mapResetValue();
//   //   this.resetMarks();
//   // }

//   // resetMarks():void {
//   //   this.mapControl.mapMark.emit(null);
//   // }

//   ngOnDestroy() {
//     // this.mapControl.resetAllValues();
//     this.mapControl.selectedCarID.next({});
//   }

//   sortMarka(value:boolean):void {
//     if(value) {
//       this.vehicles.sort(function(a, b) {
//         var nameA = a.brand.name.toUpperCase();
//         var nameB = b.brand.name.toUpperCase();
//         if (nameA < nameB) {
//           return -1;
//         }
//         if (nameA > nameB) {
//           return 1;
//         }
//         return 0;
//       });
//     }
//     else {
//       this.vehicles.sort(function(a, b) {
//         var nameA = a.brand.name.toUpperCase();
//         var nameB = b.brand.name.toUpperCase();
//         if (nameA > nameB) {
//           return -1;
//         }
//         if (nameA < nameB) {
//           return 1;
//         }
//         return 0;
//       });
//     }
//   }

//   closeSearch(close:boolean = false):void {
//     var mapfilter = this.mapControl.mapFilter.value;
//     mapfilter['search'] = "";
//     this.mapControl.mapFilter.next(mapfilter);
//     if(close) {
//       this.showSearch=false;
//     }
//   }


//   onSearchChange(value: string): void {
    
//     var mapfilter = this.mapControl.mapFilter.value;
//     mapfilter['search'] = value;
//     this.mapControl.mapFilter.next(mapfilter);

//   }

//   carStatusFilter(filter_id: number):void {
//     if(filter_id == this.carStatusFilterType) {
//       var mapfilter = this.mapControl.mapFilter.value;
//       mapfilter['statu'] = -1;
//       this.mapControl.mapFilter.next(mapfilter);
//       this.carStatusFilterType = -1;
//       return;
//     }
//     var mapfilter = this.mapControl.mapFilter.value;
//     console.log(filter_id);
//     mapfilter['statu'] = filter_id;
//     this.mapControl.mapFilter.next(mapfilter);
//     this.carStatusFilterType = filter_id;     
//   }

// }


// @Component({
//   selector: 'app-vehicle-map',
//   templateUrl: './vehicles-map.component.html',
//   styleUrls: ['./vehicles.component.css'],
// })
// export class VehiclesMapComponent implements OnInit {
//   long:any;
//   lat:any;
//   constructor(private mapControl: MapControlServiceService) {
//     mapControl.selectedCarID.subscribe((result:any) => {
//       if(result.id) {
//         this.long = result.coordinate.long;
//         this.lat = result.coordinate.lat;
//       }
//     }).unsubscribe();
//   }

//   ngOnInit(): void {

//   }
// }