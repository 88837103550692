<mat-dialog-content>
    <h2>Add Corporate Group</h2>
    <form [formGroup]="corporateGroupForm" (ngSubmit)="onSubmit()" autocomplete="off" fxLayout="column" fxLayoutAlign=" none">

        <mat-form-field appearance="fill">
            <mat-label>Input</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <div>
            <div fxLayout="row" fxLayoutAlign="space-between center">
                Models
                <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" type="button"
                    (click)="addModel()"><mat-icon>add_circle</mat-icon></button>
            </div>
            <div *ngFor="let element of corporateGroupForm.get('models').value; let i = index">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <p>{{ element.id }} - {{ element.name }} </p>
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" color="danger" type="button"
                        (click)="removeModel(i)"><mat-icon>remove_circle</mat-icon></button>
                </div>
                <div>
                    <span *ngFor="let model of element.corporates">{{ model.name }},</span>
                </div>
                <hr>
            </div>
        </div>
        <button type="submit" mat-raised-button color="primary" [disabled]="!corporateGroupForm.valid">Save</button>

    </form>
</mat-dialog-content>