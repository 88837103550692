import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CampaignCodeService } from 'src/app/services/campaign-code.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-campaign-code-add',
  templateUrl: './campaign-code-add.component.html',
  styleUrls: ['./campaign-code-add.component.css']
})
export class CampaignCodeAddComponent implements OnInit {
  campaignCodeForm = new FormGroup({
    code: new FormControl(),
    percentage: new FormControl(null),
    total: new FormControl(null),
    hour: new FormControl(null),
    used_one_time: new FormControl(false),
  });
  constructor(
    private campaignsService: CampaignCodeService,
    private dialogRef: MatDialogRef<CampaignCodeAddComponent>,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.campaignsService.saveCampaignCode(this.campaignCodeForm).subscribe((result: any) => {
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }

}
