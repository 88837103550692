import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';

import { ReportsService } from 'src/app/services/reports.service';
import { CarService } from 'src/app/services/triggers/car.service';
import * as moment from 'moment';

@Component({
  selector: 'app-vehicle-reports',
  templateUrl: './vehicle-reports.component.html',
  styleUrls: ['./vehicle-reports.component.css']
})
export class VehicleReportsComponent implements OnInit {
  public chartBatteryOptions: any;
  public chartKmOptions: any;
  public chartEnergyConsumptionOptions: any;

  show_chart_battery = true;
  show_chart_km = true;
  show_chart_energy_consumption = true;

  vehicles: any = [];
  filterGroup: FormGroup = new FormGroup({
    vehicle: new FormControl(),
    date: new FormControl(),
  });

  constructor(private reportService: ReportsService, private globalService: GlobalService, private carService: CarService) {
    this.chartBatteryOptions = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: "Battery",
        align: "left"
      },
    };
    this.chartEnergyConsumptionOptions = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: "Energy Consumption",
        align: "left"
      },
    };
    this.chartKmOptions = {
      series: [
        {
          name: "",
          data: []
        }
      ],
      chart: {
        height: 350,
        type: "line",
      },
      title: {
        text: "KM",
        align: "left"
      },
    };
  }

  getReports() {
    if (!this.filterGroup.value.vehicle && !this.filterGroup.value.date) return;
    var date = moment(this.filterGroup.value.date).format('YYYY-MM-DD');

    this.show_chart_battery = false;
    this.show_chart_km = false;
    this.show_chart_energy_consumption = false;
    this.reportService.get_battery_reports(this.filterGroup.value.vehicle, date).subscribe({
      next: (data) => {
        this.show_chart_battery = true;
        this.chartBatteryOptions.series = data;
      },
      error: this.globalService.handleError
    })
    this.reportService.get_km_reports(this.filterGroup.value.vehicle, date).subscribe({
      next: (data) => {
        this.show_chart_km = true;
        this.chartKmOptions.series = data;
      },
      error: this.globalService.handleError
    })
    this.reportService.get_energy_consumption_reports(this.filterGroup.value.vehicle, date).subscribe({
      next: (data) => {
        this.show_chart_energy_consumption = true;
        this.chartEnergyConsumptionOptions.series = data;
      },
      error: this.globalService.handleError
    })
  }

  ngOnInit(): void {
    this.carService.getVehicleList().subscribe({
      next: (data) => {
        this.vehicles = data.data;
      },
      error: this.globalService.handleError
    })
  }

  onSubmit() {
    this.getReports();
  }

}
