<div>
    <form [formGroup]="form_var" (ngSubmit)="submit_form()">

        <div fxLayout="column" fxLayoutGap="20px">
            <mat-form-field appearance="fill">
                <mat-label>Tag Name</mat-label>
                <input matInput formControlName="name">
            </mat-form-field>
            
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                <label >Color: </label>
                <input type="color" formControlName="color">
            </div>

            <div fxLayoutAlign="end center">
                <button mat-raised-button color="primary">Submit</button>
            </div>
        </div>

    </form>
</div>