<div class="electrip-form">
    <mat-dialog-content>
        <h2>Add New Reservation Additional Time</h2>
        <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <mat-form-field>
                <input matInput type="datetime-local" placeholder="Additional End Time" formControlName="additional_end_time" (change)="datetime_change($event.target.value)">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Note</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="note"></textarea>
            </mat-form-field>

            <!-- <mat-checkbox matInput formControlName="get_paid" (change)="show_paid=!show_paid" >Get paid</mat-checkbox>
            <p *ngIf="show_paid">The user will be charged as {{ form_data?.price }}</p> -->

            <button type="submit" mat-raised-button color="primary" [disabled]="!reservationForm.valid">Submit</button>

        </form>
    </mat-dialog-content>
</div>