<mat-dialog-content>
    <mat-progress-bar mode="buffer" *ngIf="!billing_data"></mat-progress-bar>
    <div class="reservation-div">

        <table class="expandedTable" *ngIf="billing_data">
            <tr>
                <td colspan="50%">ID</td>
                <td colspan="50%">{{ billing_data.id }}</td>
            </tr>
            <tr>
                <td colspan="50%">Vehicle</td>
                <td colspan="50%">{{ billing_data.vehicle.id }} - {{ billing_data.vehicle.plate }}</td>
            </tr>
            <tr>
                <td colspan="50%">Reservation ID</td>
                <td colspan="50%"><a (click)="openReservation(billing_data.reservation)">{{billing_data.reservation}}</a></td>
            </tr>
            <tr>
                <td colspan="50%">Order ID</td>
                <td colspan="50%">{{ billing_data.orderId }}</td>
            </tr>
            <tr>
                <td colspan="50%">Billing Date</td>
                <td colspan="50%">{{ billing_data.billingDate }}</td>
            </tr>
            <tr>
                <td colspan="50%">SAP</td>
                <td colspan="50%">{{ billing_data.sendingSap }}</td>
            </tr>
            <tr>
                <td colspan="50%">Asecco</td>
                <td colspan="50%">{{ billing_data.sendingAsecco }}</td>
            </tr>
            <tr>
                <td colspan="50%">Note</td>
                <td colspan="50%">{{ billing_data.description }}</td>
            </tr>
            <tr>
                <td colspan="50%">Created At</td>
                <td colspan="50%">{{ billing_data.createdOn }}</td>
            </tr>
            <tr>
                <td colspan="100%">
                    <b>ITEMS</b>
                    <button *ngIf="!billing_data.sendingAsecco && !billing_data.sendingSap" type="button" mat-button matSuffix mat-icon-button (click)="addItem(billing_data.id)"><mat-icon>add_circle</mat-icon></button>
                </td>
            </tr>
            <tr *ngFor="let item of billing_data.billingItems">
                <td colspan="20%">{{ item.name }}</td>
                <td colspan="20%">{{ item.amount }}</td>
                <td colspan="20%">{{ item.serviceFee }}</td>
                <td colspan="20%">{{ item.discountAmount }}</td>
                <td colspan="20%" style="width:8%">
                    <button *ngIf="!billing_data.sendingAsecco && !billing_data.sendingSap" type="button" mat-button matSuffix mat-icon-button (click)="removeItem(billing_data.id, item.id)"><mat-icon>remove_circle</mat-icon></button>
                </td>
            </tr>
    
        </table>
    </div>
</mat-dialog-content>
<div class="edit-buttons" *ngIf="billing_data">

    <button *ngIf="!billing_data.sendingSap" mat-raised-button color="primary" (click)="sendSap(billing_data.id)">Send SAP</button>
    <button *ngIf="!billing_data.sendingAsecco" mat-raised-button color="primary" (click)="sendAsecco(billing_data.id)" >Send Asecco</button>
    <button mat-raised-button color="warn" (click)="deleteBilling()" *ngIf="!billing_data.sendingAsecco && !billing_data.sendingSap">Delete</button>

</div>