import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChargeStationsService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient) { }

  getAllChargeStations():Observable<any>{
    this.url = baseUrl + "vehicle/getAllChargeStations";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

}
