import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateService } from 'src/app/services/triggers/corporate.service';

@Component({
  selector: 'app-corporation-segment-add-corporation',
  templateUrl: './corporation-segment-add-corporation.component.html',
  styleUrls: ['./corporation-segment-add-corporation.component.css']
})
export class CorporationSegmentAddCorporationComponent implements OnInit {

  itemForm: FormGroup = new FormGroup({
    corporation: new FormControl(null),
  });
  loading: boolean = false;
  filteredCorporation: any = null;
  constructor(
    private corporateService: CorporateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CorporationSegmentAddCorporationComponent>,
    private globalService: GlobalService
  ) { }
  subscription: Subscription;

  ngOnInit(): void {
    this.getCorporateList();
    this.subscription = this.corporateService.corporates.subscribe(result => {
      this.filteredCorporation = this.itemForm.get('corporation').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }

  getCorporateList(): void {
    this.corporateService.get_corporates({'segment_free': 1});
  }

  onSubmit(): void {
    this.corporateService.corporationSegmentAddCorporation(this.data, this.itemForm.get('corporation').value).subscribe(result => {
      
    }, this.globalService.handleError);
    this.dialogRef.close(this.itemForm.get('corporation'));
  }

    
  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();
    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
