<div class="table-style">
    <div fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="30px">
        <div fxFlex="50" fxLayout="column" fxLayoutGap="30px">
            <div>
                <h1>
                    <b>User Information</b>
                </h1>
            </div>
            <div>
                <table>
                    <tr>
                        <td>ID</td>
                        <td>{{ data?.user_id }}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{{ data?.user?.name }}</td>
                    </tr>
                    <tr>
                        <td>Surname</td>
                        <td>{{ data?.user?.surname }}</td>
                    </tr>
                    <tr>
                        <td>Identity</td>
                        <td>{{ data?.user?.tcknOrPassport }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ data?.user?.email }}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{{ data?.user?.gender }}</td>
                    </tr>
                    <tr>
                        <td>Birth Date</td>
                        <td>{{ data?.user?.birthDate }}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>{{ data?.user?.phoneNumber }}</td>
                    </tr>
                </table>
            </div>
            <app-user-document-selfie [user_id]="data.user_id" (status)="status_change($event)"></app-user-document-selfie>

        </div>
        <div fxFlex="50" fxLayout="column" fxLayoutGap="30px">
            <app-user-document-driver-license [user_id]="data.user_id" (status)="status_change($event)"></app-user-document-driver-license>
            <app-user-student-photo [user_id]="data.user_id" *ngIf="data.student_license != null" (status)="status_change($event)"></app-user-student-photo>
        </div>
    </div>
</div>