import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { VehicleBrandsService } from 'src/app/services/vehicle-brands.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

export interface DialogData {
  id: number;
  name: string;
}

@Component({
  selector: 'app-vehicle-brand-edit',
  templateUrl: './vehicle-brand-edit.component.html',
  styleUrls: ['./vehicle-brand-edit.component.css']
})
export class VehicleBrandEditComponent implements OnInit {
  vehicleBrandsEditForm: FormGroup;
  id:number;
  initialValues:any;
  header:string;

  constructor(private route: ActivatedRoute, 
    private fb: FormBuilder,
    private vehicleBrandsService: VehicleBrandsService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<VehicleBrandEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    if(this.data.id) {
      this.id = this.data.id;
    }
    else {
      this.id = +this.route.snapshot.paramMap.get('id');
    }

    this.initialValues = {};
    if(this.data.name) {
      this.initialValues.name = this.data.name;
    }

    //
    if(this.id == 0){
      this.header = "Add";
    }
    else {
      this.header = "Edit";
    }
    
    this.fetchFormData(this.id);
  }


  fetchFormData(id:number){    
    this.initForm();
  

    if(id != 0){
      this.vehicleBrandsService.getVehicleBrand(id).subscribe(result=>{
        if(result.success){
          this.initialValues.name = result.data.name;
          this.initForm();
        }
      }, this.globalService.handleError);
    }
  }

  initForm(){
    this.vehicleBrandsEditForm = this.fb.group({
      name: this.initialValues.name,
      });
  }


  saveVehicleBrand(){
    if(!this.vehicleBrandsEditForm.valid){
      this._snackBar.open('Form is not valid', 'Dismiss', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        duration: 5000,
      });
      return;
    }
    let finalValues = this.vehicleBrandsEditForm.value;

    if(this.id > 0){
      //UPDATE ACTION
      finalValues.id = this.id;
      this.vehicleBrandsService.editVehicleBrand(finalValues).subscribe(result => {
        if(result.success){        
          this._snackBar.open('Brand Updated', 'Dismiss', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close();
        }
      }, this.globalService.handleError);
    }
    else {
      //CREATE ACTION
      this.vehicleBrandsService.saveVehicleBrand(finalValues).subscribe(result => {
        if(result.success){        
          this._snackBar.open('Brand Saved.', 'Dismiss', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 5000,
          });
          this.dialogRef.close();
        }
      }, this.globalService.handleError);

    }

  }


}
