<div class="detail-center">
    <div class="detail-content">
        <mat-progress-bar mode="buffer" *ngIf="!dataLoad"></mat-progress-bar>
        <div *ngIf="dataLoad">
            <div class="detail-title">
                <h1>{{ data.name }} {{ data.surname }}</h1>
                <div>
                    <button mat-button (click)="showNotifications()">
                        <mat-icon aria-hidden="false" aria-label="Example home icon">notifications</mat-icon>
                    </button>
                    <a mat-list-item routerLink="edit">
                        Edit
                    </a>
                </div>

            </div>
            <div class="detail-list">
                <div>
                    <p> User ID </p>
                    <p>{{ data.user_id }}</p>
                    <hr>
                </div>

                <div>
                    <p> TC or Passport </p>
                    <p>{{ data.tcknOrPassport }}</p>
                    <hr>
                </div>

                <div>
                    <p> Phone Number </p>
                    <p>{{ data.phoneNumber }}</p>
                    <hr>
                </div>

                <div>
                    <p> Email </p>
                    <p>{{ data.email }}</p>
                    <hr>
                </div>

                <div>
                    <p> Is Student </p>
                    <p>{{ data.isStudent }}</p>
                    <hr>
                </div>

                <div>
                    <p> Register Approved Time </p>
                    <p>{{ data.registerDate }}</p>
                    <hr>
                </div>

                <div *ngIf="data.givenCountry">
                    <p> Given Country </p>
                    <p>{{ data.givenCountry }}</p>
                    <hr>
                </div>

                <div *ngIf="data.countryEntryDate">
                    <p> Country Entry Date </p>
                    <p>{{ data.countryEntryDate }}</p>
                    <hr>
                </div>

                <div>
                    <p> Birth Date </p>
                    <p>{{ data.birthDate }}</p>
                    <hr>
                </div>

                <div>
                    <p> Is Permission Notification </p>
                    <p>{{ data.isPermissionNotification }}</p>
                    <hr>
                </div>

                <!-- <div>
                    <p> Gender </p>
                    <p>{{ data.gender }}</p>
                    <hr>
                </div> -->

            </div>

            <div class="edit-buttons">
                <button class="user-buttons" mat-raised-button color="primary" [matMenuTriggerFor]="statuButton">Statu</button>
                <mat-menu #statuButton="matMenu" yPosition="above">
                    <button class="user-buttons" mat-menu-item (click)="userStatusChange(data.user_id, 'Active')">Active</button>
                    <button class="user-buttons" mat-menu-item (click)="userStatusChange(data.user_id, 'Deactive')">Deactive</button>
                    <button class="user-buttons" mat-menu-item (click)="userStatusChange(data.user_id, 'AddBlacklist')">Add to blacklist</button>
                    <!-- <button class="user-buttons" mat-menu-item (click)="userStatusChange(data.user_id, 'RemoveBlacklist')">Remove Blacklist</button> -->
                </mat-menu>
                <button class="user-buttons" mat-raised-button color="primary" (click)="sendWelcomeEmail(data.user_id)" >Send Welcome Email</button>
                <button class="user-buttons" mat-raised-button color="primary" (click)="openUserTags()" >Tags</button>
                <button *ngIf="!data.isActive" class="user-buttons" mat-raised-button color="warn" (click)="cancelDeactivate(data.user_id)" >Cancel Deactive</button>
            </div>

        </div>
    </div>
</div>