<form [formGroup]="billingForm" (ngSubmit)="onSubmit()">

    <table *ngIf="res_detail">
        <tr>
            <td>Reservation Detail</td>
            <td><a (click)="reservationDetail()">Reservation</a> </td>
        </tr>
    </table>

    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Reservation *</mat-label>
        <input type="text" placeholder="Pick one" aria-label="Reservation" matInput formControlName="reservation"
            [matAutocomplete]="autoReservation">
        <mat-autocomplete #autoReservation="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredReservation | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>

    <mat-form-field>
        <mat-label>İade Tutarı</mat-label>
        <input matInput type="number" formControlName="tutar" />
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>İade Nedeni</mat-label>
        <mat-select formControlName="neden">

            <mat-option *ngFor="let status of refund_status | keyvalue" value="{{ status.key }}">{{ status.value }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>İade Açıklama</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="5" formControlName="aciklama"></textarea>
    </mat-form-field>

    <button type="submit" mat-raised-button color="primary" [disabled]="!billingForm.valid">Submit</button>

</form>