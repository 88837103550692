import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { RefundSettingsAddComponent } from '../refund-settings-add/refund-settings-add.component';

@Component({
  selector: 'app-refund-settings',
  templateUrl: './refund-settings.component.html',
  styleUrls: ['./refund-settings.component.css']
})
export class RefundSettingsComponent implements OnInit {

  dataSource: any;
  displayedColumns: Array<string> = ["id", "name", "price", "text", "update", "delete"];
  constructor(
    private reservationService: ReservationsService,
    private dialog: MatDialog,
    public globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.get_codes();
  }
  
  get_codes(): void {
    this.reservationService.getRefundSettingsList().subscribe((result: any) => {
      
      this.dataSource = result.data;
    }, this.globalService.handleError);
  }

  add_new(): void {
    this.dialog.open(RefundSettingsAddComponent).afterClosed().subscribe(result => {
      if (result) {
        this.get_codes();
      }
    }, this.globalService.handleError);
  }

  updateSettings(element: any): void {
    this.dialog.open(RefundSettingsAddComponent, {
      data: element
    }).afterClosed().subscribe(result => {
      if (result) {
        this.get_codes();
      }
    });
  }

  deleteSettings(id: number): void {
    if(confirm("Are you sure ?")) {
      this.reservationService.deleteRefundSettings(id).subscribe(result => {
        this.globalService.successMessage(result);
        this.get_codes();
      }, this.globalService.handleError);
    }
  }

}
