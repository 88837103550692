import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DefaultModule } from './layouts/default/default.module';
import { MatTableModule } from '@angular/material/table';
import { VehicleModelsComponent } from './modules/vehicle-models/vehicle-models.component';
import { VehicleModelEditComponent } from './modules/vehicle-models/vehicle-model-edit/vehicle-model-edit.component';
import { VehicleBrandsComponent } from './modules/vehicle-brands/vehicle-brands.component';
import { VehicleBrandEditComponent } from './modules/vehicle-brands/vehicle-brand-edit/vehicle-brand-edit.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LicenseComponent } from './modules/license/license.component';
import { InsuranceEdit, InsurancesComponent } from './modules/vehicles/car-info/insurances/insurances.component';
import { MatSelectModule } from '@angular/material/select';
import { SelfieComponent } from './modules/selfie/selfie.component';
import { CorporationComponent } from './modules/corporation/corporation.component';
import { CorporationEditComponent } from './modules/corporation/corporation-edit/corporation-edit.component';
import { LicenseDetailComponent } from './modules/license/license-detail/license-detail.component';
import { CorporationUsersComponent } from './modules/corporation/corporation-users/corporation-users.component';
import { RejectedReasonComponent } from './modules/rejected-reason/rejected-reason.component';
import { VehicleDetailComponent } from './modules/vehicles/vehicle-detail/vehicle-detail.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { DriversComponent } from './modules/drivers/drivers.component';
import { DriverItemComponent } from './modules/drivers/driver-item/driver-item.component';
import { DriverDetailComponent } from './modules/drivers/driver-detail/driver-detail.component';
import { DriverEditComponent } from './modules/drivers/driver-edit/driver-edit.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DriverStatusComponent } from './modules/drivers/driver-status/driver-status.component';
import { MoreComponent } from './modules/more/more.component';
import { VehicleImagesComponent } from './modules/vehicles/vehicle-images/vehicle-images.component';
import { AddImageComponent } from './modules/vehicles/vehicle-images/add-image/add-image.component';
import { VehicleDamagesComponent } from './modules/vehicles/vehicle-damages/vehicle-damages.component';
import { DriverDocumentsComponent } from './modules/drivers/driver-documents/driver-documents.component';
import { HgsComponent } from './modules/more/hgs/hgs.component';
import { DriverReservationsComponent } from './modules/drivers/driver-reservations/driver-reservations.component';
import { DriverReservationsDetailComponent } from './modules/drivers/driver-reservations/driver-reservations-detail/driver-reservations-detail.component';
import { ReservationsComponent } from './modules/reservations/reservations.component';
import { StudentLicenseComponent } from './modules/student-license/student-license.component';
import { StudentLicenseDetailComponent } from './modules/student-license/student-license-detail/student-license-detail.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DriverNotificationsComponent } from './modules/drivers/driver-detail/driver-notifications/driver-notifications.component';
import { DriverAddNotificationComponent } from './modules/drivers/driver-detail/driver-notifications/driver-add-notification/driver-add-notification.component';
import { VehicleAddComponent } from './modules/vehicles/vehicle-add/vehicle-add.component';
import { ProviderAddComponent } from './modules/vehicles/providers/provider-add/provider-add.component';
import { MatDialogModule } from '@angular/material/dialog';
import { StatusTypeAddComponent } from './modules/vehicles/status-type/status-type-add/status-type-add.component';
import { VehicleStatusAddComponent } from './modules/vehicles/vehicle-states/vehicle-status-add/vehicle-status-add.component';
import { MatSortModule } from '@angular/material/sort';
import { CitizenshipTypeAddComponent } from './modules/drivers/citizenship-type/citizenship-type-add/citizenship-type-add.component';
import { DeactiveUsersComponent } from './modules/more/deactive-users/deactive-users.component';
import { ReservationAddComponent } from './modules/reservations/reservation-add/reservation-add.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ReservationDetailComponent } from './modules/reservations/reservation-detail/reservation-detail.component';
import { ReservationAdditionalTimeComponent } from './modules/reservations/reservation-additional-time/reservation-additional-time.component';
import { ReservationCancelComponent } from './modules/reservations/reservation-cancel/reservation-cancel.component';
import { ReservationRefundListComponent } from './modules/reservations/reservation-refund-list/reservation-refund-list.component';
import { SendMessageComponent } from './modules/drivers/send-message/send-message.component';
import { CampaignCodeComponent } from './modules/campaign-code/campaign-code.component';
import { CampaignCodeAddComponent } from './modules/campaign-code/campaign-code-add/campaign-code-add.component';
import { ParkLocationsAddComponent } from './modules/park-locations/park-locations-add/park-locations-add.component';
import { ParkLocationChargeStationAddComponent } from './modules/park-locations/park-location-charge-station-add/park-location-charge-station-add.component';
import { ImageUploadComponent } from './modules/public/image-upload/image-upload.component';
import { ParkLocationChargeStationsComponent } from './modules/park-locations/park-location-charge-stations/park-location-charge-stations.component';
import { ParkLocationVehiclesComponent } from './modules/park-locations/park-location-vehicles/park-location-vehicles.component';
import { ParkLocationVehiclesAddComponent } from './modules/park-locations/park-location-vehicles/park-location-vehicles-add/park-location-vehicles-add.component';
import { ListItemComponent } from './modules/public/list-item/list-item.component';
import { CorporationDetailComponent } from './modules/corporation/corporation-detail/corporation-detail.component';
import { CorporationAddComponent } from './modules/corporation/corporation-add/corporation-add.component';
import { CorporationAgreementsComponent } from './modules/corporation/corporation-agreements/corporation-agreements.component';
import { CorporationAgreementsAddComponent } from './modules/corporation/corporation-agreements/corporation-agreements-add/corporation-agreements-add.component';
import { RefundSettingsComponent } from './modules/reservations/refund-settings/refund-settings.component';
import { RefundSettingsAddComponent } from './modules/reservations/refund-settings-add/refund-settings-add.component';
import { RefundSettingsAddTestComponent } from './modules/reservations/refund-settings-add/refund-settings-add-test/refund-settings-add-test.component';
import { AdditionalBillingComponent } from './modules/additional-billing/additional-billing.component';
import { AdditionalBillingAddComponent } from './modules/additional-billing/additional-billing-add/additional-billing-add.component';
import { AdditionalBillingDetailComponent } from './modules/additional-billing/additional-billing-detail/additional-billing-detail.component';
import { AdditionalBillingAddItemComponent } from './modules/additional-billing/additional-billing-add/additional-billing-add-item/additional-billing-add-item.component';
import { ResrevationImagesComponent } from './modules/reservations/resrevation-images/resrevation-images.component';
import { VehicleSegmentOptionsComponent } from './modules/vehicles/vehicle-segment-options/vehicle-segment-options.component';
import { VehicleSegmentOptionsEditComponent } from './modules/vehicles/vehicle-segment-options/vehicle-segment-options-edit/vehicle-segment-options-edit.component';
import { VehicleSegmentOptionsDetailComponent } from './modules/vehicles/vehicle-segment-options/vehicle-segment-options-detail/vehicle-segment-options-detail.component';
import { VehicleSegmentAddVehicleComponent } from './modules/vehicles/vehicle-segment-options/vehicle-segment-options-edit/vehicle-segment-add-vehicle/vehicle-segment-add-vehicle.component';
import { CorporationSegmentsComponent } from './modules/corporation/corporation-segments/corporation-segments.component';
import { CorporationSegmentsEditComponent } from './modules/corporation/corporation-segments/corporation-segments-edit/corporation-segments-edit.component';
import { CorporationSegmentAddCorporationComponent } from './modules/corporation/corporation-segments/corporation-segments-edit/corporation-segment-add-corporation/corporation-segment-add-corporation.component';
import { PricingComponent } from './modules/pricing/pricing.component';
import { PricingEditComponent } from './modules/pricing/pricing-edit/pricing-edit.component';
import { ReservationTableComponent } from './modules/reservation-table/reservation-table.component';
import { LogsComponent } from './modules/drivers/logs/logs.component';
import { UserDocumentsComponent } from './modules/drivers/user-documents/user-documents.component';
import { UserDocumentsDetailComponent } from './modules/drivers/user-documents/user-documents-detail/user-documents-detail.component';
import { UserDocumentDriverLicenseComponent } from './modules/drivers/user-documents/user-document-driver-license/user-document-driver-license.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { RejectUserDocumentComponent } from './modules/drivers/user-documents/reject-user-document/reject-user-document.component';
import { UserDocumentSelfieComponent } from './modules/drivers/user-documents/user-document-selfie/user-document-selfie.component';
import { UserStudentPhotoComponent } from './modules/drivers/user-documents/user-student-photo/user-student-photo.component';
import { ReservationEndComponent } from './modules/reservations/reservation-end/reservation-end.component';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { AddReservationRefundComponent } from './modules/reservations/reservation-refund-list/add-reservation-refund/add-reservation-refund.component';
import { AccountDetailComponent } from './modules/drivers/account-detail/account-detail.component';
import { CorporateAccountDetailsComponent } from './modules/corporation/corporate-account-details/corporate-account-details.component';
import { OtherDocumentsComponent } from './modules/other-documents/other-documents.component';
import { StudentCardDetailComponent } from './modules/other-documents/student-card/student-card-detail/student-card-detail.component';
import { RejectReasonComponent } from './modules/other-documents/reject-reason/reject-reason.component';
import { CorporateCardDetailComponent } from './modules/other-documents/corporate-card/corporate-card-detail/corporate-card-detail.component';
import { CorporationsComponent } from './modules/other-documents/corporations/corporations.component';
import { StudentCardComponent } from './modules/other-documents/student-card/student-card.component';
import { CorporateCardComponent } from './modules/other-documents/corporate-card/corporate-card.component';
import { UserTagsComponent } from './modules/drivers/user-tags/user-tags.component';
import { AddUserTagsComponent } from './modules/drivers/user-tags/add-user-tags/add-user-tags.component';
import { UserTableComponent } from './modules/drivers/user-table/user-table.component';
import { MatChipsModule } from '@angular/material/chips';
import { ReservationsTripleComponent } from './modules/reservations-triple/reservations-triple.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ReservationDetailTabComponent } from './modules/reservations-triple/reservation-detail-tab/reservation-detail-tab.component';
import { VehicleDetailTabComponent } from './modules/reservations-triple/vehicle-detail-tab/vehicle-detail-tab.component';
import { AgmCoreModule } from '@agm/core';
import { FutureReservationOfVehicleComponent } from './modules/reservations-triple/future-reservation-of-vehicle/future-reservation-of-vehicle.component';
import { SignalComponent } from './modules/signal/signal.component';
import { VehicleReportsComponent } from './modules/reports/vehicle-reports/vehicle-reports.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ReservationTerminateImagesComponent } from './modules/reservations/reservation-terminate-images/reservation-terminate-images.component';
import { VehicleGroupComponent } from './modules/pricing/vehicle-group/vehicle-group.component';
import { AddVehicleGroupComponent } from './modules/pricing/vehicle-group/add-vehicle-group/add-vehicle-group.component';
import { CorporateGroupComponent } from './modules/pricing/corporate-group/corporate-group.component';
import { AddCorporateGroupComponent } from './modules/pricing/corporate-group/add-corporate-group/add-corporate-group.component';
import { ListModelsComponent } from './modules/pricing/vehicle-group/add-vehicle-group/list-models/list-models.component';
import { ListCorporateModelsComponent } from './modules/pricing/corporate-group/add-corporate-group/list-corporate-models/list-corporate-models.component';
import { UserCorporateVehiclesComponent } from './modules/more/user-corporate-vehicles/user-corporate-vehicles.component';
import { UserCorporateVehiclesEditComponent } from './modules/more/user-corporate-vehicles/user-corporate-vehicles-edit/user-corporate-vehicles-edit.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth-service.service';
import { CorporationUsersAddComponent } from './modules/corporation/corporation-users/corporation-users-add/corporation-users-add.component';
import { CorporationCarsAddComponent } from './modules/corporation/corporation-users/corporation-cars-add/corporation-cars-add.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    VehicleModelsComponent,
    VehicleModelEditComponent,
    VehicleBrandsComponent,
    VehicleBrandEditComponent,
    LicenseComponent,
    InsurancesComponent,
    InsuranceEdit,
    SelfieComponent,
    CorporationComponent,
    CorporationEditComponent,
    LicenseDetailComponent,
    CorporationUsersComponent,
    RejectedReasonComponent,
    VehicleDetailComponent,
    DriversComponent,
    DriverItemComponent,
    DriverDetailComponent,
    DriverEditComponent,
    DriverStatusComponent,
    MoreComponent,
    VehicleAddComponent,
    VehicleImagesComponent,
    AddImageComponent,
    VehicleDamagesComponent,
    DriverDocumentsComponent,
    HgsComponent,
    DriverReservationsComponent,
    DriverReservationsDetailComponent,
    ReservationsComponent,
    StudentLicenseComponent,
    StudentLicenseDetailComponent,
    DriverNotificationsComponent,
    DriverAddNotificationComponent,
    ProviderAddComponent,
    StatusTypeAddComponent,
    VehicleStatusAddComponent,
    CitizenshipTypeAddComponent,
    DeactiveUsersComponent,
    ReservationAddComponent,
    ReservationDetailComponent,
    ReservationAdditionalTimeComponent,
    ReservationCancelComponent,
    ReservationRefundListComponent,
    SendMessageComponent,
    CampaignCodeComponent,
    CampaignCodeAddComponent,
    ParkLocationsAddComponent,
    ParkLocationChargeStationAddComponent,
    ImageUploadComponent,
    ParkLocationChargeStationsComponent,
    ParkLocationVehiclesComponent,
    ParkLocationVehiclesAddComponent,
    ListItemComponent,
    CorporationDetailComponent,
    CorporationAddComponent,
    CorporationAgreementsComponent,
    CorporationAgreementsAddComponent,
    RefundSettingsComponent,
    RefundSettingsAddComponent,
    RefundSettingsAddTestComponent,
    AdditionalBillingComponent,
    AdditionalBillingAddComponent,
    AdditionalBillingDetailComponent,
    AdditionalBillingAddItemComponent,
    ResrevationImagesComponent,
    VehicleSegmentOptionsComponent,
    VehicleSegmentOptionsEditComponent,
    VehicleSegmentOptionsDetailComponent,
    VehicleSegmentAddVehicleComponent,
    CorporationSegmentsComponent,
    CorporationSegmentsEditComponent,
    CorporationSegmentAddCorporationComponent,
    PricingComponent,
    PricingEditComponent,
    ReservationTableComponent,
    LogsComponent,
    UserDocumentsComponent,
    UserDocumentsDetailComponent,
    UserDocumentDriverLicenseComponent,
    RejectUserDocumentComponent,
    UserDocumentSelfieComponent,
    UserStudentPhotoComponent,
    ReservationEndComponent,
    AddReservationRefundComponent,
    AccountDetailComponent,
    CorporateAccountDetailsComponent,
    OtherDocumentsComponent,
    StudentCardDetailComponent,
    StudentCardComponent,
    RejectReasonComponent,
    CorporateCardDetailComponent,
    CorporateCardComponent,
    CorporationsComponent,
    UserTagsComponent,
    AddUserTagsComponent,
    UserTableComponent,
    ReservationsTripleComponent,
    ReservationDetailTabComponent,
    VehicleDetailTabComponent,
    FutureReservationOfVehicleComponent,
    SignalComponent,
    VehicleReportsComponent,
    ReservationTerminateImagesComponent,
    VehicleGroupComponent,
    AddVehicleGroupComponent,
    CorporateGroupComponent,
    AddCorporateGroupComponent,
    ListModelsComponent,
    ListCorporateModelsComponent,
    UserCorporateVehiclesComponent,
    UserCorporateVehiclesEditComponent,
    CorporationUsersAddComponent,
    CorporationCarsAddComponent
  ],
  imports: [
    MatCardModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DefaultModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MatTabsModule,
    NgApexchartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXzv6AxxMGrfO7dp__z9ueIfg92N7Ebko',
      libraries: ['geometry']
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
