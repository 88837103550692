import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { DocumentService } from 'src/app/services/users/document.service';

@Component({
  selector: 'app-reject-reason',
  templateUrl: './reject-reason.component.html',
  styleUrls: ['./reject-reason.component.css']
})
export class RejectReasonComponent implements OnInit {
  rejectData: any;
  rejectedForm: FormGroup = new FormGroup({
    reason: new FormControl('', Validators.required),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<RejectReasonComponent>,
    private documentService: DocumentService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.documentService.getRejectedReasons(this.data).subscribe({
      next: (result: any) => {
        this.rejectData = result.data;
        console.log(result)
      },
      error: this.globalService.handleError
    })
  }

  onFormSubmit(): void {
    this.dialogRef.close(this.rejectedForm.value.reason);
  }

}
