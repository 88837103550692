import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthServiceService } from '../auth-service.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  recaptcha: any;

  constructor(private authService: AuthServiceService,
      private _router: Router,
      private globalService: GlobalService
    ) {
     }

  ngOnInit(): void {
    this.initForm();
  }

  resolved(captchaResponse: string) {
    this.recaptcha = captchaResponse;
  }

  initForm(){
    this.formGroup = new FormGroup({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }

  loginProcess(){
    if(this.formGroup.valid){
      // if(!this.recaptcha) {
      //   this.globalService.showMessage("Error Recaptcha");
        // return;
      // }
      this.authService.login(this.formGroup.value, this.recaptcha).subscribe(result=>{        
        console.log(result);
        if(result.access_token){
          localStorage.setItem('is_staff', result.is_staff);
          localStorage.setItem('is_superuser', result.is_superuser);
          localStorage.setItem('access_token', result.access_token);
          
          localStorage.setItem('refresh_token', result.refresh_token);
          var currentTime = new Date();
          currentTime = new Date(currentTime.setSeconds(result.expires_in));

          localStorage.setItem('expires_date', currentTime.getTime().toString());
          this._router.navigate(['/dashboard']);
        }
        else {
          
        }

      }, this.globalService.handleError);
    }
  }

}
