<mat-dialog-content>
    <mat-progress-bar mode="buffer" *ngIf="!reservation"></mat-progress-bar>

    <div *ngIf="reservation" fxLayout="row wrap" fxLayoutGap.gt-xs="20px">
        <div class="section" fxFlex fxLayout="column" fxFlex.lt-md="100" fxLayoutGap="20px">

            <h2 fxLayoutAlign="center center"><a (click)="dialogRef.close()" routerLink="/dashboard/vehicles/{{ reservation.vehicle.id }}">Vehicle</a></h2>
            <div fxLayoutAlign="center center" >
                <img src="{{ baseUrl + reservation?.vehicle?.model?.image }}" alt="">
            </div>
            <table>
                <tr>
                    <td>Plate Number</td>
                    <td>{{ reservation?.vehicle?.number_plate }}</td>
                </tr>
                <tr>
                    <td>Brand</td>
                    <td>{{ reservation?.vehicle?.brand?.name }}</td>
                </tr>
                <tr>
                    <td>Model</td>
                    <td>{{ reservation?.vehicle?.model?.name }}</td>
                </tr>
                <tr>
                    <td>Year</td>
                    <td>{{ reservation?.vehicle?.year }}</td>
                </tr>
                <tr>
                    <td>Color</td>
                    <td>{{ reservation?.vehicle?.colour }}</td>
                </tr>
                <tr>
                    <td>License Age</td>
                    <td>{{ reservation?.vehicle?.license_age }}</td>
                </tr>
            </table>
            <div>
                <div fxLayout="row wrap"  fxLayoutGap="10px grid">
                    <div>
                        <button mat-raised-button color="primary" [matMenuTriggerFor]="statuButton" >Set Status</button>
                        <mat-menu #statuButton="matMenu" yPosition="above">
                            <button mat-menu-item *ngFor="let status of status_var | keyvalue" (click)="reservationStatusChange(status.value)">{{ status.value }}</button>
                        </mat-menu>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="cancel_reservation(reservation)">Cancel Reservation</button>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="set_additional_time(reservation)">Set Additional Time</button>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="open_reservation_photos(reservation.id)">Photos</button>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="open_terminate_reservation_photos(reservation.id)">Terminate Photos</button>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" (click)="open_vehicle_photos(reservation.id)">Vehicle Photos</button>
                    </div>
                </div>
            </div>
            <div *ngIf="reservation.request_status === 'Waiting'" style="margin-top: 10px;">
                <td>{{ reservation.user.name }} {{ reservation.user.surname }}</td>
                <td>
                    <div fxLayout="column" fxLayoutGap="10px" >
                        <button mat-raised-button color="primary" (click)="approveReservation()">Approve Reservation</button>
                        <button mat-raised-button color="warn" (click)="rejectReservation()">Reject Reservation</button>
                    </div>
                </td>
            </div>


        </div>
        <div class="section" fxFlex fxLayout="column" fxFlex.lt-md="100">
            <h1 fxLayoutAlign="center center" style="margin-top: 9px;font-weight: bold; font-size: 2em;margin-bottom: 40px !important;">Detail</h1>
            <table>
                <tr>
                    <td>Reservation ID</td>
                    <td>{{ reservation.id }}</td>
                </tr>
                <tr>
                    <td>Order ID</td>
                    <td>{{ reservation.sap_order?.order_id }}</td>
                </tr>
                <tr>
                    <td>User Corporation</td>
                    <td>{{ reservation.user_corporation?.name }}</td>
                </tr>
                <tr>
                    <td>User</td>
                    <td>
                        <a (click)="dialogRef.close()" routerLink="/dashboard/drivers/{{ reservation.user.user_id }}">{{ reservation.user.name }} {{ reservation.user.surname }}</a>
                    </td>
                </tr>
                <tr>
                    <td>Start Destination</td>
                    <td>
                        <a (click)="dialogRef.close()" routerLink="/dashboard/park-locations/{{ reservation.start_destination.id }}">{{ reservation.start_destination.name }}</a>
                    </td>
                </tr>
                <tr>
                    <td>End Destination</td>
                    <td>
                        <a (click)="dialogRef.close()" routerLink="/dashboard/park-locations/{{ reservation.end_destination.id }}">{{ reservation.end_destination.name }}</a>
                    </td>
                </tr>
                <tr>
                    <td>Status</td>
                    <td>{{ reservation.status }}</td>
                </tr>
                <tr>
                    <td>Start Time</td>
                    <td>{{ reservation.start_time }}</td>
                </tr>
                <tr>
                    <td>Started Time</td>
                    <td>{{ reservation.started_time }}</td>
                </tr>
                <tr>
                    <td>End Time</td>
                    <td>{{ reservation.end_time }}</td>
                </tr>
                <tr>
                    <td>Additionaled End Time</td>
                    <td>{{ reservation.additional_end_time }}</td>
                </tr>
                <tr>
                    <td>Ended Time</td>
                    <td>{{ reservation.endedTime }}</td>
                </tr>
                <tr>
                    <td>Total Price</td>
                    <td>{{ reservation.total_price | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                </tr>
                <tr>
                    <td>Discounted price</td>
                    <td>{{ reservation.discounted_price | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                </tr>
                <tr>
                    <td>Discounted Reason</td>
                    <td>{{ reservation.discounted_reason }}</td>
                </tr>
                <tr>
                    <td>Currency</td>
                    <td>{{ reservation.currency }}</td>
                </tr>
                <tr>
                    <td>Note</td>
                    <td>{{ reservation.note }}</td>
                </tr>
                <tr>
                    <td>Campain Code</td>
                    <td>{{ reservation.campaign_code }}</td>
                </tr>
                <tr>
                    <td>Acceleration</td>
                    <td>{{ reservation.acceleration }}</td>
                </tr>
                <tr>
                    <td>Braking</td>
                    <td>{{ reservation.braking }}</td>
                </tr>
                <tr>
                    <td>Sudden turn</td>
                    <td>{{ reservation.sudden_turn }}</td>
                </tr>
                <tr>
                    <td>Acceleration2</td>
                    <td>{{ reservation.acceleration2 }}</td>
                </tr>
                <tr>
                    <td>Braking2</td>
                    <td>{{ reservation.braking2 }}</td>
                </tr>
                <tr>
                    <td>Movement History</td>
                    <td>{{ reservation.movement_history }}</td>
                </tr>
                <tr>
                    <td>Created On</td>
                    <td>{{ reservation.created_on }}</td>
                </tr>
            </table>
        </div>
        <div class="section" fxFlex fxFlex.lt-md="100" >
            <h2 fxLayoutAlign="center center">Additional Time</h2>
            <mat-accordion *ngFor="let item of reservation.additional_reservation">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Additional Time
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ item.id }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <table class="expandedTable">
                        <tr>
                            <td>ID</td>
                            <td>{{ item.id }}</td>
                        </tr>
                        <tr>
                            <td>Start time</td>
                            <td>{{ item.start_time }}</td>
                        </tr>
                        <tr>
                            <td>End time</td>
                            <td>{{ item.end_time }}</td>
                        </tr>
                        <tr>
                            <td>Price</td>
                            <td>{{ item.additional_price | currency:item.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                        <tr>
                            <td>Currency</td>
                            <td>{{ item.currency }}</td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td>{{ item.sap_order?.order_id }}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{{ item.status }}</td>
                        </tr>
                        <tr>
                            <td>Created Time</td>
                            <td>{{ item.created_on }}</td>
                        </tr>
        
                    </table>
                </mat-expansion-panel>
            </mat-accordion>

            <h2 fxLayoutAlign="center center" *ngIf="reservation.additional_billing.length > 0">Additional Billing</h2>
            <mat-accordion *ngFor="let item of reservation.additional_billing">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Additional Billing
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ item.description }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <table class="expandedTable">
                        <tr>
                            <td>ID</td>
                            <td>{{ item.id }}</td>
                        </tr>
                        <tr>
                            <td>Billing Date</td>
                            <td>{{ item.billing_date }}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{{ item.description }}</td>
                        </tr>
                        <tr>
                            <td>Sending Asecco</td>
                            <td>{{ item.sending_asecco }}</td>
                        </tr>
                        <tr>
                            <td>Sending Sap</td>
                            <td>{{ item.sending_sap }}</td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td>{{ item.order_id }}</td>
                        </tr>
                    </table>
                    <table class="expandedTable" *ngFor="let billing_item of item.billing_items">
                        <tr>
                            <td colspan="2"><b>Item Detail</b></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{{ billing_item.name }}</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>{{ billing_item.amount | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                        <tr>
                            <td>Discount Amount</td>
                            <td>{{ billing_item.discount_amount | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                        <tr>
                            <td>Service Fee</td>
                            <td>{{ billing_item.service_fee | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>

            

            <h2 fxLayoutAlign="center center" *ngIf="reservation.refunds.length > 0">Refund Requests</h2>
            <mat-accordion *ngFor="let item of reservation.refunds">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Refund Request
                        </mat-panel-title>
                        <mat-panel-description>
                            {{ refund_status[item.refund_reason] }}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <table class="expandedTable">
                        <tr>
                            <td>Refund Complate</td>
                            <td>{{ item.refund_complate }}</td>
                        </tr>
                        <tr>
                            <td>Refund Total</td>
                            <td>{{ item.refund_total }}</td>
                        </tr>
                        <tr>
                            <td>Description</td>
                            <td>{{ item.return_statement }}</td>
                        </tr>
                        <tr>
                            <td>Asecco Error</td>
                            <td>{{ item.asecco_error_message }}</td>
                        </tr>
                    </table>
                    <table class="expandedTable" *ngFor="let billing_item of item.billing_items">
                        <tr>
                            <td colspan="2"><b>Item Detail</b></td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{{ billing_item.name }}</td>
                        </tr>
                        <tr>
                            <td>amount</td>
                            <td>{{ billing_item.amount | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                        <tr>
                            <td>Discount Amount</td>
                            <td>{{ billing_item.discount_amount | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                        <tr>
                            <td>Service Fee</td>
                            <td>{{ billing_item.service_fee | currency:reservation.currency:'symbol-narrow':'0.2-2' }}</td>
                        </tr>
                    </table>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>


</mat-dialog-content>
