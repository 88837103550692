<div class="panelInfo">
    <p class="panelDate">
        {{openedLicense.given_date}} - {{openedLicense.expiration_date}}
    </p>
    <mat-progress-bar mode="buffer" *ngIf="openedLicense?.license_id?false:true"></mat-progress-bar>
    <div *ngIf="openedLicense?.license_id?true:false">
        <div class="flex">
            <div class="driver-images width48">
                <img [src]="licenseFront" (click)="showImage(licenseFront)">
                <img [src]="licenseBack" (click)="showImage(licenseBack)">
            </div>
            <div class="width48">
                <table class="expandedTable">
                    <tr>
                        <td>File No</td>
                        <td>{{ openedLicense.file_no }}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{{ openedLicense.name }}</td>
                    </tr>
                    <tr>
                        <td>Surname</td>
                        <td>{{ openedLicense.surname }}</td>
                    </tr>
                    <tr>
                        <td>TCKN or Passport</td>
                        <td>{{ openedLicense.tcknOrPassport }}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>{{ openedLicense.phoneNumber }}</td>
                    </tr>
                    <tr>
                        <td>Birth Date</td>
                        <td>{{ openedLicense.birthDate }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ openedLicense.email }}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{{ openedLicense.status }}</td>
                    </tr>
                    <tr>
                        <td>Rejected Reason</td>
                        <td>{{ openedLicense.status_reason }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="panel-buttons" *ngIf="openedLicense?.status == 'Waiting'">
            <button mat-raised-button color="warn" (click)="panelReject(openedLicense.license_id)">Rejected</button>
            <button mat-raised-button color="primary" (click)="panelAccept(openedLicense.license_id)">Approved</button>

        </div>


    </div>

</div>