<table class="expandedTable">
    <tr>
        <td>Reservation ID</td>
        <td>{{ reservation?.id }}</td>
    </tr>
    <tr>
        <td>Order ID</td>
        <td>{{ reservation?.sap_order?.order_id }}</td>
    </tr>
    <tr>
        <td>User</td>
        <td>
            <a href="/dashboard/drivers/{{ reservation?.user }}">Go To User</a>
        </td>
    </tr>
    <tr>
        <td>Vehicle</td>
        <td>
            <a href="/dashboard/vehicles/{{ reservation?.vehicle?.id }}">Go To Vehicle</a>
        </td>
    </tr>
    <tr>
        <td>Start Destination</td>
        <td>
            <a href="/dashboard/park-locations/{{ reservation?.start_destination?.id }}">Go To Parkin Place</a>
        </td>
    </tr>
    <tr>
        <td>End Destination</td>
        <td>
            <a href="/dashboard/park-locations/{{ reservation?.end_destination?.id }}">Go To Parkin Place</a>
        </td>
    </tr>
    <tr>
        <td>Start Time</td>
        <td>{{ reservation?.start_time }}</td>
    </tr>
    <tr>
        <td>End Time</td>
        <td>{{ reservation?.end_time }}</td>
    </tr>
    <tr>
        <td>Total Price</td>
        <td>{{ reservation?.total_price | currency:reservation?.currency:'symbol-narrow':'0.2-2' }}</td>
    </tr>
    <tr>
        <td>Discounted price</td>
        <td>{{ reservation?.discounted_price | currency:reservation?.currency:'symbol-narrow':'0.2-2' }}</td>
    </tr>
    <tr>
        <td>Discounted Reason</td>
        <td>{{ reservation?.discounted_reason }}</td>
    </tr>
    <tr>
        <td>Currency</td>
        <td>{{ reservation?.currency }}</td>
    </tr>
    <tr>
        <td>Last Success Additional End Time</td>
        <td>{{ reservation?.last_success_additional_end_time }}</td>
    </tr>
    <tr>
        <td>Payment Info</td>
        <td>{{ reservation?.payment_info }}</td>
    </tr>
    <tr>
        <td>Status</td>
        <td>{{ reservation?.status }}</td>
    </tr>
    <tr>
        <td>Campain Code</td>
        <td>{{ reservation?.campaign_code }}</td>
    </tr>
    <tr>
        <td>Explanation</td>
        <td>{{ reservation?.explanation }}</td>
    </tr>
    <tr>
        <td>Have Additional Res</td>
        <td>{{ reservation?.have_additional_res }}</td>
    </tr>

</table>

<div class="edit-buttons">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="statuButton">Set Status</button>
    <mat-menu #statuButton="matMenu" yPosition="above">
        <button mat-menu-item (click)="reservationStatusChange(reservation.id, 'Waiting')">Waiting</button>
        <button mat-menu-item (click)="reservationStatusChange(reservation.id, 'Approved')">Approved</button>
        <button mat-menu-item (click)="reservationStatusChange(reservation.id, 'Rejected')">Rejected</button>
        <button mat-menu-item (click)="reservationStatusChange(reservation.id, 'Closed')">Closed</button>
        <button mat-menu-item (click)="reservationStatusChange(reservation.id, 'Ended')">Ended</button>
    </mat-menu>
</div>