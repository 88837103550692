import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';

@Component({
  selector: 'app-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.css']
})
export class CarInfoComponent implements OnInit {
  @Input() data: any;
  carImage: string;
  baseUri:string = baseUrl;
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    this.carImage = this.data.carImage?.image;

  }

  itemClick(id: number): void {
    this.router.navigate(['/dashboard/vehicles/'+id]);
  }

}