<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Select Corporation</h2>
        <form [formGroup]="itemForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Corporation</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Corporation" matInput formControlName="corporation" [matAutocomplete]="autoCorporation">
                <mat-autocomplete #autoCorporation="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredCorporation | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!itemForm.valid">Submit</button>

        </form>
    </mat-dialog-content>
</div>