<div class="editButton">
    <button mat-raised-button color="primary" (click)="insuranceEdit(data.id,0)">Add</button>
</div>

<div *ngIf="!dataLoaded" class="my-10">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
</div>

<div *ngIf="dataLoaded" class="my-10">

    <mat-accordion>
        <mat-expansion-panel *ngFor="let item of insurance">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ item.id }} - {{ item.insurance_type_name }}
                </mat-panel-title>
                <mat-panel-description>
                    {{ item.start_date }} - {{ item.end_date }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <table class="expandedTable">
                <tr>
                  <td>Insurance ID.</td>
                  <td>{{ item.id }}</td>
                </tr>
                <tr>
                    <td>Vehicle ID.</td>
                    <td>{{ item.vehicle_id }}</td>
                </tr>
                <tr>
                    <td>Insurance Type Name</td>
                    <td>{{ item.insurance_type_name }}</td>
                </tr>
                <tr>
                    <td>Policy Number</td>
                    <td>{{ item.policy_number }}</td>
                </tr>
                <tr>
                    <td>Start Date</td>
                    <td>{{ item.start_date }}</td>
                </tr>
                <tr>
                    <td>End Date</td>
                    <td>{{ item.end_date }}</td>
                </tr>
            </table>

            <div class="editButton">
                <button mat-raised-button color="warn" *ngIf="item.id" (click)="insuranceDelete(item.id)" >Delete</button>
                <button mat-raised-button color="primary" (click)="insuranceEdit(data.id, item)">Edit</button>
            </div>

        </mat-expansion-panel>
    </mat-accordion>






</div>

<!-- <form [formGroup]="insurancesForm" (ngSubmit)="onFormSubmit()">
    <mat-form-field appearance="fill">
        <mat-label>Select an Insurances</mat-label>
        <mat-select [(value)]="insuranceType" formControlName="insuranceType">
            <mat-option [value]="0" >None</mat-option>
            <mat-option *ngFor="let item of insuranceTypes" [value]="item.id" >{{ item.name }}</mat-option>
        </mat-select>
    </mat-form-field>
    <button>submit</button>
</form> -->