import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import * as moment from 'moment';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationDetailComponent } from '../reservations/reservation-detail/reservation-detail.component';
import { MatDialog } from '@angular/material/dialog';
import { BASEURL } from 'src/environments/environment';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-reservation-table',
  templateUrl: './reservation-table.component.html',
  styleUrls: ['./reservation-table.component.css']
})
export class ReservationTableComponent implements OnInit {
  selectedVehicles: any[] = [];
  searchGroup = new FormGroup({
    vehicles: new FormControl(null)
  });
  baseUrl = BASEURL;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  day_number: number = 0;
  all_vehicles: any;
  vehicles: any;
  hours = Array.from(Array(24).keys());
  filteredVehicle: any;
  date = '';
  showVehicles: any;
  getRandomColor: any;
  constructor(
    private reservationService: ReservationsService,
    private globalService: GlobalService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    var date = new Date();
    this.date = moment(date.setDate(date.getDate() + this.day_number)).format("YYYY-MM-DD");
    this.get_res_table(this.date);
  }

  get_res_table(day: string): void {
    this.reservationService.getReservationTable(day).subscribe({
      next: result => {
        this.all_vehicles = this.showVehicles = this.vehicles = result.data;
        this.filteredVehicle = this.searchGroup.get('vehicles')?.valueChanges.pipe(
          startWith(null),
          map((vehicle: any) => (vehicle ? this._filter(vehicle) : this.all_vehicles.slice())),
        );
      },
      error: this.globalService.handleError
    })
  }

  remove_vehicle_chip(vehicle: any): void {
    this.selectedVehicles = this.selectedVehicles.filter(v => v.id != vehicle.id);
    this.showVehicles = this.selectedVehicles;
    if (this.selectedVehicles.length < 1) {
      this.showVehicles = this.vehicles;
    }
    this.vehicles.push(this.all_vehicles.filter((v: any) => v.id == vehicle.id)[0]);
    this.filteredVehicle = this.searchGroup.get('vehicles')?.valueChanges.pipe(
      startWith(null),
      map((vehicle: any) => (vehicle ? this._filter(vehicle) : this.vehicles.sort((a: any, b: any) => a.id - b.id).slice())),
    );
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedVehicles.push(event.option.value);
    this.showVehicles = this.selectedVehicles;
    this.vehicles = this.vehicles.filter((v: any) => v.id != event.option.value.id);
    this.filteredVehicle = this.searchGroup.get('vehicles')?.valueChanges.pipe(
      startWith(null),
      map((vehicle: any) => (vehicle ? this._filter(vehicle) : this.vehicles.slice())),
    );
  }

  private _filter(value: any): string[] {
    if (!value.plate) return [];
    const filterValue = value.plate.toLowerCase();

    return this.all_vehicles.filter((vehicle: any) => vehicle.plate.toLowerCase().includes(filterValue));
  }

  date_back(): void {
    var date = new Date();
    this.day_number -= 1;
    this.date = moment(date.setDate(date.getDate() + this.day_number)).format("YYYY-MM-DD");
    this.get_res_table(this.date);
  }

  date_next(): void {
    var date = new Date();
    this.day_number += 1;
    this.date = moment(date.setDate(date.getDate() + this.day_number)).format("YYYY-MM-DD");
    this.get_res_table(this.date);
  }

  openReservation(id: number): void {
    this.dialog.open(ReservationDetailComponent, {
      autoFocus: false,
      width: "80%",
      maxWidth: "90%",
      data: id
    })
  }

  randomColor(): string {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

}
