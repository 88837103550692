<div class="panel">
    <div class="panel-list" [ngClass]="{'d-none':!panelListShow}">
        <div class="list-top">
            <h1>Charge Stations</h1>
            <div class="list-query">
                <mat-form-field class="example-form-field" *ngIf="showSearch">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" (input)="onSearchChange($event)">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="showSearch=false;closeSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-button *ngIf="!showSearch" (click)="showSearch=true;">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
                </button>

                <button mat-button color="warn" [matMenuTriggerFor]="menu">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="listSort({id: ['name'], value: true})">Name A-Z</button>
                    <button mat-menu-item (click)="listSort({id: ['name'], value: false})">Name Z-A</button>

                </mat-menu>
            </div>
        </div>

        <div class="list-content">
            <div class="list-buttons">
                <button routerLinkActive="active" routerLink="/dashboard/park-locations">Park Locations</button>
                <button routerLinkActive="active" routerLink="/dashboard/charge-stations">Charge Stations</button>
            </div>

            <div class="progress-bar" *ngIf="!locations">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <div class="list-items" *ngIf="locations">
                <app-charge-stations-item *ngFor="let location of locations" [data]="location"></app-charge-stations-item>
            </div>
        </div>
    </div>

    <div class="panel-content">
        <div class="content-control">
            <button mat-button (click)="panelListShow=!panelListShow">
                <mat-icon>view_quilt</mat-icon>
            </button>
            <div class="list-buttons">
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/charge-stations/" [routerLinkActiveOptions]="{exact: true}">
                    Details
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/charge-stations/map">
                    Map
                </a>
            </div>
            <button mat-button (click)="statusShow=!statusShow">
                <mat-icon>view_quilt</mat-icon>
            </button>
        </div>
        <div class="panel-dynamic">
            <div class="router-only">
                <router-outlet></router-outlet>
                <!-- <app-charge-stations-detail></app-charge-stations-detail> -->
            </div>
            <div [ngClass]="{'d-none':!statusShow, '': true}">
                <app-charge-stations-status></app-charge-stations-status>
            </div>
        </div>
    </div>

</div>