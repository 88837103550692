<div class="status-div" *ngIf="data">
    <div class="content-status">
        <div class="info-title">
            <h1>{{ data?.status?.status_type?.name || "Yok" }}</h1>
        </div>
        <div class="info-router">
            <a mat-list-item routerLinkActive="list-item-active" routerLink="{{ data.id }}/map">
                Show in map
            </a>
        </div>
        <div class="info-image">
            <agm-map [latitude]="data?.continental?.lat" [longitude]="data?.continental?.long">
                <agm-marker *ngIf="data" [latitude]="data?.continental?.lat" [longitude]="data?.continental?.long" iconUrl="/assets/icons/car.png">
                </agm-marker>
            </agm-map>
        </div>
        <div>
            <h5>Cont Serial NO: Yok</h5>
        </div>



        <div class="info-live">
            <div class="left-align" style="width: 50%;">
                <div>
                    <mat-hint>KM</mat-hint>
                    <span class="data">{{ data?.continental?.km }}</span>
                </div>
                <div>
                    <mat-hint>Pil</mat-hint>
                    <span class="data">{{ data?.continental?.charge_level }}</span>
                </div>
                <div>
                    <mat-hint>GSM sinyal gücü</mat-hint>
                    <span class="data">{{ data?.continental?.gsm_level }}</span>
                </div>
                <div>
                    <mat-hint>Fren Pedalı Konumu</mat-hint>
                    <span class="data">
                        {{ data?.continental?.fren_pedali_konumu=="True"?"Frene Basıldı":"Frene Basılmıyor" }}
                    </span>
                </div>
                <div>
                    <mat-hint>Gaz Pedalı Konumu</mat-hint>
                    <span class="data">{{ data?.continental?.gaz_pedali_konumu=="True"?"Gaz Pedalına Basıldı":"Gaz Pedalına Basılmıyor" }}</span>
                </div>
                <div>
                    <mat-hint>Yolcu Kapısı</mat-hint>
                    <span class="data">{{ data?.continental?.passenger_side_door=="True"?"Açık": "Kapalı" }}</span>
                </div>
                <div>
                    <mat-hint>Sürücü Arka Kapı</mat-hint>
                    <span class="data">{{ data?.continental?.rear_left_door=="True"?"Açık": "Kapalı" }}</span>
                </div>
            </div>
            <div class="right-align" style="width: 50%;">
                
                <div>
                    <mat-hint>Kilit Durumu</mat-hint>
                    <span class="data">{{ data?.continental?.lock_status=="True"?"Kilitli":"Açık" }}</span>
                </div>
                <div>
                    <mat-hint>El freni</mat-hint>
                    <span class="data">{{ data?.continental?.el_freni=="True"?"Vites N":"Vites P" }}</span>
                </div>
             
                <div>
                    <mat-hint>Kontak Bilgisi</mat-hint>
                    <span class="data">{{ data?.continental?.kontak=="True"?"Kontak Açık":"Kontak Kapalı" }}</span>
                </div>
                <div>
                    <mat-hint>Şarjda</mat-hint>
                    <span class="data">{{ data?.continental?.charge_status=='False'?"Şarjda Değil":"Şarjda" }}</span>
                </div>
                <div>
                    <mat-hint>Sürücü Kapısı</mat-hint>
                    <span class="data">{{ data?.continental?.driver_side_door=="True"?"Açık": "Kapalı" }}</span>
                </div>
                <div>
                    <mat-hint>Bagaj</mat-hint>
                    <span class="data">{{ data?.continental?.trunk_door=="True"?"Açık": "Kapalı" }}</span>
                </div>
                
                <div>
                    <mat-hint>Yolcu Arka Kapı</mat-hint>
                    <span class="data">{{ data?.continental?.rear_right_door=="True"?"Açık": "Kapalı" }}</span>
                </div>
            </div>
        </div>

        <div class="status-buttons">
            <a mat-list-item>
                Book
            </a>
            <a mat-list-item>
                Kilit aç kapa
            </a>
            <a class="driver" mat-list-item>
                Aktif Sürücü -> Kısayol
            </a>
        </div>

    </div>
</div>