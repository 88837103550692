<div class="electrip-form">
    <mat-dialog-content>
        <h2>Add New Additional Billing Item</h2>
        <form [formGroup]="billingForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Item Name *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Reservation" matInput formControlName="name" [matAutocomplete]="autoReservation">
                <mat-autocomplete #autoReservation="matAutocomplete">
                    <mat-option *ngFor="let item of billing_items | keyvalue" value="{{item.key}}">{{item.value}}</mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Amount *</mat-label>
                <input matInput type="number" formControlName="amount" min="0">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Service Fee</mat-label>
                <input matInput type="number" formControlName="service_fee" min="0">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Discount Price</mat-label>
                <input matInput type="number" formControlName="discount_amount" min="0">
            </mat-form-field>


            <button type="submit" mat-raised-button color="primary" [disabled]="!billingForm.valid">Submit</button>
        </form>
    </mat-dialog-content>
</div>