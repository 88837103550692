<div class="google-map">
    <agm-map [latitude]="data?.lat" [longitude]="data?.long">
        <agm-marker [latitude]="data?.lat" [longitude]="data?.long" iconUrl="assets/icons/charging.png" (mouseOver)="chargePanel.open()" (mouseOut)="chargePanel.close()">
            <agm-info-window #chargePanel>
                <div class="map-info">

                    <p>
                        <b>City : </b>
                        <span>{{ data?.city }}</span>
                    </p>

                    <p>
                        <b>Name : </b>
                        <span>{{ data?.cp_code_no }}</span>
                    </p>

                    <p>
                        <b>Type : </b>
                        <span>{{ data?.cp_type }}</span>
                    </p>

                    <p>
                        <b>District : </b>
                        <span>{{ data?.district }}</span>
                    </p>

                </div>
            </agm-info-window>
        </agm-marker>
    </agm-map>
</div>