import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VehicleBrandsService } from 'src/app/services/vehicle-brands.service';
import { VehicleBrandEditComponent } from './vehicle-brand-edit/vehicle-brand-edit.component'
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-vehicle-brands',
  templateUrl: './vehicle-brands.component.html',
  styleUrls: ['./vehicle-brands.component.css']
})
export class VehicleBrandsComponent implements OnInit {

  vehicleBrands:any;
  displayedColumns: string[] = ['id', 'name','edit'];

  constructor(private VehicleBrandsService:VehicleBrandsService,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialog: MatDialog,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.vehicleBrandsGet();
  }

  vehicleBrandsGet() {
    this.VehicleBrandsService.getAllVehicleBrands().subscribe(result=>{
      if(result.success){    
        this.vehicleBrands = [];    
        result.data.forEach(element => {
          var vehicle:any = new Object();
          var v  = new Object;
          for (let key in element){
              v[key] = element[key];
          }        
          this.vehicleBrands.push(v);
        });
        this.changeDetectorRefs.detectChanges();        

      }
    }, this.globalService.handleError);
  }


  openEditDialog() {
    const dialogRef = this.dialog.open(VehicleBrandEditComponent, {
      width: '500px',
      data: {id:0}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.vehicleBrandsGet();
    });

  }

  openEditDialogID(id:number, name:string) {
    const dialogRef = this.dialog.open(VehicleBrandEditComponent, {
      width: '500px',
      data: {id:id, name:name},
    });

    dialogRef.afterClosed().subscribe(result => {
      this.vehicleBrandsGet();
    });

  }

}
