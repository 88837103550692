import { Component, Inject, OnInit } from '@angular/core';
import { LicenseService } from 'src/app/services/license-selfie.service';
import { disableDebugTools, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LicenseDetailComponent } from './license-detail/license-detail.component';
import { GlobalService } from 'src/app/services/global.service';
import { DocumentsService } from 'src/app/services/documents.service';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.css']
})
export class LicenseComponent implements OnInit {
  displayedColumns:Array<string>=["id", "name", "surname","email"];
  licenses:any;
  openedLicense: any = {};
  licenseFront: any;
  licenseBack: any;
  isDisabled: boolean = false;
  constructor(
    private licenseService:LicenseService, 
    private _sanitizer: DomSanitizer, 
    private dialog:MatDialog,
    private _snackBar: MatSnackBar,
    private globalService: GlobalService,
    private documentService: DocumentsService,

    ) { }

  ngOnInit(): void {
    this.getAllLicenses();
  }
  
  getAllLicenses() {
    this.documentService.getDriverLicenses().subscribe((result:any) => {
      this.licenses = result.data;
    }, this.globalService.handleError);
  }




  tableClick(id:number) {
    const dialogRef = this.dialog.open(LicenseDetailComponent, {
      width: '80%',
      maxHeight: '95vh',
      data: id
    });
    dialogRef.afterClosed().subscribe((result:any) => {
      this.getAllLicenses();
    });
  }


}

