import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';

@Component({
  selector: 'app-park-location-charge-station-add',
  templateUrl: './park-location-charge-station-add.component.html',
  styleUrls: ['./park-location-charge-station-add.component.css']
})
export class ParkLocationChargeStationAddComponent implements OnInit {
  loading: boolean = true;
  parkLocationForm: FormGroup = new FormGroup({
    charge_station: new FormControl(null),
  });
  filteredChargeStations: any = {};
  constructor(
    private parkService: ParkingPlacesService,
    private dialogRef: MatDialogRef<ParkLocationChargeStationAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data:number,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.parkService.getAllChargeStations().subscribe((result:any) => {
      this.loading = false;
      this.filteredChargeStations = this.parkLocationForm.get('charge_station').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }
  
  displayFn(data: any): string {
    return data?.cp_code_no ? data.cp_code_no : '';
  }
  private _filter(value: any, data: any): string[] {
    
    const filterValue = value.cp_code_no ? value.cp_code_no.toLowerCase():value.toString().toLowerCase();
    return data.filter(option => option?.cp_code_no?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    this.parkService.setChargeStationToParkingPlace(this.data, this.parkLocationForm.get('charge_station').value.id).subscribe((result:any) => {
      
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }

}
