<div flex fxLayoutGap="20px">

    <div fxFlex="33">
        <mat-progress-bar mode="buffer" *ngIf="corpUsers?false:true"></mat-progress-bar>

        <div *ngIf="corpUsers?true:false">
            <div class="add-button">
                <button mat-raised-button color="primary" (click)="addVehicleToCorporation()">Add Vehicle To Corporation</button>
            </div>
            <table mat-table [dataSource]="corpVehicles" class="mat-elevation-z8">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID. </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="plate">
                    <th mat-header-cell *matHeaderCellDef> Plate </th>
                    <td mat-cell *matCellDef="let element"> {{element.number_plate}} </td>
                </ng-container>

                <ng-container matColumnDef="remove">
                    <th mat-header-cell *matHeaderCellDef> Remove </th>
                    <td mat-cell *matCellDef="let element" (click)="removeVehicle(element.id)"> <mat-icon>remove_circle</mat-icon> </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumnsVehicle"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsVehicle;" ></tr>
            </table>
           
        </div>
    </div>

    <div fxFlex="33">
        <mat-progress-bar mode="buffer" *ngIf="corpUsers?false:true"></mat-progress-bar>

        <div *ngIf="corpUsers?true:false">
            <div class="add-button">
                <button mat-raised-button color="primary" (click)="addUserToCorporation()">Add User To Corporation</button>
            </div>
            <table mat-table [dataSource]="corpUsers" class="mat-elevation-z8">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef> ID. </th>
                    <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
                </ng-container>




                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="showUserDetail(row)"></tr>
            </table>
        </div>
    </div>

    <div fxFlex="33">
        <mat-progress-bar mode="buffer" *ngIf="corpDetail?.id?false:true"></mat-progress-bar>

        <div *ngIf="corpDetail?.id?true:false">
            <div class="add-button">
                <button mat-raised-button color="primary" (click)="editCorporation()">Edit Corporation</button>
                <button mat-raised-button color="warn" (click)="delCorporation()">Delete Corporation</button>
            </div>

            <table class="expandedTable">
                <tr>
                    <td>ID</td>
                    <td>{{ corpDetail?.id }}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{ corpDetail?.name }}</td>
                </tr>
                <tr>
                    <td>Address</td>
                    <td>{{ corpDetail?.address }}</td>
                </tr>
                <tr>
                    <td>City</td>
                    <td>{{ corpDetail?.city?.name }}</td>
                </tr>
                <tr>
                    <td>Town</td>
                    <td>{{ corpDetail?.town?.name }}</td>
                </tr>
                <tr>
                    <td>Contact Title</td>
                    <td>{{ corpDetail?.contact_title }}</td>
                </tr>
                <tr>
                    <td>Contact Name Surname</td>
                    <td>{{ corpDetail?.contact_name_surname }}</td>
                </tr>
                <tr>
                    <td>Contact Phone Number</td>
                    <td>{{ corpDetail?.contact_phone_number }}</td>
                </tr>
                <tr>
                    <td>Phone Number</td>
                    <td>{{ corpDetail?.phone_number }}</td>
                </tr>
                <tr>
                    <td>Tax Number</td>
                    <td>{{ corpDetail?.tax_number }}</td>
                </tr>
                <tr>
                    <td>Tax Office</td>
                    <td>{{ corpDetail?.tax_office }}</td>
                </tr>
                <tr>
                    <td>Mail</td>
                    <td>{{ corpDetail?.mail_end }}</td>
                </tr>


            </table>
        </div>


    </div>


</div>