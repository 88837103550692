<div class="status-div" *ngIf="data">
    <div class="content-status">
        <div class="info-title">
            <h1>{{ data.status }}</h1>
        </div>
        <div>
            <h3>Segment</h3>
        </div>
        <div class="info-live">
            <div class="left-align">
                <div>
                    <mat-hint>Müşteri Kodu</mat-hint>
                    <span class="data">{{ data.userId }}</span>
                </div>
                <div>
                    <mat-hint>Tags</mat-hint>
                    <span class="data tags">

                        <div class="tag" *ngFor="let tag of user_data?.tags; let i = index">
                            {{ tag.name }} <span [style]="{'background-color': tag.color}"></span> <div *ngIf="i != user_data?.tags.length - 1">,</div>
                        </div>
                        <div *ngIf="user_data?.tags.length < 1">
                            Yok
                        </div>
                    </span>
                </div>
            </div>
            <div class="right-align">
                <div>
                    <mat-hint>Tarife Bilgisi</mat-hint>
                    <span class="data">{{ data.userStatusInfo }}</span>
                </div>
                <div>
                    <mat-hint>Kampanya</mat-hint>
                    <span class="data">Yok</span>
                </div>
            </div>
        </div>

        <div class="status-buttons">
            <a mat-list-item routerLink="map">
                Book
            </a>
            <a class="driver" mat-list-item routerLink="map">
                Aktif Sürücü -> Kısayol
            </a>
        </div>

    </div>
</div>