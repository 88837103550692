<div class="">
    <div class="mt-20 p-20">

        <table mat-table [dataSource]="logs" class="mat-elevation-z8">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> ID. </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="givenDate">
                <th mat-header-cell *matHeaderCellDef> Given Date </th>
                <td mat-cell *matCellDef="let element"> {{element.givenDate}} </td>
            </ng-container>

            <ng-container matColumnDef="expirationDate">
                <th mat-header-cell *matHeaderCellDef> Expiration Date </th>
                <td mat-cell *matCellDef="let element"> {{element.expirationDate}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="rejectedReasonTitle">
                <th mat-header-cell *matHeaderCellDef> Rejected Reason Title </th>
                <td mat-cell *matCellDef="let element"> {{element.rejectedReasonTitle}} </td>
            </ng-container>

            <ng-container matColumnDef="rejectedReasonExp">
                <th mat-header-cell *matHeaderCellDef> Rejected Reason Exp </th>
                <td mat-cell *matCellDef="let element"> {{element.rejectedReasonExp}} </td>
            </ng-container>

            <ng-container matColumnDef="createdOn">
                <th mat-header-cell *matHeaderCellDef> Created On </th>
                <td mat-cell *matCellDef="let element"> {{element.createdOn}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

    </div>
</div>