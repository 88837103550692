import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-item',
  templateUrl: './driver-item.component.html',
  styleUrls: ['./driver-item.component.css']
})
export class DriverItemComponent implements OnInit {
  @Input() data: any;

  status_name = {
    'new_user': 'New User',
    'missing_document': 'Missing Document',
    'active': 'Active',
    'waitin_approval': 'Waiting Approval',
    'rejected': 'Rejected',
    'blacklist': 'Blacklist',
    'deactive': 'Deactive',
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  itemClick(id: number): void {
    this.router.navigate(['/dashboard/drivers/'+id]);
  }

}
