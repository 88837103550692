<mat-dialog-content>
    <div *ngIf="!rejectData">
        <mat-progress-bar mode="buffer"></mat-progress-bar>
    </div>
    
    <div *ngIf="rejectData"  >
        <p>
            Kullanıcı onaylanmaması durumda red mesajı SMS ve bildirim gidecektir.
        </p>
        <hr>
        <p *ngIf="rejectedForm.value.reason.body">
            Red Mesajı: {{ rejectedForm.value.reason.body }}
        </p>
        <form [formGroup]="rejectedForm" (ngSubmit)="onFormSubmit()" fxLayout="column" fxFlexAlign="stretch">
            <mat-form-field appearance="fill">
                <mat-label>Select an Reject Reason</mat-label>
                <mat-select formControlName="reason" multiple>
                    <mat-option *ngFor="let item of rejectData" [value]="item">{{ item.title }}</mat-option>
                </mat-select>
            </mat-form-field>
            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="warn" [disabled]="!rejectedForm.valid">Submit</button>
            </div>
        </form>
    </div>
</mat-dialog-content>