import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-deactive-users',
  templateUrl: './deactive-users.component.html',
  styleUrls: ['./deactive-users.component.css']
})
export class DeactiveUsersComponent implements OnInit {
  displayedColumns: Array<string> = ["id", "username", "email", "number", "activate"];
  dataSource: any;
  constructor(
    private userService: UsersService,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getDeactiveUsers();
  }

  getDeactiveUsers(): void {
    this.userService.getDeactiveUsers().subscribe(result => {
      if(result.success) {
        this.dataSource = result.data;
      }
    }, this.globalService.handleError)
  }

  userStatusChange(user_id: number, status: string): void {
    this.userService.userActiveChange(user_id, status).subscribe(result => {
      if(result.success) {
        this.getDeactiveUsers();
        return this.mapControl.showSnackbar(result.data.message);
      }
    }, this.globalService.handleError);
  }

}
