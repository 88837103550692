<div class="detail-center">
    <div class="detail-content">
        <mat-progress-bar mode="buffer" *ngIf="!data"></mat-progress-bar>
        <div *ngIf="data">
            <div class="detail-title">
                <h1>{{ data.name }}</h1>
                <div>
                    <button mat-button (click)="edit_corporation(data)">Edit</button>
                    <!-- <a mat-list-item routerLink="edit">
                        Edit
                    </a> -->
                </div>

            </div>
            <div class="detail-list">
                <div>
                    <p> Contact name surname </p>
                    <p>{{ data.contact_name_surname }}</p>
                    <hr>
                </div>

                <div>
                    <p> Phone number </p>
                    <p>{{ data.phone_number }}</p>
                    <hr>
                </div>

                <div>
                    <p> Contact title </p>
                    <p>{{ data.contact_title }}</p>
                    <hr>
                </div>

                <div>
                    <p> Contact phone number </p>
                    <p>{{ data.contact_phone_number }}</p>
                    <hr>
                </div>

                <div>
                    <p> Tax office </p>
                    <p>{{ data.tax_office }}</p>
                    <hr>
                </div>

                <div>
                    <p> Tax number </p>
                    <p>{{ data.tax_number }}</p>
                    <hr>
                </div>

                <div>
                    <p> City </p>
                    <p>{{ data.city?.name }}</p>
                    <hr>
                </div>

                <div>
                    <p> Town </p>
                    <p>{{ data.town?.name }}</p>
                    <hr>
                </div>

                <div>
                    <p> Created on </p>
                    <p>{{ data.created_on }}</p>
                    <hr>
                </div>
            </div>


        </div>
    </div>
</div>