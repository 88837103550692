import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { RejectedReasonService } from 'src/app/services/rejected-reason.service';

@Component({
  selector: 'app-rejected-reason',
  templateUrl: './rejected-reason.component.html',
  styleUrls: ['./rejected-reason.component.css']
})
export class RejectedReasonComponent implements OnInit {
  isDisabled: boolean = true;
  rejectedForm: FormGroup = new FormGroup({
    rejectedId: new FormControl(''),
  });
  rejectData: any;
  reason:string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RejectedReasonComponent>,
    private rejectedService: RejectedReasonService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.rejectedService.getRejectedReasonByLicense(this.data).subscribe((result:any) => {
      if(result.success) {
        this.isDisabled = false;
        
        this.rejectData = result.data;
      }
    }, this.globalService.handleError);
  }

  onFormSubmit() {
    this.dialogRef.close(this.rejectedForm.value);
  }

  matSelectChange(selected:any) {
    this.reason = (this.rejectData.find(x=>x.rejected_reason_id==selected)).rejected_reason;
  }

}
