<div class="panel">
    <div class="panel-list" [ngClass]="{'d-none':!panelListShow}">
        <div class="list-top">
            <h1>Cars</h1>
            <div class="list-query">
                <mat-form-field class="example-form-field" *ngIf="showSearch">
                    <mat-label>Search</mat-label>
                    <input matInput type="text" (input)="onSearchChange($event)">
                    <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="showSearch=false;closeSearch()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <button mat-button *ngIf="!showSearch" (click)="showSearch=true;">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">search</mat-icon>
                </button>

                <!-- <button mat-button color="warn" [matMenuTriggerFor]="menu">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">filter_list</mat-icon>
                </button> -->
                <!-- <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="listSort({id: ['brand', 'name'], value: true})">Brand A-Z</button>
                    <button mat-menu-item (click)="listSort({id: ['brand', 'name'], value: false})">Brand Z-A</button>
                    <button mat-menu-item (click)="listSort({id: ['model', 'name'], value: true})">Model A-Z</button>
                    <button mat-menu-item (click)="listSort({id: ['model', 'name'], value: false})">Model Z-A</button>
                    <button mat-menu-item (click)="listSort({id: ['year'], value: true})">Year Up</button>
                    <button mat-menu-item (click)="listSort({id: ['year'], value: false})">Year Down</button>

                </mat-menu> -->
            </div>
        </div>

        <div class="progress-bar" *ngIf="!cars">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="list-content" *ngIf="cars">
            <div class="list-buttons">
                <button [ngClass]="{'active':listButtonsID == 1}" (click)="listButtons({id: ['status', 'name'], value: 'Open',buttonID:1})">Aktif</button>
                <button [ngClass]="{'active':listButtonsID == 2}" (click)="listButtons({id: ['status', 'name'], value: 'Closed',buttonID:2})">Park Halinde</button>
            </div>
            <div class="list-items">
                <app-car-info *ngFor="let car of cars" [data]="car"></app-car-info>
            </div>
        </div>
    </div>

    <div class="panel-content">
        <div class="content-control">
            <div class="add-button">
                <button mat-button (click)="panelListShow=!panelListShow">
                    <mat-icon>view_quilt</mat-icon>
                </button>
                <button mat-button (click)="addNewVehicle()">
                    Add New
                </button>
            </div>
            <div class="list-buttons">
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/vehicles/{{ selectedID }}" [routerLinkActiveOptions]="{exact: true}">
                    Details
                </a>
                <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/vehicles/{{ selectedID }}/map">
                    Map
                </a>
            </div>
            <button mat-button (click)="statusShow=!statusShow">
                <mat-icon>view_quilt</mat-icon>
            </button>
        </div>
        <div class="panel-dynamic">
            <div class="router-only">
                <router-outlet></router-outlet>
            </div>
            <div [ngClass]="{'d-none':!statusShow}">
                <app-vehicle-status></app-vehicle-status>
            </div>
        </div>
    </div>

</div>
