import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { HttpClientModule } from '@angular/common/http'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { VehicleEditComponent } from '../modules/vehicles/vehicle-edit/vehicle-edit.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AgmCoreModule } from '@agm/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { SettingsComponent } from '../modules/settings/settings.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { UserSegmentTypeComponent } from '../modules/settings/user-segment-type/user-segment-type.component';
import { UserSegmentTypeEditComponent } from '../modules/settings/user-segment-type/user-segment-type-edit/user-segment-type-edit.component';
import { CorporationUserDetail } from '../modules/corporation/corporation-users/corporation-users.component';
import { VehicleStatusComponent } from '../modules/vehicles/vehicle-status/vehicle-status.component';
import { VehiclesComponent } from '../modules/vehicles/vehicles.component';
import { CarStatusComponent } from '../modules/vehicles/car-status/car-status.component';
import { CarInfoComponent } from '../modules/vehicles/car-info/car-info.component';
import { VehicleMapComponent } from '../modules/vehicles/vehicle-map/vehicle-map.component';
import { ParkLocationsItemComponent } from '../modules/park-locations/park-locations-item/park-locations-item.component';
import { ParkLocationsStatusComponent } from '../modules/park-locations/park-locations-status/park-locations-status.component';
import { ParkLocationsMapComponent } from '../modules/park-locations/park-locations-map/park-locations-map.component';
import { ParkLocationsEditComponent } from '../modules/park-locations/park-locations-edit/park-locations-edit.component';
import { ParkLocationsComponent } from '../modules/park-locations/park-locations.component';
import { ParkLocationsDetailComponent } from '../modules/park-locations/park-locations-detail/park-locations-detail.component';
import { ChargeStationsComponent } from '../modules/charge-stations/charge-stations.component';
import { ChargeStationsItemComponent } from '../modules/charge-stations/charge-stations-item/charge-stations-item.component';
import { ChargeStationsMapComponent } from '../modules/charge-stations/charge-stations-map/charge-stations-map.component';
import { ChargeStationsEditComponent } from '../modules/charge-stations/charge-stations-edit/charge-stations-edit.component';
import { ChargeStationsStatusComponent } from '../modules/charge-stations/charge-stations-status/charge-stations-status.component';
import { ChargeStationsDetailComponent } from '../modules/charge-stations/charge-stations-detail/charge-stations-detail.component';
import { VehicleImageFull } from '../modules/vehicles/vehicle-images/vehicle-images.component';
import { DriverDocumentsSelfie } from '../modules/drivers/driver-documents/driver-documents.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VehicleEditComponent,
    SettingsComponent,
    UserSegmentTypeComponent,
    UserSegmentTypeEditComponent,
    CorporationUserDetail,
    VehicleStatusComponent,
    CarInfoComponent,
    CarStatusComponent,
    VehiclesComponent,
    VehicleMapComponent,
    ParkLocationsItemComponent,
    ParkLocationsStatusComponent,
    ParkLocationsMapComponent,
    ParkLocationsEditComponent,
    ParkLocationsComponent,
    ParkLocationsDetailComponent,
    ChargeStationsComponent,
    ChargeStationsItemComponent,
    ChargeStationsMapComponent,
    ChargeStationsEditComponent,
    ChargeStationsStatusComponent,
    ChargeStationsDetailComponent,
    VehicleImageFull,
    DriverDocumentsSelfie,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDividerModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatListModule,
    MatTableModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSidenavModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXzv6AxxMGrfO7dp__z9ueIfg92N7Ebko',
      libraries: ['geometry']
    }),
    
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MatIconModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatMenuModule,
    MatTableModule,
    HttpClientModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    AgmCoreModule,
  ],
  providers: [

  ]
})
export class SharedModule { }
