import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { ReservationDetailComponent } from '../reservation-detail/reservation-detail.component';
import { AddReservationRefundComponent } from './add-reservation-refund/add-reservation-refund.component';

@Component({
  selector: 'app-reservation-refund-list',
  templateUrl: './reservation-refund-list.component.html',
  styleUrls: ['./reservation-refund-list.component.css']
})
export class ReservationRefundListComponent implements OnInit {

  displayedColumns: Array<string> = ["id", "reservationId", "refundTotal", "refundComplate", "createdBy", "createdTime", "pay", "asecco_error_message"];
  dataSource: any;
  constructor(
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getRefundList();
  }

  getRefundList(): void {
    this.reservationService.getRefundList().subscribe(result => {
      if (result.success) {
        this.dataSource = result.data;
      }
    }, this.globalService.handleError);
  }

  reservationDetail(id: number): void {
    this.dialog.open(ReservationDetailComponent, {
      maxHeight: '95vh',
      data: id
    });
  }

  reservationPay(id: number): void {
    this.reservationService.refundPay(id).subscribe((result: any) => {
      this.getRefundList();
    }, error => {
      this.getRefundList();
      this.globalService.handleError(error)
    });

  }

  add_new(): void {
    this.dialog.open(AddReservationRefundComponent, {
      width: "400px",
      autoFocus: false
    }).afterClosed().subscribe((data) => {
      if(data) {
        this.getRefundList();
      }
    })
  }
}
