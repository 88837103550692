import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent implements OnInit {
  logs: any;
  displayedColumns: Array<any> = ["id","name","givenDate","expirationDate","status","rejectedReasonTitle","rejectedReasonExp","createdOn"];
  user_id: any;
  constructor(    
    private route: ActivatedRoute,
    private userService: UsersService,
    private globalService: GlobalService
  ) { } 

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.user_id = uri.id;
      this.getRes();
    });
  }

  getRes(): void {
    this.userService.getUserLogs(this.user_id).subscribe(result => {
      this.logs = result.data;
    }, this.globalService.handleError);
  }

}
