<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Add New Reservation</h2>
        <form [formGroup]="reservationForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>User *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="User" matInput formControlName="user" [matAutocomplete]="autoUser">
                <mat-autocomplete #autoUser="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredUser | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Start Destination *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Start Destination" matInput formControlName="start_destination" [matAutocomplete]="autoStartDestination">
                <mat-autocomplete #autoStartDestination="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredStartDestination | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            
            <mat-form-field>
                <input matInput type="datetime-local" placeholder="Start Time *" formControlName="start_time">
            </mat-form-field>

            <mat-form-field>
                <input matInput type="datetime-local" placeholder="End Time *" formControlName="end_time">
            </mat-form-field>

            
            <!-- <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>End Destination *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="End Destination" matInput formControlName="end_destination" [matAutocomplete]="autoEndDestination">
                <mat-autocomplete #autoEndDestination="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredEndDestination | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->
            

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Vehicle * </mat-label>
                <input type="text" placeholder="Pick one" aria-label="Vehicle" matInput formControlName="vehicle" [matAutocomplete]="autoVehicle" >
                <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredVehicles | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Payment Card * </mat-label>
                <input type="text" placeholder="Pick one" aria-label="Card" matInput formControlName="payment_card" [matAutocomplete]="autoPaymentCard" >
                <mat-autocomplete #autoPaymentCard="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredPaymentCards | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->
<!-- 
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Campaign Code</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Campaign Code" matInput formControlName="campaign_code" [matAutocomplete]="autoCampaignCode" >
                <mat-autocomplete #autoCampaignCode="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredCampaignCodes | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->
<!-- 
            <mat-form-field>
                <input matInput type="text" placeholder="Discounted Reason" formControlName="discounted_reason">
            </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>User Corporate</mat-label>
                <input type="text" placeholder="Pick one" aria-label="User Corporate" matInput formControlName="user_corporate" [matAutocomplete]="autoUserCorporate">
                <mat-autocomplete #autoUserCorporate="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredUserCorporate | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Status *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Status" matInput formControlName="status" [matAutocomplete]="autoStatus">
                <mat-autocomplete #autoStatus="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredStatus | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field> -->

            <!-- <mat-form-field appearance="fill">
                <mat-label>Amount to be paid *</mat-label>
                <input [ngClass]="{'line' : reservationForm.get('user_corporate').value}" matInput type="number" formControlName="total_price">
            </mat-form-field> -->


            <mat-form-field>
                <mat-label>Note</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="note"></textarea>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!reservationForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>