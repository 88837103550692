<mat-toolbar color="primary">
    <mat-toolbar-row>
        <div class="site-logo">
            <img src="assets/images/logo.png" alt="">
            <span class="line"></span>
            <span>Control Center</span>
        </div>

        <div fxFlex fxLayoutAlign="flex-end">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li>
                    <button mat-icon-button routerLink="/dashboard/signal">
                        <mat-icon>notifications</mat-icon>
                    </button>
                </li>


                <li>
                    <button mat-icon-button routerLink="/dashboard/settings">
                        <mat-icon>settings</mat-icon>
                    </button>
                </li>

                <li>
                    <button mat-icon-button>
                        <mat-icon>help_outline</mat-icon>
                    </button>
                </li>

                <li>
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>person_outline</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="logOut()">Logout</button>
                    </mat-menu>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>


</mat-toolbar>