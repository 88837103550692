import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { BASEURL, baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(private http: HttpClient) { }

  getPriceList(): Observable<any> {
    var url = BASEURL + "/price/getPricingList";
    return this.http.get(url, { headers: this.headers });
  }

  savePrice(form: FormGroup): Observable<any> {
    var url = BASEURL + "/price/savePrice";
    return this.http.post(url, form.value, { headers: this.headers });
  }

  getSavePriceDetails(): Observable<any> {
    var url = BASEURL + "/price/getSavePriceDetails";
    return this.http.get(url, { headers: this.headers });
  }

  getPriceDetail(id: number): Observable<any> {
    var url = `${BASEURL}/price/getPriceDetail/${id}`;
    return this.http.get(url, { headers: this.headers });
  }

  deletePrice(id: number): Observable<any> {
    var url = `${BASEURL}/price/deletePrice/${id}`;
    return this.http.get(url, { headers: this.headers });
  }

  getVehicleGroupList(): Observable<any> {
    var url = BASEURL + "/price/getVehicleGroupList";
    return this.http.get(url, { headers: this.headers });
  }

  deleteVehicleGroup(id: number): Observable<any> {
    var url = `${BASEURL}/price/deleteVehicleGroup/${id}`;
    return this.http.get(url, { headers: this.headers });
  }

  getModelList(): Observable<any> {
    var url = BASEURL + "/price/getModelList";
    return this.http.get(url, { headers: this.headers });
  }
  
  saveVehicleGroup(form: FormGroup): Observable<any> {
    var url = BASEURL + "/price/saveVehicleGroup";
    return this.http.post(url, form.value, { headers: this.headers });
  }

  getCorporateGroupList(): Observable<any> {
    var url = BASEURL + "/price/getCorporateGroupList";
    return this.http.get(url, { headers: this.headers });
  }

  deleteCorporateGroup(id: number): Observable<any> {
    var url = `${BASEURL}/price/deleteCorporateGroup/${id}`;
    return this.http.get(url, { headers: this.headers });
  }

  saveCorporateGroup(form: FormGroup): Observable<any> {
    var url = BASEURL + "/price/saveCorporateGroup";
    return this.http.post(url, form.value, { headers: this.headers });
  }

  getCorporateList(): Observable<any> {
    var url = BASEURL + "/price/getCorporateList";
    return this.http.get(url, { headers: this.headers });
  }
  
}
