import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { CarService } from 'src/app/services/triggers/car.service';

@Component({
  selector: 'app-park-location-vehicles-add',
  templateUrl: './park-location-vehicles-add.component.html',
  styleUrls: ['./park-location-vehicles-add.component.css']
})
export class ParkLocationVehiclesAddComponent implements OnInit {
  filteredVehicles: Observable<any>;
  vehicleForm = new FormGroup({
    vehicle: new FormControl(null)
  })
  constructor(
    private parkingService: ParkingPlacesService,
    private dialogRef: MatDialogRef<ParkLocationVehiclesAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private vehicleService: CarService,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.vehicleService.getVehicleList().subscribe((result) => {
      if(result.success) {
        this.filteredVehicles = this.vehicleForm.get('vehicle').valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value, result?.data))
        );
      }
    }, this.globalService.handleError);
  }

  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

  onSubmit(): void {
    var vehicle_id = this.vehicleForm.get('vehicle').value.id;
    this.parkingService.parkingPlaceSetVehicle(this.data, vehicle_id).subscribe((result:any) => {
      this.dialogRef.close();
    }, this.globalService.handleError);
  }

}
