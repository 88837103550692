import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, startWith } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { VehicleService } from 'src/app/services/vehicle.service';

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.css']
})
export class AddImageComponent implements OnInit {
  imageUrl: string;
  imageFile: File;
  billingForm: FormGroup = new FormGroup({
    reservation: new FormControl(null),
    note: new FormControl(''),
    type: new FormControl('')
  });

  filteredReservation: any = null;
  


  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    private vehicleService: VehicleService,
    private dialogRef: MatDialogRef<AddImageComponent>,
    private globalService: GlobalService,
    private reservationService: ReservationsService
  ) { 
  }

  ngOnInit(): void {
    this.reservationService.reservationListName().subscribe(result => {
      this.filteredReservation = this.billingForm.get('reservation').valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value, result?.data))
      );
    }, this.globalService.handleError);
  }

  imageChange(file: FileList): void {
    var reader = new FileReader();
    reader.onload = (event:any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(file.item(0));
    this.imageFile = file.item(0);
  }

  saveImage(): void {
    this.vehicleService.saveVehicleImage(this.imageFile, this.data, this.billingForm.value).subscribe((result:any) => {
      this.dialogRef.close("ok");
    }, this.globalService.handleError); 
  }


  displayFn(data: any): string {
    return data?.name ? data.name : '';
  }

  private _filter(value: any, data: any): string[] {
    const filterValue = value.name ? value.name.toLowerCase():value.toLowerCase();

    return data.filter(option => option?.name?.toLowerCase().includes(filterValue));
  }

}
