import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BASEURL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorporateCardService {

  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });

  url = BASEURL + "/userLicense/";

  constructor(
    private http: HttpClient,
  ) { }


  getCorporateCards(paginator: any): Observable<any> {
    return this.http.get<any>(`${this.url}getCorporateCards`, { headers: this.headers, params: { pageSize: paginator.pageSize, page: paginator.pageIndex + 1 } });
  }

  setCorporateCardApproved(doc_id: number, corp_id: number): Observable<any> {
    return this.http.post<any>(`${this.url}setCorporateCardApproved/${doc_id}`, { corp_id }, { headers: this.headers });
  }

  setCorporateCardReject(doc_id: number, reject_reasons: any): Observable<any> {
    return this.http.post(this.url + "setCorporateCardReject/" + doc_id, { reasons: reject_reasons }, { headers: this.headers });
  }
}
