import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private http: HttpClient) {}

  getImage(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'blob'})
      .pipe(
        map(blob => {
          const imageUrl = URL.createObjectURL(blob);
          return imageUrl;
        })
      );
  }
}
