<mat-nav-list>
    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard"
            [routerLinkActiveOptions]="{exact: true}">
            <svg style="width: 40px;" version="1.0" xmlns="http://www.w3.org/2000/svg" width="55.000000pt"
                height="51.000000pt" viewBox="0 0 150.000000 150.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                    <path
                        d="M120 1013 c-108 -56 -104 -174 12 -355 40 -64 59 -62 104 8 108 168 114 268 23 337 -37 27 -97 32 -139 10z m115 -86 c52 -75 -53 -149 -107 -76 -26 35 -15 77 25 94 36 15 63 9 82 -18z" />
                    <path
                        d="M613 1018 c-14 -7 -30 -31 -42 -62 -15 -42 -24 -52 -47 -56 -34 -6 -45 -38 -19 -56 14 -10 17 -32 18 -121 l2 -108 45 0 c42 0 45 2 48 28 l3 27 135 0 134 0 0 -31 c0 -29 1 -30 43 -27 l42 3 2 108 c1 89 4 111 18 121 25 18 15 50 -17 54 -22 3 -30 11 -43 53 -25 73 -39 79 -180 78 -66 0 -130 -5 -142 -11z m249 -50 c14 -12 38 -68 38 -89 0 -5 -67 -9 -150 -9 -82 0 -150 4 -150 9 0 21 24 77 38 89 10 8 50 12 112 12 62 0 102 -4 112 -12z m-194 -196 c3 -20 -1 -22 -38 -22 -44 0 -62 19 -41 44 18 21 75 6 79 -22z m242 3 c0 -22 -4 -25 -40 -25 -33 0 -40 3 -40 19 0 22 13 29 53 30 22 1 27 -3 27 -24z" />
                    <path
                        d="M1265 1016 c-68 -31 -99 -93 -84 -169 15 -82 106 -237 138 -237 39 0 141 196 141 270 0 105 -105 178 -195 136z m108 -88 c9 -11 17 -30 17 -41 0 -30 -39 -67 -71 -67 -32 0 -69 34 -69 63 0 67 78 96 123 45z" />
                    <path
                        d="M173 524 c-10 -27 11 -49 52 -52 64 -6 87 4 83 36 -3 26 -6 27 -66 30 -51 2 -63 0 -69 -14z" />
                    <path
                        d="M431 526 c-9 -11 -10 -20 -1 -35 9 -17 20 -21 65 -21 45 0 56 4 65 21 9 15 8 24 -1 35 -16 19 -112 19 -128 0z" />
                    <path
                        d="M683 524 c-12 -32 11 -49 67 -49 56 0 79 17 67 49 -5 12 -20 16 -67 16 -47 0 -62 -4 -67 -16z" />
                    <path d="M939 524 c-23 -28 1 -49 62 -52 61 -4 87 15 68 49 -13 25 -110 27 -130 3z" />
                    <path
                        d="M1197 533 c-13 -12 -7 -51 9 -57 9 -3 35 -6 59 -6 48 0 70 18 60 50 -5 17 -15 20 -64 20 -32 0 -61 -3 -64 -7z" />
                </g>
            </svg>
            <span>Trips</span>
        </a>
    </div>

    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/vehicles">
            <mat-icon mat-list-icon>directions_car</mat-icon>
            <span>Cars</span>
        </a>
    </div>

    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/drivers">
            <svg style="width: 30px; margin:8px 0px" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="55.000000pt" height="51.000000pt" viewBox="0 0 150.000000 150.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                    <path d="M380 1462 c-107 -53 -107 -211 0 -264 113 -57 240 48 207 172 -23 90 -124 134 -207 92z" />
                    <path
                        d="M1132 1193 c-16 -14 -33 -75 -76 -265 -31 -136 -53 -254 -50 -262 8 -21 52 -32 73 -19 9 6 23 40 31 76 8 36 17 68 20 71 3 2 43 -57 89 -132 112 -182 127 -202 155 -202 30 0 50 24 44 53 -2 13 -61 115 -130 228 l-127 204 26 114 26 114 -23 19 c-28 22 -33 22 -58 1z" />
                    <path
                        d="M385 1114 c-76 -18 -115 -58 -115 -119 0 -20 16 -131 35 -247 34 -198 52 -258 79 -258 16 0 196 283 196 310 0 17 4 21 18 15 149 -65 298 -59 371 14 40 40 17 111 -36 111 -11 0 -33 -10 -48 -22 -57 -45 -186 -31 -266 28 -19 13 -51 50 -72 81 -21 32 -46 63 -56 69 -20 15 -78 24 -106 18z" />
                    <path
                        d="M96 974 c-22 -22 -21 -47 20 -265 43 -233 61 -285 120 -343 72 -72 132 -90 294 -91 165 0 207 8 222 40 13 29 3 71 -20 84 -11 6 -58 6 -127 -1 -178 -17 -268 12 -312 102 -13 27 -37 125 -59 245 -20 110 -43 210 -50 223 -17 26 -65 30 -88 6z" />
                    <path
                        d="M546 570 c-31 -49 -56 -92 -56 -95 0 -2 69 -6 152 -7 l153 -3 55 -190 c31 -104 62 -200 69 -213 13 -25 48 -42 88 -42 30 0 93 59 93 87 0 10 -33 130 -74 268 -56 188 -79 254 -96 267 -19 16 -43 18 -176 18 l-153 0 -55 -90z" />
                </g>
            </svg>
            <span>Drivers</span>
        </a>
    </div>

    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/park-locations">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="55.000000pt" height="51.000000pt"
                viewBox="0 0 150.000000 150.000000" preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                    <path
                        d="M675 1211 c-22 -10 -48 -27 -57 -37 -25 -29 -48 -89 -48 -127 0 -49 39 -118 83 -146 l37 -24 0 -184 0 -183 60 0 60 0 0 183 0 184 38 24 c44 28 82 98 82 149 0 41 -29 108 -56 132 -29 25 -89 48 -126 48 -18 0 -51 -9 -73 -19z m115 -121 c11 -11 20 -29 20 -40 0 -11 -9 -29 -20 -40 -11 -11 -29 -20 -40 -20 -11 0 -29 9 -40 20 -11 11 -20 29 -20 40 0 11 9 29 20 40 11 11 29 20 40 20 11 0 29 -9 40 -20z" />
                    <path
                        d="M455 796 c-16 -7 -40 -25 -53 -38 -29 -31 -132 -288 -132 -328 0 -48 24 -95 66 -126 l37 -29 364 -3 c405 -3 409 -3 460 64 46 60 42 101 -21 258 -30 77 -66 150 -78 164 -30 32 -78 52 -128 52 l-40 0 0 -60 0 -60 35 0 c43 0 50 -10 106 -153 33 -84 40 -112 32 -125 -9 -15 -43 -17 -353 -17 -310 0 -344 2 -353 17 -8 13 -1 41 32 125 56 143 63 153 106 153 l35 0 0 60 0 60 -42 0 c-24 -1 -56 -7 -73 -14z" />
                </g>
            </svg>
            <span>Locations</span>
        </a>
    </div>


    <!-- <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/pricing">
            <svg style="width: 30px; margin:8px 0px" version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="55.000000pt" height="51.000000pt" viewBox="0 0 150.000000 150.000000"
                preserveAspectRatio="xMidYMid meet">
                <g transform="translate(0.000000,150.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
                    <path
                        d="M766 1325 c-31 -11 -107 -81 -328 -302 -158 -158 -295 -302 -303 -319 -18 -38 -20 -106 -4 -137 16 -31 399 -415 431 -433 35 -18 102 -18 142 1 17 8 163 147 323 308 225 226 293 300 300 327 9 32 -12 293 -32 398 -8 42 -67 108 -108 121 -18 6 -99 17 -182 26 -82 8 -161 17 -175 19 -14 2 -43 -2 -64 -9z m242 -94 c136 -15 167 -22 184 -39 17 -17 24 -48 39 -184 10 -89 18 -176 19 -193 0 -25 -38 -68 -287 -317 -158 -159 -296 -291 -306 -294 -42 -13 -76 12 -248 182 -184 182 -216 224 -206 265 4 13 135 153 295 312 249 248 292 287 317 287 17 -1 104 -9 193 -19z" />
                    <path
                        d="M858 1080 c-54 -29 -81 -74 -86 -139 -4 -65 19 -109 78 -149 71 -48 194 -13 226 64 47 112 -19 232 -133 241 -34 2 -58 -2 -85 -17z m136 -100 c12 -12 20 -33 20 -55 0 -42 -26 -72 -71 -81 -27 -5 -37 -1 -63 24 -18 19 -30 41 -30 57 0 16 12 38 30 57 26 25 36 29 63 24 18 -4 41 -15 51 -26z" />
                    <path
                        d="M488 733 c-63 -65 -120 -128 -126 -140 -16 -29 -6 -52 26 -60 23 -5 40 8 154 125 124 127 142 154 116 180 -28 28 -55 11 -170 -105z" />
                    <path
                        d="M668 551 c-98 -98 -128 -134 -128 -154 0 -29 32 -53 54 -40 33 19 256 263 256 280 0 23 -18 43 -39 43 -9 0 -73 -58 -143 -129z" />
                </g>
            </svg>
            <span>Pricing</span>
        </a>
    </div> -->

    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/additional-billing">
            <mat-icon> attach_money</mat-icon>
            <span>Billing</span>
        </a>
    </div>


    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/license">
            <mat-icon> assignment_ind</mat-icon>
            <span>License</span>
        </a>
    </div>



    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/selfie">
            <mat-icon> assignment_ind</mat-icon>
            <span>Selfie</span>
        </a>
    </div>

    <div class="router-item">
        <a class="router-link" routerLinkActive="list-item-active" routerLink="/dashboard/corporation">
            <mat-icon> apartment</mat-icon>
            <span>Corporation</span>
        </a>
    </div>


    <!-- <div class="nav-list-item">
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/other-documents">
            <mat-icon mat-list-icon>assignment_ind</mat-icon>
            <span>Other</span>
        </a>
    </div> -->

    <!-- <div class="nav-list-item">
        <a mat-list-item routerLinkActive="list-item-active" routerLink="/dashboard/more">
            <mat-icon mat-list-icon>more_horiz</mat-icon>
            <span>More</span>
        </a>
    </div> -->


</mat-nav-list>