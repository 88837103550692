import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdditionalBillingService {
  access_token = localStorage.getItem('access_token');
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${this.access_token}`
  });
  constructor(
    public http: HttpClient
  ) { }

  getAdditionalBillings(page: number, size: number, filter: string): Observable<any> {
    var url = baseUrl + "payment/additionalBilling";
    const params = new HttpParams().set("page", page.toString()).set("size", size.toString()).set("filter", filter);
    return this.http.get<any>(url, { headers: this.headers, params: params });
  }

  getAdditionalBilling(id: number): Observable<any> {
    var url = baseUrl + "payment/additionalBilling/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  setAdditionalBillings(body: any): Observable<any> {
    var url = baseUrl + "payment/additionalBillingAdd";
    return this.http.post<any>(url, body, { headers: this.headers });
  }

  removeItemFromBilling(id: number, item_id: number): Observable<any> {
    var url = baseUrl + "payment/additionalBillingRemoveItem/" + id;
    return this.http.post<any>(url, {"item_id": item_id}, { headers: this.headers });
  }

  addItemForBilling(id: number, body: FormGroup): Observable<any> {
    var url = baseUrl + "payment/additionalBillingAddItem/" + id;
    return this.http.post<any>(url, body.value, { headers: this.headers });
  }

  sendBillingToSap(id: number): Observable<any> {
    var url = baseUrl + "payment/additionalBillingSAP/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  sendBillingToAsecco(id: number): Observable<any> {
    var url = baseUrl + "payment/additionalBillingAsecco/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  deleteBilling(id: number): Observable<any> {
    var url = baseUrl + "payment/deleteAdditionalBilling/" + id;
    return this.http.get<any>(url, { headers: this.headers });
  }

  getExcel(): Observable<any> {
    var url = baseUrl + "payment/additional_billing_excel";
    return this.http.get<any>(url, { headers: this.headers });
  } 

  downloadExcel(file_name: string): Observable<any> {
    var url = baseUrl + "payment/additional_billing_excel_download";
    return this.http.post(url, { file_name }, { headers: this.headers, responseType: 'blob' });
  }

}
