
<form [formGroup]="vehicleForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Vehicle * </mat-label>
        <input type="text" placeholder="Pick one" aria-label="Vehicle" matInput formControlName="vehicle" [matAutocomplete]="autoVehicle">
        <mat-autocomplete #autoVehicle="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of filteredVehicles | async" [value]="option">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <button type="submit" mat-raised-button color="primary" >Submit</button>
</form>