<div class="">
    <div class="mt-20 p-20">
        <div class="top">
            <div>
                <button mat-raised-button color="primary" (click)="addNewBilling()">Add New</button>
                <button mat-raised-button color="primary" (click)="excelDownload()">{{ excel_progress }}</button>
            </div>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Select Type</mat-label>
                    <mat-select (selectionChange)="billingStatusChange($event.value)">
                        <mat-option value="">All</mat-option>
                        <mat-option value="ARAC_KIRALAMA">ARAC KIRALAMA</mat-option>
                        <mat-option value="OTOYOL_GECİS">OTOYOL GECİS</mat-option>
                        <mat-option value="TRAFİK_CEZASI">TRAFİK CEZASI</mat-option>
                        <mat-option value="ARAC_HASAR">ARAC HASAR</mat-option>
                        <mat-option value="DIGER_CEZALAR">DIGER CEZALAR</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="billing_data"  class="">

                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef > ID </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>

                <ng-container matColumnDef="reservation">
                    <th mat-header-cell *matHeaderCellDef > Reservation ID </th>
                    <td mat-cell *matCellDef="let element">{{element.reservation}}</td>
                </ng-container>

                <ng-container matColumnDef="order_id">
                    <th mat-header-cell *matHeaderCellDef > Order ID </th>
                    <td mat-cell *matCellDef="let element">{{element.orderId}}</td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef > User Name </th>
                    <td mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>

                <ng-container matColumnDef="corporate_name">
                    <th mat-header-cell *matHeaderCellDef > Corporate </th>
                    <td mat-cell *matCellDef="let element">{{element.corporate_name}}</td>
                </ng-container>

                <ng-container matColumnDef="using_type">
                    <th mat-header-cell *matHeaderCellDef > Using Type </th>
                    <td mat-cell *matCellDef="let element">{{element.using_type}}</td>
                </ng-container>

                <ng-container matColumnDef="vehicle">
                    <th mat-header-cell *matHeaderCellDef > Plate Number </th>
                    <td mat-cell *matCellDef="let element">{{element.vehicle.plate}}</td>
                </ng-container>
                
                <ng-container matColumnDef="billing_items">
                    <th mat-header-cell *matHeaderCellDef > Item Count </th>
                    <td mat-cell *matCellDef="let element"> {{element.billingItems.length}} </td>
                </ng-container>
                
                <ng-container matColumnDef="billing_price">
                    <th mat-header-cell *matHeaderCellDef > Total Price </th>
                    <td mat-cell *matCellDef="let element"> {{element.price}} </td>
                </ng-container>
                
                <ng-container matColumnDef="billing_date">
                    <th mat-header-cell *matHeaderCellDef > Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.billingDate}} </td>
                </ng-container>
                
                <ng-container matColumnDef="sending_sap">
                    <th mat-header-cell *matHeaderCellDef > SAP </th>
                    <td mat-cell *matCellDef="let element"> {{element.sendingSap || 'None'}} </td>
                </ng-container>
                
                <ng-container matColumnDef="sending_asecco">
                    <th mat-header-cell *matHeaderCellDef > Asecco </th>
                    <td mat-cell *matCellDef="let element"> {{element.sendingAsecco || 'None'}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="tableClick(row.id)"></tr>
            </table>

            <div>
                <mat-paginator 
                    [length]="length" 
                    [pageSize]="pageSize" 
                    [pageSizeOptions]="pageSizeOptions" 
                    (page)="pageEvent = getDataPagination($event)"
                ></mat-paginator>
            </div>

        </div>
    </div>
</div>