<mat-dialog-content>


    
    <div class="images" fxFlex fxLayout="row wrap" fxLayoutAlign="center start" fxLayoutGap="20px" >
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_inside_back"></mat-progress-bar>
            <img class="image-zoom" [src]="image_inside_back"  (click)="showImage(image_inside_back)">
            <p>Ön Arka</p>
        </div>
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_inside_front"></mat-progress-bar>
            <img class="image-zoom" [src]="image_inside_front"  (click)="showImage(image_inside_front)">
            <p>Ön İleri</p>
        </div>
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_outside_back"></mat-progress-bar>
            <img class="image-zoom" [src]="image_outside_back"  (click)="showImage(image_outside_back)">
            <p>Dış Arka</p>
        </div>
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_outside_front"></mat-progress-bar>
            <img class="image-zoom" [src]="image_outside_front"  (click)="showImage(image_outside_front)">
            <p>Dış Ön</p>
        </div>
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_outside_left"></mat-progress-bar>
            <img class="image-zoom" [src]="image_outside_left"  (click)="showImage(image_outside_left)">
            <p>Dış Sağ</p>
        </div>
        <div>
            <mat-progress-bar mode="buffer" *ngIf="!image_outside_right"></mat-progress-bar>
            <img class="image-zoom" [src]="image_outside_right"  (click)="showImage(image_outside_right)">
            <p>Dış Sol</p>
        </div>

    </div>
</mat-dialog-content>







