import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from 'src/app/services/global.service';
import { StudentCardService } from 'src/app/services/users/documents/student-card.service';
import { StudentCardDetailComponent } from './student-card-detail/student-card-detail.component';

@Component({
  selector: 'app-student-card',
  templateUrl: './student-card.component.html',
  styleUrls: ['./student-card.component.css']
})
export class StudentCardComponent implements OnInit {
  dataSource: any;
  isLoading: boolean = true;
  displayedColumns: Array<any> = ["id", "name", "surname", "tc", "phone", "start_date", "graduation_date"];
  pageEvent: PageEvent = {
    previousPageIndex: 0,
    pageSize: 10,
    pageIndex: 0,
    length: 0,
  };

  @ViewChild(MatPaginator) paginator: MatPaginatorIntl | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private studentCardService: StudentCardService,
    private globalService: GlobalService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(): void {
    this.isLoading = true;
    this.studentCardService.getStudentCards(this.pageEvent).subscribe({
      next: (result: any) => {
        this.isLoading = false;
                
        this.dataSource = new MatTableDataSource<any>(result.data);
        this.dataSource.sort = this.sort;
        this.pageEvent = { ...this.pageEvent, length: result.pagination.count };
      },
      error: this.globalService.handleError
    })
  }

  paginatorChange(event: PageEvent): void {
    localStorage.setItem('studentCardPaginatorOptions', JSON.stringify(event));
    this.pageEvent = event;
    this.getData();
  }

  tableClick(row: any): void {
    this.dialog.open(StudentCardDetailComponent, {
      autoFocus: false,
      width: "500px",
      maxWidth: "90%",
      data: row
    }).afterClosed().subscribe({
      next: (res: any) => {
        if(res) this.getData();
      }
    });
  }

}
