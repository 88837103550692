import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentsService } from 'src/app/services/documents.service';
import { GlobalService } from 'src/app/services/global.service';
import { RejectUserDocumentComponent } from '../reject-user-document/reject-user-document.component';

@Component({
  selector: 'app-user-document-selfie',
  templateUrl: './user-document-selfie.component.html',
  styleUrls: ['./user-document-selfie.component.css']
})
export class UserDocumentSelfieComponent implements OnInit {
  @Input() user_id: number;
  @Output() status = new EventEmitter<Object>();
  data: any;

  userSelfie: any;
  userSelfieValue: number;
  userSelfieMode: string = 'indeterminate';

  constructor(
    private documentService: DocumentsService,
    private _sanitizer: DomSanitizer,
    public globalService: GlobalService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.documentService.getUserSelfie(this.user_id).subscribe((result) => {
      
      this.data = result.data;

      this.documentService.getMFile(result.data.document_guid_selfie).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.userSelfieMode = 'determinate';
          this.userSelfieValue = Math.round(100 * event.loaded / event.total);
        }
        if (event.type === HttpEventType.Response) {
          let reader = new FileReader();
          reader.addEventListener("load", () => {
            this.userSelfie = reader.result;
          }, false);
          reader.readAsDataURL(event.body);
        }
      });

    }, this.globalService.handleError);
  }

  
  panelAccept(): void {
    this.documentService.setSelfieStatus(this.data.id, 'Approved').subscribe(result => {
        this.data = result.data;
        this.status.emit({process: 'selfie', status: 'Approved'});
    }, this.globalService.handleError);
  }

  panelReject(): void {
    this.dialog.open(RejectUserDocumentComponent, {
      data: {
        model: 'UserPhoto',
        selected: this.data.selfie_rejected_reason
      },
      width: '500px',
      maxWidth: '90%'
    }).afterClosed().subscribe(res => {
      if(res === undefined) return;
      
      this.documentService.setSelfieRejected(this.data.id, res).subscribe(result => {
        this.data = result.data;
        this.status.emit({process: 'selfie', status: 'Rejected'});
      }, this.globalService.handleError);

      console.log(res);
    });
  }

}
