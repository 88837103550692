<div class="hgscontainer">
    <button class="add-new" mat-raised-button color="primary" (click)="add_new()">Add New</button>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="reservationId">
            <th mat-header-cell *matHeaderCellDef> Reservation </th>
            <td mat-cell *matCellDef="let element">
                <a (click)="reservationDetail(element.reservationId)">Reservation</a> </td>
        </ng-container>

        <ng-container matColumnDef="refundTotal">
            <th mat-header-cell *matHeaderCellDef> Refund Total </th>
            <td mat-cell *matCellDef="let element"> {{element.refundTotal }} </td>
        </ng-container>

        <ng-container matColumnDef="refundComplate">
            <th mat-header-cell *matHeaderCellDef> Refund Complate </th>
            <td mat-cell *matCellDef="let element"> 
                <mat-icon *ngIf="element.refundComplate">check</mat-icon>
                <mat-icon *ngIf="!element.refundComplate">close</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <th mat-header-cell *matHeaderCellDef> Created By </th>
            <td mat-cell *matCellDef="let element"> {{element.createdBy.name}} </td>
        </ng-container>

        <ng-container matColumnDef="createdTime">
            <th mat-header-cell *matHeaderCellDef> Created Time </th>
            <td mat-cell *matCellDef="let element"> {{element.createdTime}} </td>
        </ng-container>

        <ng-container matColumnDef="pay">
            <th mat-header-cell *matHeaderCellDef> Pay </th>
            <td mat-cell *matCellDef="let element"> <button mat-raised-button color="primary" (click)="reservationPay(element.id)" *ngIf="!element.refundComplate">Pay</button></td>
        </ng-container>

        <ng-container matColumnDef="asecco_error_message">
            <th mat-header-cell *matHeaderCellDef> Asecco Error Message </th>
            <td mat-cell *matCellDef="let element"> {{element.aseccoErrorMessage}} </td>
        </ng-container>

        
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

</div>