import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CorporationService {
  access_token: string;
  url: string;
  constructor(private http: HttpClient) { }

  // getAllCorporates():Observable<any>{
  //   this.access_token = localStorage.getItem('access_token');

  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': `Bearer ${this.access_token}`
  //   });
  //   this.url = baseUrl + "client/getAllCorporates";

  //   return this.http.get(this.url, { headers: headers });    
  // }

  saveCorporation(body: any):Observable<any>{
    this.url = baseUrl + "client/saveCorporation";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  editCorporation(body: any):Observable<any>{
    this.url = baseUrl + "client/editCorporation";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  getCorporation(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getCorporation/"+id;

    return this.http.get(this.url, { headers: headers });    
  }

  deleteCorporation(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/deleteCorporation/"+id;

    return this.http.get(this.url, { headers: headers });    
  }

  getAllCorporateUser(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getAllCorporateUser/"+id + "/1/100";

    return this.http.get(this.url, { headers: headers });
  }

  removeUserToCorporate(user_id: number, corp_id: number):Observable<any>{
    this.url = baseUrl + "client/removeUserToCorporate/"+user_id + "/" + corp_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {}, { headers: headers });     
  }



  addUserToCorporate(user_id:number, corp_id:number):Observable<any>{
    this.url = baseUrl + "client/addUserToCorporate/"+user_id + "/" + corp_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {}, { headers: headers });     
  }

  getCityList():Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getCityList";

    return this.http.get(this.url, { headers: headers });
  }
  
  getTownList(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "client/getTownList";

    return this.http.post(this.url, {"city_id": id}, { headers: headers });
  }

  getAllCorporateVehicles(id:number):Observable<any>{
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    this.url = baseUrl + "vehicle/getVehicleCorporation/" + id;

    return this.http.get(this.url, { headers: headers });
  }

  removeVehicleToCorporate(corp_id:number, vehicle_id:number):Observable<any>{
    this.url = baseUrl + "vehicle/removeVehicleFromCorporation";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {corporation_id: corp_id, vehicle_id: vehicle_id}, { headers: headers });     
  }
  
}
