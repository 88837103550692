<div class="electrip-form">
    <mat-dialog-content>
        <h2>Add New Vehicle</h2>
        <form [formGroup]="carForm" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="fill">
                <mat-label>Plate Number</mat-label>
                <input matInput required formControlName="number_plate">
            </mat-form-field>
      
            <div class="field-dynamic">
                <mat-form-field>
                    <mat-label>Brand</mat-label>
                    <mat-select formControlName="brand">
                        <mat-option *ngFor="let brand of brands" [value]="brand.id">
                            {{brand.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" color="primary" (click)="openBrand()">+</button>
            </div>
            <div class="field-dynamic">
                <mat-form-field>
                    <mat-label>Model</mat-label>
                    <mat-select formControlName="model">
                        <mat-option *ngFor="let model of models" [value]="model.id">
                            {{model.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" color="primary" (click)="openModel()">+</button>
            </div>
            
            <div class="field-dynamic">
                <mat-form-field>
                    <mat-label>Parking Place</mat-label>
                    <mat-select formControlName="parking_places">
                        <mat-option *ngFor="let model of parkingPlace" [value]="model.id">
                            {{model.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            
            <mat-form-field appearance="fill">
                <mat-label>Minimum License Year</mat-label>
                <input matInput formControlName="license_year">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Year</mat-label>
                <input matInput required formControlName="year">
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Colour</mat-label>
                <input matInput required formControlName="colour">
            </mat-form-field>

            
            <mat-form-field appearance="fill">
                <mat-label>Sitting Capacity</mat-label>
                <input matInput required formControlName="sitting_capacity">
            </mat-form-field>
    

            <mat-form-field appearance="fill">
                <mat-label>Daily Price</mat-label>
                <input matInput required formControlName="daily_price">
            </mat-form-field>
            
            <mat-form-field appearance="fill">
                <mat-label>Hourly Price</mat-label>
                <input matInput required formControlName="hourly_price">
            </mat-form-field>
            
            <mat-form-field appearance="fill">
                <mat-label>Minute Price</mat-label>
                <input matInput required formControlName="minute_price">
            </mat-form-field>
            
            <mat-form-field appearance="fill">
                <mat-label>Currency</mat-label>
                <input matInput required formControlName="currency">
            </mat-form-field>

          

            <button type="submit" mat-raised-button color="primary" [disabled]="!carForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>