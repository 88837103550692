import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-list-models',
  templateUrl: './list-models.component.html',
  styleUrls: ['./list-models.component.css']
})
export class ListModelsComponent implements OnInit {
  modelsForm = new FormGroup({
    model: new FormControl(),
  });
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: Array<any>,
    private dialogRef: MatDialogRef<ListModelsComponent>,
  ) { }

  ngOnInit(): void {

  }

  onSubmit(): void {
    this.dialogRef.close(this.data.find(v => v.id == this.modelsForm.get('model').value));
  }
}
