import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/services/users.service';
import { AddUserTagsComponent } from './add-user-tags/add-user-tags.component';

@Component({
  selector: 'app-user-tags',
  templateUrl: './user-tags.component.html',
  styleUrls: ['./user-tags.component.css']
})
export class UserTagsComponent implements OnInit {
  response_data: any;
  form_var: FormGroup = new FormGroup({});
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserTagsComponent>,
    private userService: UsersService,
    private globalService: GlobalService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.userService.getUserTags(this.data).subscribe(res => {
      var patch_json = {};
      res.data.forEach((element: any) => {
        patch_json[element.id] = element.have_tag
      });
      this.form_var = this.fb.group(patch_json);
      this.response_data =  res.data;
    },this.globalService.handleError);
  }

  submit_form(): void {
    this.userService.setTagsToUser(this.data, this.form_var).subscribe(res => {
      this.globalService.successMessage(res);
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }

  delete_tag(id: number): void {
    if(confirm("Are you sure ?")) {
      this.userService.deleteUserTags(id).subscribe(res => this.getData(), this.globalService.handleError);
    }
  }

  addNewTag(): void {
    this.dialog.open(AddUserTagsComponent).afterClosed().subscribe(data => {
      if(data) this.getData();
    })
  }
}
