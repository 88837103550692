import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalService } from 'src/app/services/global.service';
import { CorporateCardService } from 'src/app/services/users/documents/corporate-card.service';
import { CorporateCardDetailComponent } from './corporate-card-detail/corporate-card-detail.component';

@Component({
  selector: 'app-corporate-card',
  templateUrl: './corporate-card.component.html',
  styleUrls: ['./corporate-card.component.css']
})
export class CorporateCardComponent implements OnInit {
  dataSource: any;
  isLoading: boolean = true;
  displayedColumns: Array<any> = ["id", "name", "surname", "tc", "phone", "expiration_date"];
  pageEvent: PageEvent = {
    previousPageIndex: 0,
    pageSize: 10,
    pageIndex: 0,
    length: 0,
  };

  @ViewChild(MatPaginator) paginator: MatPaginatorIntl | undefined;
  @ViewChild(MatSort) sort: MatSort | undefined;

  constructor(
    private globalService: GlobalService,
    private dialog: MatDialog,
    private corporateCardService: CorporateCardService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(): void {
    this.isLoading = true;
    this.corporateCardService.getCorporateCards(this.pageEvent).subscribe({
      next: (result: any) => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource<any>(result.data);
        this.dataSource.sort = this.sort;
        this.pageEvent = { ...this.pageEvent, length: result.pagination.count };
      },
      error: this.globalService.handleError
    })
  }

  paginatorChange(event: PageEvent): void {
    localStorage.setItem('corporateCardPaginatorOptions', JSON.stringify(event));
    this.pageEvent = event;
    this.getData();
  }

  tableClick(row: any): void {
    this.dialog.open(CorporateCardDetailComponent, {
      autoFocus: false,
      width: "500px",
      maxWidth: "90%",
      data: row
    }).afterClosed().subscribe({
      next: (res: any) => {
        if(res) this.getData();
      }
    });
  }
}
