<div class="electrip-form">
    <mat-dialog-content>
        <h2>Add New Vehicle</h2>
        <form [formGroup]="vehicleStatusForm" (ngSubmit)="onSubmit()">
            <div class="field-dynamic">
                <mat-form-field>
                    <mat-label>Status Type</mat-label>
                    <mat-select formControlName="statusTypeId">
                        <mat-option *ngFor="let status_type of status_types" [value]="status_type.id">
                            {{status_type.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-raised-button type="button" color="primary" (click)="openStatusType()">+</button>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Status Start date</mat-label>
                <input matInput [matDatepicker]="statusStartDate" formControlName="statusStartDate" required>
                <mat-datepicker-toggle matSuffix [for]="statusStartDate"></mat-datepicker-toggle>
                <mat-datepicker #statusStartDate></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Status End date</mat-label>
                <input matInput [matDatepicker]="statusEndDate" formControlName="statusEndDate" required>
                <mat-datepicker-toggle matSuffix [for]="statusEndDate"></mat-datepicker-toggle>
                <mat-datepicker #statusEndDate></mat-datepicker>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!vehicleStatusForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>