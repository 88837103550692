import { Component, OnInit } from '@angular/core';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';

@Component({
  selector: 'app-charge-stations-detail',
  templateUrl: './charge-stations-detail.component.html',
  styleUrls: ['./charge-stations-detail.component.css']
})
export class ChargeStationsDetailComponent implements OnInit {

  stationIdExist: boolean = false;
  dataLoad: boolean = false;
  station: any;
  subscription: any;
  constructor(
    private mapControl: MapControlServiceService,
  ) {
    this.subscription = mapControl.selectedChargeID.subscribe((data:any) => {
      this.station = data;
      this.dataLoad = true;
      this.stationIdExist = true;
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
