<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2>Add New Additional Billing</h2>
        <form [formGroup]="billingForm" (ngSubmit)="onSubmit()" autocomplete="off">
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Reservation *</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Reservation" matInput formControlName="reservation" [matAutocomplete]="autoReservation">
                <mat-autocomplete #autoReservation="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredReservation | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Billing Date</mat-label>
                <input matInput [matDatepicker]="picker" formControlName="billing_date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div>
                <div class="billing-title">
                    <p>
                        Billing item selection 
                        <button type="button" mat-button matSuffix mat-icon-button (click)="addItem()"><mat-icon>add_circle</mat-icon></button>
                    </p>
                </div>
                <div>
                    <div *ngFor="let item of billingForm.value.items | keyvalue">
                        <p>
                            {{ item.value.name }} - {{ item.value.amount }} {{ item.value.service_fee?' + '+item.value.service_fee:''}} {{ item.value.discount_amount?' - '+item.value.discount_amount:''}}
                            <button type="button" mat-button matSuffix mat-icon-button (click)="removeItem(item.key)"><mat-icon>remove_circle</mat-icon></button>
                        </p>
                    </div>
                </div>
            </div>

            <mat-form-field>
                <mat-label>Note</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="note"></textarea>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!billingForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>