<div class="">

    <form [formGroup]="corporationForm" (ngSubmit)="onFormSubmit()" class="corpForm" autocomplete="off">
      
        <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input matInput placeholder="" value="" formControlName="name">
        </mat-form-field>
  
        <mat-form-field class="example-full-width">
            <mat-label>Address</mat-label>
            <input matInput placeholder="" value="" formControlName="address">
        </mat-form-field>
  
        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>City</mat-label>
            <input type="text" placeholder="Pick one" aria-label="City" matInput formControlName="city" [matAutocomplete]="autoCity">
            <mat-autocomplete #autoCity="matAutocomplete" [displayWith]="displayFn" (optionSelected)="city_change($event.option.value)">
                <mat-option *ngFor="let option of filteredCitys | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Town</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Town" matInput formControlName="town" [matAutocomplete]="autoTown">
            <mat-autocomplete #autoTown="matAutocomplete" [displayWith]="displayTn" >
                <mat-option *ngFor="let option of filteredTowns | async" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Contact Title</mat-label>
            <input matInput placeholder="" value="" formControlName="contact_title">
        </mat-form-field>

        <mat-form-field class="example-full-width">
            <mat-label>Contact Name Surname</mat-label>
            <input matInput placeholder="" value="" formControlName="contact_name_surname">
        </mat-form-field>
  
        <mat-form-field class="example-full-width">
            <mat-label>Contact Phone Number </mat-label>
            <input matInput placeholder="" value="" formControlName="contact_phone_number">
        </mat-form-field>
        
        <mat-form-field class="example-full-width">
            <mat-label>Phone Number</mat-label>
            <input matInput placeholder="" value="" formControlName="phone_number">
        </mat-form-field>
  
        <mat-form-field class="example-full-width">
            <mat-label>Tax Number</mat-label>
            <input matInput placeholder="" value="" formControlName="tax_number">
        </mat-form-field>
  
        <mat-form-field class="example-full-width">
            <mat-label>Tax Office</mat-label>
            <input matInput placeholder="" value="" formControlName="tax_office">
        </mat-form-field>
  
        <mat-form-field class="example-full-width">
            <mat-label>End with mail</mat-label>
            <input matInput placeholder="" value="" formControlName="mail_end">
        </mat-form-field>

        <div class="button">
            <button mat-raised-button color="primary" type="submit">Submit</button>
        </div>
    </form>

</div>
