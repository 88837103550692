import { AgmInfoWindow } from '@agm/core';
import { Component, OnInit, EventEmitter, Output, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VehicleService } from 'src/app/services/vehicle.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { ChargeStationsService } from 'src/app/services/charge-stations.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { GlobalService } from '../services/global.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  //map
  mapLat: any = 41.015137;
  mapLong: any =  28.979530;

  //car
  carFormControl = new FormControl('');
  allCarData: any;
  carData: any;
  carFilterData: Array<any> = [];
  allCarFilterData: Array<any> = [];
  carFilterFields: Array<object> = [
    {
      name: "Brand",
      getSelf: (data:any) => {
        return data.id;
      },
      getCar: (data:any) => {
        return data.brand.id;
      },
      push: (data:any, order:number) => {
        return {disabled:false,order:{id:order, name:"Brand"},id:data.brand.id, name: data.brand.name, value:['brand','id']};
      }
    },
    {
      name: "Model",
      getSelf: (data:any) => {
        return data.id;
      },
      getCar: (data:any) => {
        return data.model.id;
      },
      push: (data:any, order:number) => {
        return {disabled:false,order:{id:order, name:"Model"}, id:data.model.id, name: data.model.name, value:['model','id']};
      }
    },
    {
      name: "Color",
      getSelf: (data:any) => {
        return data.id;
      },
      getCar: (data:any) => {
        return data.colour;
      },
      push: (data:any, order:number) => {
        return {disabled:false,order:{id:order, name:"Color"}, id: data.colour, name: data.colour, value:['colour']}
      }
    },
    {
      name: "Year",
      getSelf: (data:any) => {
        return data.id;
      },
      getCar: (data:any) => {
        return data.year;
      },
      push: (data:any, order:number) => {
        return {disabled:false,order:{id:order, name:"Year"}, id: data.year, name: data.year, value:['year']}
      }
    },
    {
      name: "Status",
      getSelf: (data:any) => {
        return data.name;
      },
      getCar: (data:any) => {
        return data.status.name;
      },
      push: (data:any, order:number) => {
        return {disabled:false,order:{id:order, name:"Status"}, id:data.status.name, name: data.status.name, value:['status','name']};
      }
    },
  ]

  //park
  parkLocationsData: any;

  //charge
  chargeLocationsData: any;


  constructor(
    private mapControl: MapControlServiceService,
    private vehicleService: VehicleService,
    private parkingService: ParkingPlacesService,
    private chargeService: ChargeStationsService,
    private _router: Router,
    private globalService: GlobalService
  ){
    this.carFilterFields.forEach((element:any) => {
      this.carFilterData[element.name] = [];
    });
  }

  ngOnInit(): void {
    this.vehicleService.getAllVehicles().subscribe((result: any) => {
      if(result.success) {
        this.carData = result.data;
        this.allCarData = result.data;

        this.carFilterData = this.dataToFilterData(result.data, this.carFilterFields);
        this.allCarFilterData = this.carFilterData;
        
      }
    }, this.globalService.handleError);
    this.parkingService.getAllParkingPlaces().subscribe((result:any) => {
      if(result.success) {
        this.parkLocationsData = result.data;
      }
    }, this.globalService.handleError);

    this.chargeService.getAllChargeStations().subscribe((result:any) => {
      if(result.success) {
        this.chargeLocationsData = result.data;
      }
    }, this.globalService.handleError);

  }

  dataToFilterData(data:any, filterData:any): any {
    var carFilterData: Array<any> = [];
    this.carFilterFields.forEach((element:any) => {
      carFilterData[element.name] = [];
    });
    data.forEach((car:any) => {
      filterData.forEach((element:any) => {
        var isValueExist = false;
        carFilterData[element.name].forEach((filterEl:any) => {
          if(element.getSelf(filterEl) == element.getCar(car)) {
            isValueExist = true;
          }
        });
        if(!isValueExist) {
          carFilterData[element.name].push(element.push(car, carFilterData[element.name].length));
        }
      });
    });
    return carFilterData;
  }

  carFilterChange(item:any): void {
    var selectedFields = [];
    var cars = this.allCarData;
    item.forEach((element: any) => {
      var tempCars = [];
      selectedFields.push(element.order.name);
      for (let i = 0; i < this.carFilterData[element.order.name].length; i++) {
        if(i !== element.order.id) {
          this.carFilterData[element.order.name][i].disabled = true;
        }
      }
      cars.forEach((car:any) => {
        var tempCar = car;
        element.value.forEach((obPath:any) => {
          tempCar = tempCar[obPath];
        });
        if(tempCar == element.id) {
          tempCars.push(car);
        }
      });

      cars = tempCars;
    });
    Object.keys(this.carFilterData).forEach((filter:any) => {
      if(!selectedFields.includes(filter)) {
        this.carFilterData[filter].forEach((filterField:any) => {
          filterField.disabled = false;
        });
      }
    });
    this.carData = cars;
  }

  markerClicked(mark:any):void {
    console.log(mark);
  }

  onMouseOver(infoWindow: AgmInfoWindow): void {
    infoWindow.open();
  }
  onMouseOut(infoWindow: AgmInfoWindow): void {
    infoWindow.close();
  }

  markClick(uri:string): void {
    this._router.navigate([uri]);

  }


}

/*
export class DashboardComponent implements OnInit {
  latitude:number;
  longitude:number;
  marks: Array<object>;
  previousInfoWindow: AgmInfoWindow;
  map: any;
  selectBoxes: any;
  mapClickListener: any;
  carBrandNames: any;
  marksDefault:any;
  vehicleStatuTypes: any;

  constructor(
    private _router: Router, 
    private vehicleService: VehicleService, 
    private parkingPlaces: ParkingPlacesService, 
    private chargeStation: ChargeStationsService,
    private mapControl: MapControlServiceService,
    private zone: NgZone
    ) { 
      
    this.marks = [];
    this.selectBoxes = [];
    mapControl.onGetData.subscribe((data: any) => {
      //console.log("harita degisiyor", data);
      this.marks = data;
      this.marksDefault = data;
    });

    // mapControl.mapDefaultValue.subscribe((data:any) => {
    //   console.log(data);
    //   if(Object.keys(data).length > 0) {
    //     this.selectBoxChange(data);
    //   }
    //   else {
    //     this.carBrandNames = null;
    //   }
    // });

    mapControl.mapMark.subscribe((data:any) => {
      if(data!==null) {
        this.marks = data;
      }
      else {
        this.marks = this.marksDefault;
      }
    });

    mapControl.selectedCarID.subscribe((data:any) => {
      if(data['coordinate']) {
        this.marks = [data.coordinate];
        this.latitude = data.coordinate.lat;
        this.longitude = data.coordinate.long;
      }
    });

    mapControl.selectedParkID.subscribe((data:any) => {
      if(data['id']) {
        this.marks = [data];
        this.latitude = data.lat;
        this.longitude = data.long;
      }
    });

    mapControl.selectedChargeID.subscribe((data:any) => {
      if(data['city']) {
        this.marks = [data];
        this.latitude = data.lat;
        this.longitude = data.long;
      }
    });

    this.vehicleService.getAllStatusType().subscribe(result=> {
      if(result.success){
        this.vehicleStatuTypes = result.data;
      }
    });

  }

  selectBoxChange(data: any) {
    this.carBrandNames = [...new Set(data.map(x => x.brand?x.brand.name:undefined))];
  }


  brandClick(brandName:string) {
    if(brandName == "") {
      this.marks = this.marksDefault;
      return;
    }
    var a = this.vehicleStatuTypes;
    this.vehicleStatuTypes = [{}];
    this.vehicleStatuTypes = a;
    this.marks = this.marksDefault.filter((x:any) => {
      if(x.brand) {
        if(x.brand.name == brandName){
          return true;
        }
      }
    });
    return;
    var mapfilter = this.mapControl.mapFilter.value;
    mapfilter['brand'] = brandName;
    this.mapControl.mapFilter.next(mapfilter);
  }

  ngOnInit(): void {
    if(!this.mapControl.selectedCarID.value['id']) {
      this.latitude = 41.015137;
      this.longitude =  28.979530;
    }
    if(this._router.url === '/dashboard') {
      this.marksDefault = [];
      this.parkingPlaces.getAllParkingPlaces().subscribe(result => {
        if(result.success) {
          result.data.forEach((element: object) => {
            element['icon'] = "assets/icons/parking.png";
            element['category'] = "parking";
            this.marks.push(element);
            this.marksDefault.push(element);
          });
        }
        else {
        }
      });
      this.chargeStation.getAllChargeStations().subscribe(result => {
        if(result.success) {
          result.data.forEach((element: object) => {
            element['icon'] = "assets/icons/charging.png";
            element['category'] = "charge";
            this.marks.push(element);
            this.marksDefault.push(element);
          });
        }
        else {
        }
      });
      this.vehicleService.getAllVehicles().subscribe(result => {
        if(result.success) {
          result.data.forEach((element: any) => {
            element.coordinate['icon'] = "assets/icons/car.png";
            element['category'] = 'car';
            this.marks.push(element);
            this.marksDefault.push(element);
          });
          this.selectBoxChange(result.data);
          this.mapControl.mapDefaultValue.next(result.data);
        }
        else {
        }
      });
      
    }
  }

  markerClicked(infoWindow: AgmInfoWindow, mark: any) {
    if(mark.category == "car") {
      this.mapControl.selectedCarID.next(mark);
      this._router.navigate(['/dashboard/vehicles']);
    }
    else if(mark.category == "parking") {
      this.mapControl.selectedParkID.next(mark);
      this._router.navigate(['/dashboard/locations/parking-places']);
    }
    else if(mark.category == "charge") {
      this.mapControl.selectedChargeID.next(mark);
      this._router.navigate(['/dashboard/locations/charge-stations']);
    }

  }



  public mapReadyHandler(map: google.maps.Map): void {
    this.map = map;
    this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.mapControl.mapClick.emit({"lat":e.latLng.lat(), "long": e.latLng.lng()});
      });
    });
  }

  statuClick(statu:string):void {
    var mapfilter = this.mapControl.mapFilter.value;
    mapfilter['statu'] = statu;
    this.mapControl.mapFilter.next(mapfilter);
  }
  
  public ngOnDestroy(): void {
    if (this.mapClickListener) {
      this.mapClickListener.remove();
    }
  }

}
*/