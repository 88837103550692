<app-header></app-header>

<mat-drawer-container>
    <mat-drawer mode="side" opened>
        <app-sidebar></app-sidebar>
    </mat-drawer>
    <mat-drawer-content>
        <div [ngClass]="['router']">
            <router-outlet></router-outlet>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<app-footer></app-footer>