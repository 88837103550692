import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { ParkingPlacesService } from 'src/app/services/parking-places.service';
import { ParkLocationVehiclesAddComponent } from './park-location-vehicles-add/park-location-vehicles-add.component';

@Component({
  selector: 'app-park-location-vehicles',
  templateUrl: './park-location-vehicles.component.html',
  styleUrls: ['./park-location-vehicles.component.css']
})
export class ParkLocationVehiclesComponent implements OnInit {
  dataLoad: boolean;
  parkDetail: any;
  parkId: number;
  constructor(
    private dialog: MatDialog,
    private parkingService: ParkingPlacesService,
    private route: ActivatedRoute,
    private globalService: GlobalService
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe((uri:any) => {
      this.parkId = uri.id;
      this.getParkingPlaceByID();
    });
  }

  getParkingPlaceByID() {
    this.dataLoad = false;
    this.parkingService.getParkingPlaceVehicles(this.parkId).subscribe((result:any) => {
      
      if(result.success) {
        this.dataLoad = true;
        this.parkDetail = result.data;
        // this.park_location_image = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpeg;base64,' + result.data.image);
      }
    }, this.globalService.handleError);
  }

  addChargeStation() {
    this.dialog.open(ParkLocationVehiclesAddComponent, {
      autoFocus: false,
      data: this.parkId,
    }).afterClosed().subscribe(()=> {
      this.getParkingPlaceByID();
    });
  }

  
  removeChargeStation(id: number) {
    if(confirm("Are you sure ?")) {
      this.parkingService.removeParkingPlaceVehicle(this.parkId, id).subscribe((result:any) => {
        this.getParkingPlaceByID();
      }, this.globalService.handleError);
    }
  }
}
