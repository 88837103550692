<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <div class="header">
            <h2>{{ header }} Vehicle Segment</h2>
            <button *ngIf="data" type="button" mat-raised-button color="warn" (click)="delete(data)">Delete</button> 
        </div>
        <form [formGroup]="segmentForm" (ngSubmit)="onSubmit()" autocomplete="off">
          
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput required formControlName="name">
            </mat-form-field>

            <div *ngIf="data">
                <div class="item-title">
                    <p>
                        Models 
                        <button type="button" mat-button matSuffix mat-icon-button (click)="addItem()"><mat-icon>add_circle</mat-icon></button>
                    </p>
                </div>
                <div>
                    <div *ngFor="let item of segmentForm.value.items | keyvalue">
                        <p>
                            {{ item.value.name }}
                            <button type="button" mat-button matSuffix mat-icon-button (click)="removeItem(item.value)"><mat-icon>remove_circle</mat-icon></button>
                        </p>
                    </div>
                </div>
            </div>

            <button type="submit" mat-raised-button color="primary" [disabled]="!segmentForm.valid">Submit</button>


        </form>
    </mat-dialog-content>
</div>