<div class="panelInfo">
    <p class="panelDate">
        {{openedLicense.given_date}} - {{openedLicense.expiration_date}}
    </p>
    <mat-progress-bar mode="buffer" *ngIf="openedLicense?.id?false:true"></mat-progress-bar>
    <div *ngIf="openedLicense?.id?true:false">
        <div class="flex">
            <div class="driver-images width48">
                <img [src]="licenseFront" (click)="showImage(licenseFront)">
                <img [src]="licenseBack" (click)="showImage(licenseBack)">
            </div>
            <div class="width48">
                <table class="expandedTable">
                    <tr>
                        <td>File No</td>
                        <td>{{ openedLicense.file_no }}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{{ openedLicense.user.name }}</td>
                    </tr>
                    <tr>
                        <td>Surname</td>
                        <td>{{ openedLicense.user.surname }}</td>
                    </tr>
                    <tr>
                        <td>TCKN or Passport</td>
                        <td>{{ openedLicense.user.tckn }}</td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>{{ openedLicense.user.phone_number }}</td>
                    </tr>
                    <tr>
                        <td>Birth Date</td>
                        <td>{{ openedLicense.user.birth_date }}</td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>{{ openedLicense.user.email }}</td>
                    </tr>
                    <tr>
                        <td>Expiration Date</td>
                        <td>{{ openedLicense.expiration_date }}</td>
                    </tr>
                    <tr>
                        <td>Given Date</td>
                        <td>{{ openedLicense.given_date }}</td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>{{ openedLicense.status }}</td>
                    </tr>
                    <tr>
                        <td>Rejected Reason</td>
                        <td>{{ openedLicense.status_reason }}</td>
                    </tr>

                </table>
            </div>
        </div>
        <!-- <div class="panel-buttons" *ngIf="openedLicense?.status == 'Waiting'"> -->
        <div class="panel-buttons" >
            <button mat-raised-button color="warn" (click)="panelReject(openedLicense.id)">Rejected</button>
            <button mat-raised-button color="primary" (click)="panelAccept(openedLicense.id)">Approved</button>

        </div>


    </div>

</div>