import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';

@Component({
  selector: 'app-reservation-cancel',
  templateUrl: './reservation-cancel.component.html',
  styleUrls: ['./reservation-cancel.component.css']
})
export class ReservationCancelComponent implements OnInit {
  reservationForm = new FormGroup({
    refund_amount: new FormControl(0.0),
    get_paid: new FormControl(false),
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reservationService: ReservationsService,
    public dialogRef: MatDialogRef<ReservationCancelComponent>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.reservationService.refundReservation(this.data.id, this.reservationForm).subscribe((result:any) => {
      this.dialogRef.close(true);
    }, this.globalService.handleError);
  }

}
