<div class="vehicle-detail-tab">
    <div class="vehicle-detail-map">
        <agm-map [latitude]="vehicle_data?.lat" [longitude]="vehicle_data?.long">
            <agm-marker [latitude]="vehicle_data?.lat" [longitude]="vehicle_data?.long">
            </agm-marker>
        </agm-map>
    </div>
    <!-- 
    <div fxLayout="row" fxLayoutAlign="space-around start">
        <div>
            <ul>
                <li>Toplam KM: {{ vehicle_data?.continental.km }}</li>
                <li>Pil: {{ vehicle_data?.continental.charge }}</li>
                <li>Diag Speed: {{ vehicle_data?.continental.diag_speed }}</li>
                <li>Engine Start: {{ vehicle_data?.continental.engine_start }}</li>
                <li>İmmobilizer: {{ vehicle_data?.continental.immobilizer }}</li>
                <li>GSM sinyal gücü: {{ vehicle_data?.continental.qos }}</li>
                <li>Yaşam Batarya Voltajı: {{ vehicle_data?.continental.yasam_batarya_voltaji }}</li>
            </ul>

            <ul>
                <li>Fren Pedalı Konumu: {{ vehicle_data?.continental.fren_pedali_konumu }}</li>
                <li>Gaz Pedalı Konumu: {{ vehicle_data?.continental.gaz_pedali_konumu }}</li>
                <li>Hava Sıcaklığı: {{ vehicle_data?.continental.hava_sicakligi }}</li>
                <li>Motor Arıza Durumu: {{ vehicle_data?.continental.p2d }}</li>
                <li>Kontak Bilgisi: {{ vehicle_data?.continental.p7a }}</li>
            </ul>
        </div>
        <div>
            <ul>
                <li>Vites: {{ vehicle_data?.continental.p3e }}</li>
                <li>Vites Parkta: {{ vehicle_data?.continental.p4e }}</li>
                <li>Merkezi Kilit Konumu: {{ vehicle_data?.continental.p6e }}</li>
                <li>Şarjda: {{ vehicle_data?.continental.p7e }}</li>
                <li>Batarya Şarjı (L): {{ vehicle_data?.continental.p11 }}</li>
                <li>Batarya Sağlığı: {{ vehicle_data?.continental.p61 }}</li>
            </ul>
            <ul>
                <li>Sürücü Kapısı: {{ vehicle_data?.continental.p32 }}</li>
                <li>Yolcu Kapısı: {{ vehicle_data?.continental.p33 }}</li>
                <li>Sürücü Arka Kapı: {{ vehicle_data?.continental.p34 }}</li>
                <li>Yolcu Arka Kapı: {{ vehicle_data?.continental.p35 }}</li>
                <li>Bagaj: {{ vehicle_data?.continental.p36 }}</li>
                <li>Şarj Kapağı: {{ vehicle_data?.continental.p37 }}</li>
            </ul>
        </div>
        <div>
            <ul>
                <li>Speed {{ vehicle_data?.arac_engine_speed }}</li>
                <li>Toplam KM: {{ vehicle_data?.km }}</li>
                <li>Ortalama Hız</li>
                <li>Maximum Hız ve zaman</li>
            </ul>
        </div>
        <div>
            <ul>
                <li>Kalan Süre: {{ vehicle_data?.end_time }}</li>
                <li>Aracın Parka uzaklığı</li>
                <li>Şarj seviyesi: {{ vehicle_data?.arac_sarj_seviyesi }}</li>
                <li>Toplam KM: {{ vehicle_data?.arac_toplam_km }}</li>
            </ul>
  
        </div>
    </div>
    -->
    <div fxLayout="column" fxLayoutAlign=" none" fxLayoutGap="20px">
        <div class="log-table">
            <table>
                <tr>
                    <td colspan="4"><b fxLayoutAlign="center center">Logs</b></td>
                </tr>
                <tr *ngFor="let log of vehicle_data?.log">
                    <td>
                        <div fxLayoutAlign="center center">
                            ID: {{ log.id }}
                        </div>
                    </td>
                    <td>
                        <div fxLayoutAlign="center center">
                            Status:
                            <mat-icon *ngIf="log.status">lock</mat-icon>
                            <mat-icon *ngIf="!log.status">lock_open</mat-icon>
                        </div>
                    </td>
                    <td>
                        <div fxLayoutAlign="center center">
                            Work:
                            <mat-icon *ngIf="log.work">check_circle</mat-icon>
                            <mat-icon *ngIf="!log.work">clear</mat-icon>
                        </div>
                    </td>
                    <td>
                        <div fxLayoutAlign="center center">
                            {{ log.created_on }}
                        </div>
                    </td>
                </tr>
            </table>
        </div>
         
        <div fxLayout="row wrap"  fxLayoutGap="20px grid">
            <div >
                <button mat-raised-button color="primary" (click)="vehicleUnLock()" *ngIf="vehicle_data">Kapı
                    Açma</button>
            </div>
            <div >
                <button mat-raised-button color="primary" (click)="vehicleLock()" *ngIf="vehicle_data">Kapı
                    Kapatma</button>
            </div>
            <div >
                <button mat-raised-button color="primary" (click)="vehicleSuperUnLock()" *ngIf="vehicle_data">Kapı Açma
                    + Immobilizer Açma</button>
            </div>
            <div >
                <button mat-raised-button color="primary" (click)="vehicleSuperLock()" *ngIf="vehicle_data">Kapı Kapatma
                    + Immobilizer Kapatma</button>
            </div>
        </div>
    </div>
</div>