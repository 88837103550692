import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChargeStationsService } from 'src/app/services/charge-stations.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-charge-stations',
  templateUrl: './charge-stations.component.html',
  styleUrls: ['./charge-stations.component.css']
})
export class ChargeStationsComponent implements OnInit {
  panelListShow: boolean = true;
  showSearch: boolean = false;
  statusShow: boolean = true;

  locations: Array<any>;
  allLocations: Array<any>;

  selectedID: number;

  subscription:any;
  constructor(
    private chargeService: ChargeStationsService,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.getAllLocations();

    this.subscription = this.route.url.subscribe((url:any) => {
      this.route.firstChild?.params.subscribe((uri:any) => {
        this.selectedID = uri.id;
        this.subscription?.unsubscribe();
      });
    });
  }

  getAllLocations(): void {
    this.chargeService.getAllChargeStations().subscribe((result:any) => {
      if(result.success) {
        this.locations = result.data;
        this.allLocations = result.data;
      }
    }, this.globalService.handleError);

  }

  onSearchChange(event: any):void {
    if(event.target.value == "") {
      this.locations = this.allLocations;
    }
    this.locations = this.allLocations.filter(x=> x.name?x.name.toLowerCase().includes(event.target.value.toLowerCase()):false);
  }

  listSort(query:any): void {
    this.locations?.sort((a:any,b:any) => {
      var temp_a = a;
      var temp_b = b;
      query.id.forEach((element: any) => {
        temp_a = temp_a[element];
        temp_b = temp_b[element];
      });

      if(temp_a.toLowerCase() < temp_b.toLowerCase()) {
        return query.value?-1:1;
      }
      if(temp_a.toLowerCase() > temp_b.toLowerCase()) {
        return query.value?1:-1;
      }
      return 0;
    });
  }

  closeSearch(): void {
    this.locations = this.allLocations;
  }
}
