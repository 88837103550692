import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { VehicleService } from 'src/app/services/vehicle.service';
import { AgmCoreModule } from '@agm/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-vehicle-detail-tab',
  templateUrl: './vehicle-detail-tab.component.html',
  styleUrls: ['./vehicle-detail-tab.component.css']
})
export class VehicleDetailTabComponent implements OnChanges {
  @Input() rez_id: number;
  vehicle_data: any;

  constructor(
    private vehicleService: VehicleService,
    private globalService: GlobalService
  ) { }

  getVehicleInfo(): void {
    this.vehicleService.vehicleInfo(this.rez_id).subscribe((result) => {
      this.vehicle_data = result.data;
      
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getVehicleInfo();
  }

  vehicleLock(): void {
    this.vehicleService.vehicleLock(this.vehicle_data.id).subscribe(this.globalService.successMessage, this.globalService.handleError);
    setTimeout(() => {
      this.getVehicleInfo();
    }, 5000);
  }

  vehicleSuperLock(): void {
    this.vehicleService.vehicleSuperLock(this.vehicle_data.id).subscribe(this.globalService.successMessage, this.globalService.handleError);
    setTimeout(() => {
      this.getVehicleInfo();
    }, 5000);
  }

  vehicleSuperUnLock(): void {
    this.vehicleService.vehicleSuperUnLock(this.vehicle_data.id).subscribe(this.globalService.successMessage, this.globalService.handleError);
    setTimeout(() => {
      this.getVehicleInfo();
    }, 5000);
  }

  vehicleUnLock(): void {
    this.vehicleService.vehicleUnLock(this.vehicle_data.id).subscribe(this.globalService.successMessage, this.globalService.handleError);
    setTimeout(() => {
      this.getVehicleInfo();
    }, 5000);
  }

}
