import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  is_superuser: boolean;
  constructor() { }

  ngOnInit(): void {
    this.is_superuser = localStorage.getItem('is_superuser') == 'true' ? true : false;
  }

}
