import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/services/global.service';
import { MapControlServiceService } from 'src/app/services/map-control-service.service';
import { ReservationsService } from 'src/app/services/triggers/reservations.service';
import { UsersService } from 'src/app/services/users.service';

@Component({
  selector: 'app-driver-reservations-detail',
  templateUrl: './driver-reservations-detail.component.html',
  styleUrls: ['./driver-reservations-detail.component.css']
})
export class DriverReservationsDetailComponent implements OnInit {
  reservation:any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UsersService,
    private reservationService: ReservationsService,
    private mapControl: MapControlServiceService,
    private globalService: GlobalService
    ) { 

  }

  ngOnInit(): void {
    this.getResDetail();
  }

  getResDetail(): void {
    this.userService.getReservationDetail(this.data).subscribe((result:any) => {
      
      this.reservation = result.data.data;
    }, this.globalService.handleError); 
  }

  reservationStatusChange(id: number, status: string): void {
    this.reservationService.setReservationStatus(id, status).subscribe((result:any) => {
      if(result.success) {
        this.mapControl.showSnackbar("ID: " + result.data.reservationId + " Reservation Status Update.");
        this.getResDetail();
      }
    }, this.globalService.handleError);
  }

}
