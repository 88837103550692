import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-documents-detail',
  templateUrl: './user-documents-detail.component.html',
  styleUrls: ['./user-documents-detail.component.css']
})
export class UserDocumentsDetailComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserDocumentsDetailComponent>

  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  ngOnDestroy(): void {
    this.dialogRef.close(this.data);
  }

  status_change({process, status}): void {
    this.data[process] = status;
    console.log(this.data);
  }

}
