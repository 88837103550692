import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class UsersService {
  access_token:string = "";
  url:string = "";

  constructor(private http: HttpClient,
    private _snackBar: MatSnackBar,
    private zone: NgZone
    ) { }

  getAllUsers():Observable<any>{
    this.url = baseUrl + "client/getAllUsers/1/100";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.get(this.url, { headers: headers });    
  }

  searchUser(value:string):Observable<any>{
    this.url = baseUrl + "client/getAllUsers/1/100/"+value;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.get(this.url, { headers: headers });    
  }
  
  getUser(user_id:any):Observable<any>{
    this.url = baseUrl + "client/getUserById/" + user_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });
  }

  saveUser(body: any):Observable<any>{
    this.url = baseUrl + "client/saveUser";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers });     
  }

  editUser(body: any):Observable<any>{
    this.url = baseUrl + "client/editUserNew";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, body, { headers: headers })
  }

  getUserDocuments(user_id: number):Observable<any>{
    this.url = baseUrl + "client/userDocuments";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {'user_id':user_id}, { headers: headers });
  }

  getReservation(user_id: number): Observable<any> {
    this.url = baseUrl + "reservation/getUserReservations/"+user_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  
  getFeaturedReservation(user_id: number): Observable<any> {
    this.url = baseUrl + "reservation/getFeaturedReservationsByUser/"+user_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }


  getReservationDetail(user_id: number): Observable<any> {
    this.url = baseUrl + "reservation/detail/"+user_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }


  getReservationFeatureDetail(user_id: number): Observable<any> {
    this.url = baseUrl + "reservation/vehicleFutureReservations/"+user_id;
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.get(this.url, { headers: headers });    
  }

  saveNotification(user_id: number, title: string, message: string):Observable<any>{
    this.url = baseUrl + "client/saveNotification";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {'user_id':user_id, 'title':title, 'message': message}, { headers: headers });
  }

  sendFirebaseMessageAll(title: string, message: string):Observable<any>{
    this.url = baseUrl + "client/sendFirebaseMessageAll";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {'title':title, 'message': message}, { headers: headers });
  }
  
  getNotification(user_id: number):Observable<any>{
    this.url = baseUrl + "client/getNotificationsById";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {'userId':user_id}, { headers: headers });
  }
  
  delNotification(id: number):Observable<any>{
    this.url = baseUrl + "client/deleteNotification";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    })

    return this.http.post<any>(this.url, {'notification_id':id}, { headers: headers });
  }
  
  getCitizenTypes(): Observable<any> {
    this.url = baseUrl + "client/getAllCitizenshipTypes";
    this.access_token = localStorage.getItem('access_token');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.get(this.url, { headers: headers });    
  }

  addCitizenType(body: FormGroup):Observable<any>{
    this.url = baseUrl + "client/saveCitizenshipType";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, body.value, { headers: headers });
  }

  userActiveChange(user_id: number, status: string): Observable<any> {
    this.url = baseUrl + "client/userStatus" + status + "/" + user_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  getDeactiveUsers(): Observable<any> {
    this.url = baseUrl + "client/usersDeactiveList";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  getUserList(): Observable<any> {
    this.url = baseUrl + "client/getUserListNumbers";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }
  
  sendMessage(body: FormGroup):Observable<any>{
    this.url = baseUrl + "client/sendMessageToUser";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, body.value, { headers: headers });
  }
  
  sendWelcomeEmail(user_id: number): Observable<any>{
    this.url = baseUrl + "client/sendUserEmail";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"user_id": user_id, "mail": "welcome"}, { headers: headers });
  }

  
  
  userCancelDeactive(user_id: number): Observable<any>{
    this.url = baseUrl + "client/userCancelDeactive";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });

    return this.http.post<any>(this.url, {"user_id": user_id}, { headers: headers });
  }

  getUserLogs(user_id): Observable<any> {
    this.url = baseUrl + "client/user/"+user_id+"/license_history";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  getAccountDetail(user_id): Observable<any> {
    this.url = baseUrl + "payment/userAccountDetail/"+user_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  getCorporateAccountDetail(corp_id): Observable<any> {
    this.url = baseUrl + "payment/corporateAccountDetail/"+corp_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  getUserTags(user_id: number): Observable<any> {
    this.url = baseUrl + "client/getUserTags/"+user_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  addUserTags(form: FormGroup): Observable<any> {
    this.url = baseUrl + "client/addUserTag";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.post(this.url, form.value, { headers: headers });
  }

  deleteUserTags(tag_id: number): Observable<any> {
    this.url = baseUrl + "client/deleteUserTag/"+tag_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers });
  }

  setTagsToUser(user_id: number, tag_form: FormGroup): Observable<any> {
    this.url = baseUrl + "client/setTagsToUser/"+user_id;
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.post(this.url, tag_form.value, { headers: headers });
  }

  getUserTable(page_size, current_page): Observable<any> {
    this.url = baseUrl + "client/userTable";
    this.access_token = localStorage.getItem('access_token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.access_token}`
    });
    return this.http.get(this.url, { headers: headers, params: {page: current_page, length: page_size} });
  }


  
}
