
import { Time } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { baseUrl } from 'src/environments/environment';
import { GlobalService } from './services/global.service';

@Injectable({
providedIn: 'root'
})

export class AuthguardGuard implements CanActivate {
    constructor(private router: Router, private _router: Router,private http:HttpClient, private globalService: GlobalService) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const routeurl: string = state.url;
        
        return this.isLogin(routeurl);
    }

    isLogin(routeurl: string) {
        var access_token = localStorage.getItem("access_token");
        if(window.location.pathname == "/") {
            console.log("pathname",window.location.pathname);
            return true;
        }
        if(!access_token) {
            this._router.navigate(['/']);
        }
        
        var expires_date:number = +localStorage.getItem("expires_date");
        var now = new Date();
        var expiresDate = new Date(expires_date);
        if(now.getTime() < expiresDate.getTime()) {
            return true;
        }
        else {
            var refresh_token = localStorage.getItem("refresh_token");
            console.log(refresh_token);
            console.log("local",localStorage);
            if(refresh_token === "undefined" || !refresh_token) {
                window.location.href = "/";
                return false;
            }
            var client_id = 'ef6ItkG2OyIyMcYvUZ5AncnTdh5MeTn4BW17deFZ';
            var client_secret = 'XwMJaxPvbIHVtIRaRc9mngvuXUgcKOljtWSzluWWhKPfr0SKccE8nttO4ia3SXB525dUzyFmPo8qilAwb8MgPyOAsv4dQOBILzeFqBfTOO6d8s66WpxXkxrG31dCup1S';
            this.http.post(`${baseUrl}client/refreshToken`, {'clientIp': client_id,'clientSecret': client_secret,'refreshToken': refresh_token}).subscribe((data:any)=> {
                localStorage.setItem("access_token", data.data.access_token);
                localStorage.setItem("refresh_token", data.data.refresh_token);

                var currentTime = new Date();
                currentTime = new Date(currentTime.setSeconds(data?.data?.expires_in));

                localStorage.setItem("expires_date", currentTime.getTime().toString());
                this._router.navigate(['dashboard/']);
            }, this.globalService.handleError);
        }

    }
}
