<div class="electrip-form">
    <mat-progress-bar mode="buffer" *ngIf="loading"></mat-progress-bar>

    <mat-dialog-content *ngIf="!loading">
        <h2><span *ngIf="data">Edit</span><span *ngIf="!data">Add New</span>  Park Location</h2>
        <form [formGroup]="parkLocationForm" (ngSubmit)="onSubmit()" autocomplete="NoAutocomplete">

            <mat-form-field>
                <input matInput type="text" placeholder="Name" formControlName="name">
            </mat-form-field>

            <mat-form-field>
                <input matInput type="number" placeholder="Car Capacity" formControlName="car_capacity">
            </mat-form-field>

            <mat-form-field>
                <input matInput type="text" placeholder="Lat" formControlName="lat">
            </mat-form-field>
            
            <mat-form-field>
                <input matInput type="text" placeholder="Long" formControlName="long">
            </mat-form-field>

            <mat-form-field>
                <input matInput type="text" placeholder="Image Url" formControlName="image">
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>City *</mat-label>
                <input type="text" name="city" placeholder="Pick one" aria-label="City" matInput formControlName="city_id" [matAutocomplete]="autoCity" autocomplete="NoAutocomplete">
                <mat-autocomplete  #autoCity="matAutocomplete" [displayWith]="displayFn" (optionSelected)="city_change($event.option.value)">
                    <mat-option *ngFor="let option of filteredCitys | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            
            <mat-form-field class="example-full-width" appearance="fill">
                <mat-label>Town *</mat-label>
                <input type="text" name="town" placeholder="Pick one" aria-label="Town" matInput formControlName="town_id" [matAutocomplete]="autoTown" autocomplete="NoAutocomplete">
                <mat-autocomplete #autoTown="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredTowns | async" [value]="option">
                        {{option.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            

            <mat-form-field>
                <input matInput type="text" placeholder="DistrictCode" formControlName="district_code">
            </mat-form-field>
            
            <mat-form-field>
                <mat-label>Address</mat-label>
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" formControlName="address"></textarea>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary" [disabled]="!parkLocationForm.valid">Submit</button>

        </form>
    </mat-dialog-content>
</div>